import { Accordion, Flex, Heading, UnorderedList } from '@chakra-ui/react';
import { Map } from 'immutable';
import { FormattedMessage } from 'react-intl';

import { SimpleEntityList, VStack } from 'components';
import { DamageEnergyMechanism } from 'entities';

import { DamageEnergyCard } from './components';

export function Scenarios({ riskUUID }: { riskUUID: string }) {
  return (
    <Flex
      layerStyle="cardLike"
      sx={{ gap: 4, border: 0, padding: 0, marginTop: 8 }}
    >
      <VStack as="section">
        <Heading as="h3">
          <FormattedMessage
            id="global-framework.scenarios.title"
            defaultMessage="Scenarios"
          />
        </Heading>
      </VStack>

      <VStack as="section">
        <Accordion
          as={UnorderedList}
          defaultIndex={[0, 1, 2]}
          allowMultiple
          layerStyle="nakedList"
        >
          <SimpleEntityList
            Entity={DamageEnergyMechanism}
            RecordComponent={DamageEnergyCard}
            params={Map({ damage_energy__risk: riskUUID })}
          />
        </Accordion>
      </VStack>
    </Flex>
  );
}
