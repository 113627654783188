import type { List } from 'immutable';

import { VStack } from 'components';
import { type RAOPickerProps, RAOPickerRadio } from 'components/widgets';
import type { SiteCompliancePlanEntityRecord } from 'entities/api/CompliancePlan/SiteCompliancePlan';
import type { EntityFormProps } from 'types';

import useLocationFormInterface from './SiteComplianceGRCForm.hooks';

export type SiteComplianceGRCFormProps = EntityFormProps<
  List<SiteCompliancePlanEntityRecord>
> &
  RAOPickerProps;

/**
 * Serves to add/remove operations from sitecomplianceplan_set
 */
export const SiteComplianceGRCForm = ({
  baseParams,
  ...rest
}: SiteComplianceGRCFormProps) => {
  const { operations, onChangeOperations } = useLocationFormInterface(rest);

  return (
    <VStack>
      <RAOPickerRadio
        name="__intermediate_operations"
        value={operations}
        onChange={onChangeOperations}
        baseParams={baseParams}
        disabled={rest.disabled}
      />
    </VStack>
  );
};
