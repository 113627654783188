import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { type SingleValue } from 'react-select';

import { useLocale } from 'locales/useLocale.tsx';

import type { CompliancePlanSelectorProps } from './ControlSelector.tsx';
import { COMPLIANCE_TABS } from '../../tabs/COMPLIANCE_TABS.ts';

export function useControlSelector({
  subTabSegment,
  value: applicableControlsForPlan,
}: CompliancePlanSelectorProps) {
  const { toString } = useLocale();
  const navigate = useNavigate();
  const {
    compliancePlanUuid: compliancePlanInfoUuid,
    siteCompliancePlanUuid,
    controlUuid,
  } = useParams();

  const navigateToControl = useCallback(
    (applicableControlUuid: string | undefined) =>
      navigate(
        `/${COMPLIANCE_TABS.base}/${compliancePlanInfoUuid}/site/${siteCompliancePlanUuid}/${subTabSegment}/control/${applicableControlUuid}`,
      ),
    [compliancePlanInfoUuid, navigate, siteCompliancePlanUuid, subTabSegment],
  );

  // auto-navigate to the first Control if no controlUuid is provided
  useEffect(() => {
    if (!controlUuid && applicableControlsForPlan?.size) {
      const firstControlUuid = applicableControlsForPlan?.first()?.get('uuid');
      if (firstControlUuid) navigateToControl(firstControlUuid);
    }
  }, [applicableControlsForPlan, navigateToControl, controlUuid]);

  const options =
    applicableControlsForPlan
      ?.map((rec) => ({
        value: rec?.get('uuid'),
        label: toString(rec?.get('global_control')),
      }))
      ?.toArray() ?? [];

  const onChange = (
    newValue: SingleValue<{ value: string; label: string }>,
  ) => {
    if (!newValue) return;
    navigateToControl(newValue.value);
  };

  const selectedOption = controlUuid
    ? options.find((option) => option.value === controlUuid)
    : null;
  return { onChange, options, selectedOption };
}
