import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

class CompliancePlanReportEntity extends Entity {
  paginated = false;

  static paths = {
    apiBase: '/cube/v1/reporting/compliance_plan_report/',
  };

  static fields: EntityFields<CompliancePlanReportEntityFields> = {
    plans_in_progress: new Fields.NumberField({
      default: 0,
    }),
    controls_total: new Fields.NumberField({
      default: 0,
    }),
    controls_assessed: new Fields.NumberField({
      default: 0,
    }),
    gaps: new Fields.NumberField({
      default: 0,
    }),
    actions: new Fields.NumberField({
      default: 0,
    }),
  };
}

export type CompliancePlanReportEntityFields = {
  plans_in_progress: number;
  controls_total: number;
  controls_assessed: number;
  gaps: number;
  actions: number;
};

export type CompliancePlanReportEntityRecord =
  EntityRecord<CompliancePlanReportEntityFields>;

CompliancePlanReportEntity.duck = new DjangoRestFramework({
  app: 'Reports',
  entity: CompliancePlanReportEntity,
  name: 'CompliancePlanReport',
});

export default CompliancePlanReportEntity;
