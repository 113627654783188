import { Flex } from '@chakra-ui/react';
import { useMemo } from 'react';

import type { TreePickerWrapperProps } from 'components/widgets/TreePicker/DefaultComponents';
import { useTreePicker } from 'components/widgets/TreePicker/TreePicker.hooks';

/** Renders at least three levels of lists */
export const Wrapper = ({ children, ...props }: TreePickerWrapperProps) => {
  const { parents, listComponent } = useTreePicker();

  const ExtraList = listComponent;

  const REQUIRED_COL_COUNT = 3;
  const extraLists = useMemo(() => {
    const extraListCount = REQUIRED_COL_COUNT - (parents?.size ?? 0);
    return [...Array(extraListCount > 0 ? extraListCount : 0)];
  }, [parents]);

  return (
    <Flex {...props}>
      {children}

      {
        // render empty lists for stable layout
        extraLists.map((_, i) => (
          // @ts-expect-error Type 'undefined' is not assignable to type 'ElementType'.
          <ExtraList key={i} level={parents?.size + i} />
        ))
      }
    </Flex>
  );
};
