import { Flex } from '@chakra-ui/react';
import { type List, Map } from 'immutable';
import { Outlet, useOutletContext } from 'react-router';

import { useQuery } from '@burnsred/entity-duck-query';
import { VStack } from 'components';
import { ApplicableControlForPlanListEntity } from 'entities/api/CompliancePlan';
import type { ApplicableControlForPlanListEntityRecord } from 'entities/api/CompliancePlan/ApplicableControlForPlanListOnly';
import type { WithQueryReturnProps } from 'types';

import { type SiteCompliancePlanContext } from '..';

export type BaselineAssessmentContext = SiteCompliancePlanContext & {
  applicableControlsForPlanFormProps: WithQueryReturnProps<
    List<ApplicableControlForPlanListEntityRecord>
  >;
};

export function BaselineAssessment() {
  const parentContext = useOutletContext<SiteCompliancePlanContext>();
  const siteCompliancePlanUuid =
    parentContext.siteCompliancePlanFormProps.value?.get('uuid');

  const applicableControlsForPlanFormProps = useQuery<
    List<ApplicableControlForPlanListEntityRecord>
  >({
    action: ApplicableControlForPlanListEntity.duck.actions.get({
      params: Map({ site_compliance_plan: siteCompliancePlanUuid }),
    }),
  });

  const context: BaselineAssessmentContext = {
    ...parentContext,
    applicableControlsForPlanFormProps,
  };

  return (
    <Flex layerStyle="cardLike" sx={{ paddingX: 0 }}>
      <VStack layerStyle="loadingWrapper">
        <Outlet context={context} />
      </VStack>
    </Flex>
  );
}
