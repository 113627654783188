import type { List, Map } from 'immutable';
import { useSelector } from 'react-redux';

import type { Entity as EntityType } from '@burnsred/entity';
import type { BasicEntityRecord, EntityRecord } from 'types';

type PaginationEntityFields<T = BasicEntityRecord> = {
  count: number;
  next: string;
  num_pages: number;
  previous: string;
  results: List<T>;
};

export type PaginationEntityRecord<T = BasicEntityRecord> = EntityRecord<
  PaginationEntityFields<T>
>;

/**
 * Returns a PaginationEntityRecord (total `count` of results, `num_pages`, etc)
 * for the given Entity and params.
 *
 * @example
 *
 * ```tsx
 * const [page, setPage] = useState<string>('1');
 * const [page_size, setPageSize] = useState<string>(DEFAULT_PAGE_SIZE);
 *
 * // key detail: params are kept in sync between `useQuery` and `usePagination`
 * const params = useMemo(
 *   () => fromJS({ page, page_size }),
 *   [page, page_size],
 * );
 *
 * const { value } = useQuery<List<MyEntityRecord>>({
 *   action: MyEntity.duck.actions.get({ params }),
 * });
 *
 * const pagination = usePagination<MyEntityRecord>(MyEntity, params);
 * ```
 */
export function usePagination<
  T extends Map<string, unknown> = BasicEntityRecord,
>(Entity: typeof EntityType, params: Map<string, string>) {
  return useSelector<unknown, PaginationEntityRecord<T>>((state) =>
    Entity.duck.selectors.pagination(state, { params }),
  );
}
