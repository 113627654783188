import { List } from 'immutable';
import type { Moment } from 'moment/moment';

import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { axiosInstance } from 'api';
import { type EntityFields, type EntityRecord } from 'types';

import BaselineAssessmentEntity, {
  type BaselineAssessmentEntityRecord,
  type BaselineAssessmentEntityRecordWithIndex,
} from './BaselineAssessment';
import { type BaselineAssessmentQuestionEntityRecord } from './BaselineAssessmentQuestion';
import {
  type SiteEquipmentOperatingContextEntityRecord,
  type SiteEquipmentOperatingContextEntityTreeRecord,
} from './SiteEquipmentOperatingContext';
import { createImmutableSelector, getDescendants } from '../abstract';
import ApplicableRuleEntity, {
  type ApplicableRuleEntityRecord,
} from '../ApplicableRule';
import type { ControlEntityRecord } from '../Control';
import ControlEntity from '../Control';
import { toString } from '../i18n/I18nText';
import { type OperatingContextEntityRecord } from '../OperatingContext';

const MANDATORYTEMPLATE = BaselineAssessmentEntity.dataToRecord({});
const ASESSTEMPLATE = BaselineAssessmentEntity.dataToRecord({
  is_mandatory: false,
});

class ApplicableControlForPlanEntity extends Entity {
  static paginated = true;

  static paths = {
    // note: we only anticipate using the list view here
    apiBase: '/cube_compliance/v1/applicable_controls/',
  };

  static fields: EntityFields<ApplicableControlForPlanEntityFields> = {
    uuid: new Fields.IdField({ blank: true }),
    global_control: new Fields.EntityField({
      entity: ControlEntity,
      blank: true,
    }),
    applicable_rules: new Fields.EntityField({
      entity: ApplicableRuleEntity,
      many: true,
      blank: true,
    }),
    baseline_assessments: new Fields.EntityField({
      entity: BaselineAssessmentEntity,
      cleaners: [
        (record: BaselineAssessmentEntityRecord) =>
          BaselineAssessmentEntity.clean(record),
      ],
      many: true,
      blank: true,
    }),

    created: new Fields.DateField(),
    modified: new Fields.DateField(),
  };

  static toString = toString<ApplicableControlForPlanEntityRecord>;

  static getMandatoryAssessments = createImmutableSelector(
    [
      (record: ApplicableControlForPlanEntityRecord) =>
        record.get('baseline_assessments'),
    ],
    (baseline_assessments) => {
      return baseline_assessments.filter((assessment) =>
        assessment.get('is_mandatory'),
      );
    },
  );

  static getAssessAssessments = createImmutableSelector(
    [
      (record: ApplicableControlForPlanEntityRecord) =>
        record.get('baseline_assessments'),
    ],
    (baseline_assessments) => {
      return baseline_assessments.filter(
        (assessment) => !assessment.get('is_mandatory'),
      );
    },
  );

  static getEmptyAssessments = createImmutableSelector(
    [
      (record: ApplicableControlForPlanEntityRecord) =>
        record.get('baseline_assessments'),
    ],
    (baseline_assessments) => {
      return baseline_assessments.filter(
        (assessment) => assessment.get('answers').size === 0,
      );
    },
  );

  static shouldHaveAssess = createImmutableSelector(
    [
      (record: ApplicableControlForPlanEntityRecord) =>
        record.get('applicable_rules'),
    ],
    (applicable_rules) => {
      return applicable_rules.some((rule) => !rule.get('is_mandatory'));
    },
  );

  static shouldHaveMandatory = createImmutableSelector(
    [
      (record: ApplicableControlForPlanEntityRecord) =>
        record.get('applicable_rules'),
    ],
    (applicable_rules) => {
      return applicable_rules.some((rule) => rule.get('is_mandatory'));
    },
  );

  static getRules = createImmutableSelector(
    [
      (record: ApplicableControlForPlanEntityRecord, _is_mandatory: boolean) =>
        record.get('applicable_rules'),
      (_record: ApplicableControlForPlanEntityRecord, is_mandatory: boolean) =>
        is_mandatory,
    ],
    (applicable_rules, is_mandatory) => {
      return applicable_rules.filter(
        (rule) => rule.get('is_mandatory') === is_mandatory,
      );
    },
  );

  static filterOperatingContextsForAssessmentType = createImmutableSelector(
    [
      (
        record: ApplicableControlForPlanEntityRecord,
        _operatingContexts: List<OperatingContextEntityRecord>,
        is_mandatory: boolean,
      ) =>
        ApplicableRuleEntity.getIDsForModel(
          ApplicableControlForPlanEntity.getRules(record, is_mandatory),
          'operatingcontext',
        ),
      (
        _record: ApplicableControlForPlanEntityRecord,
        operatingContexts: List<OperatingContextEntityRecord>,
        _is_mandatory: boolean,
      ) => operatingContexts,
    ],
    (model_ids, operatingContexts) => {
      return operatingContexts?.filter((operatingContext) =>
        model_ids.includes(operatingContext.get('uuid')),
      );
    },
  );

  static getEquipmentOptions = createImmutableSelector(
    [
      (
        record: ApplicableControlForPlanEntityRecord,
        _equipmentList: List<SiteEquipmentOperatingContextEntityRecord>,
        _equipmentTree: List<SiteEquipmentOperatingContextEntityTreeRecord>,
        is_mandatory = true,
      ) =>
        ApplicableRuleEntity.getIDsForModel(
          ApplicableControlForPlanEntity.getRules(record, is_mandatory),
          'equipment',
        ),
      (
        record: ApplicableControlForPlanEntityRecord,
        _equipmentList: List<SiteEquipmentOperatingContextEntityRecord>,
        _equipmentTree: List<SiteEquipmentOperatingContextEntityTreeRecord>,
        is_mandatory = true,
      ) =>
        ApplicableRuleEntity.getIDsForModel(
          ApplicableControlForPlanEntity.getRules(record, is_mandatory),
          'operatingcontext',
        ),
      (
        _record: ApplicableControlForPlanEntityRecord,
        equipmentList: List<SiteEquipmentOperatingContextEntityRecord>,
        _equipmentTree: List<SiteEquipmentOperatingContextEntityTreeRecord>,
        _is_mandatory = true,
      ) => equipmentList,
      (
        _record: ApplicableControlForPlanEntityRecord,
        _equipmentList: List<SiteEquipmentOperatingContextEntityRecord>,
        equipmentTree: List<SiteEquipmentOperatingContextEntityTreeRecord>,
        _is_mandatory = true,
      ) => equipmentTree,
    ],
    (
      equipmentIdsFromMatchedRules,
      operatingContextIdsFromMatchedRules,
      equipmentList,
      equipmentTree,
    ) => {
      let ruleLeafEquipmentList = equipmentList
        ?.filter((equipment) =>
          equipmentIdsFromMatchedRules.includes(
            equipment.get('compliance_equipment').get('equipment').get('uuid'),
          ),
        )
        .filter((item) => !item?.get('operating_contexts')?.isEmpty())
        .filter(
          (item) =>
            !item
              ?.get('operating_contexts')
              .filter((operatingContext) => {
                return operatingContextIdsFromMatchedRules.includes(
                  operatingContext.get('uuid'),
                );
              })
              .isEmpty(),
        );
      /**
       * https://burnsred.atlassian.net/browse/BC-1257
       *
       * Need to make sure we don't include equipment that is a parent of a rules equipment
       * since the descendants could introduce equipment that isn't part of the rules equipment list.
       *
       * Descendants need to be evaluated at the leaf level of the rule.
       */
      ruleLeafEquipmentList = ruleLeafEquipmentList.filter((equipment) => {
        return ruleLeafEquipmentList
          .filter((item) => {
            const equip_id = equipment
              .get('compliance_equipment')
              .get('equipment')
              .get('uuid');
            const parent = item
              .get('compliance_equipment')
              .get('equipment')
              .get('parent');
            return equip_id === parent;
          })
          .isEmpty();
      });
      return (
        (ruleLeafEquipmentList
          ?.filter((equipment) =>
            equipmentIdsFromMatchedRules.includes(
              equipment
                .get('compliance_equipment')
                .get('equipment')
                .get('uuid'),
            ),
          )
          // Remap to List of descendant tree nodes
          .map((equipment) => {
            return getDescendants(
              equipment.get('uuid'),
              equipmentTree,
            ) as List<SiteEquipmentOperatingContextEntityTreeRecord>;
          })
          // Flatten and include only relevant leaf nodes
          .reduce((acc, listOfNodesAndDescendants) => {
            listOfNodesAndDescendants = listOfNodesAndDescendants
              // Filter out equipment not being operating under any context
              .filter((item) => !item?.get('operating_contexts')?.isEmpty())
              // Filter out equipment not being operating under the matched rule operating contexts
              .filter(
                (item) =>
                  !item
                    ?.get('operating_contexts')
                    .filter((operatingContext) => {
                      return operatingContextIdsFromMatchedRules.includes(
                        operatingContext.get('uuid'),
                      );
                    })
                    .isEmpty(),
              );
            listOfNodesAndDescendants.forEach((node) => {
              // If leaf node, include
              if (node.get('children').size === 0) {
                acc = acc.push(node);
              } else {
                // If not leaf node, include only if no children are in the list
                if (
                  !node.get('children').some((child) => {
                    return listOfNodesAndDescendants.contains(child);
                  })
                ) {
                  acc = acc.push(node);
                }
              }
            });
            return acc;
          }, List<SiteEquipmentOperatingContextEntityRecord>())
          .map((node) => {
            // Remap to SiteEquipmentOperatingContextEntityRecord
            return equipmentList.find(
              (item) => item.get('uuid') === node.get('uuid'),
            );
          })
          .toSet()
          .toList() as List<SiteEquipmentOperatingContextEntityRecord>) ??
        List<SiteEquipmentOperatingContextEntityRecord>()
      );
    },
  );

  static getExpectedCoverage = createImmutableSelector(
    [
      (equipmentContext: List<SiteEquipmentOperatingContextEntityRecord>) =>
        equipmentContext,
      (
        _equipmentContext: List<SiteEquipmentOperatingContextEntityRecord>,
        operatingContextIds: List<string>,
      ) => operatingContextIds,
    ],
    (equipmentContext, operatingContextIds) => {
      // Filter out operating contexts not in the matched rule operating contexts
      return equipmentContext.map((equipment) => {
        return equipment.set(
          'operating_contexts',
          equipment
            .get('operating_contexts')
            .filter((operatingContext) =>
              operatingContextIds.includes(operatingContext.get('uuid')),
            ),
        );
      });
    },
  );

  static getCoverageTotal = createImmutableSelector(
    [
      (equipmentContext: List<SiteEquipmentOperatingContextEntityRecord>) =>
        equipmentContext,
    ],
    (equipmentContext) => {
      return equipmentContext.reduce((acc, equipment) => {
        return acc + equipment.get('operating_contexts').size;
      }, 0);
    },
  );

  static getCurrentCoverage = createImmutableSelector(
    [
      (record: ApplicableControlForPlanEntityRecord) =>
        record.get('baseline_assessments'),
      (_record: ApplicableControlForPlanEntityRecord, is_mandatory: boolean) =>
        is_mandatory,
    ],
    (baseLineAssessments, is_mandatory) => {
      const assessmentsForType = baseLineAssessments.filter(
        (assessment) => assessment.get('is_mandatory') === is_mandatory,
      );

      const oc = assessmentsForType.reduce((acc, assessment) => {
        const operatingContexts = assessment.get('operating_contexts');

        assessment.get('equipment').forEach((equipment) => {
          const newEquip = equipment.set(
            'operating_contexts',
            equipment.get('operating_contexts').filter((operatingContext) => {
              return operatingContexts.includes(operatingContext);
            }),
          );
          const existingEquip = acc.find(
            (item) => item.get('uuid') === newEquip.get('uuid'),
          );
          if (existingEquip) {
            acc = acc.update(
              acc.findIndex(
                (item) => item.get('uuid') === newEquip.get('uuid'),
              ),
              (item) => {
                return item?.set(
                  'operating_contexts',
                  item
                    .get('operating_contexts')
                    .concat(newEquip.get('operating_contexts'))
                    .toSet()
                    .toList(),
                );
              },
            );
          } else {
            acc = acc.push(newEquip);
          }
        });

        return acc;
      }, List<SiteEquipmentOperatingContextEntityRecord>());
      return oc;
    },
  );

  static getCoverageDifference = createImmutableSelector(
    [
      (expected: List<SiteEquipmentOperatingContextEntityRecord>) => expected,
      (
        _expected: List<SiteEquipmentOperatingContextEntityRecord>,
        actual: List<SiteEquipmentOperatingContextEntityRecord>,
      ) => actual,
    ],
    (expected, actual) => {
      return expected
        .map((equipment) => {
          const actualEquipment = actual.find(
            (item) => item.get('uuid') === equipment.get('uuid'),
          );
          return equipment.set(
            'operating_contexts',
            equipment.get('operating_contexts').filter((operatingContext) => {
              return !actualEquipment
                ?.get('operating_contexts')
                .includes(operatingContext);
            }),
          );
        })
        .filter((equipment) => !equipment.get('operating_contexts').isEmpty());
    },
  );

  /**
    # @WARNING
    #
    # ATTENTION DEVELOPERS: MODIFICATIONS TO THIS FUNCTION AND RELATED FUNCTION
    # WILL REQUIRE IMPACT ANALYSIS TO THE PARALLEL IMPLEMENTATION IN THE BACKEND
    #
    #
   */
  static getCoverageReport(
    value: ApplicableControlForPlanEntityRecord,
    equipOptionsMandatory: List<SiteEquipmentOperatingContextEntityRecord>,
    equipOptionsAssess: List<SiteEquipmentOperatingContextEntityRecord>,
  ) {
    const currentCoverageAssess =
      ApplicableControlForPlanEntity.getCurrentCoverage(value, false);
    const currentCoverageMandatory =
      ApplicableControlForPlanEntity.getCurrentCoverage(value, true);
    const expectedCoverageMandatory =
      ApplicableControlForPlanEntity.getExpectedCoverage(
        equipOptionsMandatory,
        ApplicableRuleEntity.getIDsForModel(
          ApplicableControlForPlanEntity.getRules(value, true),
          'operatingcontext',
        ) as List<string>,
      );
    const expectedCoverageAssess =
      ApplicableControlForPlanEntity.getExpectedCoverage(
        equipOptionsAssess,
        ApplicableRuleEntity.getIDsForModel(
          ApplicableControlForPlanEntity.getRules(value, false),
          'operatingcontext',
        ) as List<string>,
      );
    const currentTotalMandatory =
      ApplicableControlForPlanEntity.getCoverageTotal(currentCoverageMandatory);
    const expectedTotalMandatory =
      ApplicableControlForPlanEntity.getCoverageTotal(
        expectedCoverageMandatory,
      );
    const currentTotalAssess = ApplicableControlForPlanEntity.getCoverageTotal(
      currentCoverageAssess,
    );
    const expectedTotalAssess = ApplicableControlForPlanEntity.getCoverageTotal(
      expectedCoverageAssess,
    );
    const percent =
      ((currentTotalMandatory + currentTotalAssess) /
        (expectedTotalMandatory + expectedTotalAssess)) *
      100;

    const missingCoverageMandatory =
      ApplicableControlForPlanEntity.getCoverageDifference(
        expectedCoverageMandatory,
        currentCoverageMandatory,
      );
    const missingCoverageAssess =
      ApplicableControlForPlanEntity.getCoverageDifference(
        expectedCoverageAssess,
        currentCoverageAssess,
      );

    return {
      currentCoverageMandatory,
      missingCoverageMandatory,
      currentCoverageAssess,
      missingCoverageAssess,
      percent,
    };
  }

  static updateEmptyAssessment(
    record: ApplicableControlForPlanEntityRecord,
    questions: List<BaselineAssessmentQuestionEntityRecord>,
  ) {
    let assessments = record.get('baseline_assessments');
    const emptyAssessments = this.getEmptyAssessments(record);
    if (emptyAssessments.size > 0) {
      assessments = assessments.map((assessment) => {
        if (assessment.get('answers').size === 0) {
          return BaselineAssessmentEntity.createQuestionAnswers(
            assessment,
            questions,
          );
        }
        return assessment;
      });
    }
    return record.set('baseline_assessments', assessments);
  }

  static makeInitialAssessments(record: ApplicableControlForPlanEntityRecord) {
    if (this.shouldHaveAssess(record)) {
      if (this.getAssessAssessments(record).size === 0) {
        // Filter auto created blank assessments
        record = record.set(
          'baseline_assessments',
          record
            .get('baseline_assessments')
            .push(
              BaselineAssessmentEntity.dataToRecord({ is_mandatory: false }),
            ),
        );
      }
    }
    if (this.shouldHaveMandatory(record)) {
      if (this.getMandatoryAssessments(record).size === 0) {
        // Filter auto created blank assessments
        record = record.set(
          'baseline_assessments',
          record
            .get('baseline_assessments')
            .push(BaselineAssessmentEntity.dataToRecord({})),
        );
      }
    }
    return record;
  }

  static _createTuples = createImmutableSelector(
    [
      (equipOptions: List<SiteEquipmentOperatingContextEntityRecord>) =>
        equipOptions,
    ],
    (equipOptions) => {
      return equipOptions.reduce((acc, equipment) => {
        equipment.get('operating_contexts').forEach((operatingContext) => {
          acc = acc.push(List([equipment, operatingContext]));
        });
        return acc;
      }, List<List<SiteEquipmentOperatingContextEntityRecord | OperatingContextEntityRecord | List<BaselineAssessmentEntityRecord>>>());
    },
  );

  static cleanOverlappingAssessmentList = createImmutableSelector(
    [
      (assessments: List<BaselineAssessmentEntityRecord>) => assessments,
      (
        _assessments: List<BaselineAssessmentEntityRecord>,
        equipOptions: List<SiteEquipmentOperatingContextEntityRecord>,
      ) => equipOptions,
    ],
    (assessments, equipOptions) => {
      if (!assessments.isEmpty()) {
        // We aren't guaranteed that the assessments have UUIDs, so we need to track the original index
        // If we have order jumping of assessments in the app we can consider generating front end mock
        // UUIDS in the DataToRecord method, and cleaning mocked UUIDs in the ToData method
        const enhancedAssessments = assessments.map((assessment, key) => {
          return assessment.set('original_index', key);
        }) as List<BaselineAssessmentEntityRecordWithIndex>;
        // Create a list of tuples of equipment and operating context
        const tuples = this._createTuples(equipOptions);
        // Find assessments that have the same equipment and operating context
        const updatedAsssments = tuples
          .map((tuple) => {
            return tuple.push(
              enhancedAssessments.filter((assessment) => {
                const equipment = tuple.get(
                  0,
                ) as SiteEquipmentOperatingContextEntityRecord;
                const operatingContext = tuple.get(
                  1,
                ) as OperatingContextEntityRecord;
                const hasEquipment = assessment
                  .get('equipment')
                  .some((equip) => equip.get('uuid') === equipment.get('uuid'));
                const hasOperatingContext = assessment
                  .get('operating_contexts')
                  .some(
                    (oc) => oc.get('uuid') === operatingContext.get('uuid'),
                  );
                return hasEquipment && hasOperatingContext;
              }),
            );
          })
          // Filter out tuples that have only one assessment
          .filter((tuple) => tuple?.get(2)?.size ?? 0 > 1)
          // Remove the duplicate equipment from the assessments
          .reduce((acc, tuple) => {
            let conflicedAssessments = tuple.get(
              2,
            ) as List<BaselineAssessmentEntityRecordWithIndex>;
            const duplicatedEquipment = tuple.get(
              0,
            ) as SiteEquipmentOperatingContextEntityRecord;
            // Keep the first occurrence;
            conflicedAssessments = conflicedAssessments.pop();
            conflicedAssessments.forEach((assessment) => {
              acc = acc.push(
                assessment.set(
                  'equipment',
                  assessment
                    .get('equipment')
                    .filter(
                      (equip) =>
                        equip.get('uuid') !== duplicatedEquipment.get('uuid'),
                    ),
                ),
              );
            });
            return acc;
          }, List<BaselineAssessmentEntityRecordWithIndex>());
        return updatedAsssments;
      }
      return List<BaselineAssessmentEntityRecordWithIndex>();
    },
  );

  static cleanOverlappingAssessments(
    record: ApplicableControlForPlanEntityRecord,
    equipOptionsMandatory: List<SiteEquipmentOperatingContextEntityRecord>,
    equipOptionsAsess: List<SiteEquipmentOperatingContextEntityRecord>,
  ) {
    const mandatory = this.getMandatoryAssessments(record);
    let newMandatory = mandatory;
    const assess = this.getAssessAssessments(record);
    let newAssess = assess;

    const updatedMandatory = this.cleanOverlappingAssessmentList(
      this.getMandatoryAssessments(record),
      equipOptionsMandatory,
    );
    const updatedAssess = this.cleanOverlappingAssessmentList(
      this.getAssessAssessments(record),
      equipOptionsAsess,
    );
    if (!updatedMandatory.isEmpty()) {
      updatedMandatory.forEach((assessment) => {
        newMandatory = newMandatory.set(
          assessment.get('original_index'),
          assessment.delete('original_index'),
        );
      });
    }
    if (!updatedAssess.isEmpty()) {
      updatedAssess.forEach((assessment) => {
        newAssess = newAssess.set(
          assessment.get('original_index'),
          assessment.delete('original_index'),
        );
      });
    }
    if (!mandatory.equals(newMandatory) || !assess.equals(newAssess)) {
      // This may potentially cause order jumping on the form, but less likely since we try to preserve the order
      // via indexes and we already split the assessments into mandatory and assess. However if ordering becomes an
      // issue we can consider mocking UUID see above comments
      return record.set(
        'baseline_assessments',
        newMandatory.concat(newAssess).map((assessment) => {
          // Run the assessment cleaner since we may have removed equipment
          return BaselineAssessmentEntity.clean(assessment);
        }),
      );
    }
    return record;
  }

  static clean(
    record: ApplicableControlForPlanEntityRecord,
    configs?: Record<string, unknown>,
  ) {
    let newRecord = super.clean(record, configs); // note: we don't need to clean anything here
    newRecord = this.makeInitialAssessments(newRecord);
    return newRecord;
  }

  static dataToRecord(data?: Record<string, unknown> | null) {
    let newRecord = super.dataToRecord(data);
    newRecord = this.makeInitialAssessments(newRecord);
    return newRecord;
  }

  static toData(record: ApplicableControlForPlanEntityRecord) {
    // Mutate data before transforming into JSON for payload generation for the
    // axios request
    if (this.shouldHaveAssess(record)) {
      if (this.getAssessAssessments(record).size === 1) {
        // Filter auto created blank assessments
        record = record.set(
          'baseline_assessments',
          record
            .get('baseline_assessments')
            // This doesn't work because we also have to dynamically construct answers and at this
            // level we don't have access to the questions in the data design
            // need to be clever and check if answers are empty
            // @FIXME
            .filter((assessment) => !ASESSTEMPLATE.equals(assessment)),
        );
      }
    }
    if (this.shouldHaveMandatory(record)) {
      if (this.getMandatoryAssessments(record).size === 1) {
        // Filter auto created blank assessments
        // This doesn't work because we also have to dynamically construct answers and at this
        // level we don't have access to the questions in the data design
        record = record.set(
          'baseline_assessments',
          record
            .get('baseline_assessments')
            // This doesn't work because we also have to dynamically construct answers and at this
            // level we don't have access to the questions in the data design
            // need to be clever and check if answers are empty
            // @FIXME
            .filter((assessment) => !MANDATORYTEMPLATE.equals(assessment)),
        );
      }
    }
    const data = super.toData(record);
    return data;
  }

  static unlinkAction(
    controlUuid: string,
    gapUuid: string,
    actionUuid: string,
  ) {
    return axiosInstance.post(
      `${ApplicableControlForPlanEntity.paths.apiBase}${controlUuid}/unlink_action_from_gap/`,
      {
        gap: gapUuid,
        gapaction: actionUuid,
      },
    );
  }
}

export type ApplicableControlForPlanEntityFields = {
  uuid: string;
  global_control: ControlEntityRecord;
  applicable_rules: List<ApplicableRuleEntityRecord>;
  baseline_assessments: List<BaselineAssessmentEntityRecord>;
  created: Moment;
  modified: Moment;
};

export type ApplicableControlForPlanEntityRecord =
  EntityRecord<ApplicableControlForPlanEntityFields>;

ApplicableControlForPlanEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: ApplicableControlForPlanEntity,
  name: 'ApplicableControlForPlan',
});

export default ApplicableControlForPlanEntity;
