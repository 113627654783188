import { Table, TableContainer, Tbody } from '@chakra-ui/react';
import { type List } from 'immutable';

import { L1EquipmentRow } from 'components/ControlGrid/components/MandatoryAssessTable/L1EquipmentRow';
import { tableSx } from 'components/ControlGrid/components/MandatoryAssessTable.styles';
import type { ExtendedApplicableRuleEntityRecord } from 'entities/api/ApplicableRule';
import type { ScenarioEntityRecord } from 'entities/api/Scenario';
import { type UseControlVisualisationControlsReturn } from 'screens/global-frameworks/ControlsVisualisation/ControlsVisualisation.hooks';

import { MandatoryAssessTableHeaderRow } from './MandatoryAssessTableHeaderRow';

export type MandatoryAssessTableProps = {
  applicableRules: List<ExtendedApplicableRuleEntityRecord>;
  filterRecord?: UseControlVisualisationControlsReturn['filterRecord'];

  // note: when rendered in ControlAssessment, these props are fetched from API, and may be undefined
  equipmentLevel1?: UseControlVisualisationControlsReturn['equipmentLevel1'];
  equipmentLevel2?: UseControlVisualisationControlsReturn['equipmentLevel2'];
  operatingContexts?: UseControlVisualisationControlsReturn['operatingContexts'];
  scenarios?: List<ScenarioEntityRecord>;
  damageEnergyToScenarioMap: Map<string, List<ScenarioEntityRecord>>;
};

export const MandatoryAssessTable = ({
  applicableRules,
  scenarios,
  damageEnergyToScenarioMap,
  filterRecord,
  equipmentLevel1,
  equipmentLevel2,
  operatingContexts,
}: MandatoryAssessTableProps) => {
  return (
    <TableContainer>
      <Table size="sm" sx={tableSx}>
        <MandatoryAssessTableHeaderRow
          operatingContexts={operatingContexts}
          filterRecord={filterRecord}
        />
        <Tbody>
          {equipmentLevel1?.map((l1Equipment, i) => {
            return (
              <L1EquipmentRow
                key={l1Equipment.get('uuid')}
                l1Equipment={l1Equipment}
                applicableRules={applicableRules}
                scenarios={scenarios}
                damageEnergyToScenarioMap={damageEnergyToScenarioMap}
                filterRecord={filterRecord}
                equipmentLevel2={equipmentLevel2}
                operatingContexts={operatingContexts}
                showScenario={i === 0}
              />
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
