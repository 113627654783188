import { Map } from 'immutable';
import { useCallback, useState } from 'react';
import { useOutletContext } from 'react-router';

import { withQuery } from '@burnsred/entity-duck-query';
import ApplicableRuleEntity from 'entities/api/ApplicableRule';
import { usePagination } from 'entities/filter/PaginationEntity';

import { GlobalControlRules } from './GlobalControlRules/GlobalControlRules';
import type { GlobalControlDetailContextType } from '../../GlobalFrameworkControlDetail';

export const GlobalFrameworkControlRules = withQuery(() => {
  const { controlFormControls } =
    useOutletContext<GlobalControlDetailContextType>();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const pagination = usePagination(
    ApplicableRuleEntity,
    Map({
      control: controlFormControls?.value?.get('uuid'),
      page: String(page),
      page_size: String(pageSize),
    }),
  );

  const totalItems = pagination?.get('count') || 0;

  const handlePageChange = useCallback((newPage: number, newSize: number) => {
    setPage(newPage);
    setPageSize(newSize);
  }, []);

  return {
    action: ApplicableRuleEntity.duck.actions.get({
      params: Map({
        control: controlFormControls?.value?.get('uuid'),
        page: String(page),
        page_size: String(pageSize),
      }),
    }),
    componentProps: {
      onPageChange: handlePageChange,
      initialPage: page,
      pageSize: pageSize,
      totalItems: totalItems,
    },
  };
})(GlobalControlRules);
