import type { List } from 'immutable';
import type { ReactNode } from 'react';

export type DefaultListComponentProps = {
  children: ReactNode;
  records: List<unknown>;
} & Record<string, unknown>;

export const DefaultListComponent = ({
  children,
}: DefaultListComponentProps) => <>{children}</>;
