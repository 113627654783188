import {
  Input as ChakraInput,
  type InputProps as ChakraInputProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import type { InputProps } from '@burnsred/entity-form';
import { filterProps } from '@burnsred/higher-order-component';
import { type I18nTranslationEntityRecord } from 'entities/api/i18n/I18nTranslation';

/**
 * Renders an Input widget with a title matching the language of the parentValue.
 * Based widgets/Input.
 */
export const I18nTranslation = ({
  parentValue,
  ...props
}: InputProps<I18nTranslationEntityRecord, false> &
  ChakraInputProps & { parentValue: I18nTranslationEntityRecord }) => {
  const errors = props?.errors?.join('. ');

  return (
    <FormControl isInvalid={!!errors} isDisabled={props.disabled}>
      <FormLabel>
        <FormattedMessage
          id="compliance.operating-environment.equipment.modal.label.title"
          defaultMessage="Vehicle name ({language})"
          values={{ language: parentValue?.get('language') }}
        />
      </FormLabel>
      <ChakraInput {...filterProps<ChakraInputProps>(props)} />
      <FormErrorMessage>{errors}</FormErrorMessage>
    </FormControl>
  );
};
