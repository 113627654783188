import { type SystemStyleObject } from '@chakra-ui/react';

export const OperationAccordionSx: SystemStyleObject = {
  paddingBottom: 2,
  '> div.chakra-accordion__item': {
    borderTop: 0,
    borderBottom: 0,
    '> button': {
      backgroundColor: '#f67b2f',
      color: 'white',
      paddingX: 0,
      paddingY: 2,
      // AccordionButton > Grid
      '> div': {
        gridTemplateColumns: 'repeat(10, 1fr)',
        gap: 'gutter',
        width: '100%',
        paddingX: 4,
        '> div': {
          color: 'white',
          fontSize: 'sm',
          fontWeight: 'bold',
        },
        '> div:nth-of-type(1)': {
          textAlign: 'left',
        },
        '> div:nth-of-type(2)': {
          textAlign: 'right',
        },
      },
    },
    '> button:hover': {
      backgroundColor: '#f67b2f',
    },
    'div.chakra-accordion__panel': {
      padding: 0,
      paddingBottom: 2,
    },
    'div.chakra-accordion__panel:last-of-type': {
      paddingBottom: 0,
    },
  },
};
