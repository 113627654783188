import type { SystemStyleObject } from '@chakra-ui/react';

const cellStyles: SystemStyleObject = {
  verticalAlign: 'top',
  paddingX: 2,
  borderColor: 'border',
  fontSize: 'xs',
  height: '36px',
  lineHeight: '20px',
  '&:first-of-type': {
    paddingLeft: '20px !important',
  },
  '&:last-of-type': {
    paddingRight: '20px !important',
  },
  '&.left': {
    paddingX: 0,
    textAlign: 'left',
  },
  '&.right': {
    paddingX: 0,
    textAlign: 'right',
  },
  '&.center': {
    paddingX: 0,
    textAlign: 'center',
  },
};
export const tableSx: SystemStyleObject = {
  '&.chakra-table': {
    paddingX: 10,
    boxSizing: 'border-box',
  },
  tr: {
    '&.isInterruption': {
      color: 'error',
    },
    backgroundColor: '#f7fafc',
  },
  th: {
    ...cellStyles,
    paddingRight: 0,
    verticalAlign: 'bottom',
    fontWeight: 'bold',
    textTransform: 'inherit',
    color: 'muted',
    borderBottom: 'none', // Remove bottom border of header cells
    paddingY: 2,
    paddingBottom: 6,
    backgroundColor: 'white',
  },
  td: {
    ...cellStyles,
  },
};
export const iconSx: SystemStyleObject = {
  boxSize: 4,
  // HACK prevent icons from distorting row spacing
  marginBottom: '-5px',
  _hover: { cursor: 'help' },
};
export const openLinkSx: SystemStyleObject = {
  fontWeight: 'normal',
};
