import { Entity, Fields } from '@burnsred/entity';
import { type BaseAttachmentEntityFields } from 'entities/api/BaseAttachment.ts';
import { BaseAttachment } from 'entities/api/index.ts';
import { type EntityRecord } from 'types.ts';

class BaselineAssessmentAnswerAttachmentEntity extends Entity {
  static fields = {
    ...BaseAttachment.fields,
    assessment_answer: new Fields.CharField(),
  };
}

export type BaselineAssessmentAnswerAttachmentEntityFields =
  BaseAttachmentEntityFields & {
    assessment_answer: string;
  };

export type BaselineAssessmentAnswerAttachmentEntityRecord =
  EntityRecord<BaselineAssessmentAnswerAttachmentEntityFields>;

export default BaselineAssessmentAnswerAttachmentEntity;
