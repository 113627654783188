import {
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VisuallyHidden,
} from '@chakra-ui/react';
import { type List, fromJS } from 'immutable';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet, useNavigate, useParams } from 'react-router';

import { withQuery } from '@burnsred/entity-duck-query';
import { RouterLink } from '@burnsred/ui-chakra';
import { RiskIcon, Tooltip, VStack } from 'components';
import { Risk } from 'entities';
import GlobalFrameworkEntity, {
  type GlobalFrameworkEntityRecord,
} from 'entities/api/GlobalFramework';
import PersonEntity, { useCurrentUser } from 'entities/api/Person/Person';
import { useLocale } from 'locales/useLocale';
import type { WithQueryReturnProps } from 'types';
import { createLogger } from 'util/createLogger';

import { riskIconSx, tabsSx } from './GlobalFrameworks.styles';

const log = createLogger('GlobalFrameworksScreen');

export type GlobalFrameworkContext = {
  framework: GlobalFrameworkEntityRecord;
  formControls: {
    value: GlobalFrameworkEntityRecord;
  };
};

function GlobalFrameworksScreen({
  value: frameworks,
}: WithQueryReturnProps<List<GlobalFrameworkEntityRecord>>) {
  const navigate = useNavigate();
  const { toString } = useLocale();

  log('%o', frameworks);

  const { globalFrameworkUuid } = useParams();
  const tabPaths: Record<string, number> = frameworks.reduce(
    (acc, fr, i) => ({ ...acc, [fr?.get('uuid')]: i }),
    {},
  );
  const currentTab = globalFrameworkUuid ? tabPaths[globalFrameworkUuid] : 0;

  useEffect(() => {
    // if we're on the list page, redirect to the first framework
    if (frameworks?.size && !globalFrameworkUuid)
      navigate(`/global-frameworks/${frameworks.first()?.get('uuid')}`);
  }, [frameworks, globalFrameworkUuid, navigate]);

  return (
    <Flex direction="column" gap={8}>
      <VStack as="section">
        <Heading size="lg">
          <FormattedMessage
            id="global-frameworks.visualisation.page-title"
            defaultMessage="Global control frameworks"
          />
        </Heading>
      </VStack>

      <Tabs index={currentTab} isLazy variant="enclosed" sx={tabsSx}>
        <TabList>
          {frameworks.map((framework, i) => {
            const riskIconPath = Risk.getRiskIconPath(framework?.get('risk'));

            return (
              <Tab key={i}>
                <Tooltip label={toString(framework.get('risk'))}>
                  <RouterLink
                    to={`/global-frameworks/${framework.get('uuid')}`}
                    className={
                      globalFrameworkUuid == framework.get('uuid')
                        ? 'active'
                        : ''
                    }
                  >
                    <RiskIcon path={riskIconPath} sx={riskIconSx} />

                    <VisuallyHidden>
                      {toString(framework.get('risk'))}
                    </VisuallyHidden>
                  </RouterLink>
                </Tooltip>
              </Tab>
            );
          })}
        </TabList>

        <TabPanels>
          {frameworks.map((framework) => (
            <TabPanel key={framework.get('uuid')}>
              <Outlet context={{ framework }} />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default withQuery(() => {
  const user = useCurrentUser();
  let params = {};
  if (PersonEntity.isPublicUser(user)) {
    params = {
      ...params,
      is_globalized: 'true',
    };
  }
  return {
    action: GlobalFrameworkEntity.duck.actions.get({ params: fromJS(params) }),
  };
})(GlobalFrameworksScreen);
