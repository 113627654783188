import { type List, Map } from 'immutable';
import { useEffect } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';

import { useQuery } from '@burnsred/entity-duck-query';
import { Loading } from '@burnsred/ui-chakra';
import ApplicableControlForPlanEntity from 'entities/api/CompliancePlan/ApplicableControlForPlan';
import type { ApplicableControlForPlanListEntityRecord } from 'entities/api/CompliancePlan/ApplicableControlForPlanListOnly';
import { createLogger } from 'util/createLogger';
import useCounter from 'util/useCounter';

import { COMPLIANCE_TABS } from '../COMPLIANCE_TABS';
import type { SiteCompliancePlanContext } from '../SiteCompliancePlanContext';

const log = createLogger('useActions');

export const useActions = () => {
  const navigate = useNavigate();
  const { compliancePlanUuid, siteCompliancePlanUuid, controlUuid } =
    useParams();
  const parentContext = useOutletContext<SiteCompliancePlanContext>();

  const applicableControlsForPlanFormProps = useQuery<
    List<ApplicableControlForPlanListEntityRecord>
  >({
    action: ApplicableControlForPlanEntity.duck.actions.get({
      params: Map({ site_compliance_plan: siteCompliancePlanUuid }),
    }),
    componentProps: { siteCompliancePlanUuid },
    processingComponent: Loading,
  });

  const [actionsStatsKey, refreshActionsStats] = useCounter();

  // bounce to list if controlUuid is not found for current site
  useEffect(() => {
    // ensure value has resolved before evaluating the redirect!
    if (!applicableControlsForPlanFormProps.value) return;

    const isControlPresentForSite =
      controlUuid &&
      applicableControlsForPlanFormProps.value
        ?.map((record) => record.get('uuid'))
        ?.includes(controlUuid);

    if (!isControlPresentForSite) {
      navigate(
        `/compliance/${compliancePlanUuid}/site/${siteCompliancePlanUuid}/${COMPLIANCE_TABS.tabPaths.actions.routeName}`,
      );
    }
  }, [
    applicableControlsForPlanFormProps.value,
    compliancePlanUuid,
    controlUuid,
    navigate,
    siteCompliancePlanUuid,
  ]);

  log('%o', { applicableControlsForPlanFormProps });

  return {
    parentContext,
    applicableControlsForPlanFormProps,
    actionsStatsKey,
    refreshActionsStats,
  };
};

export type UseActionReturn = ReturnType<typeof useActions>;
