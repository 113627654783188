import type { SystemStyleObject } from '@chakra-ui/react';

export const checkboxSx: SystemStyleObject = {
  '> span': {
    '> div': {
      '> svg': {
        marginRight: '0 !important',
      },
    },
  },
};
