import { Flex, type SystemStyleObject } from '@chakra-ui/react';
import React, { type ReactNode } from 'react';

import { Portal } from 'components/atoms';

import {
  stickyActionsFooterInnerSx,
  stickyActionsFooterSx,
} from './StickyActionsFooter.styles';

type StickyActionsFooterProps = {
  /** if children are not provided, the footer will be hidden */
  children?: ReactNode;
  /** @see Portal */
  containerQuerySelector?: string;
  sx?: SystemStyleObject;
  /** default true */
  isVisible?: boolean;
};

/**
 * Renders a sticky actions footer intended for next/previous navigation between
 * tabs.
 *
 * ## Example
 *
 * Minimal demo. You can pass `actions` &  `setActions` to `TabPanel`s via
 * useOutletContext, enabling next/previous navigation between nested tabbed interfaces.
 *
 * ```tsx
 * function TabbedComponent() {
 *   const navigate = useNavigate();
 *   const { actions, setActions } = useStickyActions();
 *
 *   useEffect(() => {
 *     const nextPath = `/base/${tabSegment == 'foo' ? bar' : 'foo'}`;
 *     const previousPath = `/base/${tabSegment == 'bar' ? foo' : 'bar'}`;
 *
 *     setActions((prev) => ({
 *       ...prev,
 *       next: () => navigate(nextPath),
 *       previous: () => navigate(previousPath),
 *     }));
 *   }, [ navigate, tabSegment, setActions, pathname ]);
 *
 *   return (
 *     <div>
 *       <Tabs>...</Tabs>
 *
 *       <StickyActionsFooter>
 *         <Button className="action-button--previous" onClick={actions.previous}>
 *           Previous
 *         </Button>
 *
 *         <Button type="submit" variant="primary" onClick={actions.next}>
 *         Next
 *       </Button>
 *     </StickyActionsFooter>
 *   </div>
 *   )
 * }
 * ```
 */
export const StickyActionsFooter = ({
  children,
  containerQuerySelector = '.Scrollbars > div:first-of-type',
  sx = stickyActionsFooterSx,
  isVisible = true,
}: StickyActionsFooterProps) => {
  const numberChildren = React.Children.count(children);

  return (
    <Portal containerQuerySelector={containerQuerySelector}>
      <Flex
        as="footer"
        className="StickyActions"
        sx={{
          zIndex: 'sticky',
          ...sx,
          ...(isVisible ? {} : { display: 'none' }),
          // ensure that the footer is not visible when there are no children
          ...(children ? {} : { display: 'none' }),
        }}
      >
        <Flex
          className="StickyActions__inner"
          sx={{
            ...stickyActionsFooterInnerSx,
            ...(numberChildren > 1
              ? { justifyContent: 'space-between' }
              : { justifyContent: 'flex-end' }),
          }}
        >
          {children}
        </Flex>
      </Flex>
    </Portal>
  );
};
