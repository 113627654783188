import {
  Button,
  Card,
  CardBody,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { type Field } from '@burnsred/entity';
import { Form, Input } from '@burnsred/entity-form';
import { VStack } from 'components';
import { Autocomplete } from 'components/widgets';
import { type ControlListFilterEntityRecord } from 'entities/filter/ControlListFilter';
import { type EntityDuckAction, type FormChangeEvent } from 'types';

type ControlFiltersProps = {
  name: string;
  filterRecord: ControlListFilterEntityRecord;
  onChange: (event: FormChangeEvent) => EntityDuckAction;
  handleApplyParams: () => void;
  FilterField: Field;
  errors: Immutable.List<never>;
  damagingEnergyMechanismFilterParams: Record<string, string | undefined>;
};

export const ControlsFilters = ({
  name,
  filterRecord,
  onChange,
  handleApplyParams,
  FilterField,
  errors,
  damagingEnergyMechanismFilterParams,
}: ControlFiltersProps) => {
  return (
    <Card variant="filled" bgColor="gray.200">
      <CardBody as="form" onSubmit={(event) => event.preventDefault()}>
        <VStack gap={6}>
          <Heading as="h3" size="xs">
            <FormattedMessage
              id="global-framework.controls.list.add-controls"
              defaultMessage="Add controls"
            />
          </Heading>

          <Form
            name={name}
            field={FilterField}
            value={filterRecord}
            errors={errors}
            onChange={onChange}
            onSubmit={onChange}
          >
            <Grid gridTemplateColumns="repeat(10, 1fr)" gap={4}>
              <GridItem colSpan={{ base: 10, md: 5, lg: 3 }}>
                <FormControl>
                  <FormLabel>
                    <FormattedMessage
                      id="global-framework.controls.list.filters.search-by-damage-energy-mech"
                      defaultMessage="Search by damaging energy mechanism"
                    />
                  </FormLabel>
                  <Input
                    name="damaging_energy_mechanism"
                    component={Autocomplete}
                    loadOptionsFromAPI
                    filterParams={damagingEnergyMechanismFilterParams}
                  />
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ base: 10, md: 5, lg: 6 }}>
                <FormControl>
                  <FormLabel>
                    <FormattedMessage
                      id="global-framework.controls.list.filters.search-by-keyword"
                      defaultMessage="Search by keyword"
                    />
                  </FormLabel>
                  <Input name="title" />
                </FormControl>
              </GridItem>

              <GridItem
                colSpan={{ base: 10, md: 10, lg: 1 }}
                alignContent="flex-end"
              >
                <Button
                  type="submit"
                  width="full"
                  variant="outline"
                  bgColor="white"
                  onClick={handleApplyParams}
                >
                  <FormattedMessage
                    id="global-framework.controls.list.filters.select-button"
                    defaultMessage="Select"
                  />
                </Button>
              </GridItem>
            </Grid>
          </Form>
        </VStack>
      </CardBody>
    </Card>
  );
};
