import en from './en.json'; // with { type: 'json' };
import es from './es.json'; // with { type: 'json' };

export const SPANISH = 'es';
export const ENGLISH = 'en';
export const localeKeys = [ENGLISH, SPANISH] as const;
export type LocaleKey = (typeof localeKeys)[number];

export const localeOptions = [
  { value: ENGLISH, label: 'English' },
  { value: SPANISH, label: 'Español' },
] satisfies { value: LocaleKey; label: string }[];

const messages: Record<LocaleKey[number], Record<string, string>> = {
  es,
  en,
};

export const defaultLocale = ENGLISH satisfies LocaleKey;

export default messages;
