import {
  Divider,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  VisuallyHidden,
} from '@chakra-ui/react';
import { type List } from 'immutable';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';

import { withQuery } from '@burnsred/entity-duck-query';
import { SimpleEntityList, VStack } from 'components';
import { type DefaultListComponentProps } from 'components/SimpleEntityList/DefaultListComponent.tsx';
import ControlEntity from 'entities/api/Control';
import { type ControlDesignationEntityRecord } from 'entities/api/ControlDesignation';
import SupportFactorEntity, {
  type SupportFactorEntityRecord,
} from 'entities/api/SupportFactor';
import { useLocale } from 'locales/useLocale';
import { LinkedControlCard } from 'screens/control-frameworks/components/LinkedControlCard.tsx';
import type { WithQueryReturnProps } from 'types';
import { createLogger } from 'util/createLogger';

import {
  type UseSupportFactorDetailReturn,
  useSupportFactorDetail,
} from './SupportFactorDetail.hooks.ts';
import {
  ContentBlock,
  controlDesignationSx,
  statRowLabelSx,
} from './SupportFactorDetail.styles.tsx';

const log = createLogger('SupportFactorDetail');

type SupportFactorDetailProps = WithQueryReturnProps<SupportFactorEntityRecord>;

function SupportFactorDetail(props: SupportFactorDetailProps) {
  const { toString, locale } = useLocale();
  const { params, controlDesignations, setPageCounts, totalCardCount } =
    useSupportFactorDetail();
  log('%o', { props, controlDesignations });

  return (
    <Flex layerStyle="cardLike">
      <VStack>
        <Heading size="xs" variant="primary">
          {toString(props.value)}
        </Heading>
        <ContentBlock
          heading={
            <FormattedMessage
              id="global-framework.support-factors.purpose"
              defaultMessage="Purpose"
            />
          }
        >
          <Text sx={statRowLabelSx}>{toString(props.value, 'purpose')}</Text>
        </ContentBlock>

        <ContentBlock
          heading={
            <FormattedMessage
              id="global-framework.support-factors.features"
              defaultMessage="Features"
            />
          }
        >
          <Text sx={statRowLabelSx} whiteSpace="pre-line">
            {toString(props.value, 'features')}
          </Text>
        </ContentBlock>

        <ContentBlock
          heading={
            <FormattedMessage
              id="global-framework.support-factors.erosion_factors"
              defaultMessage="Erosion Factors"
            />
          }
        >
          <Text sx={statRowLabelSx} whiteSpace="pre-line">
            {toString(props.value, 'erosion_factors')}
          </Text>
        </ContentBlock>

        <Divider />
        <Heading as="h3" size="xs">
          <FormattedMessage
            id="global-framework.support-factors.linked-controls"
            defaultMessage="Linked Controls ({totalCardCount})"
            values={{ totalCardCount }}
          />
        </Heading>

        <VStack layerStyle="loadingWrapper">
          {controlDesignations?.map((controlDesignation) => (
            <SimpleEntityList
              key={controlDesignation.get('uuid')}
              Entity={ControlEntity}
              RecordComponent={LinkedControlCard}
              recordComponentProps={{
                locale,
                linkTarget: '../../controls/:controlUuid/',
              }}
              params={params.set(
                'control_designation__in',
                controlDesignation.get('uuid'),
              )}
              EmptyMessageComponent={VisuallyHidden}
              ListComponent={ControlsByControlDesignationList}
              listComponentProps={{
                controlDesignation,
                setPageCounts,
              }}
            />
          ))}
        </VStack>
      </VStack>
    </Flex>
  );
}

export default withQuery(() => {
  const { supportfactorUuid } = useParams();
  return {
    action: SupportFactorEntity.duck.actions.get({ id: supportfactorUuid }),
  };
})(SupportFactorDetail);

type TestListProps = DefaultListComponentProps & {
  records: List<ControlDesignationEntityRecord>;
  controlDesignation: ControlDesignationEntityRecord;
  setPageCounts: UseSupportFactorDetailReturn['setPageCounts'];
};

const ControlsByControlDesignationList = ({
  children,
  records,
  controlDesignation,
  setPageCounts,
}: TestListProps) => {
  const { toString } = useLocale();
  useEffect(() => {
    setPageCounts((prevCounts) => ({
      ...prevCounts,
      [controlDesignation.get('uuid')]: records.size,
    }));
  }, [controlDesignation, records.size, setPageCounts]);

  return (
    <>
      <Heading
        sx={{
          ...controlDesignationSx,
          color: `controlType_${controlDesignation?.get('order')}`,
        }}
      >
        {toString(controlDesignation)}
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} gap={2}>
        {children}
      </SimpleGrid>
    </>
  );
};
