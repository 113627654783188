import type { SystemStyleObject } from '@chakra-ui/react';

import { raoPickerSx } from '../RAOPicker/RAOPicker.styles';

export const raoPickerRadioSx: SystemStyleObject = {
  ...raoPickerSx,

  '.TreePicker__Node': {
    // @ts-expect-error expression of type '".TreePicker__Node"' can't be used to index type 'SystemStyleObject'
    ...raoPickerSx['.TreePicker__Node'],

    rounded: 'sm',
    padding: 1.5,

    '.chakra-checkbox': {
      // @ts-expect-error expression of type '".TreePicker__Node"' can't be used to index type 'SystemStyleObject'
      ...raoPickerSx['.TreePicker__Node']['.chakra-checkbox'],
      padding: 0,
    },

    '.chakra-radio': {
      // ensure that the radio label fills the entire row, to capture clicks
      display: 'flex',
      width: 'full',
      padding: 0,
    },
    '&[aria-disabled="true"] .chakra-radio__control': {
      cursor: 'not-allowed',
    },

    '.chakra-radio__label': {
      display: 'flex',
      alignItems: 'center',
      width: 'full',

      _disabled: { opacity: 1 },
    },

    '.chakra-radio__control[data-checked]': {
      background: 'unset',
      borderColor: 'white',
    },
    '.chakra-checkbox__control[data-checked]': {
      background: 'unset !important',
      borderColor: 'white !important',
      color: 'white !important',
    },
    '.chakra-radio__control[data-checked]::before': {
      background: 'white',
    },

    '.TreePicker__Node__label': {
      fontSize: 'sm',
    },
  },
};
