import { type List, fromJS } from 'immutable';
import { useMemo } from 'react';

import { withQuery } from '@burnsred/entity-duck-query';
import { Loading } from '@burnsred/ui-chakra';
import { type CompliancePlanEntityRecord } from 'entities/api/CompliancePlan/CompliancePlan.ts';
import ScenarioEntity, {
  type ScenarioEntityRecord,
} from 'entities/api/Scenario.ts';

import { useScenarioTableBody } from './ScenarioTableBody.hooks.tsx';
import { OperationAccordion } from '../OperationAccordion';

type ScenarioTableBodyProps = {
  compliancePlan: CompliancePlanEntityRecord;
  value: List<ScenarioEntityRecord>;
  columnMapping: Record<number, string>;
};

const ScenarioTableBody = (props: ScenarioTableBodyProps) => {
  const {
    siteCompliancePlans,
    groupedScenarios,
    equipmentIntersections,
    equipmentScenarioData,
  } = useScenarioTableBody(props);

  return (
    <>
      {siteCompliancePlans?.map((plan) => (
        <OperationAccordion
          key={`scenario_operation_${plan.get('uuid')}`}
          siteCompliancePlan={plan}
          groupedScenarios={groupedScenarios}
          allScenarios={props.value}
          equipmentIntersections={equipmentIntersections}
          columnMapping={props.columnMapping}
          equipmentScenarioData={equipmentScenarioData}
        />
      ))}
    </>
  );
};

export default withQuery((props) => {
  const { compliancePlan } = props;
  const params = useMemo(
    () =>
      fromJS({
        damage_energy_mechanism__damage_energy__risk: compliancePlan
          ?.get('risk')
          .get('uuid'),
        page_size: '30',
        ordering: 'mapping_id',
      }),
    [compliancePlan],
  );
  return {
    action: ScenarioEntity.duck.actions.get({
      params: params,
    }),
    processingComponent: Loading,
  };
})(ScenarioTableBody);
