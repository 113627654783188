import { Text } from '@chakra-ui/react';
import { type List } from 'immutable';
import { FormattedMessage } from 'react-intl';

import { type SiteEquipmentOperatingContextEntityRecord } from 'entities/api/CompliancePlan/SiteEquipmentOperatingContext';

import TooltipItem from './TooltipItem';

export type TooltipItemsProps = {
  mandatoryEquipmentOperation: List<SiteEquipmentOperatingContextEntityRecord>;
  assessEquipmentOperation: List<SiteEquipmentOperatingContextEntityRecord>;
};

export default function TooltipItems(props: TooltipItemsProps) {
  const { mandatoryEquipmentOperation, assessEquipmentOperation } = props;
  return (
    <Text as="div">
      {!mandatoryEquipmentOperation.isEmpty() && (
        <>
          <FormattedMessage
            id={
              'compliance.baseline-assessment.control-assessment.tooltip-items.mandatory'
            }
            defaultMessage={'Mandatory'}
          />
          <TooltipItem equipmentOperation={mandatoryEquipmentOperation} />
        </>
      )}
      {!assessEquipmentOperation.isEmpty() && (
        <>
          <FormattedMessage
            id={
              'compliance.baseline-assessment.control-assessment.tooltip-items.assess'
            }
            defaultMessage={'Assess'}
          />
          <TooltipItem equipmentOperation={assessEquipmentOperation} />
        </>
      )}
    </Text>
  );
}
