import type { SystemStyleObject } from '@chakra-ui/react';
import { type List, type Map, fromJS } from 'immutable';

import type { MPTTEntityRecord } from 'entities/api/MPTT';
import type MPTTEntity from 'entities/api/MPTT';
import type { UseLocaleReturn } from 'locales/useLocale';
import type { EntityFormProps } from 'types';

import {
  type DefaultEmptyRecord,
  type DefaultList,
  type DefaultRecord,
  type DefaultWrapper,
} from './DefaultComponents';
import { TreePickerProvider } from './TreePicker.hooks';
import { treePickerSx } from './TreePicker.styles';
import { TreePickerWrapper } from './TreePickerWrapper';

const defaultBaseParams = fromJS({
  is_active: 'true',
});

export type TreePickerProps<T extends MPTTEntityRecord> = {
  Entity: typeof MPTTEntity;
  name: string;
  value: EntityFormProps<List<T>>['value'];
  disabled?: boolean;
  onChange: EntityFormProps<T>['onChange'];
  /**
   * Allow overriding the pre-processing of the onChange call.
   *
   * Passes a context object as the second argument, which includes `allItems`,
   * a flat list of all items which were loaded in the Lists.
   */
  onChangeValue?: (item: T, { allItems }: { allItems: List<T> }) => void;

  /**
   * These are params which are unique to the target entity,
   * excluding the MPT param: parent
   */
  baseParams: Map<string, string>;

  /**
   * The parent param for first level to be displayed (eg "Vehicles").
   *
   * Defaults to `TOP_LEVEL_ID`.
   */
  baseParent?: string;

  isDisabled?: boolean;

  wrapperComponent?: typeof DefaultWrapper;
  listComponent?: typeof DefaultList;
  recordComponent?: typeof DefaultRecord;
  emptyRecordComponent?: typeof DefaultEmptyRecord;

  /**
   * Getter for the label rendered by renderComponent.
   *
   * Defaults to `(record) => toString(record)`.
   *
   * ```tsx
   * const getLabel = (item, toString) => toString(item.get('myField'));
   * ```
   */
  getLabel?: (item: T, toString: UseLocaleReturn['toString']) => string;

  /**
   * Callback determines whether an item is present in `value`
   * (because the shape of the entity may not match)
   *
   * Falls back to `value?.includes(item)`.
   */
  isSelectedCallback?: (item: T, value: List<T>) => boolean;

  /** if true, clicking an open item will close it. Defaults false. */
  allowToggle?: boolean;

  /** if passed, this will override the default styles */
  sx?: SystemStyleObject;

  /** causes helpful debug styles to be rendered (if using the default styles) */
  debug?: boolean;
};

/**
 * Renders a list of hierarchical MPTTEntities. Initially, the top level (items
 * without parents) are fetched and rendered. Any item with children is
 * clickable, and clicking will fetch and render its items.
 *
 * For more complex use cases, consider using the hooks `useTreePicker` &
 * `useTreePickerList` directly.
 */
export const TreePicker = <EntityRecordType extends MPTTEntityRecord>({
  baseParams = defaultBaseParams,
  sx = treePickerSx,
  ...rest
}: TreePickerProps<EntityRecordType>) => (
  <TreePickerProvider<EntityRecordType> {...{ baseParams, ...rest }}>
    <TreePickerWrapper sx={sx} {...rest} />
  </TreePickerProvider>
);
