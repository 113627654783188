import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Grid,
  GridItem,
  SimpleGrid,
  Text,
  useToast,
} from '@chakra-ui/react';
import { type List } from 'immutable';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { withQuery } from '@burnsred/entity-duck-query';
import { Form, Input } from '@burnsred/entity-form';
import CompliancePlanEntity, {
  type CompliancePlanEntityRecord,
} from 'entities/api/CompliancePlan/CompliancePlan';
import SiteCompliancePlanEntity, {
  type SiteCompliancePlanEntityRecord,
} from 'entities/api/CompliancePlan/SiteCompliancePlan';
import { type OperatingContextEntityRecord } from 'entities/api/OperatingContext';
import { useSubmitOnUnmount } from 'forms/hooks/submit';
import { type WithQueryReturnProps } from 'types';

import { titleSx } from './SiteCompliancePlan.styles';
import {
  EquipmentOperationWidget,
  RootEquipmentOperationWidget,
} from './Widgets/EquipmentOperationWidget';
import {
  contentGridItemProps,
  iconGridItemProps,
  innerContentGridProps,
  innerGridTitleProps,
  mainGridProps,
} from '../OperatingContext.styles';

type SiteCompliancePlanFormProps =
  WithQueryReturnProps<SiteCompliancePlanEntityRecord> & {
    siteCompliancePlan: SiteCompliancePlanEntityRecord;
    compliancePlan: CompliancePlanEntityRecord;
    operatingContexts: List<OperatingContextEntityRecord>;
  };

const SiteCompliancePlanForm = (props: SiteCompliancePlanFormProps) => {
  const {
    compliancePlan,
    operatingContexts,
    siteCompliancePlan: _,
    ...formProps
  } = props;
  const operationName = formProps.value?.get('site')?.get('name');
  const toast = useToast();
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  useSubmitOnUnmount<SiteCompliancePlanEntityRecord>(
    formProps.name,
    formProps.value,
    formProps.valueInitial,
    formProps.onSubmit,
    (record: SiteCompliancePlanEntityRecord) =>
      SiteCompliancePlanEntity.clean(record),
    (action) => {
      if (action.name == 'save_resolved') {
        toast({
          title: formatMessage(
            {
              id: 'toast.savedGrc',
              defaultMessage: 'Saved {operationName}',
            },
            { operationName },
          ),
          status: 'success',
          duration: 1000,
          isClosable: true,
        });
      }
      // The backend indicated there is a data conflict, most likely
      // that a change has occurred outside of the current tabs session (stale data)
      // @ts-expect-error - type needs updating
      if (action?.payload?.response?.status === 409) {
        // Clear the site compliance plan and compliance plan
        formProps.clear();
        // Reloads the equipment list stored in a separate entity
        dispatch(
          CompliancePlanEntity.duck.actions.clear({
            id: compliancePlan.get('uuid'),
          }),
        );
        toast({
          title: formatMessage(
            {
              id: 'toast.staleGrcData',
              defaultMessage:
                'Stale data for {operationName}, automatically refreshing',
            },
            { operationName },
          ),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    },
  );

  return (
    <Accordion defaultIndex={[0]} allowToggle>
      <Form {...formProps}>
        <AccordionItem sx={{ borderTop: 0, borderBottom: 0 }} pb={2}>
          <AccordionButton
            px={0}
            py={2}
            sx={{ backgroundColor: '#f67b2f', color: 'white' }}
            _hover={{ bg: 'f67b2f' }}
          >
            <SimpleGrid {...mainGridProps}>
              <GridItem {...contentGridItemProps}>
                <Grid {...innerContentGridProps}>
                  <GridItem {...innerGridTitleProps}>
                    <Text sx={titleSx}>{operationName}</Text>
                  </GridItem>
                  <Input
                    name="siteequipmentoperatingcontext_set"
                    component={RootEquipmentOperationWidget}
                    componentProps={{
                      compliancePlan: compliancePlan,
                      operatingContexts: operatingContexts,
                    }}
                  />
                </Grid>
              </GridItem>

              <GridItem {...iconGridItemProps}>
                <AccordionIcon />
              </GridItem>
            </SimpleGrid>
          </AccordionButton>
          <AccordionPanel pb={2} p={0}>
            <Input
              name="siteequipmentoperatingcontext_set"
              component={EquipmentOperationWidget}
              componentProps={{
                compliancePlan: compliancePlan,
                operatingContexts: operatingContexts,
              }}
            />
          </AccordionPanel>
        </AccordionItem>
      </Form>
    </Accordion>
  );
};

export default withQuery((props) => {
  const { siteCompliancePlan } = props;
  const id = siteCompliancePlan?.get('uuid');
  return {
    action: SiteCompliancePlanEntity.duck.actions.get({ id }),
    persistDirty: true,
  };
})(SiteCompliancePlanForm);
