import { type List, type Map, isImmutable } from 'immutable';

import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import type { LocaleKey } from 'locales';
import { type EntityFields, type EntityRecord } from 'types';

import I18nTranslationEntity, {
  type I18nTranslationEntityRecord,
} from './I18nTranslation';

/**
 * helper for creating type safe toString methods for Entities with translations
 *
 * ```ts
 * class MyEntity extends Entity {
 *   static fields: EntityFields<MyEntityFields> = {
 *     // ...
 *     title: new Fields.EntityField({
 *       entity: I18nTextEntity,
 *     }),
 *   };
 *
 *   static toString = toString<MyEntityRecord>;
 * }
 *
 * // gets 'title'
 * MyEntity.toString(record, locale);
 *
 * // gets 'other-field-name'
 * MyEntity.toString(record, locale, 'other-field-name');
 * ```
 */
export const toString = <T = Map<string, I18nTextEntityRecord>>(
  record: T | undefined,
  locale: LocaleKey,
  fieldName = 'title',
) => {
  if (!isImmutable(record)) return '';
  return I18nTextEntity.toString(
    record.get(fieldName as keyof T) as I18nTextEntityRecord,
    locale,
  );
};

class I18nTextEntity extends Entity {
  static fields: EntityFields<I18nTextEntityFields> = {
    uuid: new Fields.IdField({
      blank: true,
    }),
    translations: new Fields.EntityField({
      entity: I18nTranslationEntity,
      many: true,
      blank: true,
    }),
  };

  /** return the I18nTranslationEntityRecord for the requested locale, if it exists */
  static getRecordForLocale(
    record: I18nTextEntityRecord,
    locale: string,
  ): I18nTranslationEntityRecord | undefined {
    return record
      ?.get('translations')
      ?.find((translation) => translation?.get('language') == locale);
  }

  static toString(record: I18nTextEntityRecord, locale: string): string {
    if (!locale || !record) return '';
    const localeTranslation = this.getRecordForLocale(record, locale);

    return (
      localeTranslation?.get('translated_message') ||
      record?.get('translations')?.first()?.get('translated_message') ||
      ''
    );
  }

  static dataToRecord(data?: Partial<I18nTextEntityFields>) {
    return super.dataToRecord(data) as I18nTextEntityRecord;
  }
}

export type I18nTextEntityFields = {
  translations: List<I18nTranslationEntityRecord>;
  uuid: string;
};

export type I18nTextEntityRecord = EntityRecord<I18nTextEntityFields>;

I18nTextEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: I18nTextEntity,
  name: 'I18nText',
});

export default I18nTextEntity;
