import {
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { Outlet, useLocation, useNavigate } from 'react-router';

import { withQuery } from '@burnsred/entity-duck-query';
import { VStack } from 'components';
import GlobalFrameworkEntity, {
  type GlobalFrameworkEntityRecord,
} from 'entities/api/GlobalFramework';
import { useLocale } from 'locales/useLocale';
import { type EntityFormProps } from 'types';

export type GlobalFrameworkContext = {
  formControls: Pick<
    EntityFormProps<GlobalFrameworkEntityRecord>,
    | 'name'
    | 'value'
    | 'field'
    | 'onChange'
    | 'valueInitial'
    | 'onSubmit'
    | 'errors'
  >;
};

type GlobalFrameworkProps = EntityFormProps<GlobalFrameworkEntityRecord>;

const getTabName = (pathname: string, uuid: string) =>
  pathname
    // the path segment immediately after the controlUuid...
    .split(`${uuid}/`)
    .at(-1)
    // ...dropping trailing path segments
    ?.split('/')[0] as TabPathSegment;

export const GLOBAL_FRAMEWORK_TABS = {
  base: 'global-frameworks',
  tabPaths: {
    details: {
      routeName: 'details',
      index: 0,
    },
    controls: {
      routeName: 'controls',
      index: 1,
    },
    supportfactors: {
      routeName: 'supportfactors',
      index: 2,
    },
  },
} as const;

export const controlHrefCb = (frameworkUuid: string, controlUuid: string) =>
  `/${GLOBAL_FRAMEWORK_TABS.base}/${frameworkUuid}/${GLOBAL_FRAMEWORK_TABS.tabPaths.controls.routeName}/${controlUuid}`;

export const frameworkHrefCb = (frameworkUuid: string) =>
  `/${GLOBAL_FRAMEWORK_TABS.base}/${frameworkUuid}/${GLOBAL_FRAMEWORK_TABS.tabPaths.details.routeName}`;

type TabPathSegment = keyof (typeof GLOBAL_FRAMEWORK_TABS)['tabPaths'];

const GlobalFramework = (props: GlobalFrameworkProps) => {
  const { name, value, field, onChange, valueInitial, onSubmit, errors } =
    props;
  const formControls = {
    name,
    value,
    field,
    onChange,
    valueInitial,
    onSubmit,
    errors,
  };
  const { toString } = useLocale();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const { tabPaths } = GLOBAL_FRAMEWORK_TABS;
  const slug = getTabName(pathname, value?.get('uuid'));
  const currentTab = slug ? tabPaths[slug] : tabPaths.details;

  const context = {
    formControls,
  } satisfies GlobalFrameworkContext;

  /** type safe navigation factory */
  const createHandleNavigate = (slug: TabPathSegment) => () =>
    void navigate(tabPaths[slug].routeName);

  return (
    <VStack>
      <Heading>{toString(value.get('risk'))}</Heading>

      <Tabs index={currentTab.index} isLazy variant="enclosed">
        <TabList>
          <Tab onClick={createHandleNavigate('details')}>
            <FormattedMessage
              id="global-framework.tab.framework"
              defaultMessage="Context"
            />
          </Tab>
          <Tab onClick={createHandleNavigate('controls')}>
            <FormattedMessage
              id="global-framework.tab.controls"
              defaultMessage="Global controls"
            />
          </Tab>
          <Tab onClick={createHandleNavigate('supportfactors')}>
            <FormattedMessage
              id="global-framework.tab.support-factors"
              defaultMessage="Support Factors"
            />
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Outlet context={context} />
          </TabPanel>
          <TabPanel>
            <Outlet context={context} />
          </TabPanel>
          <TabPanel>
            <Outlet context={context} />
          </TabPanel>
          <TabPanel>
            <Outlet context={context} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

export type GlobalFrameworkWithQueryProps = {
  uuid: string;
};
export default withQuery((props: GlobalFrameworkWithQueryProps) => {
  return {
    action: GlobalFrameworkEntity.duck.actions.get({ id: props.uuid }),
  };
})(GlobalFramework);
