import type {
  ButtonProps,
  FlexProps,
  StackProps,
  SystemStyleObject,
} from '@chakra-ui/react';

import { RouterLink, type RouterLinkProps } from '@burnsred/ui-chakra';

export const headingSx: SystemStyleObject = {
  color: 'primary',
  fontSize: 'md',
};

export const subheadingSx: SystemStyleObject = {
  fontSize: '12px',
  color: 'gray.500',
};

export const controlListButtonSx: SystemStyleObject = {
  fontSize: '12px',
};

export const baseFlexProps: FlexProps = {
  direction: 'row',
  justifyContent: 'space-between',
};

export const buttonGroupFlexProps: FlexProps = {
  ...baseFlexProps,
  gap: 2,
};

export const controlListButtonBaseProps: ButtonProps = {
  sx: controlListButtonSx,
  variant: 'primary',
  size: 'sm',
};

export const importAssessmentButtonProps = {
  ...controlListButtonBaseProps,
  sx: {
    ...controlListButtonSx,
    minWidth: '200px',
  },
  as: RouterLink,
  to: '#',
} as ButtonProps & Partial<RouterLinkProps>;

export const pageTitleFlexProps: StackProps = {
  gap: 2,
  sx: {
    '> h2': {
      ...headingSx,
    },
    '> p': {
      ...subheadingSx,
    },
  },
};

export const pageContainerStackProps: StackProps = {
  gap: 8,
  paddingX: 'gutter',
};

export const mandatoryAssessLegendSx: SystemStyleObject = {
  paddingTop: 3,
  justifyContent: 'flex-end',
  '> div': {
    gap: 3,
    '> div': {
      gap: 1,
      alignItems: 'center',
      fontSize: 'xs',
    },
  },
};
