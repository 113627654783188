import { List, type OrderedMap } from 'immutable';

import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { createImmutableSelector } from 'entities/api/abstract.ts';
import { type EntityFields, type EntityRecord } from 'types';

import type { PredicateKey, RulePredicateEntityRecord } from './RulePredicate';
import RulePredicateEntity from './RulePredicate';

class ApplicableRuleEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/applicable_rule/',
  };

  static fields: EntityFields<ApplicableRuleEntityFields> = {
    uuid: new Fields.IdField(),
    predicates: new Fields.EntityField({
      entity: RulePredicateEntity,
      many: true,
    }),
    is_active: new Fields.BooleanField(),
    is_mandatory: new Fields.BooleanField(),
    risk: new Fields.CharField(),
    global_framework_id: new Fields.CharField(),
    page: new Fields.CharField({ default: '1', blank: true }),
    page_size: new Fields.CharField({ default: '20', blank: true }),
  };

  /** extend a list of applicableRules with predicatesByModelName */
  static groupPredicatesByModelName(
    applicableRules: List<ApplicableRuleEntityRecord>,
  ) {
    return (applicableRules?.map((rule) =>
      rule.set(
        'predicatesByModelName',
        rule
          .get('predicates')
          .groupBy((predicate) => predicate.get('model_name')),
      ),
    ) ?? List()) as List<ExtendedApplicableRuleEntityRecord>;
  }

  /** return a list of applicableRules that belong to a specific model */
  static getRulesForModel = createImmutableSelector(
    /* eslint-disable unused-imports/no-unused-vars */
    [
      (record: List<ApplicableRuleEntityRecord>, _model_name: string) => record,
      (_record: List<ApplicableRuleEntityRecord>, model_name: string) =>
        model_name,
    ],
    (record, model_name) => {
      return record?.filter((rule) =>
        rule
          .get('predicates')
          .find((predicate) => predicate.get('model_name') === model_name),
      );
    },
  );

  /** return a list of applicableRules UUIDs that belong to a specific model */
  static getIDsForModel = createImmutableSelector(
    [
      ApplicableRuleEntity.getRulesForModel,
      (_record: List<ApplicableRuleEntityRecord>, model_name: string) =>
        model_name,
    ],
    (rules, model_name) => {
      return rules
        ?.reduce((accum, rule) => {
          rule.get('predicates').forEach((predicate) => {
            if (predicate.get('model_name') === model_name) {
              accum = accum.push(predicate.get('object_id'));
            }
          });
          return accum;
        }, List())
        .toSet()
        .toList();
    },
  );

  /** Given a model name, and ID of the model instance return the first rule found  */
  static searchRulesWithModelObject = createImmutableSelector(
    [
      (
        record: List<ApplicableRuleEntityRecord>,
        model_name: string,
        object_id: string,
      ) => record,
      (
        record: List<ApplicableRuleEntityRecord>,
        model_name: string,
        object_id: string,
      ) => model_name,
      (
        record: List<ApplicableRuleEntityRecord>,
        model_name: string,
        object_id: string,
      ) => object_id,
    ],
    (record, model_name, object_id) => {
      const result = record?.filter((rule) =>
        rule
          .get('predicates')
          .find(
            (predicate) =>
              predicate?.get('model_name') === model_name &&
              predicate?.get('object_id') === object_id,
          ),
      );
      return result?.first();
    },
  );
}

export type ApplicableRuleEntityFields = {
  uuid: string;
  predicates: List<RulePredicateEntityRecord>;
  is_active: boolean;
  is_mandatory: boolean;
  risk: string;
  global_framework_id: string;
  page: string;
  page_size: string;
};

export type ApplicableRuleEntityRecord =
  EntityRecord<ApplicableRuleEntityFields>;

ApplicableRuleEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: ApplicableRuleEntity,
  name: 'ApplicableRule',
});

export default ApplicableRuleEntity;

type PredicatesByModelNameFields = {
  predicatesByModelName: OrderedMap<
    PredicateKey,
    List<RulePredicateEntityRecord>
  >;
};

/** @see ApplicableRuleEntity.groupPredicatesByModelName */
export type ExtendedApplicableRuleEntityRecord = EntityRecord<
  ApplicableRuleEntityFields & PredicatesByModelNameFields
>;
