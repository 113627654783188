import {
  Radio,
  RadioGroup,
  Stack,
  type StackProps,
  type UseRadioGroupProps,
} from '@chakra-ui/react';
import type { Map } from 'immutable';
import { useCallback, useMemo } from 'react';

import type { InputProps } from '@burnsred/entity-form';
import type { BasicEntityRecord } from 'types';
import { asEvent } from 'util/asEvent';
import { createLogger } from 'util/createLogger';

const log = createLogger('widget:WidgetRadio');

export type WidgetRadioProps<
  T extends BasicEntityRecord,
  Many extends boolean | undefined,
> = InputProps<T, Many> &
  StackProps & {
    /** filter options that are offered for user selection */
    filterOptions: () => unknown[];

    getOptionLabel?: (option: Map<string, unknown> | unknown) => string;
    transformValueFrom?: (newValue: string | number) => unknown;
  };

// TODO provide a Field wrapper for this, which renders errors, labels, etc
/**
 * Renders: RadioGroup > Stack > Radio(s)
 *
 * Pass StackProps via `componentProps`:
 *
 * ```tsx
 * <Input
 *   name="operating_contexts"
 *   component={WidgetRadio}
 *   componentProps={{ gap: 2, direction: 'column' }}
 * />
 * ```
 *
 * @default direction "row"
 * @default gap "6"
 */
export function WidgetRadio<T extends BasicEntityRecord>(
  props: WidgetRadioProps<T, false>,
) {
  const {
    name,
    onChange,
    value,
    field,
    options: rawOptions,
    transformValueFrom: _transformValueFrom,
    getOptionLabel: _gol,
    disabled,
    // StackProps we care about
    direction = 'row',
    gap = 6,
    sx,
  } = props;
  log('%o', props);

  const options = useMemo(() => {
    return rawOptions?.toJS()?.map((o) => {
      return { ...o, value: String(o.value) };
    });
  }, [rawOptions]);

  const transformValueFrom = useCallback(
    (v: string | number) => (_transformValueFrom ? _transformValueFrom(v) : v),
    [_transformValueFrom],
  );

  const handleChange: UseRadioGroupProps['onChange'] = (newValue) => {
    const valAsEvent = asEvent({
      name,
      value: transformValueFrom(newValue),
    });
    log('handleChange: %o', { newValue, valAsEvent });
    onChange?.(valAsEvent);
  };

  const getOptionLabel = useCallback(
    (option: BasicEntityRecord | unknown) => {
      return typeof props.getOptionLabel == 'function'
        ? props.getOptionLabel(option)
        : field?.toString(option);
    },
    [field, props],
  );

  return (
    <RadioGroup
      name={name}
      onChange={handleChange}
      value={String(value)}
      isDisabled={disabled}
    >
      <Stack {...{ direction, gap, sx }}>
        {options?.map((option, i) => (
          <Radio key={i} value={option.value}>
            {getOptionLabel(option)}
          </Radio>
        ))}
      </Stack>
    </RadioGroup>
  );
}
