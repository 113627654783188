import { Map } from 'immutable';

import { Entity, Fields } from '@burnsred/entity';
import ScreenDuck from '@burnsred/entity-duck-namespace-screen';
import {
  type EntityFields,
  type EntityRecord,
  type FormChangeEvent,
} from 'types';

class SupportFactorListFilterEntity extends Entity {
  static fields: EntityFields<SupportFactorListFilterEntityFields> = {
    for_risk: new Fields.CharField(),
    page: new Fields.CharField({
      default: '1',
    }),
    page_size: new Fields.CharField({
      default: '30',
    }),
  };

  static asMappedParams(record: SupportFactorListFilterEntityRecord) {
    return Map({
      for_risk: record?.get('for_risk') ? `${record?.get('for_risk')}` : '',
      page: record?.get('page') ? `${record?.get('page')}` : '1',
      page_size: record?.get('page_size') ? `${record?.get('page_size')}` : '1',
    });
  }

  static onChangeClean(
    record: SupportFactorListFilterEntityRecord,
    evt: FormChangeEvent,
    rootName: string,
  ) {
    const { name, value } = evt.target;
    if (name === rootName) {
      return evt.target.value as SupportFactorListFilterEntityRecord;
    }
    // Warning does not work with nested updates
    return record.set(name, value);
  }
}

type SupportFactorListFilterEntityFields = {
  for_risk: string;
  page_size: string;
  page: string;
};

export type SupportFactorListFilterEntityRecord =
  EntityRecord<SupportFactorListFilterEntityFields>;

SupportFactorListFilterEntity.duck = new ScreenDuck({
  app: 'Cube',
  entity: SupportFactorListFilterEntity,
  name: 'SupportFactorListFilter',
});

export default SupportFactorListFilterEntity;
