import { ChakraProvider } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { DefaultProvider } from '@burnsred/default';
import registerAppErrorInterceptor from 'api/axios-error-interceptor.ts';
import { axiosInstance, languageHeaders } from 'api/axiosInstance';
import { AppErrorModal, DebugResponsive } from 'components';
import defaults from 'defaults';
import { useCurrentUserPreferences } from 'entities/api/Person/Person';
import locales, { type LocaleKey, defaultLocale } from 'locales';
import { BrIntlContext } from 'locales/useLocale';
import router from 'routes';
import store from 'store';
import { theme as theme } from 'theme';

// Registered after store instantiation to avoid circular dependency
registerAppErrorInterceptor(axiosInstance);

function I18nContext(props: React.PropsWithChildren) {
  const { children } = props;
  const [locale, _setLocale] = useState<LocaleKey>(defaultLocale);
  const messages = useMemo(() => locales[locale], [locale]);

  const preferences = useCurrentUserPreferences();
  const setLocale = useCallback(
    (value: LocaleKey) => {
      _setLocale(value);
      // Some typescript issues with compatibility have to use as
      axiosInstance.defaults.headers['Accept-Language'] =
        languageHeaders[value as LocaleKey];
    },
    [_setLocale],
  );
  // Synchronize duplicated state redux vs local context state. duplication is necessary
  // to support authenticated and non authenticated pages (no preferences).
  useEffect(() => {
    if (preferences) {
      if (locale !== preferences.get('locale')) {
        setLocale(preferences.get('locale'));
      }
    }
  }, [preferences, locale, setLocale]);
  return (
    <BrIntlContext.Provider value={{ locale, setLocale }}>
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </BrIntlContext.Provider>
  );
}
export function App() {
  return (
    <Provider store={store}>
      <I18nContext>
        <DefaultProvider {...defaults}>
          <ChakraProvider theme={theme}>
            {import.meta.env.DEV && <DebugResponsive />}
            <RouterProvider router={router} />
            <AppErrorModal />
          </ChakraProvider>
        </DefaultProvider>
      </I18nContext>
    </Provider>
  );
}
