import { RequirementCell } from 'components/ControlGrid/components/MandatoryAssessTable/RequirementCell';
import type { EquipmentEntityRecord } from 'entities/api/Equipment';

import type { MandatoryAssessTableProps } from './MandatoryAssessTable';

export type RequirementCellsProps = Pick<
  MandatoryAssessTableProps,
  'applicableRules' | 'filterRecord' | 'operatingContexts'
> & {
  equipment: EquipmentEntityRecord;
  isFiltered: boolean;
};

export const EquipmentRequirementCells = ({
  operatingContexts,
  isFiltered,
  ...props
}: RequirementCellsProps) => {
  return (
    <>
      {operatingContexts?.map((opCtx) => (
        <RequirementCell
          key={opCtx.get('uuid')}
          operatingContext={opCtx}
          equipmentIsFiltered={isFiltered}
          {...props}
        />
      ))}
    </>
  );
};
