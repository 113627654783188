import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { type SingleValue } from 'react-select';

import type { CompliancePlanSelectorProps } from './SiteCompliancePlanSelector.tsx';
import { COMPLIANCE_TABS } from '../../tabs/COMPLIANCE_TABS.ts';

export function useCompliancePlanSelector({
  compliancePlanFormProps,
  subTabSegment,
}: CompliancePlanSelectorProps) {
  const {
    compliancePlanUuid: compliancePlanInfoUuid,
    siteCompliancePlanUuid,
    controlUuid,
  } = useParams();
  const navigate = useNavigate();

  const siteCompliancePlans = compliancePlanFormProps.value.get(
    'sitecomplianceplan_set',
  );

  const navigateToCompliancePlan = useCallback(
    (siteCompliancePlanUuid: string) => {
      const route = controlUuid
        ? `/${COMPLIANCE_TABS.base}/${compliancePlanInfoUuid}/site/${siteCompliancePlanUuid}/${subTabSegment}/control/${controlUuid}`
        : `/${COMPLIANCE_TABS.base}/${compliancePlanInfoUuid}/site/${siteCompliancePlanUuid}/${subTabSegment}`;
      navigate(route);
    },
    [compliancePlanInfoUuid, controlUuid, navigate, subTabSegment],
  );

  // auto-navigate to the first SiteCompliancePlan if no siteCompliancePlanUuid is provided
  useEffect(() => {
    if (!siteCompliancePlanUuid) {
      const firstSiteCompliancePlanUuid = siteCompliancePlans
        ?.first()
        ?.get('uuid');
      if (!firstSiteCompliancePlanUuid) return;
      navigateToCompliancePlan(firstSiteCompliancePlanUuid);
    }
  }, [siteCompliancePlans, navigateToCompliancePlan, siteCompliancePlanUuid]);

  const options = siteCompliancePlans
    .map((plan) => ({
      value: plan.get('uuid'),
      label: `${compliancePlanFormProps.value.get('name')} - ${plan.get('site').get('name')}`,
    }))
    .toArray();

  const onChange = (
    newValue: SingleValue<{ value: string; label: string }>,
  ) => {
    if (!newValue) return;
    navigateToCompliancePlan(newValue.value);
  };

  const selectedOption = siteCompliancePlanUuid
    ? options.find((option) => option.value === siteCompliancePlanUuid)
    : null;

  return { onChange, options, selectedOption };
}
