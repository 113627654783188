import { type List, Map } from 'immutable';
import { useState } from 'react';
import { useParams } from 'react-router';
import { type SingleValue } from 'react-select';

import { useQuery } from '@burnsred/entity-duck-query';
import ActionEntity, {
  type ActionEntityRecord,
} from 'entities/api/CompliancePlan/Action.ts';

type useLinkToActionModalProps = {
  onLinkAction: (action: ActionEntityRecord) => void;
  gapUuid: string;
};

export const useLinkToActionModal = (props: useLinkToActionModalProps) => {
  const [selectedActionUuid, setSelectedActionUuid] = useState<string>('');
  const { gapUuid, onLinkAction } = props;
  const { compliancePlanUuid } = useParams();
  const { value: actions } = useQuery<List<ActionEntityRecord>>({
    action: ActionEntity.duck.actions.get({
      params: Map({
        compliance_plan: compliancePlanUuid,
        exclude_linked_to_gap: gapUuid,
      }),
    }),
  });

  const onActionSelection = (
    e: SingleValue<{ label: string; value: string }>,
  ) => {
    if (!e) return;
    setSelectedActionUuid(e.value);
  };

  const linkableActions = actions
    ? actions
        .map((action) => ({
          label: action.get('title'),
          value: action.get('uuid'),
        }))
        .toJS()
    : [];

  const linkAction = () => {
    const action = actions.find(
      (action) => action.get('uuid') === selectedActionUuid,
    );
    if (!action) return;
    onLinkAction(action);
    return;
  };

  return {
    actions,
    linkableActions,
    linkAction,
    onActionSelection,
  };
};
