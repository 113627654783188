import { List } from 'immutable';
import { useMemo } from 'react';

import { getDescendants } from 'entities/api/abstract.ts';
import type { EquipmentEntityRecord } from 'entities/api/Equipment.ts';
import { useLocale } from 'locales/useLocale';

import { type EquipmentOperatingNodeProps } from './EquipmentOperatingNode';

const useEquipmentOperatingNode = (props: EquipmentOperatingNodeProps) => {
  const { equipmentNode, equipmentOperationList, operatingContexts, onChange } =
    props;

  const { toString } = useLocale();

  const descendants = getDescendants(
    equipmentNode?.get('uuid'),
    List([equipmentNode]),
  ) as List<EquipmentEntityRecord>;

  const equipmentOperatingContext = useMemo(
    () =>
      equipmentOperationList?.find(
        (operatingEquipment) =>
          operatingEquipment
            ?.get('compliance_equipment')
            ?.get('equipment')
            ?.get('uuid') === equipmentNode.get('uuid'),
      ),
    [equipmentNode, equipmentOperationList],
  );

  const childEquipmentOperationList = useMemo(
    () =>
      equipmentOperationList.filter((operatingEquipment) =>
        descendants
          .map((child) => child.get('uuid'))
          ?.contains(
            operatingEquipment
              ?.get('compliance_equipment')
              ?.get('equipment')
              ?.get('uuid'),
          ),
      ),
    [descendants, equipmentOperationList],
  );

  return {
    equipmentNode,
    equipmentOperatingContext,
    childEquipmentOperationList,
    operatingContexts,
    onChange,
    toString,
  };
};

export default useEquipmentOperatingNode;
