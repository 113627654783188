import { Checkbox, Flex, Icon, Radio } from '@chakra-ui/react';
import { List } from 'immutable';
import type { MouseEventHandler } from 'react';

import { noop } from '@burnsred/ui-chakra';
import { icons } from 'assets/icons';
import type { TreePickerRecordProps } from 'components/widgets/TreePicker/DefaultComponents';
import { createLogger } from 'util/createLogger';

const log = createLogger('RAOPickerRadio.Record');

export const Record = ({
  children,
  item,
  hasChildren,
  isOpen,
  isSelected,
  level,
  disabled,
  isIndeterminate: _isIndeterminate,
  count,
  onClick: _onClick,
  extraProps,
  ...rest
}: TreePickerRecordProps) => {
  const isCheckbox = !hasChildren;
  const Element = isCheckbox ? Checkbox : Radio;

  const { onChangeParents, onChange, setParents } = extraProps;

  /**
   * Differentiates between click intentions:
   * - clicking the checkbox should trigger onChange
   * - clicking anywhere else should trigger onChangeParents
   *
   * Note: ensure styles cause the Checkbox to completely fill its parent so it
   * catches all clicks
   */
  const handleClick: MouseEventHandler = (event) => {
    // if we don't preventDefault, this function gets called twice!
    event.preventDefault();

    /** whether we clicked on a checkbox or radio button, otherwise it's the label */
    const isCheckbox = (event.target as HTMLElement).matches(
      '.chakra-checkbox__control, .chakra-checkbox__control *, .chakra-radio__control, .chakra-radio__control *',
    );

    // note these levels are zero-indexed
    const isNPI = level == 0 && !hasChildren;
    const isOperation = level == 2;

    const debug = {
      isCheckbox,
      isSelected,
      hasChildren,
      isNPI,
      isOperation,
      level,
      item,
    };
    log('handleClick %o', debug);

    // WORKAROUND handle clicks on special cases as label clicks
    if (isCheckbox && (isOperation || isNPI)) {
      if (!disabled) {
        onChange(item);
        // ensure top-level item selection un-expands other lists
        if (level == 0) setParents(List(['None']));
      }
    } else {
      if (level == 0) {
        if (hasChildren) setParents(List(['None', item.get('uuid')]));
        else setParents(List(['None']));
      } else if (hasChildren)
        onChangeParents({
          childLevel: level + 1,
          parentUuid: item.get('uuid'),
          debug,
        });
    }
  };

  return (
    <Flex alignItems="center" onClick={handleClick} {...rest}>
      <Element
        variant="none"
        value={item.get('uuid') as string}
        isChecked={isCheckbox ? isSelected : isOpen}
        // isIndeterminate={isCheckbox ? isIndeterminate : undefined}
        onChange={noop}
        // never display the Radio as disabled, as serves navigation, not selection
        isDisabled={isCheckbox && disabled}
      >
        <span className="TreePicker__Node__label">{children}</span>

        {count && isCheckbox && (
          <span className="TreePicker__Node__count">{count}</span>
        )}

        {isOpen && isCheckbox && (
          <Icon
            as={icons.MdOutlineChevronRight}
            className="TreePicker__Node__expanded-icon"
          />
        )}
      </Element>
    </Flex>
  );
};
