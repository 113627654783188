import { List } from 'immutable';

import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import GapAttachmentEntity, {
  type GapAttachmentEntityRecord,
} from 'entities/api/CompliancePlan/GapAttachment.ts';
import { type EntityFields, type EntityRecord } from 'types';

import ActionEntity, { type ActionEntityRecord } from './Action';
import { toString } from '../i18n/I18nText';

const EMPTYACTION = ActionEntity.dataToRecord({});

class GapEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube_compliance/v1/gap/',
  };

  static fields: EntityFields<GapEntityFields> = {
    uuid: new Fields.IdField(),
    identifier: new Fields.CharField({ blank: true, readonly: true }),
    title: new Fields.CharField(),
    description: new Fields.CharField({ blank: true }),
    actions: new Fields.EntityField({
      entity: ActionEntity,
      many: true,
      blank: true,
    }),
    attachments: new Fields.EntityField({
      entity: GapAttachmentEntity,
      many: true,
    }),
  };
  static toString = toString<GapEntityRecord>;

  static updateActions(record: GapEntityRecord) {
    if (record.get('actions').isEmpty()) {
      record = record.set('actions', List([EMPTYACTION]));
    }
    return record;
  }
  // onchange / onsubmit
  static clean(record: GapEntityRecord) {
    record = this.updateActions(record);
    return record;
  }

  // On create (includes from server)
  static dataToRecord(data?: Record<string, unknown> | null) {
    let newRecord = super.dataToRecord(data);
    newRecord = this.updateActions(newRecord);
    return newRecord;
  }

  // When sending to the server
  static toData(record: GapEntityRecord) {
    if (!record.get('actions').isEmpty()) {
      record = record.set(
        'actions',
        record.get('actions').filter((action) => !EMPTYACTION.equals(action)),
      );
    }
    return super.toData(record);
  }
}

export type GapEntityFields = {
  uuid: string;
  title: string;
  description: string;
  identifier: string;
  actions: List<ActionEntityRecord>;
  attachments: List<GapAttachmentEntityRecord>;
};

export type GapEntityRecord = EntityRecord<GapEntityFields>;

GapEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: GapEntity,
  name: 'Gap',
});

export default GapEntity;
