import { Flex, FormControl, FormLabel } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { Autocomplete } from 'components/Autocomplete';
import type { CompliancePlanContext } from 'screens/compliance/CompliancePlan';

import { useCompliancePlanSelector } from './SiteCompliancePlanSelector.hooks.tsx';

export type CompliancePlanSelectorProps = {
  compliancePlanFormProps: CompliancePlanContext['compliancePlanFormProps'];
  subTabSegment: string;
};

export const SiteCompliancePlanSelector = ({
  compliancePlanFormProps,
  subTabSegment,
}: CompliancePlanSelectorProps) => {
  const { onChange, options, selectedOption } = useCompliancePlanSelector({
    compliancePlanFormProps,
    subTabSegment,
  });

  return (
    <Flex maxWidth={'560px'}>
      <FormControl>
        <FormLabel>
          <FormattedMessage
            id="compliance.site-compliance-plan-selector.label"
            defaultMessage="Compliance plans"
          />
        </FormLabel>
        <Autocomplete
          options={options}
          onChange={onChange}
          value={selectedOption}
        />
      </FormControl>
    </Flex>
  );
};
