import { useToast } from '@chakra-ui/react';
import { List, Map } from 'immutable';
import { useCallback, useMemo, useState } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import { useOutletContext } from 'react-router-dom';

import { useQuery } from '@burnsred/entity-duck-query';
import CompliancePlanEntity, {
  type CompliancePlanEntityRecord,
} from 'entities/api/CompliancePlan/CompliancePlan';
import CompliancePlanBaseEquipmentEntity from 'entities/api/CompliancePlan/CompliancePlanBaseEquipment';
import type { EquipmentEntityRecord } from 'entities/api/Equipment';
import EquipmentEntity from 'entities/api/Equipment';
import { useSubmitOnUnmount } from 'forms/hooks/submit';
import type { CompliancePlanContext } from 'screens/compliance/CompliancePlan';
import { createLogger } from 'util/createLogger';
import useCounter from 'util/useCounter';

const log = createLogger('useCompliancePlanEquipment');

const invalidDeselectionError = defineMessage({
  id: 'compliance.operating-environment.equipment.invalid-deselection',
  defaultMessage:
    'Cannot deselect; item is an ancestor of a selected equipment',
});

export function useCompliancePlanEquipment() {
  const { compliancePlanFormProps } = useOutletContext<CompliancePlanContext>();
  // extract props used to assemble the form
  const {
    value: compliancePlan,
    name,
    onChange,
    field,
  } = compliancePlanFormProps;

  // set errors manually; the Entity errors are _deeply_ nested & contain spurious errors
  const [errors, setErrors] = useState('');
  const toast = useToast();
  const { formatMessage } = useIntl();

  /**
   * @Fixme The find the base parent approach is still problematic as there isn't any restriction
   * for a global frameworks equipment set to contain two roots.
   *
   * Would prefer an api based approach to determine the first page of the equipment picker
   * so that structural assumptions don't cause side effects when they aren't mean't
   */
  const { value: baseParentData } = useQuery<List<EquipmentEntityRecord>>({
    action: EquipmentEntity.duck.actions.get({
      params: Map({
        is_active: 'true',
        page_size: '1',
        parent__isnull: 'false',
        globalframework__risk: compliancePlan?.get('risk')?.get('uuid'),
      }),
    }),
  });

  const baseParams = useMemo(() => {
    return Map({
      is_active: 'true',
      page_size: '200',
      globalframework__risk: compliancePlan?.get('risk')?.get('uuid'),
    });
  }, [compliancePlan]);

  /** UUID of the equipment baseParent that matches the risk */
  const baseParent = useMemo(
    () =>
      baseParentData
        ? baseParentData
            ?.first()
            ?.get('ancestors')
            ?.find((ancestor) => !ancestor.get('parent'))
            ?.get('uuid')
        : '',
    [baseParentData],
  );

  /**
   * TieredEquipmentPicker expects List<EquipmentEntityRecord>, but
   * complianceplanbaseequipment_set nests equipment inside
   * List<CompliancePlanBaseEquipmentEntityRecord>
   */
  const equipmentValue = useMemo(
    () =>
      compliancePlan
        ?.get('complianceplanbaseequipment_set')
        ?.map((rec) => rec?.get('equipment')) ?? List([]),
    [compliancePlan],
  );

  /**
   * Business rule: for any selected equipment, all of its ancestors must also be selected.
   *
   * Note!! This function must handle the complexity that TieredEquipmentPicker expects
   * List<EquipmentEntityRecord>, but complianceplanbaseequipment_set is
   * List<CompliancePlanBaseEquipmentEntityRecord>, which is like `{ uuid, equipment: equipmentRecord }`
   */
  const onChangeValue = useCallback(
    (
      item: EquipmentEntityRecord,
      { allItems }: { allItems: EquipmentEntityRecord[] },
    ) => {
      let complianceEquipment = compliancePlan?.get(
        'complianceplanbaseequipment_set',
      );

      // @ts-expect-error doesn't see entity correctly
      if (field.entity.hasDescendantEquipment(compliancePlan, item)) {
        setErrors(formatMessage(invalidDeselectionError));
        return;
      }

      const isItemPresentInValue = complianceEquipment.some((rec) =>
        rec?.get('equipment')?.equals(item),
      );

      if (isItemPresentInValue) {
        // DE-SELECT: Remove item from the set
        complianceEquipment = complianceEquipment.filter(
          (rec) => !rec?.get('equipment')?.equals(item),
        );
      } else {
        // SELECT: Add item and all its ancestors into the set
        const prevEquipmentUuids = complianceEquipment.map((rec) =>
          rec?.get('equipment')?.get('uuid'),
        );

        const newItem = CompliancePlanBaseEquipmentEntity.dataToRecord({}).set(
          'equipment',
          item,
        );

        const newItemAncestors = item
          .get('ancestors')
          .filter(
            (rec) =>
              !prevEquipmentUuids.includes(rec.get('uuid')) &&
              // don't consider baseParent (eg 'Vehicles & Mobile Equipment)
              rec.get('uuid') != baseParent,
          )
          .map((ancestorRecord) =>
            CompliancePlanBaseEquipmentEntity.dataToRecord({}).set(
              'equipment',
              allItems.find(
                (rec) => rec.get('uuid') == ancestorRecord.get('uuid'),
              ),
            ),
          );

        complianceEquipment = complianceEquipment.push(
          newItem,
          ...newItemAncestors,
        );
      }

      complianceEquipment = CompliancePlanEntity.cleanBaseEquipment(
        complianceEquipment,
        compliancePlanFormProps.valueInitial?.get(
          'complianceplanbaseequipment_set',
        ),
      );

      onChange({
        target: {
          name: name,
          // @ts-expect-error doesn't seem to understand field.entity is a class and clean exists
          value: field.entity.clean(
            compliancePlan.set(
              'complianceplanbaseequipment_set',
              complianceEquipment,
            ),
          ),
        },
      });

      setErrors('');
    },
    [
      baseParent,
      compliancePlan,
      field.entity,
      formatMessage,
      name,
      onChange,
      compliancePlanFormProps.valueInitial,
    ],
  );

  log('%o', {
    compliancePlan,
    equipmentValue,
    compliancePlanFormProps,
  });

  useSubmitOnUnmount<CompliancePlanEntityRecord>(
    compliancePlanFormProps.name,
    compliancePlanFormProps.value,
    compliancePlanFormProps.valueInitial,
    compliancePlanFormProps.onSubmit,
    (record: CompliancePlanEntityRecord) => CompliancePlanEntity.clean(record),
    (action) => {
      if (action.name == 'save_resolved') {
        toast({
          title: formatMessage({
            id: 'toast.savedBaseEquipment',
            defaultMessage: 'Saved base equipment',
          }),
          status: 'success',
          duration: 1000,
          isClosable: true,
        });
      }
    },
  );

  const [tieredEquipmentPickerKey, refreshTieredEquipmentPicker] = useCounter();

  const tieredEquipmentProps = useMemo(
    () => ({
      value: equipmentValue,
      onChangeValue,
      baseParent,
      baseParams,
      key: tieredEquipmentPickerKey,
    }),
    [
      equipmentValue,
      onChangeValue,
      baseParent,
      baseParams,
      tieredEquipmentPickerKey,
    ],
  );

  return {
    parentProps: compliancePlanFormProps,
    baseParams,
    baseParent,
    equipmentValue,
    errors,
    tieredEquipmentProps,
    refreshTieredEquipmentPicker,
  };
}
