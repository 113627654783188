import { Th, Thead, Tr } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { useLocale } from 'locales/useLocale';

import type { MandatoryAssessTableProps } from './MandatoryAssessTable';

export type MandatoryAssessTableHeaderRowProps = Pick<
  MandatoryAssessTableProps,
  'operatingContexts' | 'filterRecord'
>;

export const MandatoryAssessTableHeaderRow = ({
  operatingContexts,
  filterRecord,
}: MandatoryAssessTableHeaderRowProps) => {
  const { toString } = useLocale();
  const operatingContextsFiltered = filterRecord?.get('operating_context');

  return (
    <Thead>
      <Tr>
        <Th className="blank" />
        <Th className="blank" />

        {operatingContexts?.map((opCtx, i) => (
          <Th
            key={i}
            className={
              operatingContextsFiltered?.includes(opCtx)
                ? 'isFilteredColumn'
                : ''
            }
          >
            {toString(opCtx)}
          </Th>
        ))}
        <Th>
          <FormattedMessage
            id="MandatoryAssessTable.thead.scenarios"
            defaultMessage="List of scenarios for this control"
          />
        </Th>
      </Tr>
    </Thead>
  );
};
