import { List, fromJS, is } from 'immutable';
import { createSelectorCreator, defaultMemoize } from 'reselect';

import { type EntityRecord } from 'types';

export const createImmutableSelector = createSelectorCreator(defaultMemoize, {
  equalityCheck: is,
  resultEqualityCheck: is,
  maxSize: 100,
});

// @ts-expect-error needs typing done in this file
function buildLevels(root) {
  for (const child of root.children) {
    child.relative_level = root.relative_level + 1;
    buildLevels(child);
  }
}

// @ts-expect-error needs typing done in this file
function createTree(list) {
  // Algorithm expect mutability convert records to list
  if (!List.isList(list)) return [List(), List()];

  // Init vars
  const map = {};
  const existingList = list.toJS();
  existingList.forEach((item, index) => {
    // @ts-expect-error needs typing done in this file
    item.children = [];
    // Map uuid -> index of item in list
    // @ts-expect-error needs typing done in this file
    map[item.uuid] = index;
  });
  // @ts-expect-error needs typing done in this file
  const finalRootList = [];
  // Build Tree
  existingList.forEach((item) => {
    // @ts-expect-error needs typing done in this file
    if (item.parent && Object.hasOwn(map, item.parent)) {
      // Find
      // @ts-expect-error needs typing done in this file
      existingList[map[item.parent]].children.push(item);
    } else {
      // Set item level to 0 and push root
      // @ts-expect-error needs typing done in this file
      item.relative_level = 0;
      finalRootList.push(item);
    }
  });
  // @ts-expect-error needs typing done in this file
  finalRootList.forEach((treeRoot) => {
    buildLevels(treeRoot);
  });
  // @ts-expect-error needs typing done in this file
  return [list, fromJS(finalRootList)];
}

function _enhanceFromNestedTree(
  list: List<EntityRecord<unknown>>,
  treeNodeField: string[],
) {
  return list.map((item) => {
    // @ts-expect-error needs typing done in this file
    const parent_id = item.getIn(treeNodeField)?.get('parent');
    const parentItem_id = list
      // @ts-expect-error needs typing done in this file
      .find((item) => item.getIn(treeNodeField)?.get('uuid') === parent_id)
      // @ts-expect-error needs typing done in this file
      ?.get('uuid');
    return item.set('parent', parentItem_id || null);
  });
}

// @ts-expect-error needs typing done in this file
function _search(id, immutableList) {
  // Depth first search
  if (!immutableList || !List.isList(immutableList)) {
    return null;
  }
  for (const item of immutableList.toArray()) {
    // @ts-expect-error needs typing done in this file
    if (item.get('uuid') === id) {
      return item;
    } else {
      // @ts-expect-error needs typing done in this file
      const result = _search(id, item.get('children'));
      if (result && result.get('uuid') === id) {
        return result;
      }
    }
  }
  return null;
}

// @ts-expect-error needs typing done in this file
function _toDescendantsList(node, list: List<unknown> = []) {
  list = list.push(node);
  // @ts-expect-error needs typing done in this file
  node?.get('children')?.forEach((child) => {
    list = _toDescendantsList(child, list);
  });

  return list;
}

// Memoized selector of the derived Tree
export const selectComputedTree = createImmutableSelector(
  [(state, field) => state.get(field)],
  (fieldResult) => createTree(fieldResult),
);

export const createMemoizedTree = createImmutableSelector(
  [(list) => list],
  (list) => createTree(list),
);

export const createTreeEnhancedList = createImmutableSelector(
  [
    (list: List<EntityRecord<unknown>>, _fieldKey: string[]) => list,
    (list: List<EntityRecord<unknown>>, fieldKey: string[]) => fieldKey,
  ],

  (list, fieldKey) => _enhanceFromNestedTree(list, fieldKey),
);

// Memoized search of the derived Tree
export const search = createImmutableSelector(
  [(id) => id, (_, tree) => tree],
  (id, tree) => _search(id, tree),
);

// Memoized selector
export const isInTreeMemoized = createImmutableSelector(
  [search],
  (element) => !!element,
);

export const getDescendants = createImmutableSelector([search], (node) => {
  return _toDescendantsList(node, List());
});

export const getAncestors = createImmutableSelector(
  [search, (_, tree) => tree],
  (root, tree) => {
    let node = root;
    let ancestors = List();
    while (node) {
      node = _search(node?.get('parent'), tree);
      if (node) {
        ancestors = ancestors.push(node);
      }
    }
    return ancestors;
  },
);

// need to add a tree traversal function
