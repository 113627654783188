import { type List } from 'immutable';
import type { Moment } from 'moment/moment';

import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import ApplicableRuleEntity, {
  type ApplicableRuleEntityRecord,
} from '../ApplicableRule';
import type { ControlEntityRecord } from '../Control';
import ControlEntity from '../Control';
import { toString } from '../i18n/I18nText';

export const COMPLETE = 'Complete';
export const INPROGRESS = 'In progress';
export const NOTSTARTED = 'Not started';
export const TOREVIEW = 'To review';

export const ACTION_STATUS_CHOICES = [INPROGRESS, NOTSTARTED, TOREVIEW];
export const BASELINE_STATUS_CHOICES = [COMPLETE, INPROGRESS, NOTSTARTED];

export type ActionStatusEnType = (typeof ACTION_STATUS_CHOICES)[number];
export type BaselineStatusEnType = (typeof BASELINE_STATUS_CHOICES)[number];

class ApplicableControlForPlanListEntity extends Entity {
  static paginated = true;

  static paths = {
    // note: we only anticipate using the list view here
    apiBase: '/cube_compliance/v1/applicable_controls_list_only/',
  };

  static fields: EntityFields<ApplicableControlForPlanListEntityFields> = {
    uuid: new Fields.IdField({ blank: true }),
    global_control: new Fields.EntityField({
      entity: ControlEntity,
      blank: true,
    }),
    applicable_rules: new Fields.EntityField({
      entity: ApplicableRuleEntity,
      many: true,
      blank: true,
    }),

    created: new Fields.DateField(),
    modified: new Fields.DateField(),
    action_status_en: new Fields.CharField(),
    action_status_es: new Fields.CharField(),
    baselineassessment_status_en: new Fields.CharField(),
    baselineassessment_status_es: new Fields.CharField(),
    has_some_not_implemented_baseline_assessment: new Fields.BooleanField(),
    gaps: new Fields.NumberField(),
    actions: new Fields.NumberField(),
  };

  static toString = toString<ApplicableControlForPlanListEntityRecord>;
}

export type ApplicableControlForPlanListEntityFields = {
  uuid: string;
  global_control: ControlEntityRecord;
  applicable_rules: List<ApplicableRuleEntityRecord>;
  created: Moment;
  modified: Moment;
  action_status_en: ActionStatusEnType;
  action_status_es: string;
  baselineassessment_status_en: BaselineStatusEnType;
  baselineassessment_status_es: string;
  has_some_not_implemented_baseline_assessment: boolean;
  gaps: number;
  actions: number;
};

export type ApplicableControlForPlanListEntityRecord =
  EntityRecord<ApplicableControlForPlanListEntityFields>;

ApplicableControlForPlanListEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: ApplicableControlForPlanListEntity,
  name: 'ApplicableControlForPlanListOnly',
});

export default ApplicableControlForPlanListEntity;
