import { type ReactNode, createContext, useContext, useState } from 'react';
import { Outlet } from 'react-router';

function useStickyActionsProvider() {
  const [isStickActionsFooterVisible, setIsStickActionsFooterVisible] =
    useState<boolean>(true);

  const [children, setChildren] = useState<ReactNode[]>([]);

  return {
    children,
    setChildren,
    isStickActionsFooterVisible,
    setIsStickActionsFooterVisible,
  };
}

type StickyActionsContext = ReturnType<typeof useStickyActionsProvider>;

const StickyActionsContext = createContext<StickyActionsContext>(
  null as unknown as StickyActionsContext,
);

export function StickyActionsProvider() {
  return (
    <StickyActionsContext.Provider value={useStickyActionsProvider()}>
      <Outlet />
    </StickyActionsContext.Provider>
  );
}

export const useStickyActionsContext = () => {
  const context = useContext(StickyActionsContext);
  if (context === null) {
    throw new Error(
      'useStickyActions must be used within a StickyActionsProvider',
    );
  }
  return context;
};
