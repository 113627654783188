import type { List } from 'immutable';
import { useMemo } from 'react';

import { useQuery } from '@burnsred/entity-duck-query';
import { type PaginatorProps, type PaginatorState } from 'components';
import {
  SiteFrameworkListEntity,
  SiteFrameworkReportEntity,
  usePagination,
} from 'entities';
import SiteFrameworkCountEntity, {
  type SiteFrameworkCountEntityRecord,
} from 'entities/api/SiteFrameworkCountReport';
import GlobalDashboardFilterEntity, {
  type GlobalDashboardFilterEntityRecord,
} from 'entities/filter/GlobalDashboardFilter';
import type { SiteFrameworkReportEntityRecord } from 'entities/reporting/SiteFrameworkReport';
import { useEntityFilterControls } from 'forms/hooks/filters';

export function useGlobalDashboardControls() {
  const { name, filterRecord, onChange, FilterField, errors } =
    useEntityFilterControls<
      typeof GlobalDashboardFilterEntity,
      GlobalDashboardFilterEntityRecord
    >(
      GlobalDashboardFilterEntity,
      'GlobalDashboardFilters',
      GlobalDashboardFilterEntity.onChangeClean,
    );
  // memoise params to prevent a duplicate API call triggered by useSelector call
  const params = useMemo(
    () => GlobalDashboardFilterEntity.asMappedParams(filterRecord),
    [filterRecord],
  );
  const pagination = usePagination(SiteFrameworkListEntity, params);
  const onChangePagination = ({ page, page_size }: PaginatorState) => {
    // note that filterRecord stores page & page_size as strings, and Paginator requires numbers
    const newRecord = filterRecord.withMutations((rec) => {
      rec.set('page', String(page) || '1');
      rec.set('page_size', String(page_size) || '21');
    });
    onChange({
      target: { name, value: newRecord },
    });
  };
  const paginatorProps: PaginatorProps = {
    page: parseInt(filterRecord?.get('page') ?? '1'),
    page_size: parseInt(filterRecord?.get('page_size') ?? '21'),
    totalItems: pagination?.get('count') ?? 0,
    onChange: onChangePagination,
    pageSizeOptions: [21, 42],
  };

  const { value: counts } = useQuery<List<SiteFrameworkCountEntityRecord>>({
    action: SiteFrameworkCountEntity.duck.actions.get({
      params:
        GlobalDashboardFilterEntity.asMappedCountReportsParams(filterRecord),
    }),
  });
  const { value: stats } = useQuery<List<SiteFrameworkReportEntityRecord>>({
    action: SiteFrameworkReportEntity.duck.actions.get({
      params:
        GlobalDashboardFilterEntity.asMappedForReportsParams(filterRecord),
    }),
  });
  return {
    name,
    filterRecord,
    params,
    onChange,
    FilterField,
    errors,
    stats,
    paginatorProps,
    counts,
  };
}
