import { type List, Map } from 'immutable';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { useQuery } from '@burnsred/entity-duck-query';
import type { PaginatorProps, PaginatorState } from 'components';
import { usePagination } from 'entities';
import ControlEntity from 'entities/api/Control.ts';
import ControlDesignationEntity, {
  type ControlDesignationEntityRecord,
} from 'entities/api/ControlDesignation.ts';
import { useLocale } from 'locales/useLocale';
// import { type GlobalFrameworkContext } from 'screens/global-frameworks/GlobalFramework/GlobalFramework.tsx';

// import {
//   type TabPathSegment,
//   nextTabMap,
//   prevTabMap,
// } from '../ControlTab/GlobalFrameworkControlDetail.tsx';

export function useSupportFactorDetail() {
  const localeContext = useLocale();
  // const { actions, setActions } = useOutletContext<GlobalFrameworkContext>();
  // const navigate = useNavigate();
  // const { pathname } = useLocation();
  const { supportfactorUuid, _globalFrameworkUuid } = useParams();
  const [page_size, setPageSize] = useState('30');
  const [page, setPage] = useState('1');

  const [pageCounts, setPageCounts] = useState<Record<string, number>>({});
  const totalCardCount = useMemo(
    () => Object.values(pageCounts).reduce((acc, count) => acc + count, 0),
    [pageCounts],
  );

  const { value: controlDesignations, errors: _ } = useQuery<
    List<ControlDesignationEntityRecord>
  >({
    action: ControlDesignationEntity.duck.actions.get(),
  });

  // memoise params to prevent a duplicate API call triggered by useSelector call
  const params = useMemo(
    () =>
      Map({
        support_factors__in: supportfactorUuid ?? '',
        page_size,
        page,
      }),
    [supportfactorUuid, page_size, page],
  );

  const pagination = usePagination(ControlEntity, params);

  const onChangePagination = ({ page, page_size }: PaginatorState) => {
    // note that filterRecord stores page & page_size as strings, and Paginator requires numbers
    setPage(String(page) || '1');
    setPageSize(String(page_size) || '10');
  };

  const paginatorProps: PaginatorProps = {
    page: parseInt(page ?? '1'),
    page_size: parseInt(page_size ?? 30),
    totalItems: pagination?.get('count') ?? 0,
    onChange: onChangePagination,
    pageSizeOptions: [30, 60],
  };

  // @Fixme, missing actions from context
  // useEffect(() => {
  //   const lastPathSegment = pathname.split(`${supportfactorUuid}/`).at(-1);
  //   const targetBase = `/global-frameworks/${globalFrameworkUuid}/supportfactors/${supportfactorUuid}/`;

  //   setActions({
  //     ...actions,
  //     previous: () =>
  //       navigate(targetBase + prevTabMap[lastPathSegment as TabPathSegment]),
  //     next: () =>
  //       navigate(targetBase + nextTabMap[lastPathSegment as TabPathSegment]),
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pathname]);

  return {
    localeContext,
    params,
    paginatorProps,
    controlDesignations,
    setPageCounts,
    totalCardCount,
  };
}

export type UseSupportFactorDetailReturn = ReturnType<
  typeof useSupportFactorDetail
>;
