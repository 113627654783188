import {
  Box,
  Button,
  CloseButton,
  GridItem,
  HStack,
  Heading,
  ListItem,
  SimpleGrid,
  type SystemStyleObject,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { List, fromJS } from 'immutable';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useQuery } from '@burnsred/entity-duck-query';
import { Link, Loading } from '@burnsred/ui-chakra';
import { Tooltip, VStack } from 'components/atoms';
import {
  ContentBlock,
  buttonSx,
  closeButtonSx,
  col3Sx,
  gridItemSx,
  linkSx,
  listStyles,
} from 'components/ControlGrid/components/ControlPreview.styles';
import { MandatoryAssessTable } from 'components/ControlGrid/components/MandatoryAssessTable';
import { headingSx } from 'components/PerformanceCard/PerformanceCard.style';
import { RiskIcon } from 'components/RiskIcon';
import { Risk } from 'entities';
import ApplicableRuleEntity, {
  type ApplicableRuleEntityRecord,
} from 'entities/api/ApplicableRule';
import type { DamageEnergyMechanismEntityRecord } from 'entities/api/DamageEnergyMechanism';
import DamageEnergyMechanismEntity from 'entities/api/DamageEnergyMechanism';
import { type RiskEntityRecord } from 'entities/api/Risk';
import type { RulePredicateEntityRecord } from 'entities/api/RulePredicate';
import { type SupportFactorEntityRecord } from 'entities/api/SupportFactor';
import { useLocale } from 'locales/useLocale';
import type { CommonControlEntityRecord } from 'screens/global-frameworks/ControlsVisualisation/ControlsVisualisation';
import type { UseControlVisualisationControlsReturn } from 'screens/global-frameworks/ControlsVisualisation/ControlsVisualisation.hooks';
import { GLOBAL_FRAMEWORK_TABS } from 'screens/global-frameworks/GlobalFramework/GlobalFramework';
import { createLogger } from 'util/createLogger';

import {
  tooltipListItemSx,
  tooltipTextSx,
} from './MandatoryAssessTable.styles';

const log = createLogger('ControlPreview');

type ControlPreviewProps = {
  risk: RiskEntityRecord;
  control: CommonControlEntityRecord;
  setExpandedControl?: React.Dispatch<React.SetStateAction<string | null>>;
  controlHref: string;
  filterRecord?: UseControlVisualisationControlsReturn['filterRecord'];
  equipmentLevel1: UseControlVisualisationControlsReturn['equipmentLevel1'];
  equipmentLevel2: UseControlVisualisationControlsReturn['equipmentLevel2'];
  operatingContexts: UseControlVisualisationControlsReturn['operatingContexts'];
  scenarios: UseControlVisualisationControlsReturn['scenarios'];
  /** defaults true */
  renderHeader?: boolean;
  /** optionally pass a style override; pass an empty object to negate default wrapper styles */
  sx?: SystemStyleObject;
};

type SupportFactorsLinkFieldProps = {
  records: List<SupportFactorEntityRecord>;
  globalFrameworkUuid: string;
};

const SupportFactorsLinkField = ({
  records,
  globalFrameworkUuid,
}: SupportFactorsLinkFieldProps) => {
  const { toString } = useLocale();
  return (
    <VStack>
      <UnorderedList sx={listStyles} layerStyle={'nakedList'}>
        {records?.count() > 0
          ? records?.map((item, index) => (
              <ListItem key={index}>
                <Link
                  to={`/${GLOBAL_FRAMEWORK_TABS.base}/${globalFrameworkUuid}/${GLOBAL_FRAMEWORK_TABS.tabPaths.supportfactors.routeName}/${item.get('uuid')}/`}
                  sx={linkSx}
                  isExternal
                >
                  {toString(item)}
                </Link>
              </ListItem>
            ))
          : 'supportFactorsFallback'}
      </UnorderedList>
    </VStack>
  );
};

export const ControlPreview = ({
  risk,
  control,
  setExpandedControl,
  controlHref,
  filterRecord,
  equipmentLevel1,
  equipmentLevel2,
  operatingContexts,
  scenarios: scenarioOptions,
  renderHeader = true,
  sx = gridItemSx,
}: ControlPreviewProps) => {
  const globalFrameworkUuid = risk.get('global_framework_id');
  const { toString } = useLocale();

  const params = useMemo(
    () =>
      fromJS({
        control: control?.get('uuid'),
        // @Fixme: need to traverse and store the entire api result
        // @Hack: setting page_size to a high number to retrieve all rules.
        page_size: '100',
      }),
    [control],
  );

  // FIXME BC-42 error handling
  const {
    value: _applicableRules,
    processing: isLoading,
    errors: _,
  } = useQuery<List<ApplicableRuleEntityRecord>>({
    action: ApplicableRuleEntity.duck.actions.get({ params }),
  });

  const iconPath = Risk.getRiskIconPath(risk);

  const inScope = toString(control, 'in_scope');
  const outOfScope = toString(control, 'out_of_scope');

  const applicableRules = useMemo(
    () => ApplicableRuleEntity.groupPredicatesByModelName(_applicableRules),
    [_applicableRules],
  );

  const scenarioUuids = applicableRules
    .map((rule) => rule.get('predicatesByModelName'))
    .flatMap<RulePredicateEntityRecord>(
      (group) => group?.get('scenario') ?? List(),
    )
    .map((el) => el.get('object_id'))
    .toSet() // de-duplicate
    .toList();

  // TODO BC-1073 groupBy DEM
  const scenarios = scenarioUuids.flatMap((uuid) => {
    const matchingScenario = scenarioOptions?.find(
      (scenario) => scenario.get('uuid') === uuid,
    );
    return matchingScenario ? [matchingScenario] : [];
  });

  const demParams = useMemo(
    () => fromJS({ damage_energy__risk: risk.get('uuid') }),
    [risk],
  );
  const { value: _damageEnergyMechanisms } = useQuery<
    List<DamageEnergyMechanismEntityRecord>
  >({
    action: DamageEnergyMechanismEntity.duck.actions.get({
      params: demParams,
    }),
  });

  const damagingEnergyMechanisms = useMemo(() => {
    const demTitleStrings = _damageEnergyMechanisms
      ?.filter((dem, _i, _demList) =>
        dem
          ?.get('scenarios')
          ?.map((s) => s.get('uuid'))
          ?.some((uuid, _j, _uuidList) => scenarioUuids.includes(uuid)),
      )
      ?.map((el) => ({
        uuid: el.get('uuid'),
        title: toString(el),
        description: toString(el, 'description'),
      }));
    return demTitleStrings?.size
      ? demTitleStrings
      : List([{ uuid: 'none', title: '-', description: '' }]);
  }, [_damageEnergyMechanisms, scenarioUuids, toString]);

  const damageEnergyToScenarioMap = useMemo(() => {
    const mapping = new Map();
    _damageEnergyMechanisms?.forEach((dem) => {
      const damageEnergyTitle = toString(dem);
      const scenarioUuids = dem.get('scenarios').map((s) => s.get('uuid'));
      const associatedScenarios = scenarioUuids
        .map((uuid) => scenarios.find((s) => s.get('uuid') === uuid))
        .filter(Boolean); // Remove undefined values

      if (associatedScenarios.size > 0) {
        mapping.set(damageEnergyTitle, associatedScenarios);
      }
    });

    return mapping;
  }, [_damageEnergyMechanisms, scenarios, toString]);

  const maybeSupportFactors: List<string> = control
    .get('support_factors')
    .map((el) => toString(el));
  const supportFactors: List<string> = maybeSupportFactors.size
    ? maybeSupportFactors
    : List(['-']);

  log('%o', {
    risk,
    control,
    applicableRules,
    _damageEnergyMechanisms,
    damagingEnergyMechanisms,
    supportFactors,
    scenarioUuids,
    scenarios,
    scenarioOptions,
  });

  return (
    <GridItem as={VStack} colStart={1} colEnd={7} sx={sx}>
      {renderHeader && (
        <HStack gap={3}>
          <RiskIcon path={iconPath} filter="orange" />

          <Heading as="h4" variant="primary" sx={headingSx}>
            {toString(control)}
          </Heading>

          <CloseButton
            onClick={() => setExpandedControl?.(null)}
            sx={closeButtonSx}
          />
        </HStack>
      )}

      <SimpleGrid columns={3} gap={6}>
        <GridItem as={VStack} gap={4}>
          <ContentBlock
            heading={
              <FormattedMessage
                id="ControlPreview.heading.purpose"
                defaultMessage="Purpose"
              />
            }
          >
            <Text>{toString(control, 'purpose')}</Text>
          </ContentBlock>

          <ContentBlock
            heading={
              <FormattedMessage
                id="ControlPreview.heading.control-type"
                defaultMessage="Control type"
              />
            }
          >
            <Text>{toString(control.get('control_designation'))}</Text>
          </ContentBlock>

          <ContentBlock
            heading={
              <FormattedMessage
                id="ControlPreview.heading.damage-energy-mechanism"
                defaultMessage="Damaging energy mechanism"
              />
            }
          >
            <VStack>
              {damagingEnergyMechanisms.count() ? (
                <UnorderedList sx={listStyles}>
                  {damagingEnergyMechanisms.map((el) => (
                    <ListItem
                      key={el.uuid}
                      className={el.description ? 'has-tooltip' : ''}
                      sx={tooltipListItemSx}
                    >
                      {el.description ? (
                        <Tooltip
                          label={
                            <Text sx={tooltipTextSx}>{el.description}</Text>
                          }
                          placement="right-start"
                        >
                          <span>{el.title}</span>
                        </Tooltip>
                      ) : (
                        <span>{el.title}</span>
                      )}
                    </ListItem>
                  ))}
                </UnorderedList>
              ) : (
                '-'
              )}
            </VStack>
          </ContentBlock>
        </GridItem>

        <GridItem as={VStack} gap={4}>
          {inScope && (
            <ContentBlock
              heading={
                <FormattedMessage
                  id="ControlPreview.heading.in_scope"
                  defaultMessage="Scope - Included"
                />
              }
            >
              <Text>{inScope}</Text>
            </ContentBlock>
          )}

          {outOfScope && (
            <ContentBlock
              heading={
                <FormattedMessage
                  id="ControlPreview.heading.out-of-scope"
                  defaultMessage="Scope - Excluded"
                />
              }
            >
              <Text>{outOfScope}</Text>
            </ContentBlock>
          )}

          <ContentBlock
            heading={
              <FormattedMessage
                id="ControlPreview.heading.implementation-requirements"
                defaultMessage="Implementation requirements"
              />
            }
          >
            <Text whiteSpace="pre-line">
              {toString(control, 'implementation') || '-'}
            </Text>
          </ContentBlock>
        </GridItem>

        <GridItem sx={col3Sx} gap={4}>
          <ContentBlock
            heading={
              <FormattedMessage
                id="ControlPreview.heading.support-factors"
                defaultMessage="Support factors"
              />
            }
          >
            <SupportFactorsLinkField
              globalFrameworkUuid={globalFrameworkUuid}
              records={control.get('support_factors')}
            />
          </ContentBlock>
          <Button
            as={Link}
            to={controlHref}
            isExternal
            variant="primary"
            sx={buttonSx}
          >
            <FormattedMessage
              id="ControlPreview.cta.go-to-control"
              defaultMessage="Go to the control"
            />
          </Button>
        </GridItem>
      </SimpleGrid>

      <Box layerStyle="loadingWrapper">
        {isLoading ? (
          <Loading />
        ) : applicableRules.count() ? (
          <MandatoryAssessTable
            applicableRules={applicableRules}
            scenarios={scenarios}
            filterRecord={filterRecord}
            equipmentLevel1={equipmentLevel1}
            equipmentLevel2={equipmentLevel2}
            operatingContexts={operatingContexts}
            damageEnergyToScenarioMap={damageEnergyToScenarioMap}
          />
        ) : (
          // TODO test when there are no applicableRules with equipment or operatingContexts
          <FormattedMessage
            id="ControlPreview.no-applicable-rules"
            defaultMessage="No applicable rules"
          />
        )}
      </Box>
    </GridItem>
  );
};
