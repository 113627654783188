import { List, Map, fromJS } from 'immutable';
import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import type { MPTTEntityRecord } from 'entities/api/MPTT';
import { useLocale } from 'locales/useLocale';
import { createLogger } from 'util/createLogger';

import type { TreePickerProps } from './TreePicker';

const log = createLogger('TreePicker.useProvideTreePicker');

/** this is the parent param */
export const TOP_LEVEL_ID = 'None';

export const useProvideTreePicker = <T extends MPTTEntityRecord>(
  props: TreePickerProps<T>,
) => {
  const { toString } = useLocale();

  const initialParent = props.baseParent ?? TOP_LEVEL_ID;
  const emptyMapOfList = Map({
    [initialParent]: [] as unknown as List<T>,
  });
  const [lists, setLists] = useState<Map<string, List<T>>>(emptyMapOfList);

  /** a flat list of all the items in the tree */
  const allItems = useMemo(
    () => List(lists.values()).flatten(1) as List<T>,
    [lists],
  );

  const initialParents = fromJS([initialParent]);
  /** parents indicates which lists are open */
  const [parents, setParents] = useState<List<string>>(initialParents);

  // load & focus the first value
  useEffect(() => {
    const firstValue = props.value?.first();
    if (firstValue) {
      const ancestorUuids = firstValue
        .get('ancestors')
        .map((rec) => rec.get('uuid'));
      setParents((state) => {
        // workaround: prevent StrictMode duplicating parents in dev
        log('initial values', { state, ancestorUuids, firstValue });
        return state.some((rec) => ancestorUuids.includes(rec))
          ? state
          : state.concat(ancestorUuids);
      });
    }
    // only run on first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeList = useCallback(
    ({ parentUuid, list }: { parentUuid: string; list: List<T> | null }) => {
      setLists((state) => {
        const updatedLists = state.set(parentUuid, list as List<T>);
        log('onChangeList %o', {
          updatedLists: updatedLists?.toJS(),
          list: list?.toJS(),
        });
        return updatedLists;
      });
    },
    [],
  );

  /**
   * Controls how many lists/levels are rendered, and the parentUuid for each
   * (number of lists == parents.size).
   *
   * Responsible for ensuring the stale lists are cleared out
   *
   * @param childLevel - the level of the new child list to be rendered
   * @param parentUuid - the uuid of the parent of the new list
   */
  const onChangeParents = useCallback(
    ({
      childLevel,
      parentUuid,
      debug,
    }: {
      childLevel: number;
      parentUuid: string;
      debug?: unknown;
    }) => {
      const parentIndex = parents.findIndex((str) => str == parentUuid);
      const wasAlreadySet = parentIndex >= 0;

      log('onChangeParents 1 %o', {
        childLevel,
        parentUuid,
        wasAlreadySet,
        debug,
      });

      // REVIEW
      if (!props.allowToggle && wasAlreadySet) return;

      // if wasAlreadySet, clear the value
      const newValue = wasAlreadySet ? '' : parentUuid;

      const updatedParents = parents
        .set(childLevel, newValue)
        // clear all children below the update level
        .filter((p, i) => !!p && i <= childLevel);

      log('onChangeParents 2 %o', { updatedParents });

      setParents(updatedParents);

      const isListLengthChanged = lists.size != updatedParents.size;

      // update lists: push a new empty list onto the stack, or set a list to null to have it removed
      if (isListLengthChanged)
        onChangeList({
          parentUuid,
          list: wasAlreadySet ? null : fromJS([]),
        });
    },
    [lists.size, onChangeList, parents, props.allowToggle],
  );

  const getLabel = useCallback(
    (record: T) => {
      return typeof props.getLabel == 'function'
        ? props.getLabel(record, toString)
        : toString(record);
    },
    [props, toString],
  );

  /** dict of the total number of selected descendants of each node (and a total) */
  const counts = useMemo(() => {
    const counts: Record<string, number> = {
      total: props?.value?.size ?? 0,
    };

    for (const val of props?.value ?? fromJS([])) {
      // requires that values have `ancestors`!
      for (const ancestor of val?.get('ancestors') ?? []) {
        const ancestorUuid = ancestor?.get('uuid');
        if (counts[ancestorUuid]) counts[ancestorUuid]++;
        else counts[ancestorUuid] = 1;
      }
    }
    return counts;
  }, [props.value]);

  /** FIXME Not implemented: returns hard-coded false */
  const isIndeterminate = useCallback(
    (_item: T) => false, // counts[item.get('uuid')] > 0,
    [],
  );

  const onChange = useCallback(
    (item: T, debug: Record<string, unknown> = {}) => {
      if (props.onChangeValue) return props.onChangeValue(item, { allItems });

      const value = props?.value ?? fromJS([]);
      const isItemPresentInValue = value?.some(
        (rec) => rec?.get('uuid') == item.get('uuid'),
      );
      log('onChange %o', {
        item,
        prevValue: value,
        isItemPresentInValue,
        name: props.name,
        debug,
      });
      props.onChange({
        target: {
          name: props.name,
          // TODO allow passing a clean function
          value: isItemPresentInValue
            ? value.filter((rec) => rec.get('uuid') != item.get('uuid'))
            : value.push(item),
        },
      });
    },
    [allItems, props],
  );

  log('update %o', {
    parents,
    lists,
    value: props.value,
    disabled: props.disabled,
    counts,
    props,
  });

  return {
    ...props,

    lists,
    allItems,
    onChangeList,
    parents,
    setParents,
    onChangeParents,
    isIndeterminate,
    counts,

    // overrides
    getLabel,
    onChange,
  };
};

export type UseTreePickerReturn<T extends MPTTEntityRecord> = ReturnType<
  typeof useProvideTreePicker<T>
>;

const TreePickerContext = createContext<UseTreePickerReturn<MPTTEntityRecord>>(
  null as unknown as UseTreePickerReturn<MPTTEntityRecord>,
);

export function TreePickerProvider<T extends MPTTEntityRecord>({
  children,
  ...rest
}: TreePickerProps<T> & { children: ReactNode }) {
  const value = useProvideTreePicker<T>(rest);
  return (
    // @ts-expect-error Types of property 'onChange' are incompatible.
    // Type '(value: List<T>) => void' is not assignable to type '(value: List<MPTTEntityRecord>) => void'.
    <TreePickerContext.Provider value={value}>
      {children}
    </TreePickerContext.Provider>
  );
}

export const useTreePicker = () => {
  const context = useContext(TreePickerContext);
  if (context === null) {
    throw new Error('useTreePicker must be used within a TreePickerProvider');
  }
  return context;
};
