import type { SystemStyleObject } from '@chakra-ui/react';

export const modalHeaderSx: SystemStyleObject = {
  borderBottom: 0,
  paddingTop: 8,
  paddingBottom: 0,
};

export const modalBodySx: SystemStyleObject = {
  flexDirection: 'column',
  gap: 8,
  paddingY: 6,
};

export const modalFooterSx: SystemStyleObject = {
  justifyContent: 'space-between',
  paddingTop: 6,
};
