import {
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { Form, Input, type InputProps } from '@burnsred/entity-form';
import { icons } from 'assets/icons';
import { ThreeWaySwitch, VStack } from 'components';
import { AttachmentModal } from 'components/AttachmentModal';
import { FileItemRow } from 'components/FilePicker';
import { Textarea } from 'components/widgets';
import { getAttachmentUrl } from 'entities/api/BaseAttachment';
import { type BaselineAssessmentAnswerEntityRecord } from 'entities/api/CompliancePlan/BaselineAssessmentAnswer';
import BaselineAssessmentAnswerAttachmentEntity, {
  type BaselineAssessmentAnswerAttachmentEntityRecord,
} from 'entities/api/CompliancePlan/BaselineAssessmentAnswerAttachment.ts';

import { useAnswerFormControls } from './AnswerForm.hooks';
import {
  addGapWrapperSx,
  buttonProps,
  commentWrapperSx,
  gapFormWrapperSx,
  gridProps,
  iconButtonProps,
  iconProps,
  questionTextProps,
  topRowSx,
  wrapperSx,
} from './AnswerForm.styles';
import { GapForm } from '../GapForm';

export type AnswerFormFormProps =
  InputProps<BaselineAssessmentAnswerEntityRecord> & {
    value: BaselineAssessmentAnswerEntityRecord;
  };

export function AnswerForm(props: AnswerFormFormProps) {
  const {
    getI18nField,
    formProps,
    toggleShowComments,
    isCommentsVisible,
    addNewGap,
    removeGap,
    gaps,
    threeWaySwitchProps,
    fileList,
    setFileList,
    batchUploadFilerFileList,
    handleAnswerAttachmentChange,
    removeAnswerAttachment,
    isOpenFileModal,
    onOpenFileModal,
    onCloseFileModal,
  } = useAnswerFormControls(props);

  const { value, disabled } = props;

  const comments = formProps.value.get('comments');
  const attachments = formProps.value.get('attachments');
  const responseIsPositive =
    value.get('response') === value.get('positive_response');

  return (
    <Form {...props}>
      <VStack sx={wrapperSx} className="AnswerForm">
        <Flex className={disabled ? 'isDisabled' : ''} sx={topRowSx}>
          <Text as="label" {...questionTextProps}>
            {getI18nField(formProps.value, 'question_text')}
          </Text>
          {responseIsPositive && (
            <IconButton
              aria-label="Add comment"
              className={
                comments.length || attachments.size ? 'has-comment' : ''
              }
              icon={
                <Icon
                  as={
                    comments.length || attachments.size
                      ? icons.MdOutlineMessage
                      : icons.MdChatBubbleOutline
                  }
                  {...iconProps}
                />
              }
              onClick={toggleShowComments}
              isDisabled={value.get('gaps').size > 0}
              {...iconButtonProps}
            />
          )}
          <Input
            name="response"
            component={ThreeWaySwitch}
            componentProps={threeWaySwitchProps}
          />
        </Flex>

        {isCommentsVisible && (
          <VStack
            className={disabled ? 'isDisabled' : ''}
            sx={commentWrapperSx}
          >
            <Grid {...gridProps}>
              <Input
                name="comments"
                component={Textarea}
                componentProps={{
                  label: (
                    <FormattedMessage
                      id="compliance.baseline-assessment.answer-form.comments"
                      defaultMessage="Comments"
                    />
                  ),
                  variant: 'reset',
                  size: 'sm',
                }}
              />
              <GridItem>
                <Button {...buttonProps} onClick={onOpenFileModal}>
                  <FormattedMessage
                    id="compliance.baseline-assessment.answer-form.attach-media"
                    defaultMessage="Attach media"
                  />
                </Button>
              </GridItem>
            </Grid>
            <Grid {...gridProps}>
              <VStack gap={0}>
                {value
                  ?.get('attachments')
                  ?.map(
                    (
                      attachment: BaselineAssessmentAnswerAttachmentEntityRecord,
                      attachmentIndex: number,
                    ) => (
                      <FileItemRow
                        key={attachmentIndex}
                        canDelete
                        onDelete={() => {
                          removeAnswerAttachment(attachmentIndex);
                        }}
                        name={
                          (attachment?.getIn([
                            'attachment',
                            'name',
                          ]) as string) || 'unnamed'
                        }
                        url={getAttachmentUrl(attachment)}
                      />
                    ),
                  )}
              </VStack>
            </Grid>
          </VStack>
        )}

        <VStack
          className={['GapFormWrapper', disabled ? 'isDisabled' : ''].join(' ')}
          sx={gapFormWrapperSx}
        >
          <Input
            name="gaps"
            component={GapForm}
            componentProps={{ removeGap }}
            many
          />

          {!!gaps.size && (
            <HStack sx={addGapWrapperSx}>
              <Button variant="whiteOutline" size="sm" onClick={addNewGap}>
                <FormattedMessage
                  id="compliance.baseline-assessment.answer-form.add-gap"
                  defaultMessage="Add another"
                />
              </Button>
            </HStack>
          )}
        </VStack>
      </VStack>
      <AttachmentModal
        isOpen={isOpenFileModal}
        onOpen={onOpenFileModal}
        onClose={onCloseFileModal}
        // @ts-expect-error  TS2322: Type (new_attachments: GapAttachmentEntityRecord) => void is not assignable
        onConfirm={handleAnswerAttachmentChange}
        attachmentEntity={BaselineAssessmentAnswerAttachmentEntity}
        fileList={fileList}
        setFileList={setFileList}
        batchUploadFilerFileList={batchUploadFilerFileList}
        maxFiles={10}
      />
    </Form>
  );
}
