import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router';

import type { CompliancePlanContext } from 'screens/compliance/CompliancePlan';
import {
  OPERATING_ENVIRONMENT_TABS,
  type OperatingEnvironmentTabsRouteNames,
} from 'screens/compliance/tabs/OperatingEnvironment/tabs/OPERATING_ENVIRONMENT_TABS';
import { createLogger } from 'util/createLogger';

import { COMPLIANCE_TABS } from '..';

const log = createLogger('OperatingEnvironment');

const pathMap = Object.values(OPERATING_ENVIRONMENT_TABS.tabPaths).reduce(
  (acc, { index, routeName }) => ({ ...acc, [routeName]: index }),
  {} as Record<OperatingEnvironmentTabsRouteNames, number>,
);

export const OperatingEnvironment = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { compliancePlanUuid } = useParams();
  const compliancePlanContext = useOutletContext<CompliancePlanContext>();
  const { isNew } = compliancePlanContext;

  const tabSegment =
    pathname.split('/').at(-1) ??
    OPERATING_ENVIRONMENT_TABS.tabPaths.settings.routeName;

  const currentTab = pathMap[tabSegment as keyof typeof pathMap];

  log('%o', { tabSegment, currentTab });

  /** type safe navigation factory */
  const createHandleNavigate =
    (slug: OperatingEnvironmentTabsRouteNames) => () =>
      void navigate(
        `/${OPERATING_ENVIRONMENT_TABS.base}/${compliancePlanUuid}/${COMPLIANCE_TABS.tabPaths['operatingEnvironment'].routeName}/${slug}`,
      );

  const context: CompliancePlanContext = compliancePlanContext;

  return (
    <Flex layerStyle="cardLike">
      <Tabs
        index={currentTab} // control via routing
        isLazy // only load the selected tab
        variant="line"
      >
        <TabList>
          <Tab onClick={createHandleNavigate('settings')}>
            <FormattedMessage
              id="compliance.tab.operating-environment.tab.settings"
              defaultMessage="Settings"
            />
          </Tab>
          <Tab onClick={createHandleNavigate('equipment')} isDisabled={isNew}>
            <FormattedMessage
              id="compliance.tab.operating-environment.tab.equipment"
              defaultMessage="Equipment"
            />
          </Tab>
          <Tab
            onClick={createHandleNavigate('operating-context')}
            isDisabled={isNew}
          >
            <FormattedMessage
              id="compliance.tab.operating-environment.tab.operating-context"
              defaultMessage="Operating Context"
            />
          </Tab>
          <Tab onClick={createHandleNavigate('scenarios')} isDisabled={isNew}>
            <FormattedMessage
              id="compliance.tab.operating-environment.tab.scenarios"
              defaultMessage="Scenarios"
            />
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Outlet context={context} />
          </TabPanel>

          <TabPanel>
            <Outlet context={context} />
          </TabPanel>

          <TabPanel>
            <Outlet context={context} />
          </TabPanel>

          <TabPanel>
            <Outlet context={context} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};
