import { Flex, Heading } from '@chakra-ui/react';
import { useIntl } from 'react-intl';

import type { TreePickerListProps } from 'components/widgets/TreePicker/DefaultComponents';
import { cx } from 'util/cx';

import { headerSx, headingSx, listSx, sectionSx } from './List.styles';

/** Displays a list of GrcOrganisationalUnits belonging to the same parent */
export const ListComponent = ({
  children,
  level,
  role,
  className,
  ...rest
}: TreePickerListProps) => {
  const { formatMessage } = useIntl();
  return (
    <Flex
      as="section"
      className={cx('TieredEquipmentPicker__List', className)}
      sx={sectionSx}
      data-test-id={`TieredEquipmentPicker.List.level-${level}`}
      data-has-expanded-nodes={rest['data-has-expanded-nodes']}
    >
      <Flex as="header" sx={headerSx}>
        <Heading as="h4" sx={headingSx}>
          {formatMessage(
            {
              id: 'TieredEquipmentPicker.List.heading.level',
              defaultMessage: 'Level {oneIndexedLevel}',
            },
            { oneIndexedLevel: level + 1 },
          )}
        </Heading>
      </Flex>

      <Flex as="ul" sx={listSx} role={role}>
        {children}
      </Flex>
    </Flex>
  );
};
