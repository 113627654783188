import { type Map } from 'immutable';
import { useCallback, useEffect, useRef, useState } from 'react';

import {
  type EntityDuckAction,
  type EntityDuckActionResolved,
  type EntityRecord,
  type FormChangeEvent,
} from 'types';

export function useFormSubmit(
  name: string,
  onSubmit: (evt: FormChangeEvent) => EntityDuckAction,
) {
  return useCallback(
    (value: Map<string, unknown>) => {
      return onSubmit({
        target: {
          name,
          value,
        },
      });
    },
    [onSubmit, name],
  );
}

export function useSubmitOnUnmount<T extends EntityRecord<unknown>>(
  name: string,
  value: T,
  valueInitial: T,
  onSubmit: (evt: FormChangeEvent<T>) => EntityDuckAction,
  clean?: (record: T) => T,
  callback?: (action: EntityDuckActionResolved) => void,
) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  value = clean?.(value) ?? value;
  const saveSubmit = useCallback(() => {
    if (!value.equals(valueInitial) && !isSubmitting) {
      setIsSubmitting(true);
      onSubmit({
        target: {
          name,
          value,
        },
      }).promise.then((action) => {
        setIsSubmitting(false);
        callback?.(action);
      });
    }
  }, [value, valueInitial, isSubmitting, onSubmit, callback, name]);
  const submit = useRef({ submit: saveSubmit });

  // Update ref so unmount has the latest update values
  submit.current = { submit: saveSubmit };

  // Submit on unmount
  useEffect(() => {
    return () => {
      submit?.current?.submit();
    };
  }, []);
}
