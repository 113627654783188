import { Flex, Heading, SimpleGrid } from '@chakra-ui/react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router';

import {
  Paginator,
  type PaginatorState,
  SimpleEntityList,
  VStack,
} from 'components';
import SiteControlEntity from 'entities/api/SiteControl';
import { useLocale } from 'locales/useLocale';
import { ControlCard } from 'screens/control-frameworks/components/ControlCard';
import { ControlCardAdd } from 'screens/control-frameworks/components/ControlCardAdd';
import { ControlsFilters } from 'screens/control-frameworks/components/ControlsFilters';
import { type SiteFrameworkContext } from 'screens/control-frameworks/ControlFramework';
import { useControlListControls } from 'screens/control-frameworks/controls/ControlFrameworkControlsList.hooks.ts';
import { createLogger } from 'util/createLogger';

const log = createLogger('ControlFrameworkControlsList');

export function ControlFrameworkControlsList() {
  const localeContext = useLocale();
  const controlListControls = useControlListControls();
  const { siteFramework, controlCount, appliedParams } = controlListControls;
  log('%o', { siteFramework });

  const [paginatorState, setPaginatorState] = useState<PaginatorState>({
    page: 1,
    page_size: 23,
  });

  const site_controls = siteFramework?.get('controls');

  return (
    <Flex layerStyle="cardLike" gap={8}>
      <ControlsFilters {...controlListControls} />

      {/** 30/1/2025 Global (mandatory?) controls section removed as per team discussion */}

      <VStack gap={4}>
        <Heading as="h3" size="xs">
          <FormattedMessage
            id="control-framework.controls.list.local-controls"
            defaultMessage="Local controls"
          />{' '}
          ({controlCount})
        </Heading>

        <VStack layerStyle="loadingWrapper">
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={2}>
            <SimpleEntityList
              Entity={SiteControlEntity}
              params={appliedParams}
              RecordComponent={ControlCard}
              recordComponentProps={{
                localeContext,
                controlType: 'Local',
                linkTarget: '../controls/:controlUuid/',
              }}
            />
            <ControlCardAdd />
          </SimpleGrid>
        </VStack>

        <Paginator
          page={paginatorState.page}
          page_size={paginatorState.page_size}
          totalItems={site_controls.size}
          onChange={setPaginatorState}
          pageSizeOptions={[23, 47]}
        />
      </VStack>

      {/* renders the preview Drawer */}
      <Outlet context={{ siteFramework } satisfies SiteFrameworkContext} />
    </Flex>
  );
}
