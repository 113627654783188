import { useDisclosure, useToast } from '@chakra-ui/react';
import { type List, Map } from 'immutable';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

import { useQuery } from '@burnsred/entity-duck-query';
import { useFilePicker } from 'components/FilePicker';
import ActionEntity, {
  type ActionEntityRecord,
} from 'entities/api/CompliancePlan/Action';
import { type ActionAttachmentEntityRecord } from 'entities/api/CompliancePlan/ActionAttachment.ts';
import { type ActionFormFormProps } from 'screens/compliance/tabs/Actions/ActionPlanForControl/ActionForm/ActionForm.tsx';
import { type EntityDuckActionResolved } from 'types';

export function useActionFormControls(props: ActionFormFormProps) {
  const {
    name,
    onChange,
    field,
    value,
    valueInitial,
    index,
    onSubmit,
    errors,
    onChangeInput,
    options,
  } = props;

  const toast = useToast();
  const { formatMessage } = useIntl();

  const { fileList, setFileList, batchUploadFilerFileList } = useFilePicker([]);
  const {
    isOpen: isOpenFileModal,
    onOpen: onOpenFileModal,
    onClose: onCloseFileModal,
  } = useDisclosure();

  const handleActionAttachmentChange = useCallback(
    (new_attachments: ActionAttachmentEntityRecord[]) => {
      const retVal = value.withMutations((rec) => {
        let updated_attachments = rec.getIn([
          'attachments',
        ]) as List<ActionAttachmentEntityRecord>;

        new_attachments.forEach((attachment) => {
          attachment.set('Action', value);
          updated_attachments = updated_attachments.push(attachment);
        });

        rec.setIn(['attachments'], updated_attachments);
      });

      props.onChange({
        target: {
          name: name,
          index: index,
          value: retVal,
        },
      });
      onCloseFileModal();
    },

    [value, props, name, index, onCloseFileModal],
  );
  const { compliancePlanUuid } = useParams();

  const { value: allActionsInCompliancePlan } = useQuery<
    List<ActionEntityRecord>
  >({
    action: ActionEntity.duck.actions.get({
      params: Map({
        compliance_plan: compliancePlanUuid,
      }),
    }),
  });

  /**
  @fixme This is overly expensive, the toJS conversion is unnecessary as List features all needed functions
  Additionally this is re computed on each render better to move this to the ActionEntity and utilize 
  caching using reselect and the createImmutableSelector helper
*/
  const allActionsUuids = allActionsInCompliancePlan
    ? allActionsInCompliancePlan.map((action) => action.get('uuid')).toJS()
    : [];

  const removeActionAttachment = useCallback(
    (attachmentIndex: number) => {
      onChange({
        target: {
          name,
          index,
          value: value.set(
            'attachments',
            value.get('attachments').remove(attachmentIndex),
          ),
        },
      });
    },
    [name, onChange, value, index],
  );

  const saveAction = () => {
    if (!value.equals(valueInitial)) {
      onSubmit({
        target: {
          name,
          index,
          value,
        },
      }).promise.then((action: EntityDuckActionResolved) => {
        if (action.name == 'save_resolved') {
          toast({
            title: formatMessage({
              id: 'toast.savedAction',
              defaultMessage: 'Saved action',
            }),
            status: 'success',
            duration: 1000,
            isClosable: true,
          });
        } else {
          toast({
            title: formatMessage({
              id: 'toast.errorSavingAction',
              defaultMessage: 'Error saving action',
            }),
            status: 'error',
            duration: 1000,
            isClosable: true,
          });
        }
      });
    }
  };

  return {
    fileList,
    setFileList,
    batchUploadFilerFileList,
    isOpenFileModal,
    onOpenFileModal,
    onCloseFileModal,
    removeActionAttachment,
    handleActionAttachmentChange,
    saveAction,
    allActionsUuids,

    formProps: {
      name,
      field,
      onChange,
      errors,
      onChangeInput,
      onSubmit,
      value,
      valueInitial,
      index,
      options,
    },
  };
}
