import { useEffect } from 'react';

import { useQuery } from '@burnsred/entity-duck-query';
import { type InputProps } from '@burnsred/entity-form';
import { type SiteCompliancePlanEntityRecord } from 'entities/api/CompliancePlan/SiteCompliancePlan';
import BhpPersonEntity, {
  type BHPPersonEntityRecord,
} from 'entities/api/Person/BhpPerson';
import { useCurrentUser } from 'entities/api/Person/Person';

export function useSiteCompliancePlanRowWidgetHooks({
  value: siteCompliancePlan,
  onChange,
  name,
  index,
}: InputProps<SiteCompliancePlanEntityRecord, false>) {
  const currentUser = useCurrentUser();

  // currentUser is not a BHPPerson shape; fetch the valid shape
  const { value: currentPerson } = useQuery<BHPPersonEntityRecord>({
    action: BhpPersonEntity.duck.actions.get({
      id: currentUser?.get('uuid'),
    }),
  });

  useEffect(() => {
    if (currentPerson && !siteCompliancePlan.get('local_control_advisor')) {
      onChange({
        target: {
          name,
          index,
          value: siteCompliancePlan.set('local_control_advisor', currentPerson),
        },
      });
    }
  }, [currentPerson, index, name, onChange, siteCompliancePlan]);
}
