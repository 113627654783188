import { Grid, GridItem } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { Input } from '@burnsred/entity-form';
import { VStack } from 'components';

import { defaultColspan, headersGridSx } from './OwnershipTable.styles';
import { OwnershipTableHeaderWidget } from './OwnershipTableHeaderWidget';
import { SiteCompliancePlanRow } from './SiteCompliancePlanRowWidget';

export const OwnershipTable = ({
  siteCompliancePlansCount,
}: {
  siteCompliancePlansCount: number;
}) => {
  return (
    <VStack gap={0.5}>
      {/* Column headings */}
      <Grid as="header" sx={headersGridSx}>
        <GridItem colSpan={defaultColspan}>
          <FormattedMessage
            id="compliance.operating-environment.settings.ownership.asset-operation"
            defaultMessage="Asset / Operation"
          />
        </GridItem>

        <GridItem colSpan={defaultColspan}>
          <FormattedMessage
            id="compliance.operating-environment.settings.ownership.lca"
            defaultMessage="LCA"
          />
        </GridItem>

        <GridItem colSpan={defaultColspan}>
          <FormattedMessage
            id="compliance.operating-environment.settings.ownership.gm"
            defaultMessage="GM"
          />
        </GridItem>

        <GridItem colSpan={defaultColspan}>
          <FormattedMessage
            id="compliance.operating-environment.settings.ownership.vp-ap"
            defaultMessage="VP / AP"
          />
        </GridItem>
      </Grid>

      <Input
        name="sitecomplianceplan_set"
        component={OwnershipTableHeaderWidget}
      />

      <Input
        name="sitecomplianceplan_set"
        // FIXME options are not sorted consistently on update
        component={SiteCompliancePlanRow}
        componentProps={{ siteCompliancePlansCount }}
        many
      />
    </VStack>
  );
};
