import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import I18nTextEntity, { toString } from '../i18n/I18nText';
import { type TitleEntityFields } from '../types';

const MANDATORY = 'man';
const ASSESS = 'assess';
const BOTH = 'both';
const MANDATE_CHOICES = [MANDATORY, ASSESS, BOTH];

export type ControlMandate = 'man' | 'assess' | 'both';

class BaselineAssessmentQuestionEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube_compliance/v1/baseline_assessment_question/',
  };

  static fields: EntityFields<BaselineAssessmentQuestionEntityFields> = {
    uuid: new Fields.IdField({ blank: true }),
    title: new Fields.EntityField({
      entity: I18nTextEntity,
    }),
    positive_response: new Fields.BooleanField({}),
    control_mandate: new Fields.CharField({
      validators: [
        (value: string) => {
          if (!MANDATE_CHOICES.includes(value)) {
            return 'Invalid control mandate';
          }
        },
      ],
    }),
    order: new Fields.IntegerField({
      read_only: true,
    }),
  };

  static toString = toString<BaselineAssessmentQuestionEntityRecord>;
}

export type BaselineAssessmentQuestionEntityFields = TitleEntityFields & {
  positive_response: boolean;
  control_mandate: ControlMandate;
  uuid: string;
  order: number;
};

export type BaselineAssessmentQuestionEntityRecord =
  EntityRecord<BaselineAssessmentQuestionEntityFields>;

BaselineAssessmentQuestionEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: BaselineAssessmentQuestionEntity,
  name: 'BaselineAssessmentQuestionEntity',
});

export default BaselineAssessmentQuestionEntity;
