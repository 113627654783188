import { type ButtonProps, type SystemStyleObject } from '@chakra-ui/react';

export const headerSx: SystemStyleObject = {
  gap: 0,
  bgColor: 'gray.100',
};

export const header__headingSx: SystemStyleObject = {
  paddingY: 2,
  paddingX: 4,
  justifyContent: 'space-between',

  '&.is-mandatory': {
    bgColor: 'brandDustyOrange',
    color: 'white',
  },

  '&.is-assess': {
    bgColor: 'gray.400',
    color: 'white',
  },
};

export const header__contentSx: SystemStyleObject = {
  padding: 4,
};

export const deleteButtonProps: ButtonProps = {
  variant: 'ghost',
  size: 'xs',
  border: '1px',
  borderColor: 'transparent',

  sx: {
    color: 'white',
    _hover: {
      bgColor: 'errorFg',
      borderColor: 'white',
      color: 'white',
    },
  },
};

export const modalButtonProps: ButtonProps = {
  sx: {
    minWidth: 24,
  },
};
