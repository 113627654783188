import type { SystemStyleObject } from '@chakra-ui/react';

/**
 * Colors can be themed by setting CSS variables:
 *
 * ```tsx
 * <EquipmentPicker sx={{
 *    ...equipmentPickerSx,
 *    '--active-selected': 'var(--chakra-colors-blue-400)',
 *    '--active-selected-strong': 'var(--chakra-colors-blue-500)',
 *  }}
 * />
 *
 * Most of the styling complexity in here is concentrated in <div className="chakra-checkbox">
 *
 * Overlapping states to consider are:
 *
 * - Node.is-selected / Node > .chakra-checkbox[data-checked]
 * - Node.has-children
 * - Node[data-expanded] (ie when the current Nodes's children are shown in the following List)
 * - Node[data-has-selected-children]
 * - Node[aria-disabled]
 * - List[data-has-expanded-nodes] (ie when any child of the current List is expanded)
 *
 * Note: some selectors (esp addressing .chakra-checkbox) are not nested to due to
 * postCSS inheritance challenges with `&`.
 * ```
 */
export const equipmentPickerSx: SystemStyleObject = {
  '--light-contrast': 'white',
  '--inactive': 'var(--chakra-colors-gray-100)',
  '--active-selected': 'var(--chakra-colors-brandDustyOrange)',
  '--active-selected-strong': 'var(--chakra-colors-primary)',
  '--passive-selected': 'var(--chakra-colors-gray-400)',
  '--disabled': 'var(--chakra-colors-gray-200)',
  '--disabled-strong': 'var(--chakra-colors-gray-400)',
  '--rounded': 'var(--chakra-radii-base)',

  '&.TreePicker__Wrapper': {
    display: 'flex',
    flexDirection: 'row',
    gap: 2.5,
    // TODO scrollIntoViewIfNeeded
    overflowX: 'auto',

    fontSize: 'sm',
  },

  '.TreePicker__List': {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    minWidth: 72,
  },

  '.TreePicker__Node': {
    // .chakra-checkbox hosts the bgColor for all states
    '.chakra-checkbox': {
      // ensure that the checkbox label fills the entire row, to capture clicks
      display: 'flex',
      width: 'full',
      padding: 2,
      rounded: 'var(--rounded)',
      bgColor: 'var(--inactive)',
      color: 'bodyText',

      // don't show pointer unless .has-children
      cursor: 'default',
    },

    // base selected style: show as passive-selected
    '.chakra-checkbox[data-checked]': {
      bgColor: 'var(--passive-selected)',
      color: 'var(--light-contrast)',
    },

    // if we're navigating through this node, show active
    '&[data-expanded="true"] .chakra-checkbox': {
      bgColor: 'var(--active-selected)',
      color: 'var(--light-contrast)',
    },

    '.chakra-checkbox__label': {
      display: 'flex',
      alignItems: 'center',
      width: 'full',

      _disabled: { opacity: 1 },
    },

    '.chakra-checkbox__control': {
      cursor: 'pointer',

      _checked: {
        bgColor: 'transparent',
        borderColor: 'var(--active-selected-strong)',
        color: 'var(--active-selected-strong)',

        _disabled: {
          color: 'var(--disabled-strong)',
          borderColor: 'var(--disabled)',
        },
      },

      _disabled: {
        bgColor: 'var(--disabled)',
        borderColor: 'var(--disabled-strong)',
      },
    },

    [[
      '&[data-has-selected-children="true"] .chakra-checkbox__control',
      '[data-checked] .chakra-checkbox__control',
    ].join(', ')]: {
      borderColor: 'var(--light-contrast)',
      color: 'var(--light-contrast)',

      _disabled: {
        borderColor: 'var(--disabled)',
      },
    },

    '&.has-children': {
      '.chakra-checkbox': { cursor: 'pointer' },
    },

    _disabled: {
      '.chakra-checkbox__control': {
        cursor: 'not-allowed',
      },
    },

    '.TreePicker__Node__label': {
      marginRight: 'auto',
    },

    '.TreePicker__Node__count': {
      paddingLeft: 2,

      _before: { content: '"("' },
      _after: { content: '")"' },
    },

    '.TreePicker__Node__expanded-icon': {
      width: 6,
      height: 6,
      color: 'var(--passive-selected)',
    },

    [[
      '&[data-expanded="true"] .TreePicker__Node__expanded-icon',
      '[data-checked] .TreePicker__Node__expanded-icon',
    ].join(', ')]: {
      color: 'var(--light-contrast)',
    },
  },

  '.TreePicker__DefaultEmptyRecord': {
    padding: 2,
    rounded: 'base',
    bgColor: 'var(--inactive)',
  },
};
