import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Grid,
  GridItem,
  useToast,
} from '@chakra-ui/react';
import { type List, type OrderedMap } from 'immutable';
import { useIntl } from 'react-intl';

import { withQuery } from '@burnsred/entity-duck-query';
import { Form } from '@burnsred/entity-form';
import SiteCompliancePlanEntity, {
  type SiteCompliancePlanEntityRecord,
} from 'entities/api/CompliancePlan/SiteCompliancePlan.ts';
import { type DamageEnergyMechanismEntityRecord } from 'entities/api/DamageEnergyMechanism.ts';
import { type ScenarioEntityRecord } from 'entities/api/Scenario.ts';
import { useSubmitOnUnmount } from 'forms/hooks/submit.ts';
import { type WithQueryReturnProps } from 'types';

import { GroupedScenarioAccordion } from './GroupedScenarioAccordion';
import { useCompliancePlanScenario } from './OperationAccordion.hooks.tsx';
import { OperationAccordionSx } from './OperationAccordion.style.tsx';
import { type equipmentScenarioDataType } from '../ScenarioTableBody/ScenarioTableBody.hooks.tsx';

const defaultColspan = { base: 2, lg: 2 };

type OperationAccordionProps =
  WithQueryReturnProps<SiteCompliancePlanEntityRecord> & {
    groupedScenarios: OrderedMap<
      DamageEnergyMechanismEntityRecord,
      List<ScenarioEntityRecord>
    >;
    allScenarios: List<ScenarioEntityRecord>;
    equipmentIntersections: boolean[];
    columnMapping: Record<number, string>;
    equipmentScenarioData: equipmentScenarioDataType | null;
  };

const OperationAccordion = (props: OperationAccordionProps) => {
  const toast = useToast();
  const { formatMessage } = useIntl();

  const operationName = props.value?.get('site')?.get('name');
  const {
    groupedScenarios,
    allScenarios,
    equipmentIntersections,
    columnMapping,
    equipmentScenarioData,
    ...parentProps
  } = props;

  const { onChangeValue } = useCompliancePlanScenario({
    allScenarios,
    parentProps,
  });

  useSubmitOnUnmount<SiteCompliancePlanEntityRecord>(
    parentProps.name,
    parentProps.value,
    parentProps.valueInitial,
    parentProps.onSubmit,
    (record: SiteCompliancePlanEntityRecord) =>
      SiteCompliancePlanEntity.clean(record) as SiteCompliancePlanEntityRecord,
    (action) => {
      if (action.name == 'save_resolved') {
        toast({
          title: formatMessage(
            {
              id: 'toast.savedGrc',
              defaultMessage: 'Saved {operationName}',
            },
            { operationName },
          ),
          status: 'success',
          duration: 1000,
          isClosable: true,
        });
      }
    },
  );

  return (
    <Accordion defaultIndex={[0]} allowToggle sx={OperationAccordionSx}>
      <AccordionItem>
        <AccordionButton>
          <Grid>
            <GridItem colSpan={{ base: 8, lg: 8 }}>{operationName}</GridItem>
            <GridItem colSpan={defaultColspan}>
              <AccordionIcon />
            </GridItem>
          </Grid>
        </AccordionButton>
        <AccordionPanel pb={2} p={0}>
          <Form {...parentProps}>
            {groupedScenarios.entrySeq().map(([mechanism, scenarios], i) => {
              return (
                <GroupedScenarioAccordion
                  mechanism={mechanism}
                  scenarios={scenarios}
                  key={`grouped_${i}`}
                  onChangeValue={onChangeValue}
                  planScenarios={parentProps.value?.get('scenarios')}
                  equipmentIntersections={equipmentIntersections}
                  columnMapping={columnMapping}
                  equipmentScenarioData={equipmentScenarioData}
                />
              );
            })}
          </Form>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
export default withQuery((props) => {
  const { siteCompliancePlan } = props;
  const id = siteCompliancePlan?.get('uuid');
  return {
    action: SiteCompliancePlanEntity.duck.actions.get({ id }),
    persistDirty: true,
  };
})(OperationAccordion);
