import type {
  ButtonProps,
  GridProps,
  IconButtonProps,
  IconProps,
  SystemStyleObject,
  TextProps,
} from '@chakra-ui/react';

const commonLayoutSx: SystemStyleObject = {
  paddingX: 3,
  bgColor: 'gray.100',
};

export const wrapperSx: SystemStyleObject = {
  gap: 0,
};

export const topRowSx: SystemStyleObject = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: 'full',
  gap: 6,
  paddingY: 1,
  ...commonLayoutSx,

  '&.isDisabled': {
    pointerEvents: 'none',
    opacity: 0.5,
  },
};

export const questionTextProps: TextProps = {
  sx: {
    marginRight: 'auto',
    fontSize: 'sm',
  },
};

export const iconButtonProps: Partial<IconButtonProps> = {
  size: 'sm',
  fontSize: '22px',
};

export const iconProps: IconProps = {
  sx: {
    color: 'gray.300',

    _hover: { cursor: 'pointer' },

    '.has-comment &': { color: 'bodyText' },
  },
};

export const commentWrapperSx: SystemStyleObject = {
  ...commonLayoutSx,
  paddingY: 3,
  '&.isDisabled': {
    pointerEvents: 'none',
    opacity: 0.5,
  },
};

export const gapFormWrapperSx: SystemStyleObject = {
  ...commonLayoutSx,
  gap: 6,
  '&.isDisabled': {
    pointerEvents: 'none',
    opacity: 0.5,
  },
};

export const addGapWrapperSx: SystemStyleObject = {
  paddingBottom: 6,
};

export const gridProps: GridProps = {
  gridTemplateColumns: '6fr 2fr 2fr',
  gap: 6,
};

export const buttonProps: ButtonProps = {
  variant: 'whiteOutline',
  size: 'sm',

  sx: {
    width: '50%',
    // attempt to match the label height of neighbouring field labels
    marginTop: '29px',
  },
};
