import { Entity, Fields } from '@burnsred/entity';
import type { EntityFields, EntityRecord } from 'types.ts';

import I18nTextEntity from './i18n/I18nText.ts';
import { type TimeZoneEntityRecord } from './TimeZone.ts';

class FilerFileEntity extends Entity {
  static fields: EntityFields<FilerFileFields> = {
    id: new Fields.NumberField(),
    name: new Fields.CharField(),
    url: new Fields.CharField(),
  };

  static toString(record: TimeZoneEntityRecord, locale = '') {
    return I18nTextEntity.toString(record.get('title'), locale);
  }
}

export type FilerFileFields = {
  id: number;
  name: string;
  url: string;
};

export type FilerFileEntityRecord = EntityRecord<FilerFileFields>;

export default FilerFileEntity;
