import { type List, fromJS } from 'immutable';
import { useMemo } from 'react';
import { useParams } from 'react-router';

import { useQuery } from '@burnsred/entity-duck-query';
import ApplicableControlForPlanReportEntity, {
  type ApplicableControlForPlanReportEntityFields,
  type ApplicableControlForPlanReportEntityRecord,
} from 'entities/api/CompliancePlan/ApplicableControlForPlanReport';

export const useActionPlanStats = () => {
  const { siteCompliancePlanUuid } = useParams();

  const params = useMemo(
    () => fromJS({ site_compliance_plan: siteCompliancePlanUuid }),
    [siteCompliancePlanUuid],
  );
  const { value: compliancePlanDashboardReportList } = useQuery<
    List<ApplicableControlForPlanReportEntityRecord>
  >({
    action: ApplicableControlForPlanReportEntity.duck.actions.get({
      params,
    }),
  });

  const applicableControlForPlanReport = (compliancePlanDashboardReportList
    ?.first()
    ?.toJS() ?? {}) as ApplicableControlForPlanReportEntityFields;

  return {
    ...applicableControlForPlanReport,
  };
};
