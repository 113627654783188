import { useDisclosure } from '@chakra-ui/react';
import { List } from 'immutable';
import { useCallback, useState } from 'react';

import { useFilePicker } from 'components/FilePicker';
import BaselineAssessmentAnswerEntity from 'entities/api/CompliancePlan/BaselineAssessmentAnswer.ts';
import type { BaselineAssessmentAnswerAttachmentEntityRecord } from 'entities/api/CompliancePlan/BaselineAssessmentAnswerAttachment.ts';

import { type ImplementationAnswerFormFormProps } from './ImplementationAnswerForm.tsx';

export function useImplementationAnswerForm(
  props: ImplementationAnswerFormFormProps,
) {
  const { name, onChange, value, index } = props;

  const {
    isOpen: isOpenWarningModal,
    onOpen: onOpenWarningModal,
    onClose: onCloseWarningModal,
  } = useDisclosure();

  const [isCommentsVisible, setIsCommentsVisible] = useState(() => {
    const isMandatory = value.get('is_mandatory');
    const isImplemented = value.get('is_control_being_implemented');

    if (isImplemented === null) return false;
    return (isMandatory && !isImplemented) || (!isMandatory && !isImplemented);
  });

  const toggleShowComments = useCallback(
    () => setIsCommentsVisible(!isCommentsVisible),
    [isCommentsVisible],
  );

  const determineCommentVisibility = useCallback(
    (response: boolean) => {
      setIsCommentsVisible(value.get('is_mandatory') ? response : !response);
    },
    [value, setIsCommentsVisible],
  );

  const determineResponseProps = useCallback(
    () => [
      { value: 'false', label: '' },
      ...(value.get('is_mandatory') ? [] : [{ value: 'null', label: '' }]),
      { value: 'true', label: '' },
    ],
    [value],
  );

  const determineValue = useCallback(() => {
    const isMandatory = value.get('is_mandatory');
    const isImplemented = value.get('is_control_being_implemented');

    if (isMandatory && isImplemented === null) return 'false';
    if (!isMandatory && isImplemented === null) return 'null';
    return isMandatory === isImplemented ? 'false' : 'true';
  }, [value]);

  const determineSaveValue = useCallback(
    (val: boolean | null) => {
      const isMandatory = value.get('is_mandatory');

      if (val === null) return isMandatory;
      return isMandatory ? !val : val;
    },
    [value],
  );

  const updateImplementationStatus = useCallback(
    (val: boolean) => {
      const invertedResponse = determineSaveValue(val);
      let updatedValue = value.set(
        'is_control_being_implemented',
        invertedResponse,
      );

      if (updatedValue.get('is_control_being_implemented')) {
        updatedValue = updatedValue
          // REVIEW should baselineAssessment.attachments be cleared?
          .set('attachments', List())
          .set('non_implementation_reason', '');
      } else {
        const answersWithNullResponsesNoGaps = updatedValue
          .get('answers')
          .map((answer) =>
            BaselineAssessmentAnswerEntity.updateGaps(
              answer.set('response', null),
            ),
          );
        updatedValue = updatedValue.set(
          'answers',
          answersWithNullResponsesNoGaps,
        );
      }

      onChange({ target: { name, index, value: updatedValue } });
      determineCommentVisibility(val);
    },
    [
      determineSaveValue,
      value,
      onChange,
      name,
      index,
      determineCommentVisibility,
    ],
  );

  /** converts the string values of the Switch */
  const onChangeResponse = useCallback(
    (stringVal: string) => {
      const val = stringVal === 'true';

      if (
        // Mandatory - when requesting not to implement control
        (value.get('is_mandatory') && val) ||
        // Assess - selecting not planning to implement control
        (!value.get('is_mandatory') && !val)
      ) {
        onOpenWarningModal();
        return;
      }

      updateImplementationStatus(val);
    },
    [value, updateImplementationStatus, onOpenWarningModal],
  );

  const confirmChange = useCallback(() => {
    const val = !!value.get('is_mandatory');
    updateImplementationStatus(val);
    onCloseWarningModal();
  }, [value, updateImplementationStatus, onCloseWarningModal]);

  const { fileList, setFileList, batchUploadFilerFileList } = useFilePicker([]);
  const {
    isOpen: isOpenFileModal,
    onOpen: onOpenFileModal,
    onClose: onCloseFileModal,
  } = useDisclosure();

  const handleImplementationAttachmentChange = useCallback(
    (new_attachments: BaselineAssessmentAnswerAttachmentEntityRecord[]) => {
      const retVal = value.withMutations((rec) => {
        let updated_attachments = rec.getIn([
          'attachments',
        ]) as List<BaselineAssessmentAnswerAttachmentEntityRecord>;

        new_attachments.forEach((attachment) => {
          updated_attachments = updated_attachments.push(attachment);
        });

        rec.setIn(['attachments'], updated_attachments);
      });

      props.onChange({
        target: {
          name: name,
          index: index,
          value: retVal,
        },
      });
      onCloseFileModal();
    },

    [value, props, name, index, onCloseFileModal],
  );

  const removeImplementationAttachment = useCallback(
    (attachmentIndex: number) => {
      onChange({
        target: {
          name,
          index,
          value: value.set(
            'attachments',
            value.get('attachments').remove(attachmentIndex),
          ),
        },
      });
    },
    [name, onChange, value, index],
  );

  const responseProps = {
    options: determineResponseProps(),
    value: determineValue(),
    onChange: onChangeResponse,
    unsetEnabled: false,
    positiveAnswerColor: value.get('is_mandatory') ? '#e53e3e' : '#28cd41',
  };

  return {
    responseProps,
    toggleShowComments,
    isCommentsVisible,
    fileList,
    setFileList,
    batchUploadFilerFileList,
    isOpenFileModal,
    onOpenFileModal,
    onCloseFileModal,
    handleImplementationAttachmentChange,
    removeImplementationAttachment,
    isOpenWarningModal,
    onCloseWarningModal,
    confirmChange,
  };
}
