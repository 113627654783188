import type { SystemStyleObject } from '@chakra-ui/react';

export const stickyActionsFooterSx: SystemStyleObject = {
  position: { base: 'static', sm: 'sticky' },
  width: 'full',
  bottom: '0',
  paddingTop: 4,
  paddingBottom: 6,

  bgColor: 'white',
  shadow: 'southToNorth',

  '.action-button--previous': {
    width: { base: 'full', sm: 'auto' },
    marginRight: 'auto',
  },
};

export const stickyActionsFooterInnerSx: SystemStyleObject = {
  flexDirection: { base: 'column', sm: 'row' },
  justifyContent: 'flex-end',
  width: 'var(--chakra-sizes-container-xl)',
  marginX: 'auto',
  paddingRight: 'gutter',
  paddingLeft: {
    base: 'gutter',
    xl: 'calc(var(--chakra-sizes-navColumnWidth) + var(--chakra-space-gutter))',
  },
  gap: 4,
};
