import { type List, fromJS } from 'immutable';
import { useMemo, useState } from 'react';

import { useQuery } from '@burnsred/entity-duck-query';
import type { PaginatorProps, PaginatorState } from 'components';
import { usePagination } from 'entities';
import SiteCompliancePlanRowEntity, {
  type SiteCompliancePlanRowEntityRecord,
} from 'entities/api/CompliancePlan/SiteCompliancePlanRow.ts';
import { useCurrentUser } from 'entities/api/Person/Person.ts';
import { createLogger } from 'util/createLogger';

const log = createLogger('useCompliancePlansDashboard');

const DEFAULT_PAGE_SIZE = '20';

export function useCompliancePlansDashboard() {
  const currentUser = useCurrentUser();
  const [page, setPage] = useState<string>('1');
  const [page_size, setPageSize] = useState<string>(DEFAULT_PAGE_SIZE);

  const params = useMemo(
    () =>
      fromJS({
        user_uuid: currentUser?.get('uuid'),
        ordering: '-created',
        page,
        page_size,
      }),
    [currentUser, page, page_size],
  );

  const { value: siteCompliancePlanRows } = useQuery<
    List<SiteCompliancePlanRowEntityRecord>
  >({
    action: SiteCompliancePlanRowEntity.duck.actions.get({ params }),
    persist: false,
  });

  const pagination = usePagination<SiteCompliancePlanRowEntityRecord>(
    SiteCompliancePlanRowEntity,
    params,
  );

  const paginatorProps: PaginatorProps = {
    page: parseInt(page),
    page_size: parseInt(page_size),
    totalItems: pagination?.get('count') ?? 0,
    onChange: ({ page, page_size }: PaginatorState) => {
      // note that filterRecord stores page & page_size as strings, and Paginator requires numbers
      setPage(String(page) || '1');
      setPageSize(String(page_size) || DEFAULT_PAGE_SIZE);
    },
    pageSizeOptions: [20, 40, 60],
  };

  log('%o', { siteCompliancePlanRows });

  return {
    siteCompliancePlanRows,
    paginatorProps,
  };
}
