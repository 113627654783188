import type { SystemStyleObject } from '@chakra-ui/react';

const cellStyles: SystemStyleObject = {
  paddingX: 2,
  borderColor: 'white',
  borderBottomWidth: '2px',
  fontSize: 'sm',
  height: '38px',
  lineHeight: '20px',
  verticalAlign: 'middle',
  '&:first-of-type': {
    paddingLeft: '8px !important',
  },
  '&:last-of-type': {
    paddingRight: '8px !important',
    paddingLeft: 0,
    width: '170px',
  },
  '&.left': {
    paddingX: 0,
    textAlign: 'left',
  },
  '&.right': {
    paddingX: 0,
    textAlign: 'right',
  },
  '&.center': {
    paddingX: 0,
    textAlign: 'center',
  },
};

export const tableSx: SystemStyleObject = {
  '&.chakra-table': {
    paddingX: 10,
    boxSizing: 'border-box',
  },
  tr: {
    '&.isInterruption': {
      color: 'error',
    },
    backgroundColor: 'gray.100',
  },
  th: {
    ...cellStyles,
    paddingRight: 0,
    verticalAlign: 'bottom',
    fontWeight: 'normal',
    textTransform: 'inherit',
    color: 'muted',
    borderBottom: 'none', // Remove bottom border of header cells
    paddingY: 2,
    backgroundColor: 'white',
  },
  td: {
    ...cellStyles,
  },
};

export const mandatoryAssessLegendSx: SystemStyleObject = {
  paddingTop: 3,
  justifyContent: 'flex-end',
  '> div': {
    gap: 3,
    '> div': {
      gap: 1,
      alignItems: 'center',
      fontSize: 'xs',
    },
  },
};
