import { ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { type List } from 'immutable';

import { type SiteEquipmentOperatingContextEntityRecord } from 'entities/api/CompliancePlan/SiteEquipmentOperatingContext';
import { useLocale } from 'locales/useLocale';

export type TooltipItemProps = {
  equipmentOperation: List<SiteEquipmentOperatingContextEntityRecord>;
};
export default function TooltipItem(props: TooltipItemProps) {
  const { toString } = useLocale();
  const { equipmentOperation } = props;
  return (
    <UnorderedList>
      {equipmentOperation.map((item) => (
        <ListItem key={item.get('uuid')}>
          {toString(item?.get('compliance_equipment')?.get('equipment'))}
          {' - '}
          {item?.get('operating_contexts')?.map((operatingContext) => (
            <Text as="span" key={operatingContext.get('uuid')}>
              {toString(operatingContext)}{' '}
            </Text>
          ))}
        </ListItem>
      ))}
    </UnorderedList>
  );
}
