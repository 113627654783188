import { Grid } from '@chakra-ui/react';
import { fromJS } from 'immutable';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { withQuery } from '@burnsred/entity-duck-query';
import { PerformanceCard } from 'components';
import { performanceCardGridSx } from 'components/PerformanceCard/PerformanceCard.style';
import CompliancePlanDashboardReportEntity, {
  type CompliancePlanDashboardReportEntityRecord,
} from 'entities/api/CompliancePlan/CompliancePlanDashboardReport.ts';
import { useCurrentUser } from 'entities/api/Person/Person.ts';
import type { WithQueryReturnProps } from 'types';

type ComplianceStatsProps =
  WithQueryReturnProps<CompliancePlanDashboardReportEntityRecord>;

const ComplianceStats = ({
  value: compliancePlanDashboardReportList,
}: ComplianceStatsProps) => {
  const compliancePlanDashboardReport =
    compliancePlanDashboardReportList.first() as CompliancePlanDashboardReportEntityRecord;

  return (
    <Grid sx={performanceCardGridSx}>
      <PerformanceCard
        heading={
          <FormattedMessage
            id="compliance.stats.plans-in-progress"
            defaultMessage="Plans in progress"
          />
        }
        stats={[
          {
            label: (
              <FormattedMessage
                id="compliance.stats.plans-in-progress"
                defaultMessage="Plans in progress"
              />
            ),
            metric: compliancePlanDashboardReport.get('plans_in_progress'),
          },
        ]}
      />

      <PerformanceCard
        heading={
          <FormattedMessage
            id="compliance.stats.controls-assessed"
            defaultMessage="Controls assessed"
          />
        }
        stats={[
          {
            label: (
              <FormattedMessage
                id="compliance.stats.controls-assessed"
                defaultMessage="Controls assessed"
              />
            ),
            metric: [
              {
                metric: compliancePlanDashboardReport.get(
                  'controls_assessed_count',
                ),
              },
              {
                metric: compliancePlanDashboardReport.get(
                  'controls_assessed_total',
                ),
              },
            ],
          },
        ]}
      />

      <PerformanceCard
        heading={
          <FormattedMessage id="compliance.stats.gaps" defaultMessage="Gaps" />
        }
        stats={[
          {
            label: (
              <FormattedMessage
                id="compliance.stats.gaps"
                defaultMessage="Gaps"
              />
            ),
            metric: compliancePlanDashboardReport.get('gap_total'),
          },
        ]}
      />

      <PerformanceCard
        heading={
          <FormattedMessage
            id="compliance.stats.actions"
            defaultMessage="Actions"
          />
        }
        stats={[
          {
            label: (
              <FormattedMessage
                id="compliance.stats.actions"
                defaultMessage="Actions"
              />
            ),
            metric: compliancePlanDashboardReport.get('actions_total'),
          },
        ]}
      />
    </Grid>
  );
};
export default withQuery(() => {
  const currentUser = useCurrentUser();
  const params = useMemo(
    () => fromJS({ user_uuid: currentUser?.get('uuid') }),
    [currentUser],
  );
  return {
    action: CompliancePlanDashboardReportEntity.duck.actions.get({
      params: params,
    }),
  };
})(ComplianceStats);
