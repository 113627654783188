import { Button } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

export const DeleteButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button variant="outline" onClick={onClick}>
      <FormattedMessage id="ui.delete" defaultMessage="Delete" />
    </Button>
  );
};
