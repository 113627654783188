import { Flex, Heading, type SystemStyleObject, Text } from '@chakra-ui/react';
import { useOutletContext } from 'react-router';

import { VStack } from 'components';
import { useLocale } from 'locales/useLocale.tsx';
import type { CompliancePlanContext } from 'screens/compliance/CompliancePlan';

import { ScenarioTable } from './ScenarioTable';
import { VehicleClassHelper } from './VehicleClassHelper';

const EquipmentPageHeading: SystemStyleObject = {
  color: 'primary',
};

const EquipmentPageSubheading: SystemStyleObject = {
  fontSize: '12px',
  color: 'gray.500',
};

export const Scenarios = () => {
  const {
    compliancePlanFormProps: { value: compliancePlan },
  } = useOutletContext<CompliancePlanContext>();
  const { toString } = useLocale();

  return (
    <VStack>
      <Flex direction="column" justifyContent="space-between">
        <Heading size="sm" sx={EquipmentPageHeading}>
          {compliancePlan.get('name')}
        </Heading>
        <Text size="xs" sx={EquipmentPageSubheading}>
          {toString(compliancePlan.get('risk'))}
        </Text>
      </Flex>
      <VStack gap={0}>
        <VehicleClassHelper />
        <ScenarioTable compliancePlan={compliancePlan} />
      </VStack>
    </VStack>
  );
};
