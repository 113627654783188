import type {
  ButtonProps,
  FormControlProps,
  SystemStyleObject,
} from '@chakra-ui/react';

export const commonFieldSx: SystemStyleObject = {
  fontSize: 'sm',
  borderRadius: 'sm',
};

export const actionFormWrapperSx: SystemStyleObject = {
  gridTemplateColumns: 'repeat(12, 1fr)',
  gap: 4,
  padding: 3,
  bgColor: 'white',
  border: '1px',
  borderColor: 'gray.300',
  borderRadius: 'sm',
};

/**
 * Title needs to be large to match the height of the PersonPicker,
 * but otherwise match the styling of sm fields.
 */
export const titleProps: FormControlProps = {
  size: 'lg',
  sx: commonFieldSx,
};

export const descriptionFieldProps: FormControlProps = {
  variant: 'reset',
  size: 'sm',
  sx: {
    '.chakra-textarea': {
      minHeight: '125px', // HACK match height of fields in the left column (without errors)
      ...commonFieldSx,
      border: '1px',
      borderColor: 'gray.300',
    },
  },
};

export const deleteButtonProps: ButtonProps = {
  variant: 'outline',
  size: 'sm',
  border: '1px',
  borderColor: 'delete',
  width: 'fit-content',

  sx: {
    color: 'error',
    _hover: {
      bgColor: 'error',
      borderColor: 'white',
      color: 'white',
    },
  },
};

export const personPickerStyles = {
  control: (prev: SystemStyleObject) => ({
    ...prev,
    borderRadius: 'sm',
  }),
};
