import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  HStack,
  Heading,
  LinkBox,
  LinkOverlay,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { type List } from 'immutable';
import type { Moment } from 'moment';
import { useMemo } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useNavigate } from 'react-router';

import { RouterLink } from '@burnsred/ui-chakra';
import { RiskIcon, TimeZoneGraph } from 'components';
import { Risk } from 'entities';
import type { GrcOrganisationalUnitEntityRecord } from 'entities/api/Person/GrcOrganisationalUnit';
import type { PersonEntityRecord } from 'entities/api/Person/Person';
import type { RiskEntityRecord } from 'entities/api/Risk';
import { type SiteFrameworkCountEntityRecord } from 'entities/api/SiteFrameworkCountReport';
import { type SiteFrameworkListEntityRecord } from 'entities/api/SiteFrameworkList';
import type { ActivityFilterEntityFields } from 'entities/filter/ActivityFilter';
import { useLocale } from 'locales/useLocale';
import {
  activityListFiltersReset,
  useActivityListControls,
} from 'screens/activities/components';

import {
  cardBodySx,
  cardHeaderSx,
  cardSx,
  damagingEnergyHeaderSx,
  headingSx,
  miniStatCard,
  riskIconSx,
} from './FrameworkCard.styles';

export type FrameworkCardProps = {
  filterRecord?: Map<string, unknown>;
  record: SiteFrameworkListEntityRecord;
  showStats: boolean;
  risk_owner?: PersonEntityRecord;
  site_control_owner?: PersonEntityRecord;
  counts?: List<SiteFrameworkCountEntityRecord>;
};

export const FrameworkCard = (props: FrameworkCardProps) => {
  const navigate = useNavigate();
  const { createHandleNavigateToActivityList } = useActivityListControls();
  const { toString, getI18nField } = useLocale();
  const { filterRecord, record, risk_owner, counts } = props;
  const controlFrameworkUrl = `/control-frameworks/${record.get('uuid')}`;

  /** set defaults and overrides for ActivityListFilters */
  const commonActivityListFilters = {
    ...activityListFiltersReset,
    observation_date__gte: filterRecord?.get('with_statistics__gte') as Moment,
    risk: filterRecord?.get('risk') as RiskEntityRecord,
    asset: filterRecord?.get(
      'descendants',
    ) as GrcOrganisationalUnitEntityRecord,
    operation: filterRecord?.get(
      'grc_organisational_unit',
    ) as GrcOrganisationalUnitEntityRecord,
    site_framework: record,
    site_association__risk_owner: risk_owner,
  } satisfies Partial<ActivityFilterEntityFields>;

  /** aggregated timezones from all controls */
  const timezones = record
    .get('controls')
    .toSet()
    .flatMap((control) => control.get('timezones'))
    .toList();

  const countRecord = useMemo(
    () => counts?.find((x) => x.get('uuid') == record?.get('uuid')),
    [counts, record],
  );
  const countCC = countRecord?.get('count_cc') ?? 0;
  const countCCC = countRecord?.get('count_ccc') ?? 0;
  const countInterruptions = countRecord?.get('count_interruptions') ?? 0;

  return (
    <Card size="sm" sx={cardSx}>
      <CardHeader as={LinkBox} sx={cardHeaderSx}>
        <RiskIcon
          path={Risk.getRiskIconPath(record?.get('risk'))}
          sx={riskIconSx}
          filter="orange"
        />
        <LinkOverlay as={RouterLink} to={controlFrameworkUrl}>
          <Text sx={damagingEnergyHeaderSx}>
            {toString(record.get('risk'))}
          </Text>
        </LinkOverlay>
      </CardHeader>

      <CardBody as={LinkBox} sx={cardBodySx}>
        <Heading as="h3" size="sm" sx={headingSx}>
          <LinkOverlay as={RouterLink} to={controlFrameworkUrl}>
            <span>{record.get('identifier')}</span> {toString(record)}
          </LinkOverlay>
        </Heading>

        {props?.showStats && (
          <Flex gap="2" marginTop="auto">
            <Card
              as={Stat}
              variant="unstyled"
              sx={miniStatCard}
              onClick={createHandleNavigateToActivityList({
                ...commonActivityListFilters,
                observation_types: ['CriticalControlCheck'],
              })}
            >
              <StatLabel>
                <FormattedMessage
                  id="FrameworkCard.StatLabel.ccc"
                  defaultMessage="CCC"
                />
              </StatLabel>
              <StatNumber>
                <FormattedNumber value={countCCC} />
              </StatNumber>
            </Card>

            <Card
              as={Stat}
              variant="unstyled"
              sx={miniStatCard}
              onClick={createHandleNavigateToActivityList({
                ...commonActivityListFilters,
                observation_types: ['ControlConfirmation'],
              })}
            >
              <StatLabel>
                <FormattedMessage
                  id="FrameworkCard.StatLabel.fcc"
                  defaultMessage="FCC"
                />
              </StatLabel>
              <StatNumber>
                <FormattedNumber value={countCC} />
              </StatNumber>
            </Card>

            <Card
              as={Stat}
              variant="unstyled"
              className={countInterruptions > 0 ? 'has-interrupts' : ''}
              sx={miniStatCard}
              onClick={createHandleNavigateToActivityList({
                ...commonActivityListFilters,
                observation_types: ['CriticalControlCheck'],
                CriticalControlCheck__has_interruption: true,
              })}
            >
              <StatLabel>
                <FormattedMessage
                  id="FrameworkCard.StatLabel.interrupts"
                  defaultMessage="Interrupts"
                />
              </StatLabel>
              <StatNumber>
                <FormattedNumber value={countInterruptions} />
              </StatNumber>
            </Card>

            <Card
              as={Stat}
              variant="unstyled"
              sx={miniStatCard}
              onClick={() => {
                navigate(`/control-frameworks/${record.get('uuid')}/controls`);
              }}
            >
              <StatLabel>
                <FormattedMessage
                  id="FrameworkCard.StatLabel.controls"
                  defaultMessage="Controls"
                />
              </StatLabel>
              <StatNumber>
                <FormattedNumber value={record?.get('controls')?.count()} />
              </StatNumber>
            </Card>
          </Flex>
        )}

        <Text fontSize="xs">
          {record
            .get('scenarios')
            .map((s) => s.get('damage_energy_mechanism'))
            ?.toSet()
            ?.map((dem) => toString(dem))
            ?.join(', ')}
        </Text>

        <HStack marginTop="auto" justifyContent="space-between">
          <UnorderedList layerStyle="nakedList">
            {/* {record.get('administrators').map((uuid, i) => (
              // TODO BC-505 add administrator details to the serializer
              // https://burnsred.atlassian.net/browse/BC-505
              <ListItem key={uuid} sx={adminListItemSx} data-uuid={uuid}>
                Admin {i + 1}
              </ListItem>
            ))} */}
          </UnorderedList>

          <Text fontSize="xs" color="primary" fontWeight="bold">
            {getI18nField(record, 'status')}
          </Text>
        </HStack>
      </CardBody>

      <CardFooter>
        <TimeZoneGraph timezones={timezones} />
      </CardFooter>
    </Card>
  );
};
