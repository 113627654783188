import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useToast,
} from '@chakra-ui/react';
import React, { type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { type Entity } from '@burnsred/entity';
import { FilePicker, type UseFilePickerReturn } from 'components/FilePicker';
import FilerFileEntity, {
  type FilerFileEntityRecord,
} from 'entities/api/FilerFile.ts';
import { type EntityRecord } from 'types.ts';

export type AttachmentModalProps = {
  heading?: string;
  children?: ReactNode;
  colorScheme?: string;
  isOpen: boolean;
  onOpen: VoidFunction;
  onClose: VoidFunction;
  onConfirm: (new_attachments: EntityRecord<unknown>[]) => void;
  fileList: UseFilePickerReturn['fileList'];
  setFileList: UseFilePickerReturn['setFileList'];
  batchUploadFilerFileList: UseFilePickerReturn['batchUploadFilerFileList'];
  attachmentEntity: typeof Entity;
  maxFiles: number;
};

/**
 * Modal component used to upload attachments
 *
 * You can pass the modal an icon in order to override the button
 * used to open the modal.
 */
export const AttachmentModal = ({
  isOpen,
  onClose,
  onConfirm,
  fileList,
  setFileList,
  batchUploadFilerFileList,
  attachmentEntity,
  maxFiles = 1,
}: AttachmentModalProps) => {
  React.useEffect(() => {
    if (isOpen) {
      setFileList([]);
    }
  }, [isOpen, setFileList]);

  const toast = useToast();
  const { formatMessage } = useIntl();

  const onConfirmClick = async () => {
    const filerFiles = await batchUploadFilerFileList(fileList);
    if (!filerFiles?.length) {
      toast({
        title: formatMessage({
          id: 'toast.errorUpload',
          defaultMessage: 'Upload encountered an error',
        }),
        status: 'error',
      });
      return;
    }

    const newAttachments = filerFiles.map((filerFile) => {
      const file = FilerFileEntity.dataToRecord(
        filerFile,
      ) as FilerFileEntityRecord;

      return attachmentEntity
        .dataToRecord({})
        .withMutations((rec: EntityRecord<unknown>) => {
          rec.set('attachment', file);
        });
    });

    onConfirm(newAttachments);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Attachment</ModalHeader>
        <ModalBody sx={{ minHeight: '200px' }}>
          <VStack
            spacing={4}
            alignItems={'flex-start'}
            justifyContent={'center'}
            width={'full'}
          >
            <Box width="full">
              <FilePicker
                maxFiles={maxFiles}
                fileList={fileList}
                setFileList={setFileList}
              />
            </Box>
          </VStack>
        </ModalBody>

        <ModalFooter justifyContent="space-between">
          <Button w={160} h={12} variant="outline" onClick={onClose}>
            <FormattedMessage id="ui.cancel" defaultMessage="Cancel" />
          </Button>
          <Button w={160} h={12} variant="outline" onClick={onConfirmClick}>
            <FormattedMessage id="ui.confirm" defaultMessage="Confirm" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
