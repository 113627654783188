import { Heading, type SystemStyleObject } from '@chakra-ui/react';
import type { ReactNode } from 'react';

import { VStack } from 'components';

export const contentBlockSx: SystemStyleObject = {
  '.chakra-text': {
    fontSize: 'xs',
  },
  '.chakra-stack': {
    gap: 0,
  },
};

export const ContentBlock = ({
  heading,
  children,
}: {
  heading: ReactNode;
  children: ReactNode;
}) => (
  <VStack gap={2} sx={contentBlockSx}>
    <Heading as="h5" fontSize="xs">
      {heading}
    </Heading>

    {children}
  </VStack>
);

export const controlDesignationSx: SystemStyleObject = {
  fontSize: 'xs',
};

export const statRowLabelSx: SystemStyleObject = {
  fontSize: 'xs',
  color: 'gray.500',
};
