import { Map } from 'immutable';
import type { Moment } from 'moment';

import { Entity, Fields } from '@burnsred/entity';
import ScreenDuck from '@burnsred/entity-duck-namespace-screen';
import PersonEntity, {
  type PersonEntityRecord,
} from 'entities/api/Person/Person';
import {
  type EntityFields,
  type EntityRecord,
  type FormChangeEvent,
} from 'types';

import { LAST_SEVEN_DAYS } from './periodOptions';
class ControlDashboardFilterEntity extends Entity {
  static fields: EntityFields<ControlDashboardFilterEntityFields> = {
    with_statistics__gte: new Fields.DateTimeField({
      blank: true,
      default: LAST_SEVEN_DAYS.value,
    }),
    control_page: new Fields.CharField({
      default: '1',
    }),
    control_page_size: new Fields.CharField({
      default: '21',
    }),
    site_framework_page: new Fields.CharField({
      default: '1',
    }),
    site_framework_page_size: new Fields.CharField({
      default: '21',
    }),
    siteframeworkcontrol__sitecontrolstatus__control_owner:
      new Fields.EntityField({
        entity: PersonEntity,
        blank: true,
      }),
    is_critical: new Fields.BooleanField({
      blank: true,
    }),
  };

  static asMappedParamsForSiteFramework(
    record: ControlDashboardFilterEntityRecord,
  ) {
    return Map({
      page: record?.get('site_framework_page')
        ? `${record?.get('site_framework_page')}`
        : '1',
      page_size: record?.get('site_framework_page_size'),
      siteframeworkcontrol__sitecontrolstatus__control_owner: record?.get(
        'siteframeworkcontrol__sitecontrolstatus__control_owner',
      )
        ? `${record?.get('siteframeworkcontrol__sitecontrolstatus__control_owner')?.get('uuid')}`
        : '',
      is_critical: record?.get('is_critical')?.toString(),
    });
  }
  static asMappedCountReportsFrameworkParams(
    record: ControlDashboardFilterEntityRecord,
  ) {
    return Map({
      with_statistics__gte:
        record?.get('with_statistics__gte')?.toISOString() || '',
      page: record?.get('site_framework_page')
        ? `${record?.get('site_framework_page')}`
        : '1',
      page_size: record?.get('site_framework_page_size'),
      siteframeworkcontrol__sitecontrolstatus__control_owner: record?.get(
        'siteframeworkcontrol__sitecontrolstatus__control_owner',
      )
        ? `${record?.get('siteframeworkcontrol__sitecontrolstatus__control_owner')?.get('uuid')}`
        : '',
      is_critical: record?.get('is_critical')?.toString(),
    });
  }

  static asMappedParamsForControlReport(
    record: ControlDashboardFilterEntityRecord,
  ) {
    return Map({
      period_start__gte:
        record?.get('with_statistics__gte')?.toISOString() || '',
      siteframeworkcontrol__sitecontrolstatus__control_owner: record?.get(
        'siteframeworkcontrol__sitecontrolstatus__control_owner',
      )
        ? `${record?.get('siteframeworkcontrol__sitecontrolstatus__control_owner')?.get('uuid')}`
        : '',
      is_critical: record?.get('is_critical')?.toString(),
    });
  }

  static asMappedParamsForControls(record: ControlDashboardFilterEntityRecord) {
    return Map({
      with_statistics__gte:
        record?.get('with_statistics__gte')?.toISOString() || '',
      page: record?.get('control_page')
        ? `${record?.get('control_page')}`
        : '1',
      page_size: record?.get('control_page_size'),
      siteframeworkcontrol__sitecontrolstatus__control_owner: record?.get(
        'siteframeworkcontrol__sitecontrolstatus__control_owner',
      )
        ? `${record?.get('siteframeworkcontrol__sitecontrolstatus__control_owner')?.get('uuid')}`
        : '',
      is_critical: record?.get('is_critical')?.toString(),
    });
  }

  static toParams(record: ControlDashboardFilterEntityRecord) {
    const params = new URLSearchParams(
      this.asMappedParamsForSiteFramework(record).toJS(),
    );
    return params.toString();
  }

  static onChangeClean(
    record: ControlDashboardFilterEntityRecord,
    evt: FormChangeEvent,
    rootName: string,
  ) {
    const { name, value } = evt.target;
    if (name === rootName) {
      return evt.target.value as ControlDashboardFilterEntityRecord;
    }
    // Warning does not work with nested updates
    return record.set(name, value);
  }
}

export type ControlDashboardFilterEntityFields = {
  control_page_size: string;
  control_page: string;
  site_framework_page_size: string;
  site_framework_page: string;
  with_statistics__gte: Moment;
  siteframeworkcontrol__sitecontrolstatus__control_owner: PersonEntityRecord;
  /** actually optional; if undefined: return both is_critical: True & False */
  // ...but making it optional causes type errors elsewhere
  is_critical: boolean;
};

export type ControlDashboardFilterEntityRecord =
  EntityRecord<ControlDashboardFilterEntityFields>;

ControlDashboardFilterEntity.duck = new ScreenDuck({
  app: 'Cube',
  entity: ControlDashboardFilterEntity,
  name: 'ControlDashboardFilter',
});

export default ControlDashboardFilterEntity;
