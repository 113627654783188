import { type List, Map } from 'immutable';

import { Entity, Fields } from '@burnsred/entity';
import ScreenDuck from '@burnsred/entity-duck-namespace-screen';
import ControlDesignationEntity, {
  type ControlDesignationEntityRecord,
} from 'entities/api/ControlDesignation';
import DamageEnergyMechanismEntity, {
  type DamageEnergyMechanismEntityRecord,
} from 'entities/api/DamageEnergyMechanism';
import EquipmentEntity, {
  type EquipmentEntityRecord,
} from 'entities/api/Equipment';
import OperatingContextEntity, {
  type OperatingContextEntityRecord,
} from 'entities/api/OperatingContext';
import ScenarioEntity, {
  type ScenarioEntityRecord,
} from 'entities/api/Scenario';
import SupportFactorEntity, {
  type SupportFactorEntityRecord,
} from 'entities/api/SupportFactor';
import {
  type EntityFields,
  type EntityRecord,
  type FormChangeEvent,
} from 'types';

class GlobalVisualisationFilterEntity extends Entity {
  static fields: EntityFields<GlobalVisualisationFilterEntityFields> = {
    scenarios__damage_energy_mechanism: new Fields.EntityField({
      entity: DamageEnergyMechanismEntity,
      many: true,
      blank: true,
    }),
    scenarios: new Fields.EntityField({
      entity: ScenarioEntity,
      blank: true,
      many: true,
    }),
    operating_context: new Fields.EntityField({
      entity: OperatingContextEntity,
      blank: true,
      many: true,
    }),
    equipment_l1: new Fields.EntityField({
      entity: EquipmentEntity,
      blank: true,
      many: true,
    }),
    equipment_l2: new Fields.EntityField({
      entity: EquipmentEntity,
      blank: true,
      many: true,
    }),
    control_designation: new Fields.EntityField({
      entity: ControlDesignationEntity,
      blank: true,
      many: true,
    }),
    support_factor: new Fields.EntityField({
      entity: SupportFactorEntity,
      blank: true,
      many: true,
    }),
    is_mandatory: new Fields.BooleanField({
      blank: true,
    }),
    page_size: new Fields.CharField({
      default: '100',
      blank: true,
    }),
    page: new Fields.CharField({
      default: '1',
      blank: true,
    }),
  };

  static getUuidList(record: List<EntityRecord<{ uuid: string }>> | undefined) {
    return record?.map((x) => x.get('uuid'))?.join(',') || '';
  }

  static asMappedParams(record: GlobalVisualisationFilterEntityRecord) {
    const baseParams = Map({
      // Limitation in entity lib forces us to use csv like parameters instead of common ?param=1&param=2
      rules__scenarios__damage_energy_mechanism: this.getUuidList(
        record?.get('scenarios__damage_energy_mechanism'),
      ),
      rules__equipment_l1: this.getUuidList(record?.get('equipment_l1')),
      rules__equipment_l2: this.getUuidList(record?.get('equipment_l2')),
      rules__scenarios: this.getUuidList(record?.get('scenarios')),
      rules__operating_context: this.getUuidList(
        record?.get('operating_context'),
      ),
      control_designation__in: this.getUuidList(
        record?.get('control_designation'),
      ),
      support_factors__in: this.getUuidList(record?.get('support_factor')),
      rules__is_mandatory: record?.get('is_mandatory')?.toString() ?? '',
      page: record?.get('page') ?? '1',
      page_size: record?.get('page_size') ?? '100',
    });
    if (record?.get('scenarios') && !record?.get('scenarios')?.isEmpty()) {
      // @Hack: Optimization since the DEM filter is very slow in the backend,
      // and we don't need it if scenarios are specified due to clean logic.
      return baseParams.remove('rules__scenarios__damage_energy_mechanism');
    }
    return baseParams;
  }

  static toParams(record: GlobalVisualisationFilterEntityRecord) {
    const params = new URLSearchParams(this.asMappedParams(record).toJS());
    return params.toString();
  }

  static onChangeClean(
    record: GlobalVisualisationFilterEntityRecord,
    evt: FormChangeEvent,
    rootName: string,
  ) {
    const { name, value } = evt.target;
    if (name === rootName)
      return value as GlobalVisualisationFilterEntityRecord;
    // when a field changes, reset to page 1 to avoid requesting out of range results from API
    const updatedRecord = name != 'page' ? record.set('page', '1') : record;

    // Warning does not work with nested updates
    switch (name) {
      case 'scenarios__damage_energy_mechanism':
        return updatedRecord.withMutations((rec) => {
          rec.set('scenarios', null);
          rec.set(name, value);
        });
      case 'equipment_l1':
        return updatedRecord.withMutations((rec) => {
          rec.set('equipment_l2', null);
          rec.set(name, value);
        });
      default:
        return updatedRecord.set(name, value);
    }
  }
}

type GlobalVisualisationFilterEntityFields = {
  scenarios__damage_energy_mechanism: List<DamageEnergyMechanismEntityRecord>;
  scenarios: List<ScenarioEntityRecord>;
  operating_context: List<OperatingContextEntityRecord>;
  equipment_l1: List<EquipmentEntityRecord>;
  equipment_l2: List<EquipmentEntityRecord>;
  control_designation: List<ControlDesignationEntityRecord>;
  support_factor: List<SupportFactorEntityRecord>;
  page_size: string;
  is_mandatory: boolean | null | undefined;
  page: string;
};

export type GlobalVisualisationFilterEntityRecord =
  EntityRecord<GlobalVisualisationFilterEntityFields>;

GlobalVisualisationFilterEntity.duck = new ScreenDuck({
  app: 'Cube',
  entity: GlobalVisualisationFilterEntity,
  name: 'GlobalVisualisationFilter',
});

export default GlobalVisualisationFilterEntity;
