import type { SystemStyleObject } from '@chakra-ui/react';

export const raoPickerSx: SystemStyleObject = {
  fontSize: 'sm',

  '&.TreePicker__Wrapper': {
    display: 'flex',
    flexDirection: 'row',
    gap: 2.5,
  },

  '.TreePicker__List': {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    minWidth: 72,
  },

  '.TreePicker__Node': {
    rounded: 'base',
    bgColor: 'gray.100',

    _expanded: {
      bgColor: 'brandDustyOrange',
      color: 'white',

      _disabled: {
        bgColor: 'gray.400',
      },
    },

    '.chakra-checkbox': {
      // ensure that the checkbox label fills the entire row, to capture clicks
      display: 'flex',
      width: 'full',
      padding: 2,

      // don't show pointer unless .has-children
      cursor: 'default',
    },

    '.chakra-checkbox__label': {
      display: 'flex',
      alignItems: 'center',
      width: 'full',

      _disabled: { opacity: 1 },
    },

    '.chakra-checkbox__control': {
      cursor: 'pointer',

      _checked: {
        bgColor: 'transparent',
        borderColor: 'primary',
        color: 'primary',

        _disabled: {
          color: 'gray.400',
        },
      },

      _disabled: {
        cursor: 'not-allowed',
        bgColor: 'gray.200',
        borderColor: 'gray.400',
      },
    },

    // note: not nested to due to postCSS inheritance challenges with `&`
    '&[data-expanded] .chakra-checkbox__control': {
      borderColor: 'white',
      color: 'white',
    },

    '&.has-children': {
      '.chakra-checkbox': { cursor: 'pointer' },
    },

    _disabled: {
      '.chakra-checkbox__control': {
        cursor: 'not-allowed',
      },
    },

    '.TreePicker__Node__label': {
      marginRight: 'auto',
    },
    '.TreePicker__Node__count': {
      paddingLeft: 2,

      _before: { content: '"("' },
      _after: { content: '")"' },
    },
    '.TreePicker__Node__expanded-icon': {
      width: 6,
      height: 6,
    },
  },

  '.TreePicker__DefaultEmptyRecord': {
    padding: 2,
    rounded: 'base',
    bgColor: 'gray.100',
  },
};
