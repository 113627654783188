// cSpell:ignore datepicker
import {
  Box,
  type FormControlOptions,
  type InputGroupProps,
  type InputProps,
  type SystemStyleObject,
} from '@chakra-ui/react';
import { type ForwardRefExoticComponent, type RefAttributes } from 'react';
import {
  type CalendarContainerProps,
  default as ReactDatePicker,
  type ReactDatePickerCustomHeaderProps,
  type ReactDatePickerProps,
} from 'react-datepicker';
import { useIntl } from 'react-intl';

import { noop } from '@burnsred/ui-chakra';

import { ChakraDateInput, styles } from './DatePicker.styles';

/**
 * @see https://moment.github.io/luxon/#/formatting?id=table-of-tokens
 */
export const dateFormats = {
  /** yyyy-MM-dd */
  isoShort: 'yyyy-MM-dd',
  /** dd/MM/yyyy */
  localDate: 'dd/MM/yyyy',
  /** 'dd/MM/yyyy HH:mm' */
  localDateTime24hr: 'dd/MM/yyyy HH:mm',
  /** 9:07 AM */
  localizedTime: 't',
};

/** TODO Integrate with project defaults */
export const DEFAULT_DATE_FORMAT = dateFormats.localDate;

/** For compatibility with other collapsible chakra components */
const defaultPopperProps = { strategy: 'fixed' };

export type DatePickerChangeHandler = ReactDatePickerProps['onChange'];

/**
 * ReactDatePickerProps; we override some of these in order to attach
 * doc strings to frequently used props.
 */
export type DatePickerProps =
  // prettier-ignore
  & Omit<ReactDatePickerProps, 'selected' | 'value' | 'onChange' | 'disabled'> &
  {
    name?: string;
    /**
     * The selected value of the component.
     *
     * We remap `value` to `selected` (which react-datepicker expects) for
     * consistency with other fields. Additionally, we call `new Date()`
     * on truthy values, so it can receive compatible date strings.
     */
    value?: Date | string | null | undefined;
    onChange?: DatePickerChangeHandler;

    /**
     * Don't allow editing of the date (don't open the calendar).
     *
     * Sets readOnly and disables the form input, mapped from isDisabled to
     * readOnly for consistency with other fields.
     */
    isDisabled?: FormControlOptions['isDisabled'];

    isReadOnly?: FormControlOptions['isReadOnly'];

    /** @deprecated prefer isDisabled when rendering this component directly */
    disabled?: boolean;

    /**
     * Note, this states `string | undefined`, and is incompatible with modern react refs,
     * but scroll to errors from hook-form seems to work fine without it!
     * https://reactdatepicker.com/#example-custom-input
     */
    customInputRef?: ReactDatePickerProps['customInputRef'];

    /**
     * Display the time picker to the right of the month calendar
     * https://reactdatepicker.com/#example-select-time
     */
    showTimeSelect?: boolean;

    /**
     * Display the scrolling time picker without the Calendar
     * https://reactdatepicker.com/#example-select-time-only
     */
    showTimeSelectOnly?: boolean;
    dateFormat?: string;
    showMonthYearDropdown?: boolean;
    showPopperArrow?: boolean;
    popperProps?: Record<string, unknown>;
    placeholderText?: string;
    showMonthDropdown?: boolean;
    showYearDropdown?: boolean;
    scrollableYearDropdown?: boolean;
    yearDropdownItemNumber?: number;
    dropdownMode?: 'select' | 'scroll' | undefined;
    isClearable?: boolean;
    selectsEnd?: boolean;
    selectsStart?: boolean;
    startDate?: Date;
    endDate?: Date;
    minDate?: Date;
    maxDate?: Date;
    open?: boolean;

    /**
     * Pass your custom component wrapped in forwardRef:
     *
     * ```
     * // see ./Date.styles.tsx#ChakraDateInput for a full example
     * forwardRef<HTMLInputElement, InputProps>(
     *   (props, ref) => <Input ref={ref} {...props} />
     * )
     * ```
     */
    inputComponent?: ForwardRefExoticComponent<
      InputProps & RefAttributes<HTMLInputElement>
    >;

    /** Passed to `inputComponent` */
    size?: InputGroupProps['size'];
    /** Passed to `inputComponent` */
    variant?: InputGroupProps['variant'];
    /** Passed to `inputComponent` */
    colorScheme?: InputGroupProps['colorScheme'];

    /**
     * Passed as overrides to the wrapper element: `div.DatePicker`
     * @see styles
     */
    sx?: SystemStyleObject;

    // Replaceable elements:
    calendarContainer?(props: CalendarContainerProps): React.ReactNode;
    customInput?: React.ReactNode | undefined;
    customTimeInput?: React.ReactNode | undefined;
    formatWeekDay?(formattedDate: string): React.ReactNode;
    nextMonthButtonLabel?: string | React.ReactNode | undefined;
    nextYearButtonLabel?: string | React.ReactNode | undefined;
    popperContainer?(props: { children: React.ReactNode[] }): React.ReactNode;
    previousMonthButtonLabel?: string | React.ReactNode | undefined;
    previousYearButtonLabel?: string | React.ReactNode | undefined;
    renderCustomHeader?(params: ReactDatePickerCustomHeaderProps): React.ReactNode;
    renderDayContents?(dayOfMonth: number, date?: Date): React.ReactNode;
    /** Pass null to disable todayButton */
    todayButton?: React.ReactNode | undefined;
  };

/**
 * DatePicker renders an instance of react-datepicker with a Chakra Input
 *
 * https://reactdatepicker.com/
 *
 * ```tsx
 * <DatePicker
 *   value={ new Date() }
 *   onChange={ (value) => void console.log(value) }
 * />
 * ```
 *
 *  If placing this component inside a chakra component such as a card, use a
 *  higher z-index value on the card containing the DatePicker, like:
 *
 *  ```tsx
 *  <Card as={AccordionItem} zIndex='docked'>
 *    <DatePicker ... />
 *  </Card>
 *  ```
 *
 * @see https://reactdatepicker.com/
 */
export const DatePicker = ({
  name,
  value,
  onChange = noop,
  inputComponent = ChakraDateInput,
  todayButton,
  showTimeSelectOnly,
  dateFormat = DEFAULT_DATE_FORMAT,
  showPopperArrow = true,
  showMonthDropdown = true,
  showYearDropdown = true,
  scrollableYearDropdown = true,
  yearDropdownItemNumber = 60,
  dropdownMode,
  isDisabled,
  isReadOnly,
  popperProps = defaultPopperProps,
  customInputRef,
  size,
  variant,
  colorScheme,
  sx,
  ...props
}: DatePickerProps) => {
  const { formatMessage } = useIntl();

  // rename the component to PascalCase so it compiles as JSX
  const InputComponent = inputComponent;

  return (
    <Box
      className={`DatePicker ${
        showTimeSelectOnly ? 'TimePicker' : ''
      } light-theme`}
      sx={{ ...styles, ...sx }}
    >
      <ReactDatePicker
        name={name}
        selected={value ? new Date(value) : undefined}
        onChange={onChange}
        customInput={
          <InputComponent
            isReadOnly={isReadOnly}
            isDisabled={isDisabled}
            size={size}
            variant={variant}
            colorScheme={colorScheme}
          />
        }
        customInputRef={customInputRef}
        todayButton={
          todayButton ??
          formatMessage({ id: 'ui.today', defaultMessage: 'Today' })
        }
        readOnly={isReadOnly}
        disabled={isDisabled}
        showTimeSelectOnly={showTimeSelectOnly}
        dateFormat={dateFormat}
        showPopperArrow={showPopperArrow}
        popperProps={popperProps}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        scrollableYearDropdown={scrollableYearDropdown}
        dropdownMode={dropdownMode ?? undefined}
        yearDropdownItemNumber={yearDropdownItemNumber ?? undefined}
        {...props}
      />
    </Box>
  );
};
