import { Flex, Heading, type SystemStyleObject, Text } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useOutletContext } from 'react-router';

import { VStack } from 'components';
import CompliancePlanEntity from 'entities/api/CompliancePlan/CompliancePlan.ts';
import { type GrcOrganisationalUnitAncestorEntityRecord } from 'entities/api/Person/GrcOrganisationalUnitAncestor.ts';
import { useLocale } from 'locales/useLocale.tsx';
import type { CompliancePlanContext } from 'screens/compliance/CompliancePlan';

import AssetAccordion from './AssetAccordion/AssetAccordion';

const EquipmentPageHeading: SystemStyleObject = {
  color: 'primary',
};

const EquipmentPageSubheading: SystemStyleObject = {
  fontSize: '12px',
  color: 'gray.500',
};

export const OperatingContext = () => {
  const {
    compliancePlanFormProps: { value: compliancePlan },
  } = useOutletContext<CompliancePlanContext>();

  const { toString } = useLocale();

  const asset = useMemo(() => {
    return CompliancePlanEntity.getAsset(compliancePlan);
  }, [compliancePlan]);

  return (
    <VStack>
      <Flex direction="column" justifyContent="space-between">
        <Heading size="sm" sx={EquipmentPageHeading}>
          {compliancePlan.get('name')}
        </Heading>
        <Text size="xs" sx={EquipmentPageSubheading}>
          {toString(compliancePlan.get('risk'))}
        </Text>
      </Flex>
      <AssetAccordion
        asset={asset as GrcOrganisationalUnitAncestorEntityRecord}
        siteCompliancePlans={compliancePlan.get('sitecomplianceplan_set')}
        compliancePlan={compliancePlan}
      />
    </VStack>
  );
};
