import { Entity, Fields } from '@burnsred/entity';
import { type BaseAttachmentEntityFields } from 'entities/api/BaseAttachment.ts';
import { BaseAttachment } from 'entities/api/index.ts';
import { type EntityRecord } from 'types.ts';

class ActionAttachmentEntity extends Entity {
  static fields = {
    ...BaseAttachment.fields,
    action: new Fields.CharField(),
  };
}

export type ActionAttachmentEntityFields = BaseAttachmentEntityFields & {
  action: string;
};

export type ActionAttachmentEntityRecord =
  EntityRecord<ActionAttachmentEntityFields>;

export default ActionAttachmentEntity;
