import { type SystemStyleObject } from '@chakra-ui/react';

export const modalHeaderSx: SystemStyleObject = {
  color: 'primary',
  borderBottom: 0,
};

export const modalBodySx: SystemStyleObject = {
  minHeight: '160px',
};

export const modalFooterSx: SystemStyleObject = {
  borderTop: 0,
  justifyContent: 'space-between',
  '>button': {
    width: '160px',
    height: '48px',
  },
};
