import {
  Badge,
  type BadgeProps,
  type SystemStyleObject,
} from '@chakra-ui/react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export const headerSx: SystemStyleObject = {
  bgColor: 'gray.100',
  paddingBottom: 3,
};

export const controlHeadingSx: SystemStyleObject = {
  justifyContent: 'space-between',
  paddingX: 3,
  paddingY: 4,
  bgColor: 'gray.600',
  color: 'white',
};

export const header__contentWrapperSx: SystemStyleObject = {
  paddingX: 3,
  gap: 3,
};

export const header__contentDescriptionSx: SystemStyleObject = {
  width: 'full',
  justifyContent: 'flex-start',
};

type StatusBadgeProps = BadgeProps & { children: ReactNode };

// TODO update when we have the status field
export const MandatoryAssessBadge = ({
  is_mandatory,
  sx,
}: BadgeProps & { is_mandatory: boolean }) => {
  return (
    <Badge
      className={is_mandatory ? 'is-mandatory' : 'is-assess'}
      variant="solid"
      sx={{
        paddingY: 1,
        paddingX: 2,
        fontSize: 'xs',
        fontWeight: 'normal',
        textTransform: 'none',

        '&.is-mandatory': { bgColor: 'badge_mandatory' },
        '&.is-assess': { bgColor: 'badge_assess' },
        ...sx,
      }}
    >
      {is_mandatory ? (
        <FormattedMessage
          id="compliance.baseline-assessment.form.mandatory"
          defaultMessage="Mandatory"
        />
      ) : (
        <FormattedMessage
          id="compliance.baseline-assessment.form.assess"
          defaultMessage="Assess"
        />
      )}
    </Badge>
  );
};

export const StatusBadge = ({ sx, children }: StatusBadgeProps) => {
  return (
    <Badge
      variant="outline"
      colorScheme="blackAlpha"
      sx={{
        paddingY: 1,
        paddingX: 4,
        bgColor: 'white',
        fontSize: '2xs',
        fontWeight: 'normal',
        color: 'black',
        ...sx,
      }}
    >
      {children}
    </Badge>
  );
};
