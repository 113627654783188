import { useDisclosure, useToast } from '@chakra-ui/react';
import { type List } from 'immutable';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useOutletContext } from 'react-router';

import { axiosInstance } from 'api';
import type { ApplicableControlForPlanListEntityRecord } from 'entities/api/CompliancePlan/ApplicableControlForPlanListOnly';
import SiteCompliancePlanEntity from 'entities/api/CompliancePlan/SiteCompliancePlan.ts';
import type { WithQueryReturnProps } from 'types';
import { createLogger } from 'util/createLogger';

import type { BaselineAssessmentContext } from '../BaselineAssessment';

const log = createLogger('useControlList');

export type ControlListProps = WithQueryReturnProps<
  List<ApplicableControlForPlanListEntityRecord>
>;

export function useControlList() {
  const toast = useToast();
  const { formatMessage } = useIntl();

  const [isGeneratingControls, setIsGeneratingControls] = useState(false);
  const {
    isOpen: isImportModalOpen,
    onOpen: onImportModalOpen,
    onClose: onImportModalClose,
  } = useDisclosure();

  const {
    siteCompliancePlanFormProps: { value: siteCompliancePlan },
    compliancePlanFormProps,
    applicableControlsForPlanFormProps: {
      value: applicableControlsForPlan,
      clear: clearApplicableControlsForPlan,
    },
  } = useOutletContext<BaselineAssessmentContext>();

  const siteCompliancePlanUuid = siteCompliancePlan?.get('uuid');
  const compliancePlanInfo = compliancePlanFormProps.value;

  const [selectedPlanImport, setSelectedPlanImport] = useState<{
    value: string;
    label: string;
  } | null>(null);

  useEffect(() => {
    return setSelectedPlanImport(null);
  }, [siteCompliancePlanUuid]);

  const compliancePlans = compliancePlanInfo
    ?.get('sitecomplianceplan_set')
    .filter((plan) => plan?.get('uuid') !== siteCompliancePlanUuid);

  const planImportOptions = compliancePlans
    .map((plan) => ({
      value: plan.get('uuid'),
      label: `${compliancePlanInfo.get('name')} - ${plan.get('site').get('name')}`,
    }))
    .toArray();

  const generatePlanControls = async () => {
    if (!isGeneratingControls) {
      const url = `${SiteCompliancePlanEntity.paths.apiBase}${siteCompliancePlanUuid}/generate_applicable_controls/`;
      setIsGeneratingControls(true);
      try {
        await axiosInstance.post(url, {});
        setIsGeneratingControls(false);
        toast({
          title: formatMessage({
            id: 'toast.controlsGenerated',
            defaultMessage: 'Controls generated',
          }),
          status: 'success',
        });
        clearApplicableControlsForPlan();
        return;
      } catch (err) {
        setIsGeneratingControls(false);
        toast({
          title: formatMessage({
            id: 'toast.errorControlsGenerated',
            defaultMessage:
              'There was an error generating the applicable controls',
          }),
          status: 'error',
        });
        return;
      }
    }
  };

  const importBaselineAssessments = async () => {
    const url = `${SiteCompliancePlanEntity.paths.apiBase}${siteCompliancePlanUuid}/import_baseline_assessments/`;

    try {
      await axiosInstance.post(url, {
        site_compliance_plan_uuid: selectedPlanImport?.value,
      });
      toast({
        title: formatMessage({
          id: 'toast.baselineAssessmentsImported',
          defaultMessage: 'Baseline assessments successfully imported',
        }),
        status: 'success',
      });
      clearApplicableControlsForPlan();
      return;
    } catch (err) {
      toast({
        title: formatMessage({
          id: 'toast.errorBaselineAssessmentsImported',
          defaultMessage: 'An error occurred while importing assessments',
        }),
        status: 'error',
      });
      return;
    }
  };

  log('%o', { applicableControlsForPlan });

  return {
    applicableControlsForPlan,
    clearApplicableControlsForPlan,
    isGeneratingControls,
    generatePlanControls,
    compliancePlanFormProps,
    onImportModalOpen,
    onImportModalClose,
    isImportModalOpen,
    siteCompliancePlan,
    compliancePlanInfo,
    siteCompliancePlanUuid,
    planImportOptions,
    selectedPlanImport,
    setSelectedPlanImport,
    importBaselineAssessments,
  };
}
