import { Button } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

export const SaveButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button variant="outline" onClick={onClick}>
      <FormattedMessage id="ui.save" defaultMessage="Save" />
    </Button>
  );
};
