import { Entity, Fields } from '@burnsred/entity';
import { type BaseAttachmentEntityFields } from 'entities/api/BaseAttachment.ts';
import { BaseAttachment } from 'entities/api/index.ts';
import { type EntityRecord } from 'types.ts';

class GapAttachmentEntity extends Entity {
  static fields = {
    ...BaseAttachment.fields,
    gap: new Fields.CharField(),
  };
}

export type GapAttachmentEntityFields = BaseAttachmentEntityFields & {
  gap: string;
};

export type GapAttachmentEntityRecord = EntityRecord<GapAttachmentEntityFields>;

export default GapAttachmentEntity;
