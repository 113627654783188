import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  SimpleGrid,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { type Field } from '@burnsred/entity';
import { Form, Input } from '@burnsred/entity-form';
import { RouterLink } from '@burnsred/ui-chakra';
import { multiValueAutocompleteStyles } from 'components/Autocomplete/Autocomplete.styles';
import Autocomplete from 'components/widgets/Autocomplete/Autocomplete';
import { ThreeStateCheckBox } from 'components/widgets/ThreeStateCheckbox/ThreeStateCheckbox';
import { type GlobalFrameworkEntityRecord } from 'entities/api/GlobalFramework';
import GlobalVisualisationFilterEntity, {
  type GlobalVisualisationFilterEntityRecord,
} from 'entities/filter/GlobalVisualisationFilter';
import { useLocale } from 'locales/useLocale.tsx';
import { type EntityFormProps } from 'types';

import {
  FilterCount,
  buttonSx,
  haveAssessSx,
  simpleGridSx,
} from './ControlsVisualisationFilters.styles';

type ControlsVisualisationFiltersProps = {
  frameworkHref: string;
  name: string;
  filterRecord: GlobalVisualisationFilterEntityRecord;
  onChange: EntityFormProps['onChange'];
  errors: EntityFormProps['errors'];
  FilterField: Field;
  riskId: string;
  framework: GlobalFrameworkEntityRecord;
};

export const ControlsVisualisationFilters = (
  props: ControlsVisualisationFiltersProps,
) => {
  const {
    name,
    filterRecord,
    onChange,
    FilterField,
    errors,
    frameworkHref,
    riskId,
    framework,
  } = props;

  const { toString } = useLocale();
  // @ts-expect-error framework does not have a title field.
  const equipment_label = toString(framework, 'equipment_field_label');
  return (
    <Form
      name={name}
      field={FilterField}
      value={filterRecord}
      errors={errors}
      onChange={onChange}
      onSubmit={onChange}
    >
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 4 }}
        spacing="5"
        sx={simpleGridSx}
      >
        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="global-frameworks.visualisation.filters.damage_energy"
              defaultMessage="Damaging energy mechanism"
            />
            <FilterCount
              count={
                filterRecord.get('scenarios__damage_energy_mechanism')?.size
              }
            />
          </FormLabel>
          <Input
            name="scenarios__damage_energy_mechanism"
            component={Autocomplete}
            loadOptionsFromAPI
            componentProps={{
              onChange: onChange,
              isMulti: true,
              closeMenuOnSelect: false,
              hideSelectedOptions: false,
              chakraStyles: multiValueAutocompleteStyles,
            }}
            filterParams={{
              damage_energy__risk: riskId || undefined,
              is_active: 'true',
              predicates__rule__risk: riskId || undefined, // Only show DEMs related to global controls
            }}
          />
        </FormControl>

        <GridItem colSpan={3}>
          <FormControl>
            <FormLabel>
              <FormattedMessage
                id="global-frameworks.visualisation.filters.scenario"
                defaultMessage="Scenario"
              />
              <FilterCount count={filterRecord.get('scenarios')?.size} />
            </FormLabel>
            <Input
              name="scenarios"
              component={Autocomplete}
              componentProps={{
                isMulti: true,
                closeMenuOnSelect: false,
                hideSelectedOptions: false,
                chakraStyles: multiValueAutocompleteStyles,
              }}
              loadOptionsFromAPI
              filterParams={{
                page_size: '30',
                ordering: 'mapping_id',
                damage_energy_mechanism__damage_energy__risk:
                  riskId || undefined,
                damage_energy_mechanism__in:
                  GlobalVisualisationFilterEntity.getUuidList(
                    filterRecord?.get('scenarios__damage_energy_mechanism'),
                  ),
              }}
            />
          </FormControl>
        </GridItem>

        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="global-frameworks.visualisation.filters.operating_context"
              defaultMessage="Operating context"
            />
            <FilterCount count={filterRecord.get('operating_context')?.size} />
          </FormLabel>
          <Input
            name="operating_context"
            component={Autocomplete}
            componentProps={{
              isMulti: true,
              closeMenuOnSelect: false,
              hideSelectedOptions: false,
              chakraStyles: multiValueAutocompleteStyles,
            }}
            loadOptionsFromAPI
            filterParams={{
              globalframework__risk: riskId || undefined,
              predicates__rule__risk: riskId || undefined,
            }}
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            {(equipment_label && equipment_label + ' 1') || (
              <FormattedMessage
                id="global-frameworks.visualisation.filters.equipment_l1"
                defaultMessage="Equipment level 1"
              />
            )}
            <FilterCount count={filterRecord.get('equipment_l1')?.size} />
          </FormLabel>
          <Input
            name="equipment_l1"
            component={Autocomplete}
            loadOptionsFromAPI
            componentProps={{
              onChange: onChange,
              isMulti: true,
              closeMenuOnSelect: false,
              hideSelectedOptions: false,
              chakraStyles: multiValueAutocompleteStyles,
            }}
            filterParams={{
              predicates__rule__risk: riskId || undefined,
              level: '1',
            }}
          />
        </FormControl>

        <GridItem colSpan={2}>
          <FormControl>
            <FormLabel>
              {(equipment_label && equipment_label + ' 2') || (
                <FormattedMessage
                  id="global-frameworks.visualisation.filters.equipment_l2"
                  defaultMessage="Equipment level 2"
                />
              )}
              <FilterCount count={filterRecord.get('equipment_l2')?.size} />
            </FormLabel>
            <Input
              name="equipment_l2"
              component={Autocomplete}
              componentProps={{
                isMulti: true,
                closeMenuOnSelect: false,
                hideSelectedOptions: false,
                chakraStyles: multiValueAutocompleteStyles,
              }}
              loadOptionsFromAPI
              filterParams={{
                predicates__rule__risk: riskId || undefined,
                parents__in: GlobalVisualisationFilterEntity.getUuidList(
                  filterRecord?.get('equipment_l1'),
                ),
                level: '2',
              }}
            />
          </FormControl>
        </GridItem>

        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="global-frameworks.visualisation.filters.control_type"
              defaultMessage="Control type"
            />
            <FilterCount
              count={filterRecord.get('control_designation')?.size}
            />
          </FormLabel>
          <Input
            name="control_designation"
            component={Autocomplete}
            componentProps={{
              isMulti: true,
              closeMenuOnSelect: false,
              hideSelectedOptions: false,
              chakraStyles: multiValueAutocompleteStyles,
            }}
            loadOptionsFromAPI
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="global-frameworks.visualisation.filters.support_factor"
              defaultMessage="Support factor"
            />
            <FilterCount count={filterRecord.get('support_factor')?.size} />
          </FormLabel>
          <Input
            name="support_factor"
            component={Autocomplete}
            componentProps={{
              isMulti: true,
              closeMenuOnSelect: false,
              hideSelectedOptions: false,
              chakraStyles: multiValueAutocompleteStyles,
            }}
            loadOptionsFromAPI
            filterParams={{
              page_size: '30',
              ordering: 'title__translations__translated_message',
            }}
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            <br />
          </FormLabel>
          <Flex sx={haveAssessSx}>
            <Input
              name="is_mandatory"
              component={ThreeStateCheckBox}
              componentProps={{
                spacing: 5,
                lhs_label: (
                  <FormattedMessage
                    id="global-frameworks.visualisation.filters.mandatory"
                    defaultMessage="Mandatory"
                  />
                ),
                rhs_label: (
                  <FormattedMessage
                    id="global-frameworks.visualisation.filters.assess"
                    defaultMessage="Assess"
                  />
                ),
              }}
            />
          </Flex>
        </FormControl>

        <GridItem sx={buttonSx}>
          <Button as={RouterLink} to={frameworkHref} variant="primary">
            <FormattedMessage
              id="global-frameworks.cta.details"
              defaultMessage="Go to global control framework"
            />
          </Button>
        </GridItem>
      </SimpleGrid>
    </Form>
  );
};
