import { Flex, Heading, SimpleGrid, VisuallyHidden } from '@chakra-ui/react';
import { FormattedMessage, defineMessage } from 'react-intl';
import { Outlet } from 'react-router';

import { Paginator, SimpleEntityList, VStack } from 'components';
import ControlEntity from 'entities/api/Control';
import { useLocale } from 'locales/useLocale';
import { ControlCard } from 'screens/control-frameworks/components/ControlCard';
import { ControlCardAdd } from 'screens/control-frameworks/components/ControlCardAdd';
import { ControlsFilters } from 'screens/control-frameworks/components/ControlsFilters';
import { useGlobalControlListControls } from 'screens/global-frameworks/ControlTab/GlobalFrameworkControlsList.hooks.ts';
import { createLogger } from 'util/createLogger.ts';

export function GlobalFrameworkControlsList() {
  const localeContext = useLocale();
  const log = createLogger('ControlFrameworkControlsList');

  const globalControlListControls = useGlobalControlListControls();
  const { paginatorProps, formControls, controlCount, appliedParams } =
    globalControlListControls;

  log('%o', { formControls });

  return (
    <Flex layerStyle="cardLike" gap={8}>
      <ControlsFilters {...globalControlListControls} />

      <VStack gap={4}>
        <Heading as="h3" size="xs">
          <FormattedMessage
            id="global-framework.controls.list.mandatory-controls"
            defaultMessage="Mandatory controls"
          />{' '}
          ({controlCount})
        </Heading>

        <VStack layerStyle="loadingWrapper">
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={2}>
            <SimpleEntityList
              Entity={ControlEntity}
              params={appliedParams}
              RecordComponent={ControlCard}
              recordComponentProps={{
                localeContext,
                controlType: 'Global',
                linkTarget: '../controls/:controlUuid/',
              }}
              EmptyMessageComponent={VisuallyHidden}
            />
            <ControlCardAdd
              text={defineMessage({
                id: 'global-framework.controls.list.create-global-control',
                defaultMessage: 'Create a global control',
              })}
            />
          </SimpleGrid>
        </VStack>

        <Paginator
          displayAlways={
            paginatorProps.pageSizeOptions
              ? controlCount > paginatorProps.pageSizeOptions[0].toString()
              : true
          }
          {...paginatorProps}
        />
      </VStack>

      <Outlet />
    </Flex>
  );
}
