import {
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import type { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';

import { withQuery } from '@burnsred/entity-duck-query';
import { VStack } from 'components';
import {
  type StickyActionsContext,
  StickyActionsFooter,
  useStickyActions,
  useStickyActionsContext,
} from 'components/layout';
import type { CompliancePlanEntityRecord } from 'entities/api/CompliancePlan/CompliancePlan';
import CompliancePlanEntity from 'entities/api/CompliancePlan/CompliancePlan';
import {
  type EntityDuckActionResolved,
  type WithQueryReturnProps,
} from 'types';
import { createLogger } from 'util/createLogger';

import { DeleteButton } from './components/DeleteButton.tsx';
import { DeletePlanModal } from './components/DeletePlanModal';
import { SaveButton } from './components/SaveButton';
import {
  COMPLIANCE_TABS,
  type ComplianceTabsRouteNames,
  getComplianceTabRoute,
} from './tabs';

const log = createLogger('CompliancePlan');

export type CompliancePlanContext = {
  isNew: boolean;
  /** might also be 'new' */
  compliancePlanUuid: string;
  actions: StickyActionsContext['actions'];
  setActions: StickyActionsContext['setActions'];
  compliancePlanFormProps: CompliancePlanProps;
};

const pathMap = Object.values(COMPLIANCE_TABS.tabPaths).reduce(
  (acc, { index, routeName }) => ({ ...acc, [routeName]: index }),
  {} as Record<ComplianceTabsRouteNames, number>,
);

type CompliancePlanProps = WithQueryReturnProps<CompliancePlanEntityRecord>;

/**
 * Displays the tabbed interface for a Compliance Plan
 * and constructs the context to be passed down to each tab.
 */
function CompliancePlan(props: CompliancePlanProps) {
  const toast = useToast();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { compliancePlanUuid, siteCompliancePlanUuid } = useParams();
  const basePath = siteCompliancePlanUuid
    ? `/${COMPLIANCE_TABS.base}/${compliancePlanUuid}/site/${siteCompliancePlanUuid}/${COMPLIANCE_TABS.tabPaths.operatingEnvironment.routeName}`
    : `/${COMPLIANCE_TABS.base}/${compliancePlanUuid}/${'operating-environment' satisfies ComplianceTabsRouteNames}`;

  const isNew = compliancePlanUuid?.toLowerCase() == 'new';

  const {
    isOpen: isOpenDeletePlanModal,
    onOpen: onOpenDeletePlanModal,
    onClose: onCloseDeletePlanModal,
  } = useDisclosure();

  const tabSegment =
    (siteCompliancePlanUuid
      ? pathname.split(`${siteCompliancePlanUuid}/`).at(-1)?.split('/')[0]
      : pathname.split(`${compliancePlanUuid}/`).at(-1)?.split('/')[0]) ??
    COMPLIANCE_TABS.tabPaths.operatingEnvironment.routeName;

  const currentTab = pathMap[tabSegment as keyof typeof pathMap];

  log('%o', { tabSegment, compliancePlanUuid, props });

  const firstSiteCompliancePlan = useMemo(() => {
    return props.value?.get('sitecomplianceplan_set')?.first();
  }, [props.value]);

  const createHandleNavigate = (targetTab: ComplianceTabsRouteNames) => () =>
    void navigate(
      getComplianceTabRoute({
        targetTab,
        compliancePlanUuid,
        siteCompliancePlanUuid,
        firstSiteCompliancePlan,
      }),
    );

  const { actions, setActions } = useStickyActions();
  const { isStickActionsFooterVisible, children, setChildren } =
    useStickyActionsContext();

  const deleteCompliancePlan = useCallback(async () => {
    const response = dispatch(
      CompliancePlanEntity.duck.actions.delete(props.value),
    );

    const promise: EntityDuckActionResolved = await response.promise;
    if (promise.name == 'delete_resolved') {
      toast({
        title: formatMessage({
          id: 'toast.deleted',
          defaultMessage: 'Deleted',
        }),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      navigate(`/${COMPLIANCE_TABS.base}`);
    } else {
      toast({
        title: 'Error',
        description: (promise?.payload as AxiosError)?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [dispatch, formatMessage, navigate, props.value, toast]);

  const save = useCallback(async () => {
    log('🟢 save %o', props);
    const result = props.onSubmit({
      target: {
        name: props.name,
        value: props.value,
      },
    });
    const promise = await result.promise;
    log('save %o', { result, promise });
    if (promise.name == 'save_resolved') {
      toast({
        title: formatMessage({
          id: 'toast.saved',
          defaultMessage: 'Saved',
        }),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      // TODO invalidate list view
    } else {
      toast({
        title: 'Error',
        description: (promise?.payload as AxiosError)?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }

    if (isNew && promise.name == 'save_resolved') {
      navigate(
        basePath.replace('new', (promise?.payload as { uuid: string })?.uuid),
      );
    }
  }, [basePath, formatMessage, isNew, navigate, props, toast]);

  useEffect(() => {
    // the save button for CompliancePlanInfo will override
    // any save button set at a lower component level
    // eg Baseline Assessment or Actions
    if (
      // Reacting to a hardcoded pathname is brittle,
      !pathname.includes('baseline-assessment/control') &&
      !pathname.includes('actions')
    ) {
      setChildren([
        <DeleteButton key="delete-button" onClick={onOpenDeletePlanModal} />,
        <SaveButton key="save-button" onClick={save} />,
      ]);
    }
    // render cautiously, but always on pathname!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, save]);

  const context: CompliancePlanContext = {
    isNew,
    compliancePlanUuid: compliancePlanUuid ?? 'new',
    actions,
    setActions,
    compliancePlanFormProps: props,
  };

  return (
    <VStack spacing={5}>
      <Heading size="lg">
        {isNew ? (
          <FormattedMessage
            id="compliance.tab.operating-environment.heading.new"
            defaultMessage="Create a compliance plan"
          />
        ) : (
          props?.value.get('name')
        )}
      </Heading>

      <Tabs
        index={currentTab} // control via routing
        isLazy // only load the selected tab
        variant="enclosed"
      >
        <TabList>
          <Tab onClick={createHandleNavigate('operating-environment')}>
            <FormattedMessage
              id="compliance.tab.operating-environment"
              defaultMessage="Operating Environment"
            />
          </Tab>
          <Tab
            onClick={
              isNew ? undefined : createHandleNavigate('baseline-assessment')
            }
            isDisabled={isNew || !firstSiteCompliancePlan}
          >
            <FormattedMessage
              id="compliance.tab.baseline-assessment"
              defaultMessage="Baseline Assessment"
            />
          </Tab>
          <Tab
            onClick={isNew ? undefined : createHandleNavigate('actions')}
            isDisabled={isNew || !firstSiteCompliancePlan}
          >
            <FormattedMessage
              id="compliance.tab.actions"
              defaultMessage="Actions"
            />
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel layerStyle="loadingWrapper">
            <Outlet context={context} />
          </TabPanel>

          <TabPanel layerStyle="loadingWrapper">
            <Outlet context={context} />
          </TabPanel>

          <TabPanel layerStyle="loadingWrapper">
            <Outlet context={context} />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <StickyActionsFooter isVisible={isStickActionsFooterVisible}>
        {children}
      </StickyActionsFooter>
      <DeletePlanModal
        isOpen={isOpenDeletePlanModal}
        onClose={onCloseDeletePlanModal}
        onOpen={onOpenDeletePlanModal}
        onDelete={deleteCompliancePlan}
      />
    </VStack>
  );
}

export default withQuery(() => {
  const { compliancePlanUuid } = useParams();
  const id = compliancePlanUuid == 'new' ? null : compliancePlanUuid;
  log('withQuery %o', { compliancePlanUuid });
  return {
    action: CompliancePlanEntity.duck.actions.get({ id }),
  };
})(CompliancePlan);
