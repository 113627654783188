import {
  Button,
  Card,
  CardBody,
  Divider,
  HStack,
  Heading,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { RouterLink } from '@burnsred/ui-chakra';
import { Paginator, VStack } from 'components';

import { useCompliancePlansDashboard } from './CompliancePlansDashboard.hooks.ts';
import { myLatestCompliancePlanTableHeaders } from './CompliancePlanTableHeaders.tsx';
import {
  CompliancePlanTable,
  MyLatestCompliancePlanTableRow,
} from './components/CompliancePlanTable';
import { ComplianceStats } from './components/ComplianceStats';
import { cardSx } from '../control-frameworks/components/ControlCard.styles.tsx';

/** Dashboard / List view for Compliance Plans */
export default function CompliancePlansDashboard() {
  const { siteCompliancePlanRows, paginatorProps } =
    useCompliancePlansDashboard();

  return (
    <VStack gap={6}>
      <HStack justify="space-between">
        <Heading>
          <FormattedMessage
            id="compliance.dashboard.heading.dashboard"
            defaultMessage="Compliance Plans"
          />
        </Heading>

        <Button as={RouterLink} to="new" variant="primary" size="lg">
          <FormattedMessage
            id="compliance.dashboard.button.create-new"
            defaultMessage="Create a compliance plan"
          />
        </Button>
      </HStack>

      <ComplianceStats />

      <Divider />

      <Heading size="sm">
        <FormattedMessage
          id="compliance.dashboard.heading.my-latest-compliance-plans"
          defaultMessage="My latest compliance plans"
        />
        {` (${paginatorProps.totalItems})`}
      </Heading>

      <Card
        variant="outline"
        sx={{ ...cardSx, paddingLeft: 0, paddingRight: 0, paddingBottom: 5 }}
      >
        <CardBody gap={4}>
          <CompliancePlanTable
            tableColumns={myLatestCompliancePlanTableHeaders}
          >
            {siteCompliancePlanRows?.map((plan) => (
              <MyLatestCompliancePlanTableRow
                key={plan.get('uuid')}
                record={plan}
              />
            ))}
          </CompliancePlanTable>
        </CardBody>
      </Card>

      <Paginator displayAlways={false} {...paginatorProps} />
    </VStack>
  );
}
