import type { SystemStyleObject } from '@chakra-ui/react';

export const headingSx: SystemStyleObject = {
  color: 'primary',
};

export const subheadingSx: SystemStyleObject = {
  fontSize: '12px',
  color: 'gray.500',
};
