import type { Moment } from 'moment';

import { Entity, Fields } from '@burnsred/entity';
import { type EntityFields, type EntityRecord } from 'types.ts';

import FilerFile, { type FilerFileEntityRecord } from './FilerFile.ts';

export function getAttachmentUrl(
  record: EntityRecord<{ attachment: FilerFileEntityRecord }>,
): string {
  return record?.get('attachment')?.get('url') ?? '';
}

class BaseAttachmentEntity extends Entity {
  static fields: EntityFields<BaseAttachmentEntityFields> = {
    uuid: new Fields.IdField(),
    attachment: new Fields.EntityField({
      entity: FilerFile,
    }),
    created: new Fields.DateTimeField(),
  };
}

export type BaseAttachmentEntityFields = {
  uuid: string;
  created: Moment;
  attachment: FilerFileEntityRecord;
};

export type BaseAttachmentEntityRecord = EntityRecord<BaseAttachmentEntity>;

export default BaseAttachmentEntity;
