import { List } from 'immutable';

import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import { type CompliancePlanEntityRecord } from './CompliancePlan';
import SiteEquipmentOperatingContextEntity from './SiteEquipmentOperatingContext';
import type { SiteEquipmentOperatingContextEntityRecord } from './SiteEquipmentOperatingContext';
import {
  createMemoizedTree,
  createTreeEnhancedList,
  getAncestors,
  getDescendants,
} from '../abstract';
import { type EquipmentEntityRecord } from '../Equipment';
import { toString } from '../i18n/I18nText';
import { type OperatingContextEntityRecord } from '../OperatingContext';
import BhpPersonEntity from '../Person/BhpPerson';
import type { BHPPersonEntityRecord } from '../Person/BhpPerson';
import GrcOrganisationalUnitEntity, {
  type GrcOrganisationalUnitEntityRecord,
} from '../Person/GrcOrganisationalUnit';
import type { ScenarioEntityRecord } from '../Scenario';
import ScenarioEntity from '../Scenario';

class SiteCompliancePlanEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube_compliance/v1/site_complianceplan/',
  };

  static fields: EntityFields<SiteCompliancePlanEntityFields> = {
    uuid: new Fields.IdField({ blank: true }),

    general_manager: new Fields.EntityField({
      entity: BhpPersonEntity,
      blank: true,
    }),

    president: new Fields.EntityField({
      entity: BhpPersonEntity,
      blank: true,
    }),

    local_control_advisor: new Fields.EntityField({
      entity: BhpPersonEntity,
      blank: true,
    }),

    site: new Fields.EntityField({
      entity: GrcOrganisationalUnitEntity,
      blank: true,
    }),

    scenarios: new Fields.EntityField({
      entity: ScenarioEntity,
      many: true,
      blank: true,
    }),

    siteequipmentoperatingcontext_set: new Fields.EntityField({
      entity: SiteEquipmentOperatingContextEntity,
      many: true,
      blank: true,
    }),
  };

  static toString = toString<SiteCompliancePlanEntityRecord>;

  static getCompliancePlanEquipmentTree(
    record: SiteCompliancePlanEntityRecord,
  ) {
    if (record?.get('siteequipmentoperatingcontext_set')) {
      return createMemoizedTree(
        createTreeEnhancedList(
          record.get('siteequipmentoperatingcontext_set'),
          ['compliance_equipment', 'equipment'],
        ),
      );
    }
    return List();
  }

  static createMissingEquipmentOperatingContexts(
    compliancePlan: CompliancePlanEntityRecord,
    equipmentOperationList: List<SiteEquipmentOperatingContextEntityRecord>,
  ) {
    let newEquipmentOperationList = equipmentOperationList;
    compliancePlan
      .get('complianceplanbaseequipment_set')
      .forEach((baseEquipment) => {
        if (
          baseEquipment.get('uuid') &&
          !equipmentOperationList.find(
            (operatingEquipment) =>
              operatingEquipment
                ?.get('compliance_equipment')
                ?.get('equipment')
                ?.get('uuid') === baseEquipment?.get('equipment')?.get('uuid'),
          )
        ) {
          let newRecord = SiteEquipmentOperatingContextEntity.dataToRecord({});
          newRecord = newRecord.set('compliance_equipment', baseEquipment);
          newEquipmentOperationList = newEquipmentOperationList.push(newRecord);
        }
      });
    return newEquipmentOperationList;
  }

  static cleanExtraEquipmentOperatingContexts(
    compliancePlan: CompliancePlanEntityRecord,
    equipmentOperationList: List<SiteEquipmentOperatingContextEntityRecord>,
  ) {
    let newEquipmentOperationList = equipmentOperationList;
    const baseEquipment = compliancePlan.get('complianceplanbaseequipment_set');
    newEquipmentOperationList = newEquipmentOperationList.filter(
      (operatingEquipment) =>
        baseEquipment.find(
          (baseEquipment) =>
            operatingEquipment
              ?.get('compliance_equipment')
              ?.get('equipment')
              ?.get('uuid') === baseEquipment?.get('equipment')?.get('uuid'),
        ),
    );
    return newEquipmentOperationList;
  }

  static syncEquipmentOperatingContexts(
    compliancePlan: CompliancePlanEntityRecord,
    equipmentOperationList: List<SiteEquipmentOperatingContextEntityRecord>,
  ) {
    let newEquipmentOperationList = equipmentOperationList;
    newEquipmentOperationList = this.createMissingEquipmentOperatingContexts(
      compliancePlan,
      newEquipmentOperationList,
    );
    newEquipmentOperationList = this.cleanExtraEquipmentOperatingContexts(
      compliancePlan,
      newEquipmentOperationList,
    );
    return newEquipmentOperationList;
  }

  static updateEquipmentOperatingContexts(
    equipmentOperation: SiteEquipmentOperatingContextEntityRecord,
    operatingContext: OperatingContextEntityRecord,
    equipmentOperationList: List<SiteEquipmentOperatingContextEntityRecord>,
    equipmentTree: List<EquipmentEntityRecord>,
  ) {
    let newEquipmentOperationList = equipmentOperationList;
    const equipUuid = equipmentOperation
      .get('compliance_equipment')
      .get('equipment')
      .get('uuid');

    // Determine if the operation is an addition or removal
    const isAddition = !equipmentOperation
      .get('operating_contexts')
      .includes(operatingContext);

    // Memorize the descendants of the equipment
    let updatedAdditional = getDescendants(
      equipUuid,
      equipmentTree,
    ) as List<EquipmentEntityRecord>;

    // If the operation is an addition, memorize the ancestors of the equipment,
    // add them to the updatedAdditional list
    if (isAddition) {
      updatedAdditional = updatedAdditional.merge(
        getAncestors(equipUuid, equipmentTree) as List<EquipmentEntityRecord>,
      );
    }

    // Update the operating context of the equipment operation objects
    updatedAdditional.forEach((descendant) => {
      // Find the descendant in the equipment operation list
      const indexToUpdate = newEquipmentOperationList.findIndex(
        (operatingEquipment) =>
          operatingEquipment
            .get('compliance_equipment')
            .get('equipment')
            .get('uuid') === descendant.get('uuid'),
      );

      if (indexToUpdate !== -1) {
        let newDescendantEquipmentOperation =
          newEquipmentOperationList.get(indexToUpdate);
        if (isAddition) {
          if (
            !newDescendantEquipmentOperation
              ?.get('operating_contexts')
              ?.includes(operatingContext)
          ) {
            newDescendantEquipmentOperation =
              newDescendantEquipmentOperation?.set(
                'operating_contexts',
                newDescendantEquipmentOperation
                  .get('operating_contexts')
                  .push(operatingContext),
              );
          }
        } else {
          if (
            newDescendantEquipmentOperation
              ?.get('operating_contexts')
              ?.includes(operatingContext)
          ) {
            newDescendantEquipmentOperation =
              newDescendantEquipmentOperation?.set(
                'operating_contexts',
                newDescendantEquipmentOperation
                  .get('operating_contexts')
                  .filter((selected) => !selected.equals(operatingContext)),
              );
          }
        }

        // Set the new list of equipment operations list
        if (newDescendantEquipmentOperation) {
          newEquipmentOperationList = newEquipmentOperationList.set(
            indexToUpdate,
            newDescendantEquipmentOperation,
          );
        }
      }
    });
    return newEquipmentOperationList;
  }

  static updateAllEquipmentOperatingContexts(
    operatingContext: OperatingContextEntityRecord,
    equipmentOperationList: List<SiteEquipmentOperatingContextEntityRecord>,
    isAddition: boolean = false,
  ) {
    return equipmentOperationList.map((equip_operation) => {
      if (isAddition) {
        equip_operation = equip_operation.set(
          'operating_contexts',
          equip_operation
            .get('operating_contexts')
            .filter((op_context) => !op_context.includes(operatingContext))
            .push(operatingContext),
        );
        return equip_operation;
      } else {
        equip_operation = equip_operation.set(
          'operating_contexts',
          equip_operation
            .get('operating_contexts')
            .filter((op_context) => !op_context.equals(operatingContext)),
        );
        return equip_operation;
      }
    });
  }

  /** finds the asset of the SiteCompliancePlan by traversing site.ancestors */
  static getAsset(record: SiteCompliancePlanEntityRecord) {
    return record?.get('site')?.get('ancestors')?.last();
  }
}

export type SiteCompliancePlanEntityFields = {
  uuid: string;
  general_manager: BHPPersonEntityRecord;
  president: BHPPersonEntityRecord;
  local_control_advisor: BHPPersonEntityRecord;
  site: GrcOrganisationalUnitEntityRecord;
  scenarios: List<ScenarioEntityRecord>;
  // FIXME applicable_controls will change
  // applicable_controls: List<ApplicableControlForPlanEntityRecord>;
  siteequipmentoperatingcontext_set: List<SiteEquipmentOperatingContextEntityRecord>;
};

export type SiteCompliancePlanEntityRecord =
  EntityRecord<SiteCompliancePlanEntityFields>;

SiteCompliancePlanEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: SiteCompliancePlanEntity,
  name: 'SiteCompliancePlan',
});

export default SiteCompliancePlanEntity;
