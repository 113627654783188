import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

const outline = defineStyle({
  borderColor: 'gray.400',

  _disabled: {
    pointerEvents: 'none',
  },
});

const whiteOutline = defineStyle({
  // ...outline, // for some reason, composing here doesn't work as expected

  bgColor: 'white',
  border: '1px',
  borderColor: 'gray.400',

  _hover: {
    bgColor: 'gray.100',
  },

  _disabled: {
    pointerEvents: 'none',
  },
});

const solid = defineStyle({
  // textTransform: 'uppercase',
  // fontWeight: 'medium',

  _disabled: {
    pointerEvents: 'none',
  },
});

const primary = defineStyle({
  ...solid,
  bgColor: 'primary',
  color: 'white',
  _hover: {
    bgColor: 'brandDustyOrange',
    textDecoration: 'none',
  },
});

const link = defineStyle((props) => {
  const { colorScheme: c } = props;
  return {
    color: `${c}.500`,
    _dark: {
      color: `${c}.400`,
    },
    fontWeight: 'normal',
  };
});

/**
 * > The Button component is a single part component. All of the styling is
 * > applied directly to the button element.
 * @see https://chakra-ui.com/docs/components/button/theming
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/button.ts
 *
 * Note that with regard to colorScheme, there is a bunch of special-casing that
 * is applied to various colors (see `accessibleColorMap`).
 */
export const buttonTheme = defineStyleConfig({
  baseStyle: {},

  defaultProps: {
    variant: 'solid',
    size: 'md',
  },

  variants: {
    solid,
    primary,
    outline,
    whiteOutline,
    link,
  },

  sizes: {
    xs: {
      fontSize: 'xs',
      borderRadius: 'base',
    },
    sm: {
      fontSize: 'xs',
      borderRadius: 'base',
    },
    md: {
      fontSize: 'xs',
      borderRadius: 'base',
    },
    lg: {
      borderRadius: 'base',
      fontSize: 'md',
    },
  },
});
