import { type InputProps as ChakraInputProps } from '@chakra-ui/react';
import { type List, type Map, fromJS } from 'immutable';
import { useCallback, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';

import type { InputProps } from '@burnsred/entity-form';
import { Autocomplete as RawAutocomplete } from 'components/Autocomplete';
import { useWidgetOnChangeComponent } from 'forms/hooks/widget';
import { useLocale } from 'locales/useLocale';

type AutocompleteOption = Record<string, unknown>;

export type WidgetSelectInputProps<
  T extends Map<string, unknown>,
  Many extends boolean = true,
> = Omit<InputProps<T, Many>, 'options'> &
  Omit<
    ChakraInputProps,
    'onChange' | 'value' | 'size' | 'variant' | 'defaultValue' | 'isDisabled'
  > & {
    getOptionLabel: (option: object) => string;
    getOptionValue: (option: object) => string;
    options: List<T>;
    disabled: boolean;
  };

const Autocomplete = <
  T extends Map<string, unknown>,
  Many extends boolean = true,
>(
  props: WidgetSelectInputProps<T, Many>,
) => {
  const { locale } = useLocale();
  const {
    getOptionLabel,
    getOptionValue,
    field,
    processing,
    onChange,
    onChangeInput,
    options,
    value,
    name,
    index,
    disabled,
  } = props;
  const [search, setSearch] = useState('');

  const selectOptions = useMemo<AutocompleteOption[]>(
    // Consider reselect for caching the transformation
    () => options?.toJS() ?? [],
    [options],
  );

  const selectValue = useMemo<AutocompleteOption[]>(
    // @ts-expect-error 'Map<string, unknown>' is missing the following properties from type 'AutocompleteOption[]': ...
    () => (typeof value?.toJS == 'function' ? value.toJS() : value),
    [value],
  );

  const handleChange = useWidgetOnChangeComponent<Record<string, unknown>>(
    name,
    onChange,
    index,
    fromJS,
  );

  useDebounce(
    () => {
      // @ts-expect-error '(event: Event) => void' is not assignable to type '(newValue: string, actionMeta: InputActionMeta) => void'
      onChangeInput(search);
    },
    500,
    [search],
  );

  const handleGetOptionLabel = useCallback(
    (option: AutocompleteOption) => {
      return field.toString(fromJS(option), locale);
    },
    [field, locale],
  );
  const handleGetOptionValue = useCallback(
    (option: AutocompleteOption) => field.toString(fromJS(option)),
    [field],
  );

  return (
    <RawAutocomplete
      {...props}
      isDisabled={disabled}
      getOptionLabel={getOptionLabel || handleGetOptionLabel}
      getOptionValue={getOptionValue || handleGetOptionValue}
      isClearable={field.blank}
      isLoading={processing}
      isMulti={field.many}
      onChange={handleChange}
      onInputChange={setSearch}
      options={selectOptions}
      value={selectValue}
    />
  );
};

export default Autocomplete;
