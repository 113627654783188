import {
  Button,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { List } from 'immutable';
import { FormattedMessage } from 'react-intl';

import { Form, Input, type InputProps } from '@burnsred/entity-form';
import { VStack } from 'components';
import { AutocompleteField } from 'components/widgets';
import BaselineAssessmentEntity, {
  type BaselineAssessmentEntityRecord,
} from 'entities/api/CompliancePlan/BaselineAssessment';
import SiteEquipmentOperatingContextEntity, {
  type SiteEquipmentOperatingContextEntityRecord,
} from 'entities/api/CompliancePlan/SiteEquipmentOperatingContext';
import type { EquipmentEntityRecord } from 'entities/api/Equipment';
import { useLocale } from 'locales/useLocale';

import {
  deleteButtonProps,
  headerSx,
  header__contentSx,
  header__headingSx,
  modalButtonProps,
} from './BaselineAssessmentForm.styles';
import { AnswerForm } from '../AnswerForm';
import { ImplementationAnswerForm } from '../ImplementationAnswerForm';

export type BaselineAssessmentFormProps =
  InputProps<BaselineAssessmentEntityRecord> & {
    // Override, type as InputProps with Many says it should be a List
    value: BaselineAssessmentEntityRecord;
    isForMandatory: boolean;
    equipOptions: List<SiteEquipmentOperatingContextEntityRecord>;
    equipmentLevel1: List<EquipmentEntityRecord>;
    missingOptions: List<SiteEquipmentOperatingContextEntityRecord>;
    removeBaselineAssessment: (index: number) => void;
  };

export default function BaselineAssessmentForm(
  props: BaselineAssessmentFormProps,
) {
  const { toString } = useLocale();
  const {
    equipOptions,
    missingOptions,
    isForMandatory,
    equipmentLevel1,
    removeBaselineAssessment,
    ...inputProps
  } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { value } = inputProps;
  return (
    <Form {...inputProps}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage
              id="compliance.baseline-assessment.form.delete-modal-header"
              defaultMessage="Delete baseline assessment"
            />
          </ModalHeader>
          <ModalBody>
            <FormattedMessage
              id="compliance.baseline-assessment.form.delete-confirm"
              defaultMessage="Do you want to delete this baseline assessment?"
            />
          </ModalBody>
          <ModalFooter justifyContent={'space-between'} gap={2}>
            <Button
              {...modalButtonProps}
              variant="whiteOutline"
              onClick={onClose}
            >
              <FormattedMessage id="ui.cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              {...modalButtonProps}
              variant="primary"
              onClick={() => {
                onClose();
                if (inputProps.index !== undefined) {
                  removeBaselineAssessment(inputProps.index);
                }
              }}
            >
              <FormattedMessage id="ui.ok" defaultMessage="OK" />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <VStack
        as="section"
        hidden={!(isForMandatory === value.get('is_mandatory'))}
      >
        <VStack as="header" sx={headerSx}>
          <HStack
            sx={header__headingSx}
            className={value.get('is_mandatory') ? 'is-mandatory' : 'is-assess'}
          >
            <Heading size="xs">
              {value.get('is_mandatory') ? (
                <FormattedMessage
                  id="compliance.baseline-assessment.form.mandatory"
                  defaultMessage="Mandatory"
                />
              ) : (
                <FormattedMessage
                  id="compliance.baseline-assessment.form.assess"
                  defaultMessage="Assess"
                />
              )}
            </Heading>

            <Button {...deleteButtonProps} onClick={onOpen}>
              <FormattedMessage
                id="compliance.baseline-assessment.form.delete-button"
                defaultMessage="Delete baseline assessment"
              />
            </Button>
          </HStack>

          <VStack sx={header__contentSx}>
            <Text fontSize="sm">
              {value.get('is_mandatory') ? (
                <FormattedMessage
                  id="compliance.baseline-assessment.form.control-mandatory-explanation"
                  defaultMessage="This control is mandatory for the following vehicles and operating contexts:"
                />
              ) : (
                <FormattedMessage
                  id="compliance.baseline-assessment.form.control-assess-explanation"
                  defaultMessage="This control is assess for the following vehicles and operating contexts:"
                />
              )}
            </Text>

            <SimpleGrid columns={2} gap={4}>
              <Input
                name="equipment"
                component={AutocompleteField}
                componentProps={{
                  label: (
                    <>
                      <FormattedMessage
                        id="compliance.baseline-assessment.form.equipment"
                        defaultMessage="Equipment"
                      />
                      <Text as="span" color={'red'}>
                        {' '}
                        *
                      </Text>
                    </>
                  ),
                  options: BaselineAssessmentEntity.getEquipmentOptions(
                    equipOptions,
                    missingOptions,
                  ),
                  getOptionLabel: (option: unknown) =>
                    SiteEquipmentOperatingContextEntity.getEquipmentOptionLabel(
                      option,
                      equipmentLevel1,
                      toString,
                    ),
                  closeMenuOnSelect: false,
                  hideSelectedOptions: false,
                  size: 'sm',
                }}
              />

              <Input
                name="operating_contexts"
                component={AutocompleteField}
                componentProps={{
                  label: (
                    <>
                      <FormattedMessage
                        id="compliance.baseline-assessment.form.operating-contexts"
                        defaultMessage="Operating Contexts"
                      />
                      <Text as="span" color={'red'}>
                        {' '}
                        *
                      </Text>
                    </>
                  ),
                  disabled: value?.get('equipment').isEmpty(),
                  options:
                    BaselineAssessmentEntity.getOperatingContextsForEquipment(
                      value?.get('equipment') ?? List(),
                      missingOptions,
                    ),
                  closeMenuOnSelect: false,
                  hideSelectedOptions: false,
                  size: 'sm',
                }}
              />
            </SimpleGrid>
          </VStack>
        </VStack>

        <VStack gap={0.5}>
          {!value.get('is_mandatory') && (
            <ImplementationAnswerForm {...inputProps} />
          )}
          <Input
            name="answers"
            component={AnswerForm}
            many
            componentProps={{
              disabled: value.get('is_control_being_implemented') === false,
            }}
          />
          {value.get('is_mandatory') && (
            <ImplementationAnswerForm {...inputProps} />
          )}
        </VStack>
      </VStack>
    </Form>
  );
}
