import { Box, Text, Th } from '@chakra-ui/react';

import { Tooltip } from 'components';
import {
  tooltipListItemSx,
  tooltipTextSx,
} from 'components/ControlGrid/components/MandatoryAssessTable.styles';
import { type EquipmentEntityRecord } from 'entities/api/Equipment';
import { useLocale } from 'locales/useLocale';

import { equipmentIsFiltered } from './equipmentIsFiltered';
import type { MandatoryAssessTableProps } from './MandatoryAssessTable';
import { EquipmentRequirementCells } from './RequirementCells';

export type L2EquipmentRowProps = Pick<
  MandatoryAssessTableProps,
  'filterRecord' | 'operatingContexts' | 'applicableRules'
> & {
  equipmentLevel2: EquipmentEntityRecord;
  isParentFiltered: boolean;
  isAnySiblingFiltered: boolean;
};

export const L2EquipmentRow = ({
  equipmentLevel2,
  filterRecord,
  isParentFiltered,
  isAnySiblingFiltered,
  ...props
}: L2EquipmentRowProps) => {
  const { toString } = useLocale();

  const isFiltered = equipmentIsFiltered(filterRecord, equipmentLevel2);
  const description = toString(equipmentLevel2, 'description');
  return (
    <>
      <Th
        className={[
          'equipment-l2',
          isFiltered || (isParentFiltered && !isAnySiblingFiltered)
            ? 'isFilteredRow'
            : '',
        ].join(' ')}
      >
        <Box
          className={description ? 'has-tooltip' : ''}
          sx={tooltipListItemSx}
        >
          {description ? (
            <Tooltip
              label={<Text sx={tooltipTextSx}>{description}</Text>}
              placement="right-start"
            >
              <span>{toString(equipmentLevel2)}</span>
            </Tooltip>
          ) : (
            <span>{toString(equipmentLevel2)}</span>
          )}
        </Box>
      </Th>
      <EquipmentRequirementCells
        equipment={equipmentLevel2}
        filterRecord={filterRecord}
        isFiltered={isFiltered || (isParentFiltered && !isAnySiblingFiltered)}
        {...props}
      />
    </>
  );
};
