import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import { toString } from '../i18n/I18nText';
import type { BHPPersonEntityRecord } from '../Person/BhpPerson';
import BhpPersonEntity from '../Person/BhpPerson';

/**
 * This is an intermediate entity used to bulk assign owners to SiteCompliancePlans.
 * (doesn't exist on the broker)
 */
class SiteCompliancePlanBulkAssignEntity extends Entity {
  static fields: EntityFields<SiteCompliancePlanBulkAssignEntityFields> = {
    general_manager: new Fields.EntityField({
      entity: BhpPersonEntity,
      blank: true,
    }),

    president: new Fields.EntityField({
      entity: BhpPersonEntity,
      blank: true,
    }),

    local_control_advisor: new Fields.EntityField({
      entity: BhpPersonEntity,
      blank: true,
    }),
  };

  static toString = toString<SiteCompliancePlanBulkAssignEntityRecord>;
}

export type SiteCompliancePlanBulkAssignEntityFields = {
  general_manager: BHPPersonEntityRecord;
  president: BHPPersonEntityRecord;
  local_control_advisor: BHPPersonEntityRecord;
};

export type SiteCompliancePlanBulkAssignEntityRecord =
  EntityRecord<SiteCompliancePlanBulkAssignEntityFields>;

SiteCompliancePlanBulkAssignEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: SiteCompliancePlanBulkAssignEntity,
  name: 'SiteCompliancePlanBulkAssign',
});

export default SiteCompliancePlanBulkAssignEntity;
