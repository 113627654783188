import {
  Textarea as ChakraTextarea,
  type TextareaProps as ChakraTextareaProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  type SystemStyleObject,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';

import type { InputProps } from '@burnsred/entity-form';
import { filterProps } from '@burnsred/higher-order-component';
import { useLocale } from 'locales/useLocale';

export type TextareaWidgetProps = InputProps &
  ChakraTextareaProps & {
    label?: string;
    /** passed to FormControl */
    sx?: SystemStyleObject;
    debounceMs?: number;
  };

/**
 * Renders a Chakra Textarea, complete with FormControl & FormErrorMessage
 *
 * @see RawTextarea for an Textarea which renders only the Textarea
 */
export const Textarea = (props: TextareaWidgetProps) => {
  const { debounceMs, name, onChange, index, sx } = props;
  const { translateErrors } = useLocale();
  const errors = translateErrors(props?.errors);

  const [val, setVal] = useState(props.value);

  useDebounce(
    () => {
      onChange({
        target: {
          name,
          index,
          value: val,
        },
      });
    },
    debounceMs || 1000,
    [val],
  );

  useEffect(() => {
    if (props.value !== val) setVal(props.value);
    // The missing dependency is on purpose to avoid unneccessary re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  return (
    <FormControl
      isRequired={props.isRequired}
      isInvalid={!!errors}
      isDisabled={props.disabled}
      sx={sx}
    >
      {props?.label && <FormLabel>{props?.label}</FormLabel>}
      <ChakraTextarea
        {...filterProps<ChakraTextareaProps>(props)}
        value={val}
        // @ts-expect-error string not assignable to props.value type set as default
        onChange={(evt) => setVal(evt.target.value)}
      />
      <FormErrorMessage>{errors}</FormErrorMessage>
    </FormControl>
  );
};
