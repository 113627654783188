import { useState } from 'react';

import { handleNotImplemented } from 'util/handleNotImplemented';

const defaultActions = {
  next: handleNotImplemented('next'),
  saveAsDraft: handleNotImplemented('saveAsDraft'),
  cancel: handleNotImplemented('cancel'),
  previous: handleNotImplemented('previous'),
};

export type StickyActionsContext = {
  actions: typeof defaultActions;
  setActions: React.Dispatch<React.SetStateAction<typeof defaultActions>>;
};

/**
 * Facilitates passing of handlers from children up to the parent via
 * outletContext.
 *
 * @see StickyActionsFooter
 */
export const useStickyActions = () => {
  // Note, Can't use a ref because:
  // > Mutable values like 'actionsRef.current' aren't valid dependencies
  // > because mutating them doesn't re-render the component.
  const [actions, setActions] = useState<typeof defaultActions>(defaultActions);
  return { actions, setActions };
};
