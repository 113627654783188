import { type SystemStyleObject } from '@chakra-ui/react';

const MandatoryAssessBadgesSx: SystemStyleObject = {
  width: 4,
  height: 4,
  padding: 0,
  color: 'white',
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '2px',
};

export const MandatoryBadgeSx: SystemStyleObject = {
  ...MandatoryAssessBadgesSx,
  backgroundColor: '#d85b15',
};

export const AssessBadgeSx: SystemStyleObject = {
  ...MandatoryAssessBadgesSx,
  backgroundColor: '#2da3d7',
};
