import { Flex, Heading, SimpleGrid, VisuallyHidden } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router';

import { Paginator, SimpleEntityList, VStack } from 'components';
import SupportFactorEntity from 'entities/api/SupportFactor';
import { useLocale } from 'locales/useLocale';
import { SupportFactorCard } from 'screens/control-frameworks/components/SupportFactorCard';
import { useSupportFactorsList } from 'screens/global-frameworks/SupportFactorsTab/SupportFactorsList.hooks.ts';
import { createLogger } from 'util/createLogger.ts';

export function SupportFactorsList() {
  const log = createLogger('SupportFactorsList');
  const localeContext = useLocale();
  const { params, paginatorProps, formControls, supportFactorCount } =
    useSupportFactorsList();
  log('%o', { formControls });

  return (
    <Flex layerStyle="cardLike" gap={8}>
      <VStack gap={4}>
        <Heading as="h3" size="xs">
          <FormattedMessage
            id="global-framework.tab.support-factors"
            defaultMessage="Support Factors"
          />{' '}
          ({supportFactorCount})
        </Heading>

        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 4 }}
          spacing={2}
          layerStyle="loadingWrapper"
        >
          <SimpleEntityList
            Entity={SupportFactorEntity}
            params={params}
            RecordComponent={SupportFactorCard}
            recordComponentProps={{
              localeContext,
              linkTarget: '../supportfactors/:supportfactorUuid/',
            }}
            EmptyMessageComponent={VisuallyHidden}
          />
        </SimpleGrid>

        <Paginator displayAlways={false} {...paginatorProps} />
      </VStack>

      <Outlet />
    </Flex>
  );
}
