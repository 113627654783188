import { type ComponentStyleConfig } from '@chakra-ui/react';
import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

const baseStyle = {
  // Rather than set a color in here, consider setting semanticColors['chakra-body-text']
};

/**
 * Note that `fontSize` is defined as responsive values for larger sizes
 *
 * https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/heading.ts
 */
const sizes = {
  '4xl': defineStyle({
    fontSize: ['6xl', null, '7xl'],
    lineHeight: 1,
  }),
  '3xl': defineStyle({
    fontSize: ['5xl', null, '6xl'],
    lineHeight: 1,
  }),
  '2xl': defineStyle({
    fontSize: ['4xl', null, '5xl'],
    lineHeight: [1.2, null, 1],
  }),
  xl: defineStyle({
    fontSize: ['3xl', null, '4xl'],
    lineHeight: [1.33, null, 1.2],
  }),
  lg: defineStyle({
    fontSize: ['2xl', null, '3xl'],
    lineHeight: [1.33, null, 1.2],
  }),
  md: defineStyle({
    fontSize: 'xl',
    lineHeight: 1.2,
  }),
  sm: defineStyle({
    fontSize: 'md',
    lineHeight: 1.2,
  }),
  xs: defineStyle({
    fontSize: 'sm',
    lineHeight: 1.2,
  }),
};

/**
 * Heading renders an <h2> tag by default.
 *
 * ```tsx
 * <Heading>h2 size md</Heading>
 * <Heading as="h1" size="lg">h1 size lg</Heading>
 * ```
 *
 * Consider whether the styling we need can be accomplished via
 * theme/foundations/typography.
 *
 * @see https://chakra-ui.com/docs/components/heading
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/heading.ts
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/layout/src/heading.tsx
 */
export const headingTheme: ComponentStyleConfig = defineStyleConfig({
  baseStyle,

  sizes,

  variants: {
    primary: {
      color: 'primary',
    },

    /**
     * Matches <FormLabel> text style
     *
     * Note: ordinarily wouldn't set size props in here, but we want them static
     * to match FormLabel.
     */
    'form-label': {
      fontFamily: 'body',
      fontWeight: 'medium',
      color: 'muted',
      fontSize: 'sm',
      lineHeight: 1.2,
    },
  },

  defaultProps: {
    size: 'md',
    // variant: 'custom',
    // colorScheme: 'brand',
  },
});
