import { Grid, GridItem } from '@chakra-ui/react';

import { Form, Input, type InputProps } from '@burnsred/entity-form';
import { PersonPicker } from 'components/widgets/PersonPicker';
import type { SiteCompliancePlanEntityRecord } from 'entities/api/CompliancePlan/SiteCompliancePlan';

import { defaultColspan, rowSx } from './OwnershipTable.styles';
import { useSiteCompliancePlanRowWidgetHooks } from './SiteCompliancePlanRowWidget.hooks';

export const SiteCompliancePlanRow = (
  props: InputProps<SiteCompliancePlanEntityRecord, false> & {
    siteCompliancePlansCount: number;
  },
) => {
  const { value: siteCompliancePlan, index } = props;
  useSiteCompliancePlanRowWidgetHooks(props);

  const isLastRow = index === props.siteCompliancePlansCount - 1;

  return (
    <Form {...props}>
      <Grid
        key={siteCompliancePlan?.get('uuid')}
        templateColumns="repeat(12, 1fr)"
        gap="gutter"
        sx={rowSx}
      >
        <GridItem
          colSpan={defaultColspan}
          sx={{ paddingLeft: 7, alignContent: 'center' }}
        >
          {siteCompliancePlan?.get('site')?.get('name')}
        </GridItem>

        <GridItem colSpan={defaultColspan}>
          <Input
            name="local_control_advisor"
            component={PersonPicker}
            loadOptionsFromAPI
            componentProps={{
              // HACK: set a max-height on the menu to prevent it from stretching the page
              maxMenuHeight: isLastRow ? 135 : undefined,
            }}
          />
        </GridItem>

        <GridItem colSpan={defaultColspan}>
          <Input
            name="general_manager"
            component={PersonPicker}
            loadOptionsFromAPI
            componentProps={{
              // HACK: set a max-height on the menu to prevent it from stretching the page
              maxMenuHeight: isLastRow ? 135 : undefined,
            }}
          />
        </GridItem>

        <GridItem colSpan={defaultColspan}>
          <Input
            name="president"
            component={PersonPicker}
            loadOptionsFromAPI
            // only permit changing VP/AP via the OwnershipTableHeaderWidget
            componentProps={{ disabled: true }}
          />
        </GridItem>
      </Grid>
    </Form>
  );
};
