import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Grid,
  GridItem,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { type List, Map } from 'immutable';
import { useMemo } from 'react';

import { withQuery } from '@burnsred/entity-duck-query';
import { type CompliancePlanEntityRecord } from 'entities/api/CompliancePlan/CompliancePlan';
import { type SiteCompliancePlanEntityRecord } from 'entities/api/CompliancePlan/SiteCompliancePlan';
import OperatingContextEntity, {
  type OperatingContextEntityRecord,
} from 'entities/api/OperatingContext';
import { type GrcOrganisationalUnitAncestorEntityRecord } from 'entities/api/Person/GrcOrganisationalUnitAncestor';
import { useLocale } from 'locales/useLocale';

import { assetTitleSx, titleSx } from './AssetAccordion.styles.tsx';
import {
  contentGridItemProps,
  innerContentGridProps,
  innerGridTitleProps,
  mainGridProps,
  operatingContextGridItemProps,
} from '../OperatingContext.styles';
import SiteCompliancePlanForm from '../SiteCompliancePlanForm/SiteCompliancePlanForm';

type AssetAccordionProps = {
  asset: GrcOrganisationalUnitAncestorEntityRecord;
  siteCompliancePlans: List<SiteCompliancePlanEntityRecord>;
  compliancePlan: CompliancePlanEntityRecord;
  value: List<OperatingContextEntityRecord>;
};

const AssetAccordion = (props: AssetAccordionProps) => {
  const {
    asset,
    siteCompliancePlans,
    compliancePlan,
    value: operatingContexts,
  } = props;
  const { toString } = useLocale();

  return (
    <Accordion defaultIndex={[0]}>
      <AccordionItem sx={{ borderTop: 0, borderBottom: 0 }}>
        <AccordionButton px={0} _hover={{ bg: 'gray.200' }}>
          <SimpleGrid {...mainGridProps}>
            <GridItem {...contentGridItemProps}>
              <Grid {...innerContentGridProps}>
                <GridItem {...innerGridTitleProps}>
                  <Text sx={assetTitleSx}>{asset?.get('name')}</Text>
                </GridItem>
                {operatingContexts &&
                  operatingContexts.map((operatingContext) => (
                    <GridItem
                      {...operatingContextGridItemProps}
                      key={`OpContextAssetGridItem_${operatingContext?.get('uuid')}`}
                    >
                      <Text sx={titleSx}>{toString(operatingContext)}</Text>
                    </GridItem>
                  ))}
              </Grid>
            </GridItem>
          </SimpleGrid>
        </AccordionButton>
        <AccordionPanel pb={4} px={0}>
          {siteCompliancePlans.map((siteCompliancePlan) => {
            return (
              <SiteCompliancePlanForm
                siteCompliancePlan={siteCompliancePlan}
                compliancePlan={compliancePlan}
                operatingContexts={operatingContexts}
                key={`SitePlanEqOperForm_${siteCompliancePlan?.get('uuid')}`}
              />
            );
          })}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default withQuery((props) => {
  const { compliancePlan } = props;
  const risk_id = compliancePlan?.get('risk')?.get('uuid');

  const params = useMemo(() => {
    return Map({
      globalframework__risk: risk_id,
      predicates__rule__risk: risk_id,
    });
  }, [risk_id]);

  return {
    action: OperatingContextEntity.duck.actions.get({ params }),
  };
})(AssetAccordion);
