import { Flex } from '@chakra-ui/react';
import { Outlet } from 'react-router';

import { VStack } from 'components';
import { SiteCompliancePlanSelector } from 'screens/compliance/components/SiteCompliancePlanSelector';
import { ActionPlanStats } from 'screens/compliance/tabs/Actions/ActionPlanStats';

import { type UseActionReturn, useActions } from './Actions.hooks';
import { COMPLIANCE_TABS, type SiteCompliancePlanContext } from '..';

export type ActionsContext = SiteCompliancePlanContext & {
  applicableControlsForPlanFormProps: UseActionReturn['applicableControlsForPlanFormProps'];
  refreshActionsStats: UseActionReturn['refreshActionsStats'];
};

const Actions = () => {
  const {
    applicableControlsForPlanFormProps,
    parentContext,
    actionsStatsKey,
    refreshActionsStats,
  } = useActions();

  const context: ActionsContext = {
    ...parentContext,
    applicableControlsForPlanFormProps,
    refreshActionsStats,
  };

  return (
    <VStack gap={4}>
      <Flex layerStyle="cardLike">
        <SiteCompliancePlanSelector
          compliancePlanFormProps={context.compliancePlanFormProps}
          subTabSegment={COMPLIANCE_TABS.tabPaths.actions.routeName}
        />
        <ActionPlanStats key={actionsStatsKey} />
      </Flex>

      <VStack className="Actions" layerStyle={['cardLike', 'loadingWrapper']}>
        <Outlet context={context} />
      </VStack>
    </VStack>
  );
};

export default Actions;
