import {
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  Heading,
  Text,
} from '@chakra-ui/react';
import { fromJS } from 'immutable';
import { FormattedMessage } from 'react-intl';

import { Form, Input } from '@burnsred/entity-form';
import { VStack } from 'components';
import { Autocomplete } from 'components/widgets';
import { Fieldset } from 'forms/fields';
import { useCompliancePlanHooks } from 'screens/compliance/CompliancePlan.hooks';

import { OwnershipTable } from './OwnershipTable';
import { SiteComplianceGRCForm } from './SiteComplianceGRCForm/SiteComplianceGRCForm';

export const Settings = () => {
  const { compliancePlanFormProps, isAssetPlan } = useCompliancePlanHooks();
  const { value: compliancePlan } = compliancePlanFormProps;
  const siteCompliancePlans = compliancePlan.get('sitecomplianceplan_set');

  return (
    <VStack>
      <Form {...compliancePlanFormProps}>
        <Fieldset heading={null}>
          <GridItem colSpan={{ base: 3, lg: 3 }}>
            <FormControl isRequired>
              <FormLabel>
                <FormattedMessage
                  id="compliance.operating-environment.settings.level3risk"
                  defaultMessage="Level 3 Risk"
                />
              </FormLabel>
              <Input
                name="risk"
                loadOptionsFromAPI
                filterParams={{ globalframework__is_globalized: 'true' }}
                component={Autocomplete}
              />
            </FormControl>
          </GridItem>

          <GridItem colSpan={{ base: 9, lg: 9 }}>
            <FormControl isRequired>
              <FormLabel>
                <FormattedMessage
                  id="compliance.operating-environment.settings.plan-title"
                  defaultMessage="Compliance Plan Title"
                />
              </FormLabel>
              <Input name="name" />
            </FormControl>
          </GridItem>
        </Fieldset>

        <Flex direction="column">
          <Heading size="sm" sx={{ paddingY: 4 }}>
            <FormattedMessage
              id="compliance.operating-environment.settings.location"
              defaultMessage="Location"
            />
          </Heading>
          <Input
            name="sitecomplianceplan_set"
            component={SiteComplianceGRCForm}
            componentProps={{
              baseParams: fromJS({
                is_active: 'true',
                is_location: 'false',
                page_size: '200',
              }),
              disabled: isAssetPlan,
            }}
          />
          {isAssetPlan && (
            <Text layerStyle="helperText">
              <FormattedMessage
                id="compliance.operating-environment.settings.asset-plan-warning"
                defaultMessage="This is an Asset-level compliance plan: associated sites cannot be edited."
              />
            </Text>
          )}
        </Flex>

        {siteCompliancePlans.size > 0 && (
          <VStack as="section">
            <Heading size="sm" sx={{ paddingTop: 5, paddingBottom: 1 }}>
              <FormattedMessage
                id="compliance.operating-environment.settings.ownership"
                defaultMessage="Ownership"
              />
            </Heading>
            <OwnershipTable
              siteCompliancePlansCount={siteCompliancePlans.size}
            />
          </VStack>
        )}
      </Form>
    </VStack>
  );
};
