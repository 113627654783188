// cspell:ignore datepicker
import {
  Input,
  InputGroup,
  type InputProps,
  InputRightElement,
  type SystemStyleObject,
} from '@chakra-ui/react';
import { forwardRef } from 'react';
import { MdAccessTime, MdCalendarToday } from 'react-icons/md';

import vendorStyles from './vendor/datepicker.scss?inline';

const inputGroupSx: SystemStyleObject = {
  '> input[readonly]': {
    cursor: 'not-allowed',
    backgroundColor: 'gray.200',
    userSelect: 'none',
  },
};
const inputSx: SystemStyleObject = {
  paddingRight: 0,
  _placeholder: { color: 'bodyText' },
};

const inputRightElementSx: SystemStyleObject = {
  color: 'icon',
  // let the click pass through to the input below
  pointerEvents: 'none',
  // ...but can't show a hover if pointerEvents: none
  // _hover: { cursor: 'pointer' },
};

/** Default Input for `DatePickerProps.inputComponent` with Calendar Icon */
export const ChakraDateInput = forwardRef<HTMLInputElement, InputProps>(
  ({ size, variant, colorScheme, ...restProps }, ref) => (
    <InputGroup sx={inputGroupSx} size={size}>
      <Input
        ref={ref}
        autoComplete="off"
        sx={inputSx}
        variant={variant}
        colorScheme={colorScheme}
        {...restProps}
      />
      <InputRightElement sx={inputRightElementSx}>
        <MdCalendarToday />
      </InputRightElement>
    </InputGroup>
  ),
);
ChakraDateInput.displayName = 'ChakraDateInput';

/** Default Input for `DatePickerProps.inputComponent` with Time Icon */
export const ChakraTimeInput = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => (
    <InputGroup sx={inputGroupSx}>
      <Input ref={ref} autoComplete="off" sx={inputSx} {...props} />
      <InputRightElement sx={inputRightElementSx}>
        <MdAccessTime />
      </InputRightElement>
    </InputGroup>
  ),
);
ChakraTimeInput.displayName = 'ChakraTimeInput';

/**
 * Note: theme was created on an earlier version of react-datepicker: 4.3.0
 * https://codesandbox.io/s/chakra-ui-datepicker-demo-forked-x12roe?file=/src/components/Datepicker/chakra-react-datepicker.css
 */
const _colors = {
  // Note the `&.className` prefix - these vars need to be set on `.DatePicker.light-theme`
  '&.light-theme': {
    '--light-gray': 'var(--chakra-colors-gray-200, #cccccc)',
    '--gray': 'var(--chakra-colors-gray-300, #b3b3b3)',
    '--blue700': 'var(--chakra-colors-blue-600, #2a69ac)',
    '--blue600': 'var(--chakra-colors-blue-500, #3182ce)',
    '--blue500': 'var(--chakra-colors-gray-400, #a0aec0)',
    '--blue400': 'var(--chakra-colors-gray-300, #cbd5e0)',
    '--blue300': 'var(--chakra-colors-gray-200, #e2e8f0)',
    '--blue200': 'var(--chakra-colors-gray-200, #edf2f7)',
    '--blue100': 'var(--chakra-colors-gray-100, #f7fafc)',
    '--header-bg': 'var(--blue100)',
    '--monthBackground': 'var(--chakra-colors-white)',
    '--text': 'var(--chakra-colors-black)',
    '--negative-text': 'var(--chakra-colors-white)',
    '--border': '#aeaeae',
    '--triangle-outline': 'var(--border)',
    // displayed when popup is _below_ element; should match heading
    '--triangle-bg--up': 'var(--header-bg)',
    // displayed when popup is _above_ element; should match today button (or heading)
    '--triangle-bg--down': 'var(--header-bg)',
    '--today-button--bg': '#f0f0f0',
  },
  /* // dark theme not in use, needs updating
  '&.dark-theme': {
    '--light-gray': 'var(--chakra-colors-gray-600)',
    '--gray': 'var(--chakra-colors-gray-500)',
    '--blue700': 'var(--chakra-colors-blue-600)',
    '--blue600': 'var(--chakra-colors-blue-300)',
    '--blue500': 'var(--chakra-colors-gray-500)',
    '--blue400': 'var(--chakra-colors-gray-600)',
    '--blue300': 'var(--chakra-colors-gray-700)',
    '--blue200': 'var(--chakra-colors-gray-600)',
    '--blue100': 'var(--chakra-colors-gray-800)',
    '--monthBackground': 'var(--chakra-colors-gray-700)',
    '--text': 'var(--chakra-colors-gray-200)',
    '--negative-text': 'var(--chakra-colors-black)',
  },
  '&.light-theme-original': {
    '--light-gray': '#cccccc',
    '--gray': '#b3b3b3',
    '--blue700': '#2a69ac',
    '--blue600': '#3182ce',
    '--blue500': '#a0aec0',
    '--blue400': '#cbd5e0',
    '--blue300': '#e2e8f0',
    '--blue200': '#edf2f7',
    '--blue100': '#f7fafc',
  },
  */
};

/** Composes styles for react-datepicker */
export const styles = {
  '&': vendorStyles,

  '&.DatePicker': {
    '.react-datepicker-wrapper': {
      width: '100%',

      '.react-datepicker__input-container': {
        // customInput styles
      },
    },
  },
};
