import { useDisclosure } from '@chakra-ui/react';
import type { List } from 'immutable';
import { useCallback } from 'react';

import { useFilePicker } from 'components/FilePicker';
import { type GapAttachmentEntityRecord } from 'entities/api/CompliancePlan/GapAttachment.ts';
import { type GapFormProps } from 'screens/compliance/tabs/BaselineAssessment/ControlAssessment/GapForm/GapForm.tsx';

export function useGapFormControls(props: GapFormProps) {
  const {
    name,
    onChange,
    field,
    value,
    valueInitial,
    index,
    onSubmit,
    errors,
    onChangeInput,
    options,
  } = props;

  const { fileList, setFileList, batchUploadFilerFileList } = useFilePicker([]);
  const {
    isOpen: isOpenFileModal,
    onOpen: onOpenFileModal,
    onClose: onCloseFileModal,
  } = useDisclosure();

  const handleGapAttachmentChange = useCallback(
    (new_attachments: GapAttachmentEntityRecord[]) => {
      const retVal = value.withMutations((rec) => {
        let updated_attachments = rec.getIn([
          'attachments',
        ]) as List<GapAttachmentEntityRecord>;

        new_attachments.forEach((attachment) => {
          attachment.set('gap', value);
          updated_attachments = updated_attachments.push(attachment);
        });

        rec.setIn(['attachments'], updated_attachments);
      });

      props.onChange({
        target: {
          name: name,
          index: index,
          value: retVal,
        },
      });
      onCloseFileModal();
    },

    [value, props, name, index, onCloseFileModal],
  );

  const removeGapAttachment = useCallback(
    (attachmentIndex: number) => {
      onChange({
        target: {
          name,
          index,
          value: value.set(
            'attachments',
            value.get('attachments').remove(attachmentIndex),
          ),
        },
      });
    },
    [name, onChange, value, index],
  );

  return {
    fileList,
    setFileList,
    batchUploadFilerFileList,
    isOpenFileModal,
    onOpenFileModal,
    onCloseFileModal,
    removeGapAttachment,
    handleGapAttachmentChange,

    formProps: {
      name,
      field,
      onChange,
      errors,
      onChangeInput,
      onSubmit,
      value,
      valueInitial,
      index,
      options,
    },
  };
}
