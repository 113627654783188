import { type List } from 'immutable';

import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import I18nTextEntity, { type I18nTextEntityRecord } from './i18n/I18nText';
import { type TitleEntityFields } from './types';

class ScenarioEntityNested extends Entity {
  static fields: EntityFields<ScenarioEntityNestedFields> = {
    uuid: new Fields.IdField(),

    title: new Fields.EntityField({
      entity: I18nTextEntity,
    }),
    mapping_id: new Fields.CharField(),
  };

  static toString(record: ScenarioEntityNestedRecord, locale = ''): string {
    return I18nTextEntity.toString(record.get('title'), locale);
  }
}

type ScenarioEntityNestedFields = TitleEntityFields & {
  mapping_id: string;
};

export type ScenarioEntityNestedRecord =
  EntityRecord<ScenarioEntityNestedFields>;

class DamageEnergyMechanismEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/damage_energy_mechanism/',
  };

  static fields: EntityFields<DamageEnergyMechanismEntityFields> = {
    uuid: new Fields.IdField(),

    title: new Fields.EntityField({
      entity: I18nTextEntity,
    }),

    description: new Fields.EntityField({
      entity: I18nTextEntity,
      blank: true,
    }),

    scenarios: new Fields.EntityField({
      entity: ScenarioEntityNested,
      blank: true,
      many: true,
    }),
  };

  static toString(
    record: DamageEnergyMechanismEntityRecord,
    locale = '',
  ): string {
    return I18nTextEntity.toString(record.get('title'), locale);
  }
}
type DamageEnergyMechanismEntityFields = TitleEntityFields & {
  description: I18nTextEntityRecord;
  scenarios: List<ScenarioEntityNestedRecord>;
};

export type DamageEnergyMechanismEntityRecord =
  EntityRecord<DamageEnergyMechanismEntityFields>;
DamageEnergyMechanismEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: DamageEnergyMechanismEntity,
  name: 'DamageEnergyMechanism',
});

export default DamageEnergyMechanismEntity;
