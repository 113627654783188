import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import {
  modalBodySx,
  modalFooterSx,
  modalHeaderSx,
} from './DeletePlanModal.styles.tsx';

type DeletePlanModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  onDelete: () => void;
};

export const DeletePlanModal = (props: DeletePlanModalProps) => {
  const { isOpen, onClose, onDelete } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader sx={modalHeaderSx}>
          <FormattedMessage
            id={'ui.compliance.deletePlan'}
            defaultMessage={'Delete Compliance Plan'}
          />
        </ModalHeader>
        <ModalBody sx={modalBodySx}>
          <FormattedMessage
            id={'ui.compliance.deletePlanMessage.1'}
            defaultMessage={
              'Deleting this Compliance Plan will remove all Operation Compliance Plans attached to it.'
            }
          />
          <br />
          <br />
          <FormattedMessage
            id={'ui.compliance.deletePlanMessage.2'}
            defaultMessage={
              'If you wish to remove an Operation from this plan, please do so by unselecting the Operation in the Location Picker.'
            }
          />
        </ModalBody>
        <ModalFooter sx={modalFooterSx}>
          <Button variant="outline" onClick={onClose}>
            <FormattedMessage id={'ui.cancel'} defaultMessage={'Cancel'} />
          </Button>
          <Button variant="primary" onClick={onDelete}>
            <FormattedMessage id={'ui.delete'} defaultMessage={'Delete'} />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
