import { Box, Text, Th, Tr } from '@chakra-ui/react';
import { List } from 'immutable';

import { Tooltip } from 'components';
import { equipmentIsFiltered } from 'components/ControlGrid/components/MandatoryAssessTable/equipmentIsFiltered';
import { L2EquipmentRow } from 'components/ControlGrid/components/MandatoryAssessTable/L2EquipmentRow';
import { EquipmentRequirementCells } from 'components/ControlGrid/components/MandatoryAssessTable/RequirementCells';
import { ScenarioCell } from 'components/ControlGrid/components/MandatoryAssessTable/ScenarioCell';
import {
  tooltipListItemSx,
  tooltipTextSx,
} from 'components/ControlGrid/components/MandatoryAssessTable.styles';
import type { EquipmentEntityRecord } from 'entities/api/Equipment';
import { useLocale } from 'locales/useLocale';

import type { MandatoryAssessTableProps } from './MandatoryAssessTable';

export type L1EquipmentRowProps = Pick<
  MandatoryAssessTableProps,
  | 'applicableRules'
  | 'scenarios'
  | 'equipmentLevel2'
  | 'filterRecord'
  | 'operatingContexts'
  | 'damageEnergyToScenarioMap'
> & {
  l1Equipment: EquipmentEntityRecord;
  showScenario: boolean;
};

export const L1EquipmentRow = ({
  applicableRules,
  scenarios,
  damageEnergyToScenarioMap,
  filterRecord,
  l1Equipment,
  equipmentLevel2,
  operatingContexts,
  showScenario,
}: L1EquipmentRowProps) => {
  const { toString } = useLocale();
  const equipmentChildren = equipmentLevel2?.filter(
    (equipment) => equipment?.get('parent') == l1Equipment?.get('uuid'),
  );
  const isFiltered = equipmentIsFiltered(filterRecord, l1Equipment);
  const filteredL2Equipment =
    filterRecord?.get('equipment_l2') || List<EquipmentEntityRecord>();
  const isChildFiltered = equipmentChildren?.some((child) => {
    return equipmentIsFiltered(filterRecord, child);
  });

  const rowHeaderClassname =
    isFiltered || isChildFiltered ? 'isFilteredRow' : '';

  const description = toString(l1Equipment, 'description');

  if (!equipmentChildren?.size) {
    return (
      <Tr>
        <Th className={rowHeaderClassname}>
          <Box
            className={description ? 'has-tooltip' : ''}
            sx={tooltipListItemSx}
          >
            {description ? (
              <Tooltip
                label={<Text sx={tooltipTextSx}>{description}</Text>}
                placement="right-start"
              >
                <span>{toString(l1Equipment)}</span>
              </Tooltip>
            ) : (
              <span>{toString(l1Equipment)}</span>
            )}
          </Box>
        </Th>
        <Th
          className={['equipment-l2', isFiltered ? 'isFilteredRow' : ''].join(
            ' ',
          )}
        >
          -
        </Th>
        <EquipmentRequirementCells
          equipment={l1Equipment}
          applicableRules={applicableRules}
          filterRecord={filterRecord}
          operatingContexts={operatingContexts}
          isFiltered={isFiltered}
        />

        {showScenario && (
          <ScenarioCell
            scenarios={scenarios}
            damageEnergyToScenarioMap={damageEnergyToScenarioMap}
          />
        )}
      </Tr>
    );
  }

  return (
    <>
      {equipmentChildren?.map((equipmentChild, index) => {
        const siblings = equipmentChildren.filter(
          (child) => child.get('uuid') !== equipmentChild.get('uuid'),
        );

        const isAnySiblingFiltered = siblings.some((sibling) => {
          return filteredL2Equipment.includes(sibling);
        });

        return (
          <Tr key={equipmentChild.get('uuid')}>
            {index === 0 && (
              <Th
                rowSpan={equipmentChildren?.size ?? 1}
                className={rowHeaderClassname}
              >
                <Box
                  className={description ? 'has-tooltip' : ''}
                  sx={tooltipListItemSx}
                >
                  {description ? (
                    <Tooltip
                      label={<Text sx={tooltipTextSx}>{description}</Text>}
                      placement="right-start"
                    >
                      <span> {toString(l1Equipment)}</span>
                    </Tooltip>
                  ) : (
                    <span>{toString(l1Equipment)}</span>
                  )}
                </Box>
              </Th>
            )}
            <L2EquipmentRow
              equipmentLevel2={equipmentChild}
              applicableRules={applicableRules}
              filterRecord={filterRecord}
              operatingContexts={operatingContexts}
              isParentFiltered={isFiltered}
              isAnySiblingFiltered={isAnySiblingFiltered}
            />
            {showScenario && index === 0 && (
              <ScenarioCell
                scenarios={scenarios}
                damageEnergyToScenarioMap={damageEnergyToScenarioMap}
              />
            )}
          </Tr>
        );
      })}
    </>
  );
};
