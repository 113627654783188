import { Map } from 'immutable';
import type { Moment } from 'moment';

import { Entity, Fields } from '@burnsred/entity';
import ScreenDuck from '@burnsred/entity-duck-namespace-screen';
import ControlEntity, { type ControlEntityRecord } from 'entities/api/Control';
import DamageEnergyMechanismEntity, {
  type DamageEnergyMechanismEntityRecord,
} from 'entities/api/DamageEnergyMechanism';
import GrcOrganisationalUnitEntity, {
  type GrcOrganisationalUnitEntityRecord,
} from 'entities/api/Person/GrcOrganisationalUnit';
import PersonEntity, {
  type PersonEntityRecord,
} from 'entities/api/Person/Person';
import RiskEntity, { type RiskEntityRecord } from 'entities/api/Risk';
import ScenarioEntity, {
  type ScenarioEntityRecord,
} from 'entities/api/Scenario';
import {
  type EntityFields,
  type EntityRecord,
  type FormChangeEvent,
} from 'types';

import { LAST_SEVEN_DAYS } from './periodOptions';

class RiskOwnerDashboardFilterEntity extends Entity {
  static fields: EntityFields<RiskOwnerDashboardFilterEntityFields> = {
    risk: new Fields.EntityField({
      entity: RiskEntity,
      blank: true,
    }),
    descendants: new Fields.EntityField({
      entity: GrcOrganisationalUnitEntity,
      blank: true,
    }),
    grc_organisational_unit: new Fields.EntityField({
      entity: GrcOrganisationalUnitEntity,
      blank: true,
    }),
    scenarios__damage_energy_mechanism: new Fields.EntityField({
      entity: DamageEnergyMechanismEntity,
      blank: true,
    }),
    scenarios: new Fields.EntityField({
      entity: ScenarioEntity,
      blank: true,
    }),
    controls__siteframeworkcontrol__control: new Fields.EntityField({
      entity: ControlEntity,
      blank: true,
    }),
    with_statistics__gte: new Fields.DateTimeField({
      blank: true,
      default: LAST_SEVEN_DAYS.value,
    }),
    page_size: new Fields.CharField({
      default: '21',
      blank: true,
    }),
    page: new Fields.CharField({
      default: '1',
      blank: true,
    }),
    site_association__risk_owner: new Fields.EntityField({
      entity: PersonEntity,
      blank: true,
    }),
  };

  static asMappedParams(record: RiskOwnerDashboardFilterEntityRecord) {
    return Map({
      risk: record?.get('risk')?.get('uuid') || '',
      descendants: record?.get('descendants')?.get('uuid') || '',
      grc_organisational_unit:
        record?.get('grc_organisational_unit')?.get('uuid') || '',
      scenarios__damage_energy_mechanism:
        record?.get('scenarios__damage_energy_mechanism')?.get('uuid') || '',
      scenarios: record?.get('scenarios')?.get('uuid') || '',
      controls__siteframeworkcontrol__control:
        record?.get('controls__siteframeworkcontrol__control')?.get('uuid') ||
        '',
      site_association__risk_owner:
        record?.get('site_association__risk_owner')?.get('uuid') || '',
      page: record?.get('page') ?? '1',
      page_size: record?.get('page_size') ?? '6',
    });
  }

  static asMappedForReportsParams(
    record: RiskOwnerDashboardFilterEntityRecord,
  ) {
    return Map({
      risk: record?.get('risk')?.get('uuid') || '',
      descendants: record?.get('descendants')?.get('uuid') || '',
      grc_organisational_unit:
        record?.get('grc_organisational_unit')?.get('uuid') || '',
      period_start__gte:
        record?.get('with_statistics__gte')?.toISOString() || '',
      scenarios__damage_energy_mechanism:
        record?.get('scenarios__damage_energy_mechanism')?.get('uuid') || '',
      scenarios: record?.get('scenarios')?.get('uuid') || '',
      controls__siteframeworkcontrol__control:
        record?.get('controls__siteframeworkcontrol__control')?.get('uuid') ||
        '',
      site_association__risk_owner:
        record?.get('site_association__risk_owner')?.get('uuid') || '',
      page: record?.get('page') ? `${record?.get('page')}` : '1',
    });
  }

  static asMappedCountReportsParams(
    record: RiskOwnerDashboardFilterEntityRecord,
  ) {
    return Map({
      risk: record?.get('risk')?.get('uuid') || '',
      descendants: record?.get('descendants')?.get('uuid') || '',
      grc_organisational_unit:
        record?.get('grc_organisational_unit')?.get('uuid') || '',
      with_statistics__gte:
        record?.get('with_statistics__gte')?.toISOString() || '',
      scenarios__damage_energy_mechanism:
        record?.get('scenarios__damage_energy_mechanism')?.get('uuid') || '',
      scenarios: record?.get('scenarios')?.get('uuid') || '',
      controls__siteframeworkcontrol__control:
        record?.get('controls__siteframeworkcontrol__control')?.get('uuid') ||
        '',
      site_association__risk_owner:
        record?.get('site_association__risk_owner')?.get('uuid') || '',
      page: record?.get('page') ?? '1',
      page_size: record?.get('page_size') ?? '6',
    });
  }

  static toParams(record: RiskOwnerDashboardFilterEntityRecord) {
    const params = new URLSearchParams(this.asMappedParams(record).toJS());
    return params.toString();
  }

  static onChangeClean(
    record: RiskOwnerDashboardFilterEntityRecord,
    evt: FormChangeEvent,
    rootName: string,
  ) {
    const { name, value } = evt.target;
    if (name === rootName) {
      return evt.target.value as RiskOwnerDashboardFilterEntityRecord;
    }
    // Warning does not work with nested updates
    switch (name) {
      case 'descendants':
        return record.withMutations((rec) => {
          rec.set('grc_organisational_unit', null);
          rec.set(name, value);
        });
      case 'scenarios__damage_energy_mechanism':
        return record.withMutations((rec) => {
          rec.set('scenarios', null);
          rec.set(name, value);
        });
      case 'risk':
        return record.withMutations((rec) => {
          rec.set('scenarios__damage_energy_mechanism', null);
          rec.set('scenarios', null);
          rec.set(name, value);
        });
      default:
        return record.set(name, value);
    }
  }
}

type RiskOwnerDashboardFilterEntityFields = {
  risk: RiskEntityRecord;
  descendants: GrcOrganisationalUnitEntityRecord;
  grc_organisational_unit: GrcOrganisationalUnitEntityRecord;
  scenarios__damage_energy_mechanism: DamageEnergyMechanismEntityRecord;
  scenarios: ScenarioEntityRecord;
  controls__siteframeworkcontrol__control: ControlEntityRecord;
  page: string;
  page_size: string;
  with_statistics__gte: Moment;
  site_association__risk_owner: PersonEntityRecord;
};

export type RiskOwnerDashboardFilterEntityRecord =
  EntityRecord<RiskOwnerDashboardFilterEntityFields>;

RiskOwnerDashboardFilterEntity.duck = new ScreenDuck({
  app: 'Cube',
  entity: RiskOwnerDashboardFilterEntity,
  name: 'RiskOwnerDashboardFilter',
});

export default RiskOwnerDashboardFilterEntity;
