import { Radio, RadioGroup } from '@chakra-ui/react';
import { useCallback } from 'react';
import { type MessageDescriptor, defineMessage, useIntl } from 'react-intl';

import { type InputProps } from '@burnsred/entity-form';
import { VStack } from 'components';
import {
  EXEMPTION_REASON_CHOICES,
  type ExemptionReasonChoices,
} from 'entities/api/CompliancePlan/BaselineAssessment';

import { RadioGroupSx } from './ExemptionReasonWidget.styles';

const messages: Record<ExemptionReasonChoices, MessageDescriptor> = {
  R: defineMessage({
    id: 'compliance.baseline-assessment.exemption-reason.r',
    defaultMessage: 'Request approval to not implement mandatory control',
  }),
  CS: defineMessage({
    id: 'compliance.baseline-assessment.exemption-reason.cs',
    defaultMessage:
      'Not to be implemented as defined by <bold>control scope</bold>',
  }),
  IR: defineMessage({
    id: 'compliance.baseline-assessment.exemption-reason.ir',
    defaultMessage:
      'Not to be implemented as defined by <bold>implementation requirements</bold>',
  }),
};

// @ts-expect-error Type 'string' does not satisfy the constraint 'Map<string, unknown>'
type ExemptionReasonWidgetProps = InputProps<ExemptionReasonChoices, false>;

export default function ExemptionReasonWidget(
  props: ExemptionReasonWidgetProps,
) {
  const { formatMessage } = useIntl();
  const { value, onChange, name, index } = props;
  const handleChange = useCallback(
    (radioValue: string) => {
      onChange({
        target: {
          name,
          value: radioValue,
          index,
        },
      });
    },
    [onChange, index, name],
  );

  return (
    <RadioGroup value={value} onChange={handleChange} sx={RadioGroupSx}>
      <VStack>
        {EXEMPTION_REASON_CHOICES.map((choice) => (
          <Radio key={choice} value={choice} size="sm">
            {/* @ts-expect-error No overload matches this call */}
            {formatMessage(messages[choice], {
              bold: (msg: string) => <strong>{msg}</strong>,
            })}
          </Radio>
        ))}
      </VStack>
    </RadioGroup>
  );
}
