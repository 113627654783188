import {
  Button,
  Divider,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { VStack } from 'components';
import { Autocomplete } from 'components/Autocomplete';
import { useStickyActionsContext } from 'components/layout/index.ts';
import { useLocale } from 'locales/useLocale.tsx';
import {
  ControlListRow,
  ControlListTable,
} from 'screens/compliance/components/ControlListTable';
import { modalFooterSx } from 'screens/compliance/tabs/Actions/ActionPlanForControl/GapForm/LinkToActionModal/LinkToActionModal.styles.tsx';

import { useControlList } from './ControlList.hooks.ts';
import {
  baseFlexProps,
  buttonGroupFlexProps,
  controlListButtonBaseProps,
  importAssessmentButtonProps,
  pageContainerStackProps,
  pageTitleFlexProps,
} from './ControlList.styles.ts';
import { COMPLIANCE_TABS } from '../..';
import { SiteCompliancePlanSelector } from '../../../components/SiteCompliancePlanSelector';

export default function ControlList() {
  const { setIsStickActionsFooterVisible } = useStickyActionsContext();

  useEffect(() => {
    setIsStickActionsFooterVisible(false);
    return () => setIsStickActionsFooterVisible(true);
  }, [setIsStickActionsFooterVisible]);

  const {
    applicableControlsForPlan,
    isGeneratingControls,
    generatePlanControls,
    compliancePlanFormProps,
    isImportModalOpen,
    onImportModalClose,
    onImportModalOpen,
    compliancePlanInfo,
    planImportOptions,
    selectedPlanImport,
    setSelectedPlanImport,
    importBaselineAssessments,
  } = useControlList();

  const { toString } = useLocale();

  return (
    <>
      <VStack sx={{ paddingX: 'gutter' }}>
        <SiteCompliancePlanSelector
          compliancePlanFormProps={compliancePlanFormProps}
          subTabSegment={COMPLIANCE_TABS.tabPaths.baselineAssessment.routeName}
        />
      </VStack>

      <Divider />
      <VStack {...pageContainerStackProps}>
        <Flex {...baseFlexProps}>
          <VStack {...pageTitleFlexProps}>
            <Heading>{compliancePlanInfo?.get('name')}</Heading>
            <Text>{toString(compliancePlanInfo.get('risk'))}</Text>
          </VStack>

          <Flex {...buttonGroupFlexProps}>
            <Button
              {...controlListButtonBaseProps}
              onClick={() => generatePlanControls()}
              isDisabled={isGeneratingControls}
            >
              <FormattedMessage
                id="compliance.baseline-assessment.control-list.generate-plan-controls"
                defaultMessage="Generate Plan Controls"
              />
            </Button>

            <Button
              isDisabled={!applicableControlsForPlan?.size}
              onClick={onImportModalOpen}
              {...importAssessmentButtonProps}
            >
              <FormattedMessage
                id="compliance.baseline-assessment.control-list.import-baselineassessment"
                defaultMessage="Import a Baseline Assessment"
              />
            </Button>
          </Flex>
        </Flex>

        <ControlListTable
          count={applicableControlsForPlan?.size ?? 0}
          isLoading={!applicableControlsForPlan}
        >
          {applicableControlsForPlan?.map((control) => (
            <ControlListRow key={control.get('uuid')} record={control} />
          ))}
        </ControlListTable>

        <Modal isOpen={isImportModalOpen} onClose={onImportModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Import Baseline Assessment</ModalHeader>
            <ModalBody>
              <VStack paddingY={4}>
                <FormattedMessage
                  id="control-list.import-baseline-assessment"
                  defaultMessage="Select compliance plan to import baseline assessments from:"
                />
              </VStack>
              <Autocomplete
                options={planImportOptions}
                onChange={setSelectedPlanImport}
                value={selectedPlanImport}
              />
            </ModalBody>
            <ModalFooter sx={modalFooterSx}>
              <Button
                variant="outline"
                onClick={() => {
                  onImportModalClose();
                  setSelectedPlanImport(null);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                isDisabled={!selectedPlanImport}
                onClick={importBaselineAssessments}
              >
                Import
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </VStack>
    </>
  );
}
