import { List, fromJS } from 'immutable';

import { RAOPicker, type TreePickerProps } from 'components/widgets';
import GrcOrganisationalUnitEntity, {
  type GrcOrganisationalUnitEntityRecord,
} from 'entities/api/Person/GrcOrganisationalUnit';
import type { Optional } from 'types';
import { createLogger } from 'util/createLogger';

import { ListComponent } from './components/List';
import { Record } from './components/Record';
import { raoPickerRadioSx } from './RAOPickerRadio.styles';

const log = createLogger('RAOPickerRadio');

const grcBaseParams = fromJS({
  is_active: 'true',
  is_location: 'false',
});

type RAOPickerProps = Optional<
  TreePickerProps<GrcOrganisationalUnitEntityRecord>,
  'Entity' | 'baseParams'
>;

/**
 * Renders GRCOrganisationalUnits; composes RAOPickerRadio.
 */
export const RAOPickerRadio = ({
  Entity = GrcOrganisationalUnitEntity,
  baseParams = grcBaseParams,
  onChangeValue: _onChangeValue,
  ...rest
}: RAOPickerProps) => {
  log('%o', { Entity, baseParams, ...rest });

  /**
   * only permit values from a single parent;
   * if a value is selected from a new parent, drop all the previous values
   */
  const onChangeValue = (item: GrcOrganisationalUnitEntityRecord) => {
    const value = rest?.value ?? fromJS([]);
    const isItemPresentInValue = value?.find(
      (rec) => rec.get('uuid') == item.get('uuid'),
    );
    const newParent = item.get('ancestors').last();
    const isTopLevel = !newParent;
    const isItemInNewParent = value?.some((rec) => {
      const oldParent = rec.get('ancestors').last();
      return !oldParent?.equals(newParent);
    });
    rest.onChange({
      target: {
        name: rest.name,
        value: isTopLevel
          ? isItemPresentInValue
            ? List()
            : List([item])
          : isItemInNewParent
            ? List([item])
            : isItemPresentInValue
              ? value.filter((rec) => rec.get('uuid') != item.get('uuid'))
              : value.push(item),
      },
    });
  };

  return (
    <RAOPicker
      recordComponent={Record}
      listComponent={ListComponent}
      onChangeValue={onChangeValue}
      sx={raoPickerRadioSx}
      {...{ Entity, baseParams, ...rest }}
    />
  );
};
