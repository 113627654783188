import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  type SystemStyleObject,
} from '@chakra-ui/react';

import type { BHPPersonEntityRecord } from 'entities/api/Person/BhpPerson';
import { useLocale } from 'locales/useLocale';

import { PersonPicker } from './PersonPicker';
import type { WidgetSelectInputProps } from '../Autocomplete/Autocomplete';

export type PersonPickerFieldProps<
  T extends BHPPersonEntityRecord,
  Many extends boolean = true,
> = WidgetSelectInputProps<T, Many> & {
  label?: string;
  sx?: SystemStyleObject;
};

/**
 * Renders an Autocomplete with double-line options which provide more context
 * to distinguish between similarly named Persons.
 */
export const PersonPickerField = <
  T extends BHPPersonEntityRecord,
  Many extends boolean = true,
>({
  label,
  sx,
  ...props
}: PersonPickerFieldProps<T, Many>) => {
  const { translateErrors } = useLocale();
  const errors = translateErrors(props?.errors);

  return (
    <FormControl isInvalid={!!errors} isDisabled={props.disabled} sx={sx}>
      {label && <FormLabel>{label}</FormLabel>}
      {/* @ts-expect-error ugh... confusing type error */}
      <PersonPicker<T, Many> {...props} />
      <FormErrorMessage>{errors}</FormErrorMessage>
    </FormControl>
  );
};
