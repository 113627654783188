/**
 * > The text styles functionality in Chakra makes it easy to repeatably
 * > apply a collection of text properties (like line height and size) to
 * > any component.
 *
 * @see https://chakra-ui.com/docs/styled-system/text-and-layer-styles#text-styles
 *
 * Rather than creating variants for Text, consider whether a textStyle would be more useful
 *
 * ```jsx
 * <Text textStyle="shared">Shared here</Text>
 * <Box textStyle="shared">...and here</Box>
 * <Box textStyle={['shared', 'anotherTextStyle']}>mutltiple textStyles</Box>
 * ```
 *
 * Styles derived from @see https://app.zeplin.io/project/63db299855f49c3c314dbf9c/styleguide/textstyles?tsid=63db2a7f1210f1496ef76c77
 */
export const textStyles = {
  displayHeading: {
    fontSize: '28px',
    fontWeight: 'bold',
    lineHeight: 0.86,
    letterSpacing: '-0.23px',
    color: 'var(--black-alpha-800)',
  },
  displayBrand: {
    fontSize: '28px',
    fontWeight: 500,
    lineHeight: 1,
  },
  h1: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: 0.8,
  },
  h2: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: 0.89,
  },
  h3: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1,
  },
  h4: {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: 1.67,
  },

  /**
   * Matches <FormLabel> text style
   *
   * Note: if you want to apply to Heading, prefer <Heading variant="form-label" />
   */
  'form-label': {
    fontFamily: 'body',
    fontWeight: 'medium',
    fontSize: 'sm',
    lineHeight: 1.2,
    color: 'muted',
  },
};
