import {
  Input as ChakraInput,
  type InputProps as ChakraInputProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  type SystemStyleObject,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';

import type { InputProps } from '@burnsred/entity-form';
import { filterProps } from '@burnsred/higher-order-component';
import { useLocale } from 'locales/useLocale';

export type InputWidgetProps = InputProps &
  Omit<ChakraInputProps, 'isDisabled'> & {
    label?: string;
    /** passed to FormControl; target children using standard Chakra classNames */
    sx?: SystemStyleObject;
    /** passed to ChakraInput */
    size?: 'sm' | 'md' | 'lg';
    debounceMs?: number;
  };

/**
 * Renders a Chakra Input, complete with FormControl & FormErrorMessage
 *
 * @see RawInput for an Input which renders only the Input
 */
export const Input = (props: InputWidgetProps) => {
  const { debounceMs, name, onChange, index, size, sx, disabled } = props;
  const { translateErrors } = useLocale();
  const errors = translateErrors(props?.errors);
  const [val, setVal] = useState(props.value);

  useDebounce(
    () => {
      onChange({
        target: {
          name,
          index,
          value: val,
        },
      });
    },
    debounceMs || 1000,
    [val],
  );

  useEffect(() => {
    if (props.value !== val) setVal(props.value);
    // The missing dependency is on purpose to avoid unnecessary re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  return (
    <FormControl isInvalid={!!errors} isDisabled={disabled} sx={sx}>
      {props?.label && <FormLabel>{props?.label}</FormLabel>}
      <ChakraInput
        {...filterProps<ChakraInputProps>(props)}
        value={val}
        // @ts-expect-error string not assignable to props.value type set as default
        onChange={(evt) => setVal(evt.target.value)}
        size={size}
      />
      <FormErrorMessage>{errors}</FormErrorMessage>
    </FormControl>
  );
};
