import type { SystemStyleObject } from '@chakra-ui/react';

export const sectionSx: SystemStyleObject = {
  flexDirection: 'column',
  width: 'full',
  bgColor: 'white',
  border: '1px',
  borderColor: 'faint',
};

export const listSx: SystemStyleObject = {
  flexDirection: 'column',
  gap: 0.5,
  margin: 6,

  '.TreePicker__Node.is-selected': {
    color: 'white',
    backgroundColor: 'var(--chakra-colors-brandDustyOrange)',

    'chakra-checkbox__control[data-checked]': {
      color: 'white',
      borderColor: 'white',
    },
  },
};

export const headerSx: SystemStyleObject = {
  alignItems: 'center',
  padding: 6,
  borderBottom: '1px',
  borderColor: 'faint',
  fontSize: 'sm',
};

export const headingSx: SystemStyleObject = {
  display: 'inline',
  fontSize: 'sm',
};
