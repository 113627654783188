export const OPERATING_ENVIRONMENT_TABS = {
  base: 'compliance',
  tabPaths: {
    settings: {
      routeName: 'settings',
      index: 0,
    },
    equipment: {
      routeName: 'equipment',
      index: 1,
    },
    operatingContext: {
      routeName: 'operating-context',
      index: 2,
    },
    scenarios: {
      routeName: 'scenarios',
      index: 3,
    },
  },
} as const;

export type OperatingEnvironmentTabsRouteNames =
  (typeof OPERATING_ENVIRONMENT_TABS)['tabPaths'][keyof (typeof OPERATING_ENVIRONMENT_TABS)['tabPaths']]['routeName'];
