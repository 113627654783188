import {
  Switch,
  SwitchLabel,
  SwitchRadio,
  SwitchSelection,
} from './ThreeWaySwitch.styles';

type ClickableLabelProps = {
  children?: React.ReactNode;
  checked: boolean;
  onChange: (value: string) => void;
  currentIndex: number;
  label: string;
  value: string;
  isDisabled?: boolean;
};
const ClickableLabel = ({
  children,
  currentIndex,
  label,
  onChange,
  checked,
  value,
  isDisabled = false,
  ...rest
}: ClickableLabelProps) => (
  <SwitchLabel
    onClick={(e) => (!isDisabled ? onChange(value) : e.preventDefault())}
    checked={checked}
    currentIndex={currentIndex}
    {...rest}
  >
    {label}
    {children}
  </SwitchLabel>
);

type SwitchOption = {
  value: string;
  label: string;
};

type Props = {
  /**
   * Array of SwitchOptions used to render the switch.
   */
  options: SwitchOption[];
  /**
   * Sets the value of the switch. Provided string must match a value provided in the options array.
   */
  value: string;
  /**
   * Disables the center option of the three-way switch.
   */
  unsetEnabled: boolean;
  /**
   * Makes the entire switch readonly (disabled)
   * defaults to false
   */
  isReadonly?: boolean;

  /** the onChange callback is called with `value`, for consumption in the parent component */
  onChange?: (value: string) => void;

  /** a string value representing the color the switch should be for a positive response **/
  positiveAnswerColor?: string;
};

/**
 * ThreeWaySwitch component is a variation of the standard switch input,
 * but allows for three states rather than just two.
 *
 * Generally, this is true, false and unset. The values and labels are rendered
 * from an array of objects provided in the options prop.
 *
 * Three options must be passed in the options props otherwise this component will
 * throw an error and render blank.
 *
 * If a value is provided, it must match a corresponding value in the
 * options array otherwise this component will throw an error and render blank.
 *
 * // TODO update this component in @burnsred/ui-chakra
 */
export function ThreeWaySwitch({
  options,
  value,
  unsetEnabled,
  isReadonly = false,
  onChange,
  positiveAnswerColor,
}: Props) {
  const currentIndex = options.findIndex((option) => option.value === value);

  const handleChange = (value: string) => {
    if (!isReadonly) {
      onChange?.(value);
    }
  };

  if (options.length < 3) {
    console.error('Three options are expected for this ThreeWaySwitch');
    return <></>;
  }

  if (currentIndex < 0) {
    console.error('Provided value does not exist in options array.');
    return <></>;
  }
  return (
    <Switch
      currentIndex={currentIndex}
      positiveAnswerColor={positiveAnswerColor}
    >
      {options.map(({ label, value: optionValue }, index) => (
        <ClickableLabel
          key={optionValue}
          label={label}
          value={optionValue}
          onChange={handleChange}
          checked={value === optionValue}
          currentIndex={currentIndex}
          isDisabled={isReadonly || (index === 1 && !unsetEnabled)}
        >
          <SwitchRadio checked={value === optionValue} />
        </ClickableLabel>
      ))}

      <SwitchSelection currentIndex={currentIndex} />
    </Switch>
  );
}
