import { type List } from 'immutable';

import { type InputProps } from '@burnsred/entity-form';
import { type CompliancePlanEntityRecord } from 'entities/api/CompliancePlan/CompliancePlan';
import { type SiteEquipmentOperatingContextEntityRecord } from 'entities/api/CompliancePlan/SiteEquipmentOperatingContext';
import { type OperatingContextEntityRecord } from 'entities/api/OperatingContext';

import useEquipmentOperation from './EquipmentOperationWidget.hooks';
import EquipmentTree from './EquipmentTree/EquipmentTree';

export type EquipmentOperationWidgetProps =
  InputProps<SiteEquipmentOperatingContextEntityRecord> & {
    compliancePlan: CompliancePlanEntityRecord;
    operatingContexts: List<OperatingContextEntityRecord>;
  };

export const EquipmentOperationWidget = (
  props: EquipmentOperationWidgetProps,
) => {
  const {
    equipmentTree,
    equipmentOperationList,
    operatingContexts,
    handleEquipmentOperationChange,
  } = useEquipmentOperation(props);
  return (
    <EquipmentTree
      equipmentTree={equipmentTree}
      equipmentOperationList={equipmentOperationList}
      operatingContexts={operatingContexts}
      onChange={handleEquipmentOperationChange}
    />
  );
};
