import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export type CompliancePlanTableColumn = {
  width: string;
  className: string;
  name: ReactNode;
};

export const myLatestCompliancePlanTableHeaders = [
  {
    width: '25%',
    className: 'left',
    name: (
      <FormattedMessage
        id="compliance.dashboard.latestplans.table.title"
        defaultMessage="Title"
      />
    ),
  },
  {
    width: '15%',
    className: 'left',
    name: (
      <FormattedMessage
        id="compliance.dashboard.latestplans.table.operation"
        defaultMessage="Operation"
      />
    ),
  },
  {
    width: '5%',
    className: 'right',
    name: (
      <FormattedMessage
        id="compliance.dashboard.latestplans.table.controls"
        defaultMessage="Controls"
      />
    ),
  },
  {
    width: '5%',
    className: 'right',
    name: (
      <FormattedMessage
        id="compliance.dashboard.latestplans.table.gaps"
        defaultMessage="Gaps"
      />
    ),
  },
  {
    width: '5%',
    className: 'right',
    name: (
      <FormattedMessage
        id="compliance.dashboard.latestplans.table.actions"
        defaultMessage="Actions"
      />
    ),
  },
  {
    width: '2%',
    className: 'right',
    name: null,
  },
  {
    width: '10%',
    className: 'left',
    name: (
      <FormattedMessage
        id="compliance.dashboard.latestplans.table.created"
        defaultMessage="Date created"
      />
    ),
  },
  {
    width: '10%',
    className: 'left',
    name: (
      <FormattedMessage
        id="compliance.dashboard.latestplans.table.status"
        defaultMessage="Status"
      />
    ),
  },
  {
    width: '5%',
    className: 'right',
    name: null,
  },
  {
    width: '15%',
    className: 'left',
    name: (
      <FormattedMessage
        id="compliance.dashboard.latestplans.table.completion"
        defaultMessage="Completion"
      />
    ),
  },
] as CompliancePlanTableColumn[];

export const approvalStatusCompliancePlanTableHeaders = [
  {
    width: '25%',
    className: 'left',
    name: (
      <FormattedMessage
        id="compliance.dashboard.approval-status.table.title"
        defaultMessage="Title"
      />
    ),
  },
  {
    width: '15%',
    className: 'left',
    name: (
      <FormattedMessage
        id="compliance.dashboard.approval-status.table.operation"
        defaultMessage="Operation"
      />
    ),
  },
  {
    width: '5%',
    className: 'right',
    name: (
      <FormattedMessage
        id="compliance.dashboard.approval-status.table.gaps"
        defaultMessage="Gaps"
      />
    ),
  },
  {
    width: '5%',
    className: 'right',
    name: (
      <FormattedMessage
        id="compliance.dashboard.approval-status.table.actions"
        defaultMessage="Actions"
      />
    ),
  },
  {
    width: '2%',
    className: 'right',
    name: null,
  },
  {
    width: '15%',
    className: 'left',
    name: (
      <FormattedMessage
        id="compliance.dashboard.approval-status.table.generalmanager"
        defaultMessage="General Manager"
      />
    ),
  },
  {
    width: '15%',
    className: 'left',
    name: (
      <FormattedMessage
        id="compliance.dashboard.approval-status.table.ap"
        defaultMessage="AP / VP"
      />
    ),
  },
  {
    width: '5%',
    className: 'right',
    name: null,
  },
  {
    width: '15%',
    className: 'left',
    name: (
      <FormattedMessage
        id="compliance.dashboard.approval-status.table.status"
        defaultMessage="Status"
      />
    ),
  },
] as CompliancePlanTableColumn[];

export const approvedCompliancePlanTableHeaders = [
  {
    width: '25%',
    className: 'left',
    name: (
      <FormattedMessage
        id="compliance.dashboard.approved.table.title"
        defaultMessage="Title"
      />
    ),
  },
  {
    width: '15%',
    className: 'left',
    name: (
      <FormattedMessage
        id="compliance.dashboard.approved.table.operation"
        defaultMessage="Operation"
      />
    ),
  },
  {
    width: '5%',
    className: 'right',
    name: (
      <FormattedMessage
        id="compliance.dashboard.approved.table.controls"
        defaultMessage="Controls"
      />
    ),
  },
  {
    width: '5%',
    className: 'right',
    name: (
      <FormattedMessage
        id="compliance.dashboard.approved.table.gaps"
        defaultMessage="Gaps"
      />
    ),
  },
  {
    width: '5%',
    className: 'right',
    name: (
      <FormattedMessage
        id="compliance.dashboard.approved.table.actions"
        defaultMessage="Actions"
      />
    ),
  },
  {
    width: '5%',
    className: 'right',
    name: null,
  },
  {
    width: '10%',
    className: 'left',
    name: (
      <FormattedMessage
        id="compliance.dashboard.approved.table.created"
        defaultMessage="Date created"
      />
    ),
  },
  {
    width: '10%',
    className: 'left',
    name: (
      <FormattedMessage
        id="compliance.dashboard.approved.table.approved"
        defaultMessage="Date approved"
      />
    ),
  },
  {
    width: '15%',
    className: 'left',
    name: (
      <FormattedMessage
        id="compliance.dashboard.approved.table.approvedby"
        defaultMessage="Approved by"
      />
    ),
  },
] as CompliancePlanTableColumn[];
