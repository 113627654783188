/**
 * Join class names, filtering out falsy values
 *
 * @example
 *
 * ```tsx
 * <div className={cx('foo', 'bar', 'baz')} />
 * <div className={cx('foo', isBar && 'bar')} />
 * <div className={cx('foo', isBaz ? 'baz': null)} />
 * ```
 *
 * @param classNames
 * @returns joined class names
 */
export const cx = (...classNames: (string | boolean | null | undefined)[]) =>
  classNames.filter(Boolean).join(' ');
