import { useReducer } from 'react';
/**
 * > We can use useReducer hook and an incrementing counter
 * >to force a re-render even if the state has not changed.
 *
 * @see https://legacy.reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
 */
const useCounter = () => {
  const [count, increment] = useReducer((x: number) => x + 1, 0);
  return [count, increment] as const;
};

export default useCounter;
