import { useToast } from '@chakra-ui/react';
import { List, Map } from 'immutable';
import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useOutletContext } from 'react-router';

import { useQuery } from '@burnsred/entity-duck-query';
import { useStickyActionsContext } from 'components/layout';
import { BaselineAssessmentEntity } from 'entities';
import ApplicableControlForPlanEntity, {
  type ApplicableControlForPlanEntityRecord,
} from 'entities/api/CompliancePlan/ApplicableControlForPlan';
import BaselineAssessmentQuestionEntity, {
  type BaselineAssessmentQuestionEntityRecord,
} from 'entities/api/CompliancePlan/BaselineAssessmentQuestion';
import SiteCompliancePlanEntity from 'entities/api/CompliancePlan/SiteCompliancePlan';
import {
  type SiteEquipmentOperatingContextEntityRecord,
  type SiteEquipmentOperatingContextEntityTreeRecord,
} from 'entities/api/CompliancePlan/SiteEquipmentOperatingContext';
import type { EquipmentEntityRecord } from 'entities/api/Equipment';
import EquipmentEntity from 'entities/api/Equipment';
import type { OperatingContextEntityRecord } from 'entities/api/OperatingContext';
import OperatingContextEntity from 'entities/api/OperatingContext';
import ScenarioEntity, {
  type ScenarioEntityRecord,
} from 'entities/api/Scenario';
import { useSubmitOnUnmount } from 'forms/hooks/submit';
import { SaveButton } from 'screens/compliance/components/SaveButton';
import {
  type EntityDuckActionResolved,
  type WithQueryReturnProps,
} from 'types';
import { createLogger } from 'util/createLogger';

import type { ControlAssessmentProps } from './ControlAssessment';
import useGetAssessmentMandate from '../../hooks';
import type { BaselineAssessmentContext } from '../BaselineAssessment';

const log = createLogger('useControlAssessment');

export const useControlAssessment = (props: ControlAssessmentProps) => {
  const { pathname } = useLocation();
  const {
    compliancePlanFormProps,
    siteCompliancePlanFormProps,
    applicableControlsForPlanFormProps: {
      clear: clearApplicableControlsForPlan,
    },
  } = useOutletContext<BaselineAssessmentContext>();

  const { value: siteCompliancePlan } = siteCompliancePlanFormProps;
  const control = props.value.get('global_control');
  const risk = compliancePlanFormProps?.value?.get('risk');
  const riskId = risk?.get('uuid');

  const { value, name, onChange, onSubmit, errors, valueInitial, field } =
    props;

  const global_framework_id = compliancePlanFormProps?.value
    ?.get('risk')
    ?.get('global_framework_id');

  const siteName = siteCompliancePlan?.get('site')?.get('name') ?? '';

  const { value: equipmentLevel1 } = useQuery<List<EquipmentEntityRecord>>({
    action: EquipmentEntity.duck.actions.get({
      params: Map({
        predicates__rule__risk: riskId || undefined,
        level: '1',
      }),
    }),
    persist: true,
  });

  const { value: questions } = useQuery<
    List<BaselineAssessmentQuestionEntityRecord>
  >({
    action: BaselineAssessmentQuestionEntity.duck.actions.get({
      params: Map({
        globalframework__risk: riskId || undefined,
      }),
    }),
    persist: true,
  });

  const { value: equipmentLevel2 } = useQuery<List<EquipmentEntityRecord>>({
    action: EquipmentEntity.duck.actions.get({
      params: Map({
        predicates__rule__risk: riskId || undefined,
        level: '2',
      }),
    }),
    persist: true,
  });

  const { value: operatingContexts } = useQuery<
    List<OperatingContextEntityRecord>
  >({
    action: OperatingContextEntity.duck.actions.get({
      params: Map({
        globalframework__risk: riskId || undefined,
        predicates__rule__risk: riskId || undefined,
      }),
    }),
    persist: true,
  });

  const { value: scenarios } = useQuery<List<ScenarioEntityRecord>>({
    action: ScenarioEntity.duck.actions.get({
      params: Map({
        page_size: '30',
        ordering: 'mapping_id',
        damage_energy_mechanism__damage_energy__risk: riskId || undefined,
        // note: in GlobalVisualisation, we offer filtering by DEM, but we don't offer that option here 👍
        // damage_energy_mechanism__in: GlobalVisualisationFilterEntity.getUuidList(),
      }),
    }),
    persist: true,
  });

  const [equipmentList, equipmentTree] =
    SiteCompliancePlanEntity.getCompliancePlanEquipmentTree(siteCompliancePlan);

  const equipOptionsMandatory =
    ApplicableControlForPlanEntity.getEquipmentOptions(
      value,
      equipmentList as List<SiteEquipmentOperatingContextEntityRecord>,
      equipmentTree as List<SiteEquipmentOperatingContextEntityTreeRecord>,
    );

  const equipOptionsAssess = ApplicableControlForPlanEntity.getEquipmentOptions(
    value,
    equipmentList as List<SiteEquipmentOperatingContextEntityRecord>,
    equipmentTree as List<SiteEquipmentOperatingContextEntityTreeRecord>,
    false,
  );

  const {
    percent,
    currentCoverageMandatory,
    currentCoverageAssess,
    missingCoverageMandatory,
    missingCoverageAssess,
  } = ApplicableControlForPlanEntity.getCoverageReport(
    value,
    equipOptionsMandatory,
    equipOptionsAssess,
  );

  const { hasAssess, hasMandatory } = useGetAssessmentMandate(value);
  // Fixme, use callback
  const addNewBaselineAssessment = (constructionOptions = {}) => {
    const newAssessment = BaselineAssessmentEntity.createQuestionAnswers(
      BaselineAssessmentEntity.dataToRecord(constructionOptions),
      questions || List(),
    );

    onChange({
      target: {
        name,
        value: value.set(
          'baseline_assessments',
          value.get('baseline_assessments').push(newAssessment),
        ),
      },
    });
  };

  // fixme, use callback
  const removeBaselineAssessment = (index: number) => {
    onChange({
      target: {
        name,
        value: value.set(
          'baseline_assessments',
          value.get('baseline_assessments').remove(index),
        ),
      },
    });
  };

  const toast = useToast();
  const { formatMessage } = useIntl();

  const onSubmitCallback = useCallback(
    (action: EntityDuckActionResolved) => {
      if (action.name == 'save_resolved') {
        toast({
          title: formatMessage({
            id: 'toast.savedAssessment',
            defaultMessage: 'Saved assessment',
          }),
          status: 'success',
          duration: 1000,
          isClosable: true,
        });
        clearApplicableControlsForPlan();
      } else {
        toast({
          title: formatMessage({
            id: 'toast.errorSavingAssessment',
            defaultMessage: 'Error saving assessment',
          }),
          status: 'error',
          duration: 1000,
          isClosable: true,
        });
      }
    },
    // don't include clearApplicableControlsForPlan, or we get a render loop!
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toast],
  );

  useSubmitOnUnmount<ApplicableControlForPlanEntityRecord>(
    name,
    value,
    valueInitial,
    onSubmit,
    (record) => ApplicableControlForPlanEntity.clean(record),
    onSubmitCallback,
  );

  const { setChildren } = useStickyActionsContext();

  const handleClick = useCallback(async () => {
    if (!value.equals(valueInitial)) {
      onSubmit({
        target: {
          name,
          value: ApplicableControlForPlanEntity.clean(value),
        },
      }).promise.then(onSubmitCallback);
    }
  }, [name, onSubmit, onSubmitCallback, value, valueInitial]);

  useEffect(() => {
    setChildren([
      <SaveButton
        key="control-assessment.hooks.save-button"
        onClick={handleClick}
      />,
    ]);
    // render cautiously, but always on pathname!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, handleClick]);

  useEffect(() => {
    if (
      ApplicableControlForPlanEntity.getEmptyAssessments(value).size > 0 &&
      questions
    ) {
      onChange({
        target: {
          name,
          value: ApplicableControlForPlanEntity.updateEmptyAssessment(
            value,
            questions,
          ),
        },
      });
    }
  }, [questions, value, onChange, name]);

  log('%o', { props, compliancePlanFormProps, control, scenarios });

  return {
    control,
    equipmentLevel1,
    equipmentLevel2,
    operatingContexts,
    scenarios,
    risk,
    addNewBaselineAssessment,
    removeBaselineAssessment,
    hasAssess,
    hasMandatory,
    questions,
    global_framework_id,
    equipOptionsMandatory,
    equipOptionsAssess,
    percent,
    currentCoverageMandatory,
    currentCoverageAssess,
    missingCoverageMandatory,
    missingCoverageAssess,
    siteName,
    formProps: {
      value,
      name,
      onChange: (evt) => {
        onChange({
          ...evt,
          target: {
            ...evt.target,
            // Cannot perform the clean for overlapping deeper in the entity as it requires the equipment options
            value: ApplicableControlForPlanEntity.cleanOverlappingAssessments(
              ApplicableControlForPlanEntity.clean(
                evt.target.value as ApplicableControlForPlanEntityRecord,
              ),
              equipOptionsMandatory,
              equipOptionsAssess,
            ),
          },
        });
      },
      onSubmit,
      errors,
      valueInitial,
      field,
    } as WithQueryReturnProps<ApplicableControlForPlanEntityRecord>,
  };
};

export type UseControlAssessmentReturn = ReturnType<
  typeof useControlAssessment
>;
