import { Map } from 'immutable';

import { Entity, Fields } from '@burnsred/entity';
import ScreenDuck from '@burnsred/entity-duck-namespace-screen';
import { type ControlEntityRecord } from 'entities/api/Control';
import DamageEnergyMechanismEntity, {
  type DamageEnergyMechanismEntityRecord,
} from 'entities/api/DamageEnergyMechanism';
import GrcOrganisationalUnitEntity, {
  type GrcOrganisationalUnitEntityRecord,
} from 'entities/api/Person/GrcOrganisationalUnit';
import RiskEntity, { type RiskEntityRecord } from 'entities/api/Risk';
import ScenarioEntity, {
  type ScenarioEntityRecord,
} from 'entities/api/Scenario';
import SiteControlEntity from 'entities/api/SiteControl';
import TimeZoneEntity, {
  type TimeZoneEntityRecord,
} from 'entities/api/TimeZone';
import {
  type EntityFields,
  type EntityRecord,
  type FormChangeEvent,
} from 'types';

class SiteFrameworkFilterEntity extends Entity {
  static fields: EntityFields<SiteFrameworkFilterEntityFields> = {
    risk: new Fields.EntityField({
      entity: RiskEntity,
      blank: true,
    }),
    asset: new Fields.EntityField({
      entity: GrcOrganisationalUnitEntity,
      blank: true,
    }),
    operation: new Fields.EntityField({
      entity: GrcOrganisationalUnitEntity,
      blank: true,
    }),
    damage_energy_mechanism: new Fields.EntityField({
      entity: DamageEnergyMechanismEntity,
      blank: true,
    }),
    scenario: new Fields.EntityField({
      entity: ScenarioEntity,
      blank: true,
    }),
    control: new Fields.EntityField({
      entity: SiteControlEntity,
      blank: true,
    }),
    timezone: new Fields.EntityField({
      entity: TimeZoneEntity,
      blank: true,
    }),
    page_size: new Fields.CharField({
      default: '21',
      blank: true,
    }),
    page: new Fields.CharField({
      default: '1',
      blank: true,
    }),
  };

  static asMappedParams(record: SiteFrameworkFilterEntityRecord) {
    return Map({
      risk: record?.get('risk')?.get('uuid') || '',
      descendants: record?.get('asset')?.get('uuid') || '',
      grc_organisational_unit: record?.get('operation')?.get('uuid') || '',
      controls__timezones: record?.get('timezone')?.get('uuid') || '',
      scenarios__damage_energy_mechanism:
        record?.get('damage_energy_mechanism')?.get('uuid') || '',
      scenarios: record?.get('scenario')?.get('uuid') || '',
      controls__siteframeworkcontrol__site_control:
        record?.get('control')?.get('uuid') || '',
      page: record?.get('page') ?? '1',
      page_size: record?.get('page_size') ?? '21',
    });
  }

  static toParams(record: SiteFrameworkFilterEntityRecord) {
    const params = new URLSearchParams(this.asMappedParams(record).toJS());
    return params.toString();
  }
  static onChangeClean(
    record: SiteFrameworkFilterEntityRecord,
    evt: FormChangeEvent,
    rootName: string,
  ) {
    const { name, value } = evt.target;

    if (name === rootName) return value as SiteFrameworkFilterEntityRecord;

    // when a field changes, reset to page 1 to avoid requesting out of range results from API
    const updatedRecord = name != 'page' ? record.set('page', '1') : record;

    switch (name) {
      case 'asset':
        return updatedRecord.withMutations((rec) => {
          rec.set('operation', null);
          rec.set(name, value);
        });
      case 'damage_energy_mechanism':
        return updatedRecord.withMutations((rec) => {
          rec.set('scenario', null);
          rec.set(name, value);
        });
      case 'risk':
        return updatedRecord.withMutations((rec) => {
          rec.set('damage_energy_mechanism', null);
          rec.set('scenario', null);
          rec.set(name, value);
        });
      default:
        return updatedRecord.set(name, value);
    }
  }
}

type SiteFrameworkFilterEntityFields = {
  risk: RiskEntityRecord;
  asset: GrcOrganisationalUnitEntityRecord;
  operation: GrcOrganisationalUnitEntityRecord;
  damage_energy_mechanism: DamageEnergyMechanismEntityRecord;
  scenario: ScenarioEntityRecord;
  control: ControlEntityRecord;
  timezone: TimeZoneEntityRecord;
  page_size: string;
  page: string;
};

export type SiteFrameworkFilterEntityRecord =
  EntityRecord<SiteFrameworkFilterEntityFields>;

SiteFrameworkFilterEntity.duck = new ScreenDuck({
  app: 'Cube',
  entity: SiteFrameworkFilterEntity,
  name: 'SiteFrameworkFilter',
});

export default SiteFrameworkFilterEntity;
