import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAppErrorModalControls } from 'components/AppErrorModal/AppErrorModal.hooks.tsx';
import { VStack } from 'components/atoms';
import CubeAppEntity, { ErrorsEntity } from 'entities/CubeApp';

export const AppErrorModal = () => {
  const { isOpen, onOpen, onClose, state, dispatch, formatMessage } =
    useAppErrorModalControls();

  const error = state.get('errors');

  useEffect(() => {
    if (!isOpen && state.getIn(['errors', 'error_modal_open'])) {
      onOpen();
    }
  }, [onOpen, isOpen, state]);

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalContent>
        <ModalHeader>
          <FormattedMessage
            id="AppErrorModal.heading"
            defaultMessage="An error has occurred"
          />
        </ModalHeader>
        <ModalBody>
          <VStack>
            <Heading>
              <FormattedMessage
                id="ErrorModal.code"
                defaultMessage="HTTP Code: {status_code}"
                values={{ status_code: error.get('status_code') }}
              />
              {' - '}
              <FormattedMessage
                id="ErrorModal.server-message"
                defaultMessage="Server Message: {status_text}"
                values={{ status_text: error.get('status_text') }}
              />
            </Heading>
            <Text>{formatMessage(ErrorsEntity.getMessage(error))}</Text>

            {error.get('status_code') === 400 && (
              <Text
                sx={{
                  wordBreak: 'break-word',
                  overflowY: 'scroll',
                  maxHeight: 64,
                }}
              >
                {ErrorsEntity.formatData(error.get('data'))}
              </Text>
            )}
            <FormattedMessage
              id="Error.contact-support"
              defaultMessage="If you are unable to correct the issue, please contact your IT service desk."
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              dispatch(
                CubeAppEntity.duck.actions.save_local(
                  state.setIn(['errors', 'error_modal_open'], false),
                ),
              );
              onClose();
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
