import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import keysIn from 'lodash/keysIn';
import { defineMessage } from 'react-intl';

import { Entity, Fields } from '@burnsred/entity';
import DuckScreen from '@burnsred/entity-duck-namespace-screen';
import type { EntityRecord } from 'types.ts';

function formatAsJsonStringIfObject(value: Record<string, unknown>) {
  if (isPlainObject(value)) {
    return JSON.stringify(value, null, 2);
  }
  return value.toString();
}

function formatErrorObject(o: Record<string, unknown>, error: string = '') {
  const keys = keysIn(o);
  let localError = error;
  for (const key of keys) {
    if (isString(o[key])) {
      localError += `${key.toUpperCase()} - ${o[key]}\n`;
    }
    if (isArray(o[key])) {
      // @ts-expect-error object is unknown
      const value = o[key].map((element: Record<string, unknown>) =>
        formatAsJsonStringIfObject(element),
      );
      localError += `${key.toUpperCase()} - ${value.join(', ')}\n`;
    }
    if (isPlainObject(o[key])) {
      // @ts-expect-error object is unknown
      localError = formatErrorObject(o[key], localError);
    }
  }
  return localError;
}

export class ErrorsEntity extends Entity {
  static fields = {
    error_modal_open: new Fields.BooleanField({
      default: false,
    }),
    status_text: new Fields.CharField(),
    status_code: new Fields.NumberField(),
    data: new Fields.AnyField(),
  };

  static formatData(data: unknown) {
    if (isArray(data)) {
      return data.join('\n');
    }
    if (isString(data)) {
      return data;
    }
    if (isPlainObject(data)) {
      return formatErrorObject(data as Record<string, never>);
    }
    return 'Unable to format error response';
  }

  static getMessage(record: ErrorsEntityRecord) {
    const code = record.get('status_code');
    let message: { defaultMessage: string; id: string } = {
      defaultMessage: '',
      id: '',
    };
    switch (code) {
      case 500:
        message = defineMessage({
          id: 'Error.500',
          defaultMessage:
            'A critical error with the service has occurred. A crash report has automatically been sent to the development team.',
        });
        break;
      case 502:
        message = defineMessage({
          id: 'Error.502',
          defaultMessage:
            'The server was unable to complete your request. Please try again later.',
        });
        break;
      case 503:
        message = defineMessage({
          id: 'Error.503',
          defaultMessage:
            'The server was unable to complete your request. Please try again later.',
        });
        break;
      case 504:
        message = defineMessage({
          id: 'Error.504',
          defaultMessage:
            'A temporary failure with the service has occurred. Please try again.',
        });
        break;
      case 400:
        message = defineMessage({
          id: 'Error.400',
          defaultMessage:
            'The request you made was rejected by the service. Please review and correct the information you have sent.',
        });
        break;
      default:
        message = defineMessage({
          id: 'ModalGenericErrorFallback',
          defaultMessage: 'An error has occurred with your request.',
        });
    }
    return message;
  }
}

export type ErrorsEntityFields = {
  error_modal_open: boolean;
  status_text: string;
  status_code: number;
  data: unknown;
};

export type ErrorsEntityRecord = EntityRecord<ErrorsEntityFields>;

class CubeAppEntity extends Entity {
  static fields = {
    errors: new Fields.EntityField({
      entity: ErrorsEntity,
    }),
  };
}

export type CubeAppEntityFields = {
  errors: ErrorsEntityRecord;
};

export type CubeAppEntityRecord = EntityRecord<CubeAppEntityFields>;

CubeAppEntity.duck = new DuckScreen({
  app: 'Cube',
  entity: CubeAppEntity,
  name: 'CubeApp',
});

export default CubeAppEntity;
