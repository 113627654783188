import { type Map, isImmutable } from 'immutable';
import { createContext, useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { toString as i18nToString } from 'entities/api/i18n/I18nText';
import { useCurrentUserPreferences } from 'entities/api/Person/Person';
import UserPreferencesEntity from 'entities/api/Person/UserPreferences';
import { type LocaleKey, defaultLocale } from 'locales';
import type { BasicEntityRecord, ErrorList } from 'types';

import { errorMessages } from './messages';

export type LocaleContext = {
  locale: LocaleKey;
  setLocale: (locale: LocaleKey) => void;
  getI18nField?: <U extends BasicEntityRecord>(
    record: U,
    fieldName: string,
  ) => string;
  toString: <T extends BasicEntityRecord = BasicEntityRecord>(
    record: T | undefined,
    fieldName?: string,
  ) => string;
};

/**
 * Provides a way of setting locale without props drilling.
 *
 * Seems react-intl doesn't provide a way of setting the current locale, only
 * reading it?!
 *
 * ```tsx
 * const [locale, setLocale] = useState<LocaleKeys>('en');
 *
 * return (
 *   <BrIntlContext.Provider value={{ local, setLocale }}>
 *     <Component />
 *   </BrIntlContext>
 * )
 * ```
 */
export const BrIntlContext = createContext<LocaleContext>({
  locale: defaultLocale,
  setLocale: ((_locale: LocaleKey) => undefined) as LocaleContext['setLocale'],
  getI18nField: () => '',
  toString: () => '',
});

/**
 * Provide a way of setting locale without props drilling, and provides a
 * convenience method which wraps I18nText.toString, injecting the current
 * locale.
 *
 * Seems react-intl doesn't provide a way of setting the current locale, only
 * reading it?!
 *
 * https://formatjs.io/docs/react-intl/api#useintl-hook
 *
 * ```tsx
 * const { locale, setLocale, toString } = useLocale();
 * ```
 */
export const useLocale = () => {
  const { formatMessage } = useIntl();
  const context = useContext(BrIntlContext);
  const dispatch = useDispatch();
  const preferences = useCurrentUserPreferences();

  const toString = useCallback(
    <T extends BasicEntityRecord = BasicEntityRecord>(
      record: T | undefined,
      fieldName?: string,
      locale?: LocaleKey,
    ) => i18nToString(record, locale ?? context.locale, fieldName),
    [context.locale],
  );

  const setLocale = useCallback(
    (locale: LocaleKey) => {
      // Don't set the contexts state variable since we are duplicating state and the
      // Context component will remediate.
      if (preferences) {
        dispatch(
          UserPreferencesEntity.duck.actions.save(
            preferences.set('locale', locale),
          ),
        );
      } else {
        // Since we have public pages that won't have saved preferences
        context.setLocale(locale);
      }
    },
    [context, preferences, dispatch],
  );

  const getI18nField = useCallback(
    <U extends Map<string, unknown>>(record: U, fieldName: string) => {
      if (defaultLocale === context.locale) {
        // if we don't have the default locale, fallback to using the field without locale suffix
        return `${record.get(`${String(fieldName)}_${defaultLocale}`) ?? record.get(String(fieldName))}`;
      }
      return `${record.get(`${String(fieldName)}_${context.locale}`)}`;
    },
    [context.locale],
  );

  const translateErrors = useCallback(
    (errors?: ErrorList) => {
      if (!errors) return '';

      return errors
        .map((error) => {
          const errorString = isImmutable(error) ? error.get('message') : error;

          const translation = Object.values(errorMessages).find(
            ({ id }) => id == generateEntityLibErrorId(errorString),
          );
          return translation ? formatMessage(translation) : error;
        })
        .join('. ');
    },
    [formatMessage],
  );

  if (context === null)
    throw new Error('useLocale must be used within a BrIntlContext.Provider');

  return {
    ...context,
    setLocale,
    /**
     * Returns the field matching the current locale, in a situation where the
     * Entity defines a separate field for each locale, like:
     * `description_en`, `description_es`.
     *
     * ```ts
     * const description = getI18nField(record, 'description');
     * ```
     */
    getI18nField,
    /**
     * Returns a string for the current locale for the requested record
     *
     * ```ts
     * // gets 'title' by default
     * toString(record);
     *
     * // gets 'nonTitleI18nField' if second arg `fieldName` is passed
     * toString(record, 'nonTitleI18nField');
     *
     * // gets 'someOtherEntityFieldWithTitle'
     * toString(record.get('someOtherEntityFieldWithTitle'));
     *
     * // gets the 'title' field in ES (all three args required to specify locale)
     * toString(record, 'title', 'es');
     *
     * class MyEntity extends Entity {
     *   static fields: EntityFields<MyEntityFields> = {
     *     // ...
     *     title: new Fields.EntityField({
     *       entity: I18nTextEntity,
     *     }),
     *     nonTitleI18nField: new Fields.EntityField({
     *       entity: I18nTextEntity,
     *     }),
     *     someOtherEntityFieldWithTitle: new Fields.EntityField({
     *       entity: SomeOtherEntityFieldWithTitle,
     *     }),
     *   };
     *
     *   static toString = toString<MyEntityRecord>;
     * }
     * ```
     *
     * @see I18nText#toString
     */
    toString,
    /**
     * These error messages are hard-coded in entity library
     * Translate error messages that are defined in the errorMessages object in messages.ts
     * If translation is not found, return the error message without translation
     *
     * ```ts
     * <FormErrorMessage>{translateErrors(errors)}</FormErrorMessage>
     * ```
     */
    translateErrors,
  };
};

export type UseLocaleReturn = ReturnType<typeof useLocale>;

/**
 * Generate an ID for an error translation based on the hard-coded error message
 * returned by @burnsred/entity.
 *
 * @example
 * ```
 * const errorMessage = 'May not be blank';
 * const errorId = generateEntityLibErrorId(errorMessage);
 * // 'entity-lib-error.may-not-be-blank'
 * ```
 */
function generateEntityLibErrorId(error: string) {
  return `entity-lib-error.${error?.trim().toLowerCase().replace(/ +/g, '-')}`;
}
