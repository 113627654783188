import { type List, fromJS } from 'immutable';

import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { createImmutableSelector } from 'entities/api/abstract.ts';
import { type LocaleKey } from 'locales';
import type { UseLocaleReturn } from 'locales/useLocale';
import { type EntityFields, type EntityRecord } from 'types';

import CompliancePlanBaseEquipmentEntity, {
  type CompliancePlanBaseEquipmentEntityRecord,
} from './CompliancePlanBaseEquipment';
import type { EquipmentEntityRecord } from '../Equipment';
import { toString as i18nToString } from '../i18n/I18nText';
import type { OperatingContextEntityRecord } from '../OperatingContext';
import OperatingContextEntity from '../OperatingContext';

class SiteEquipmentOperatingContextEntity extends Entity {
  static fields: EntityFields<SiteEquipmentOperatingContextEntityFields> = {
    uuid: new Fields.IdField({ blank: true }),

    compliance_equipment: new Fields.EntityField({
      entity: CompliancePlanBaseEquipmentEntity,
      blank: true,
    }),

    operating_contexts: new Fields.EntityField({
      entity: OperatingContextEntity,
      many: true,
      blank: true,
    }),
  };

  static toString(
    record: SiteEquipmentOperatingContextEntityRecord,
    locale: LocaleKey,
  ) {
    return i18nToString(
      record?.get('compliance_equipment')?.get('equipment'),
      locale,
    );
  }

  static getEquipmentOptionLabel = (
    option: unknown,
    equipmentLevel1: List<EquipmentEntityRecord>,
    toString: UseLocaleReturn['toString'],
  ) => {
    const record = fromJS(
      option,
    ) as SiteEquipmentOperatingContextEntityTreeRecord;
    const equipment = record?.get('compliance_equipment')?.get('equipment');
    const title = toString(equipment);

    if (equipment?.get('level') == 1) return title;

    // for non-level-1 equipment, include the 1st-level ancestor
    // in the label to disambiguate records with the same title
    // (eg "Loader")
    const l1Parent = equipmentLevel1?.find(
      (rec) =>
        rec.get('uuid') === equipment?.get('ancestors')?.get(1)?.get('uuid'),
    );
    const l1ParentString = l1Parent ? toString(l1Parent) + ' > ' : '';
    return `${l1ParentString}${title}`;
  };

  static getEquipmentOperationContextSize = createImmutableSelector(
    [
      (
        operating_context: OperatingContextEntityRecord,
        _site_equipment_contexts: List<SiteEquipmentOperatingContextEntityRecord>,
      ) => operating_context,
      (
        _operating_context: OperatingContextEntityRecord,
        site_equipment_contexts: List<SiteEquipmentOperatingContextEntityRecord>,
      ) => site_equipment_contexts,
    ],
    (operating_context, site_equipment_contexts) => {
      const context_equipment = site_equipment_contexts?.filter(
        (equipmentOperation) => {
          const contexts = equipmentOperation?.get('operating_contexts');
          return contexts?.find(
            (val) => val.get('uuid') === operating_context.get('uuid'),
          );
        },
      );

      return context_equipment?.size || 0;
    },
  );
}

export type SiteEquipmentOperatingContextEntityFields = {
  uuid: string;
  // site_compliance_plan: SiteCompliancePlanEntityRecord;
  compliance_equipment: CompliancePlanBaseEquipmentEntityRecord;
  operating_contexts: List<OperatingContextEntityRecord>;
};

export type SiteEquipmentOperatingContextEntityTreeFields =
  SiteEquipmentOperatingContextEntityFields & {
    children: List<SiteEquipmentOperatingContextEntityTreeRecord>;
    relative_level: number;
    parent: string;
  };

export type SiteEquipmentOperatingContextEntityTreeRecord =
  EntityRecord<SiteEquipmentOperatingContextEntityTreeFields>;

export type SiteEquipmentOperatingContextEntityRecord =
  EntityRecord<SiteEquipmentOperatingContextEntityFields>;

SiteEquipmentOperatingContextEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: SiteEquipmentOperatingContextEntity,
  name: 'SiteEquipmentOperatingContext',
});

export default SiteEquipmentOperatingContextEntity;
