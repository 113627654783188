import { List } from 'immutable';
import { useCallback, useMemo } from 'react';

import SiteCompliancePlanEntity, {
  type SiteCompliancePlanEntityFields,
} from 'entities/api/CompliancePlan/SiteCompliancePlan';
import { type EntityDuckAction, type FormChangeEvent } from 'types';

import type { SiteComplianceGRCFormProps } from './SiteComplianceGRCForm';

export default function useSiteComplianceGRCFormInterface(
  props: SiteComplianceGRCFormProps,
) {
  const { name, onChange, value } = props;

  const operations = useMemo(
    () => value?.map((rec) => rec?.get('site')),
    [value],
  );

  /** add & remove operations from sitecomplianceplan_set */
  const onChangeOperations = useCallback(
    (event: FormChangeEvent) => {
      const eventValue = (event?.target?.value ?? List([])) as List<
        SiteCompliancePlanEntityFields['site']
      >;
      const newSiteCompliancePlanSet = value.filter((rec) =>
        eventValue.includes(rec.get('site')),
      );

      const existingSites = newSiteCompliancePlanSet.map((rec) =>
        rec.get('site'),
      );
      const sitesToAdd = eventValue
        .filter((rec) => !existingSites.includes(rec))
        .map((site) =>
          SiteCompliancePlanEntity.dataToRecord({
            site,
            general_manager: undefined,
            president: undefined,
            local_control_advisor: undefined,
            siteequipmentoperatingcontext_set: List([]),
            scenarios: List([]),
          }),
        );
      // Call the forms onChange Method to update the field.
      onChange({
        target: {
          name,
          value: newSiteCompliancePlanSet.push(...sitesToAdd),
        },
      });
      // @Hack, this is just for typescript compat with the RAOPicker.
      return {} as unknown as EntityDuckAction;
    },
    [onChange, name, value],
  );

  return {
    operations,
    onChangeOperations,
  };
}
