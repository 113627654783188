import {
  Tooltip as ChakraTooltip,
  type SystemStyleObject,
  type TooltipProps,
} from '@chakra-ui/react';
import { Flex, Text } from '@chakra-ui/react';

/**
 * Renders Tooltip with our preferred defaults
 *
 * @default hasArrow true
 *
 * * ## If the element is not clickable, render with a help cursor using
 * `layerStyle="hoverHelp"`
 *
 * ```tsx
 * <Tooltip label="Some label">
 *   <Text as="span" layerStyle="hoverHelp">Hover-able</Box>
 * </Tooltip>
 * ```
 *
 * ## Prevent tooltip overflowing, causing horizontal scrolling
 *
 * ```tsx
 * <Tooltip
 *   label="Some label"
 *   modifiers={[
 *     // prevent tooltip overflowing, causing horizontal scrolling
 *     { name: 'preventOverflow', options: { boundary: document.body } },
 *   ]}
 * >
 *   Content
 * </Tooltip>
 * ```
 *
 * @see https://popper.js.org/docs/v2/modifiers/prevent-overflow/
 */
export const Tooltip = (props: TooltipProps) => (
  <ChakraTooltip hasArrow {...props} />
);

type TooltipTemplateProps = {
  title: React.ReactNode;
  description?: React.ReactNode;
  titleSx?: SystemStyleObject;
  descriptionSx?: SystemStyleObject;
};

const _titleSx: SystemStyleObject = {
  fontSize: 'sm',
  fontWeight: 'bold',
  color: 'white',
};
export const titleSx = _titleSx;

export const _descriptionSx: SystemStyleObject = {
  fontSize: 'xs',
  whiteSpace: 'pre-line',
};

/**
 * TooltipTemplate Component
 *
 * Renders the content of the tooltip with a title and optional description.
 */
export const TooltipTitleDescriptionLayout = ({
  title,
  description,
  titleSx = _titleSx,
  descriptionSx = _descriptionSx,
}: TooltipTemplateProps) => (
  <Flex direction="column">
    <Text role="term" sx={titleSx}>
      {title}
    </Text>
    {description && (
      <Text role="definition" sx={descriptionSx}>
        {`${description}`.trim()}
      </Text>
    )}
  </Flex>
);
