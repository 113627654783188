import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import EquipmentEntity, { type EquipmentEntityRecord } from '../Equipment';
import { toString } from '../i18n/I18nText';

class CompliancePlanBaseEquipmentEntity extends Entity {
  static fields: EntityFields<CompliancePlanBaseEquipmentEntityFields> = {
    uuid: new Fields.IdField({ blank: true }),

    equipment: new Fields.EntityField({
      entity: EquipmentEntity,
      blank: true,
    }),
  };

  static toString = toString<CompliancePlanBaseEquipmentEntityRecord>;
}

export type CompliancePlanBaseEquipmentEntityFields = {
  uuid: string;
  // compliance_plan_info: CompliancePlanInfoEntityRecord;
  equipment: EquipmentEntityRecord;
};

export type CompliancePlanBaseEquipmentEntityRecord =
  EntityRecord<CompliancePlanBaseEquipmentEntityFields>;

CompliancePlanBaseEquipmentEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: CompliancePlanBaseEquipmentEntity,
  name: 'CompliancePlanBaseEquipment',
});

export default CompliancePlanBaseEquipmentEntity;
