import { Button, useDisclosure } from '@chakra-ui/react';
import { type List, Map } from 'immutable';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useParams } from 'react-router';

import { useQuery } from '@burnsred/entity-duck-query';
import { Loading } from '@burnsred/ui-chakra';
import { useStickyActionsContext } from 'components/layout';
import ApplicableControlForPlanListEntity, {
  type ApplicableControlForPlanListEntityRecord,
  COMPLETE,
} from 'entities/api/CompliancePlan/ApplicableControlForPlanListOnly';
import SiteCompliancePlanRowEntity, {
  type SiteCompliancePlanRowEntityRecord,
} from 'entities/api/CompliancePlan/SiteCompliancePlanRow';
import { createLogger } from 'util/createLogger';

const log = createLogger('useActionPlanControlList');

export const useActionPlanControlList = () => {
  const { pathname } = useLocation();
  const { siteCompliancePlanUuid } = useParams();

  const { value: applicableControlsForPlan } = useQuery<
    List<ApplicableControlForPlanListEntityRecord>
  >({
    action: ApplicableControlForPlanListEntity.duck.actions.get({
      params: Map({
        site_compliance_plan: siteCompliancePlanUuid,
        has_gaps: 'true',
      }),
    }),
    componentProps: { siteCompliancePlanUuid },
    processingComponent: Loading,
    persist: false,
  });

  const { value: siteComplianceShort } =
    useQuery<SiteCompliancePlanRowEntityRecord>({
      action: SiteCompliancePlanRowEntity.duck.actions.get({
        id: siteCompliancePlanUuid,
      }),
    });

  const { setChildren } = useStickyActionsContext();
  const modalDisclosure = useDisclosure();

  useEffect(() => {
    setChildren([
      <Button
        key="submit-approval-button"
        onClick={modalDisclosure.onOpen}
        isDisabled={siteComplianceShort?.get('status_en') !== COMPLETE}
      >
        <FormattedMessage
          id="compliance.actions.submitApproval"
          defaultMessage={'Submit for Approval'}
        />
      </Button>,
    ]);
    // render cautiously, but always on pathname!
  }, [pathname, siteComplianceShort, modalDisclosure.onOpen, setChildren]);

  log('%o', { applicableControlsForPlan });

  return {
    applicableControlsForPlan,
    modalDisclosure,
  };
};

export type UseActionReturn = ReturnType<typeof useActionPlanControlList>;
