import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  LinkBox,
  LinkOverlay,
} from '@chakra-ui/react';

import { RouterLink } from '@burnsred/ui-chakra';
import { TimeZoneGraph } from 'components';
import { type ControlEntityRecord } from 'entities/api/Control';
import { useLocale } from 'locales/useLocale';
import { createLogger } from 'util/createLogger';

import { cardHeaderSx, cardSx, headingSx } from './LinkedControlCard.styles';

const _log = createLogger('LinkedControlCard');

export type LinkTargetCallback = (controlUuid: string) => string;

type LinkedControlCardProps = {
  record: ControlEntityRecord;
  linkTarget: string | LinkTargetCallback | null;
};

export const LinkedControlCard = (props: LinkedControlCardProps) => {
  const { toString } = useLocale();
  const { record } = props;

  const linkTarget = props.linkTarget
    ? typeof props.linkTarget == 'function'
      ? props.linkTarget(record.get('uuid'))
      : props.linkTarget?.replace(':controlUuid', record.get('uuid'))
    : '#';

  return (
    <Card
      size="sm"
      sx={{
        ...cardSx,
        borderColor: `controlType_${record?.get('control_designation')?.get('order')}`,
      }}
    >
      <CardHeader as={LinkBox} sx={cardHeaderSx}>
        <Heading as="h4" sx={headingSx}>
          <LinkOverlay
            as={RouterLink}
            to={linkTarget}
            relative="path"
            isExternal
          >
            {toString(record) || '(Blank)'}
          </LinkOverlay>
        </Heading>
      </CardHeader>
      <CardBody />
      <CardFooter>
        <TimeZoneGraph timezones={record?.get('timezones')} thin={true} />
      </CardFooter>
    </Card>
  );
};
