import { Button, Flex, HStack, Heading } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { EntriesFound, Paginator, VStack } from 'components';
import { handleNotImplemented } from 'util/handleNotImplemented';

import { FrameworkFilters } from './components/FrameworkFilters';
import { FrameworksList } from './components/FrameworksList';
import { useControlFrameworkDashboardControls } from './ControlFrameworks.hooks';

export function ControlFrameworks() {
  const {
    name,
    filterRecord,
    params,
    onChange,
    FilterField,
    errors,
    paginatorProps,
  } = useControlFrameworkDashboardControls();

  return (
    <Flex direction="column" gap={8}>
      <FrameworkFilters
        name={name}
        filterRecord={filterRecord}
        onChange={onChange}
        FilterField={FilterField}
        errors={errors}
      />

      <VStack gap={0}>
        <Heading size="lg">
          <FormattedMessage
            id="control-frameworks.title.control-frameworks"
            defaultMessage="Local control frameworks"
          />
        </Heading>

        <VStack gap={4}>
          <HStack justifyContent="space-between" alignItems="flex-end">
            <EntriesFound count={paginatorProps.totalItems} />

            <Button
              variant="primary"
              size="lg"
              onClick={handleNotImplemented('Create control framework')}
            >
              <FormattedMessage
                id="control-frameworks.create-a-control-framework"
                defaultMessage="Create a control framework"
              />
            </Button>
          </HStack>

          <FrameworksList params={params} />
        </VStack>
      </VStack>

      <Paginator
        displayAlways={
          paginatorProps.pageSizeOptions
            ? paginatorProps.totalItems > paginatorProps.pageSizeOptions[0]
            : true
        }
        {...paginatorProps}
      />
    </Flex>
  );
}
