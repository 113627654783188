import {
  SwitchElement,
  SwitchLabel,
  SwitchRadio,
  SwitchSelection,
} from './Switch.styles.tsx';

type ClickableLabelProps = {
  children?: React.ReactNode;
  checked: boolean;
  onChange: (value: string) => void;
  currentIndex: number;
  label: string;
  value: string;
  isDisabled?: boolean;
};
const ClickableLabel = ({
  children,
  currentIndex,
  label,
  onChange,
  checked,
  value,
  isDisabled = false,
  ...rest
}: ClickableLabelProps) => (
  <SwitchLabel
    onClick={(e) => (!isDisabled ? onChange(value) : e.preventDefault())}
    checked={checked}
    currentIndex={currentIndex}
    {...rest}
  >
    {label}
    {children}
  </SwitchLabel>
);

type SwitchOption = {
  value: string;
  label: string;
};

type Props = {
  /**
   * Array of SwitchOptions used to render the switch.
   */
  options: SwitchOption[];
  /**
   * Sets the value of the switch. Provided string must match a value provided in the options array.
   */
  value: string;
  /**
   * Makes the entire switch readonly (disabled)
   * defaults to false
   */
  isReadonly?: boolean;

  /** the onChange callback is called with `value`, for consumption in the parent component */
  onChange: (value: string) => void;
  positiveAnswerColor?: string;
};

/**
 * Switch component is a standard switch input.
 *
 * This is true and false. The values and labels are rendered
 * from an array of objects provided in the options prop.
 *
 * Two options must be passed in the options props otherwise this component will
 * throw an error and render blank.
 *
 * If a value is provided, it must match a corresponding value in the
 * options array otherwise this component will throw an error and render blank.
 *
 * // TODO update this component in @burnsred/ui-chakra
 */
export function Switch({
  options,
  value,
  isReadonly = false,
  onChange,
  positiveAnswerColor,
}: Props) {
  const currentIndex = options.findIndex((option) => {
    return option.value === value;
  });

  if (options.length < 2) {
    console.error('Two options are expected for this Switch');
    return <></>;
  }

  if (currentIndex < 0) {
    console.error('Provided value does not exist in options array.');
    return <></>;
  }

  return (
    // FIXME disable ClickableLabel for the selected option so it's obvious what's clickable
    <SwitchElement
      currentIndex={currentIndex}
      positiveAnswerColor={positiveAnswerColor}
    >
      {options.map(({ label, value: optionValue }) => (
        <ClickableLabel
          key={optionValue}
          label={label}
          value={optionValue}
          onChange={onChange}
          checked={value === optionValue}
          currentIndex={currentIndex}
          // FIXME isDisabled != isReadonly

          isDisabled={isReadonly || value === optionValue}
        >
          <SwitchRadio checked={value === optionValue} />
        </ClickableLabel>
      ))}

      <SwitchSelection currentIndex={currentIndex} />
    </SwitchElement>
  );
}
