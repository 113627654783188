import type { SystemStyleObject } from '@chakra-ui/react';
import { fromJS } from 'immutable';

import { DefaultWrapper } from '../DefaultComponents';
import { useTreePicker } from '../TreePicker.hooks';
import { TreePickerList } from '../TreePickerList';

type TreePickerWrapperProps = {
  sx: SystemStyleObject;
  cachedQuery?: boolean;
};

export const TreePickerWrapper = ({
  sx,
  cachedQuery,
}: TreePickerWrapperProps) => {
  const { lists, parents, wrapperComponent, allowToggle, debug, disabled } =
    useTreePicker();

  const Wrapper = wrapperComponent ?? DefaultWrapper;

  return (
    <Wrapper
      className={['TreePicker__Wrapper', debug ? 'debug' : ''].join(' ')}
      data-allow-toggle={allowToggle}
      aria-disabled={disabled}
      sx={sx}
    >
      {parents.map((parent, i) => (
        <TreePickerList
          key={i}
          level={i}
          parent={parent}
          items={lists?.get(parent) ?? fromJS([])}
          cachedQuery={cachedQuery}
        />
      ))}
    </Wrapper>
  );
};
