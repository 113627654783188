import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Divider,
  HStack,
  Heading,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';

import { withQuery } from '@burnsred/entity-duck-query';
import { Form, Input } from '@burnsred/entity-form';
import { RiskIcon, VStack } from 'components';
import { Risk } from 'entities';
import ApplicableControlForPlanEntity, {
  type ApplicableControlForPlanEntityRecord,
} from 'entities/api/CompliancePlan/ApplicableControlForPlan';
import { useLocale } from 'locales/useLocale';
import type { WithQueryReturnProps } from 'types';

import BaselineAssessmentControlPreview from './BaselineAssessmentControlPreview/BaselineAssessmentControlPreview.tsx';
import BaselineAssessmentForm from './BaselineAssessmentForm/BaselineAssessmentForm';
import { useControlAssessment } from './ControlAssessment.hooks';
import { accordionSx } from './ControlAssessment.styles';
import { ControlSelector } from '../../../components/ControlSelector';
import { COMPLIANCE_TABS } from '../../COMPLIANCE_TABS.ts';
import { AssessmentCoverageBar } from '../AssessmentCoverageBar';

export type ControlAssessmentProps =
  WithQueryReturnProps<ApplicableControlForPlanEntityRecord>;

const ControlAssessment = (props: ControlAssessmentProps) => {
  const { toString } = useLocale();
  const {
    control,
    equipmentLevel1,
    equipmentLevel2,
    operatingContexts,
    scenarios,
    risk,
    global_framework_id,
    formProps,
    addNewBaselineAssessment,
    removeBaselineAssessment,
    hasAssess,
    hasMandatory,
    equipOptionsMandatory,
    equipOptionsAssess,
    percent,
    currentCoverageMandatory,
    currentCoverageAssess,
    missingCoverageAssess,
    missingCoverageMandatory,
    siteName,
  } = useControlAssessment(props);

  return (
    <>
      <VStack sx={{ paddingX: 'gutter' }}>
        <Heading as="h2" variant="primary" size="md">
          {siteName}
        </Heading>
        <ControlSelector
          subTabSegment={COMPLIANCE_TABS.tabPaths.baselineAssessment.routeName}
          has_gaps={false}
        />
      </VStack>

      <Divider />
      <VStack sx={{ paddingX: 'gutter' }}>
        <Accordion
          defaultIndex={0}
          allowToggle
          variant="reset"
          sx={accordionSx}
        >
          <AccordionItem>
            <AccordionButton>
              <HStack gap={3}>
                <RiskIcon path={Risk.getRiskIconPath(risk)} filter="orange" />
                <Heading as="h3" variant="primary" size="sm" sx={{}}>
                  {toString(control)}
                </Heading>
              </HStack>

              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel>
              <BaselineAssessmentControlPreview
                control={control}
                equipmentLevel1={equipmentLevel1}
                equipmentLevel2={equipmentLevel2}
                operatingContexts={operatingContexts}
                scenarios={scenarios}
                risk={risk}
                global_framework_id={global_framework_id}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </VStack>

      <Divider />

      <AssessmentCoverageBar
        assessEquipmentOperationCovered={currentCoverageAssess}
        mandatoryEquipmentOperationCovered={currentCoverageMandatory}
        assessEquipmentOperationMissing={missingCoverageAssess}
        mandatoryEquipmentOperationMissing={missingCoverageMandatory}
        percent={percent}
      />
      <VStack sx={{ paddingX: 'gutter' }}>
        <Form {...formProps}>
          {hasMandatory && (
            <>
              <Input
                name="baseline_assessments"
                component={BaselineAssessmentForm}
                componentProps={{
                  risk: risk,
                  isForMandatory: true,
                  removeBaselineAssessment: removeBaselineAssessment,
                  equipOptions: equipOptionsMandatory,
                  missingOptions: missingCoverageMandatory,
                  equipmentLevel1,
                }}
                many
              />
              <HStack>
                <Button
                  type="submit"
                  variant="outline"
                  onClick={addNewBaselineAssessment}
                >
                  <FormattedMessage
                    id="compliance.baseline-assessment.control-assessment.add-mandatory-gap-assessment"
                    defaultMessage="Add Gap Mandatory Assessment"
                  />
                </Button>
              </HStack>
            </>
          )}
          {hasAssess && (
            <>
              <Input
                name="baseline_assessments"
                component={BaselineAssessmentForm}
                componentProps={{
                  risk: risk,
                  isForMandatory: false,
                  removeBaselineAssessment: removeBaselineAssessment,
                  equipOptions: equipOptionsAssess,
                  missingOptions: missingCoverageAssess,
                  equipmentLevel1,
                }}
                many
              />
              <HStack>
                <Button
                  type="submit"
                  variant="outline"
                  onClick={() =>
                    addNewBaselineAssessment({ is_mandatory: false })
                  }
                >
                  <FormattedMessage
                    id="compliance.baseline-assessment.control-assessment.add-gap-assessment"
                    defaultMessage="Add Gap Assessment"
                  />
                </Button>
              </HStack>
            </>
          )}
        </Form>
      </VStack>
    </>
  );
};

export default withQuery((_props) => {
  const { controlUuid: id } = useParams();
  return {
    action: ApplicableControlForPlanEntity.duck.actions.get({ id }),
    persist: false,
  };
})(ControlAssessment);
