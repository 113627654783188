import { type List } from 'immutable';

import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types.ts';

import I18nTextEntity, { toString } from './i18n/I18nText';
import RiskEntity, { type RiskEntityRecord } from './Risk';
import ScenarioEntity, { type ScenarioEntityRecord } from './Scenario';
import { type SiteControlEntityRecord } from './SiteControl.ts';
import TimeZoneEntity, { type TimeZoneEntityRecord } from './TimeZone.ts';
import { type TitleEntityFields } from './types.ts';

class SiteControlSlimEntity extends Entity {
  static fields: EntityFields<SiteControlSlimEntityFields> = {
    uuid: new Fields.IdField(),
    timezones: new Fields.EntityField({
      entity: TimeZoneEntity,
      many: true,
    }),
  };
}

type SiteControlSlimEntityFields = {
  uuid: string;
  timezones: List<TimeZoneEntityRecord>;
};

export type SiteControlSlimEntityRecord =
  EntityRecord<SiteControlSlimEntityFields>;
class SiteFrameworkListEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/site_framework_list_only/',
  };

  static fields: EntityFields<SiteFrameworkListEntityFields> = {
    uuid: new Fields.IdField(),

    title: new Fields.EntityField({
      entity: I18nTextEntity,
    }),
    existing_identifier: new Fields.CharField({ blank: true }),
    controls: new Fields.EntityField({
      entity: SiteControlSlimEntity,
      many: true,
    }),

    scenarios: new Fields.EntityField({
      entity: ScenarioEntity,
      many: true,
    }),

    risk: new Fields.EntityField({
      entity: RiskEntity,
    }),
    identifier: new Fields.CharField(),
    status: new Fields.CharField(),
    status_en: new Fields.CharField(),
    status_es: new Fields.CharField(),
  };

  // SiteFrameworkListEntity.toString(record)
  static toString = toString<SiteFrameworkListEntityRecord>;
}

type SiteFrameworkListEntityFields = TitleEntityFields & {
  scenarios: List<ScenarioEntityRecord>;
  controls: List<SiteControlEntityRecord>;
  risk: RiskEntityRecord;
  identifier: string;
  existing_identifier: string;
  status: string;
  status_en: string;
  status_es: string;
};
export type SiteFrameworkListEntityRecord =
  EntityRecord<SiteFrameworkListEntityFields>;

SiteFrameworkListEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: SiteFrameworkListEntity,
  name: 'SiteFrameworkList',
});

export default SiteFrameworkListEntity;
