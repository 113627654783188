import { Flex, type FlexProps, type SystemStyleObject } from '@chakra-ui/react';

export const ScenarioRowGridSx: SystemStyleObject = {
  gridTemplateColumns: 'repeat(10, 1fr)',
  gap: '2px',
  width: '100%',
  '> div': {
    color: 'black',
    fontSize: 'xs',
    paddingY: 2,
  },
  // Scenario Title
  '> div:nth-of-type(1)': {
    textAlign: 'left',
    backgroundColor: 'gray.100',
    '> div': {
      flexDirection: 'row',
      gap: 3,
      alignItems: 'flex-start',
      paddingLeft: 1,
      '> label': {
        alignItems: 'flex-start',
        '.chakra-checkbox__control': {
          marginTop: '2px',
        },
        '.chakra-checkbox__label': {
          fontSize: 'xs',
        },
      },
    },
  },
};

export const ScenarioIntersectGridItemProps: FlexProps = {
  justifyContent: 'center',
  alignItems: 'center',
  as: Flex,
};
