import { type List, Map } from 'immutable';
import { useEffect, useMemo } from 'react';
import { useOutletContext } from 'react-router';

import { useQuery } from '@burnsred/entity-duck-query';
import RiskEntity, { type RiskEntityRecord } from 'entities/api/Risk';

import type { CompliancePlanContext } from './CompliancePlan';

export function useCompliancePlanHooks() {
  const { compliancePlanFormProps, ...rest } =
    useOutletContext<CompliancePlanContext>();
  const {
    value: compliancePlan,
    valueInitial,
    name,
    onChange,
  } = compliancePlanFormProps;

  const { value: risks } = useQuery<List<RiskEntityRecord>>({
    action: RiskEntity.duck.actions.get({
      params: Map({ globalframework__is_globalized: 'true' }),
    }),
  });

  useEffect(() => {
    if (!compliancePlan.get('risk') && risks?.size === 1) {
      onChange({
        target: {
          name: name,
          value: compliancePlan.set('risk', risks.first()),
        },
      });
    }
  }, [compliancePlan, name, onChange, risks]);

  /**
   * BC-1254: To temporarily support Asset level compliance plans we need to
   * disable the RAO picker if a it sees an asset as being chosen.
   * These plans have be manually created in the short term.
   */
  const isAssetPlan = useMemo(
    () =>
      valueInitial
        ?.get('sitecomplianceplan_set')
        ?.some((plan) => plan?.get('site')?.get('ancestors')?.count() == 1),
    [valueInitial],
  );

  return {
    compliancePlanFormProps,
    onChange,
    isAssetPlan,
    ...rest,
  };
}
