import { type SystemStyleObject } from '@chakra-ui/react';

export const vehicleClassHelperBaseFlexSx: SystemStyleObject = {
  width: '100%',
  justifyContent: 'flex-end',
  marginBottom: 3,
  '> div': {
    gap: 2,
    alignItems: 'center',
    '> svg': {
      fontSize: '24px',
      color: '#39b54a',
    },
    '> p': {
      fontSize: 'sm',
    },
  },
};
