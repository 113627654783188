import { withQuery } from '@burnsred/entity-duck-query';
import { ControlPreview } from 'components/ControlGrid/components/ControlPreview.tsx';
import ControlEntity, {
  type ControlEntityRecord,
} from 'entities/api/Control.ts';
import { controlHrefCb } from 'screens/global-frameworks/GlobalFramework/GlobalFramework.tsx';
import type { WithQueryReturnProps } from 'types';

import type { UseControlAssessmentReturn } from '../ControlAssessment.hooks';

type BaselineAssessmentControlPreviewProps =
  WithQueryReturnProps<ControlEntityRecord> & WrapperProps;

const BaselineAssessmentControlPreview = (
  props: BaselineAssessmentControlPreviewProps,
) => {
  const {
    value: control,
    global_framework_id,
    risk,
    equipmentLevel1,
    equipmentLevel2,
    operatingContexts,
    scenarios,
  } = props;

  return (
    <ControlPreview
      control={control}
      controlHref={controlHrefCb(global_framework_id, control.get('uuid'))}
      equipmentLevel1={equipmentLevel1}
      equipmentLevel2={equipmentLevel2}
      operatingContexts={operatingContexts}
      scenarios={scenarios}
      risk={risk}
      renderHeader={false}
      sx={{}}
    />
  );
};

type WrapperProps = Pick<
  UseControlAssessmentReturn,
  | 'control'
  | 'equipmentLevel1'
  | 'equipmentLevel2'
  | 'operatingContexts'
  | 'scenarios'
  | 'global_framework_id'
  | 'risk'
>;

export default withQuery((props: WrapperProps) => {
  const id = props?.control?.get('uuid');

  return {
    action: ControlEntity.duck.actions.get({ id }),
    componentProps: props,
  };
})(BaselineAssessmentControlPreview);
