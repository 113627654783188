import type { SystemStyleObject } from '@chakra-ui/react';

export const sectionSx: SystemStyleObject = {
  flexDirection: 'column',
  width: '280px',
  minWidth: '280px',
  bgColor: 'white',
  border: '1px',
  borderColor: 'faint',
};

export const listSx: SystemStyleObject = {
  flexDirection: 'column',
  gap: 1,
  margin: 2,
};

export const headerSx: SystemStyleObject = {
  paddingX: 3,
  paddingY: 4,
  borderBottom: '1px',
  borderColor: 'faint',
  fontSize: 'sm',
};

export const headingSx: SystemStyleObject = {
  fontSize: 'sm',
};
