import {
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  type UseDisclosureReturn,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { withQuery } from '@burnsred/entity-duck-query';
import type { CompliancePlanEntityRecord } from 'entities/api/CompliancePlan/CompliancePlan';
import EquipmentEntity, {
  type EquipmentEntityRecord,
} from 'entities/api/Equipment';
import type { WithQueryReturnProps } from 'types';

import { modalHeaderSx } from './NewEquipmentModal.styles';
import { NewEquipmentModalForm } from './NewEquipmentModalForm';

export type NewEquipmentModalProps =
  WithQueryReturnProps<EquipmentEntityRecord> & {
    disclosure: UseDisclosureReturn;
    compliancePlan: CompliancePlanEntityRecord;
    equipmentPickerBaseParams: Map<string, string>;
    refreshTieredEquipmentPicker: () => void;
  };

const NewEquipmentModal = (props: NewEquipmentModalProps) => {
  const {
    disclosure: { isOpen, onClose },
  } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />

        <ModalHeader sx={modalHeaderSx}>
          <Heading as="h3" size="sm" color="primary">
            <FormattedMessage
              id="compliance.operating-environment.equipment.modal.heading"
              defaultMessage="Add local vehicle"
            />
          </Heading>
        </ModalHeader>

        {/* avoid fetching / running hooks until required */}
        {isOpen && <NewEquipmentModalForm {...props} />}
      </ModalContent>
    </Modal>
  );
};

export default withQuery({
  action: EquipmentEntity.duck.actions.get({ id: null }),
})(NewEquipmentModal);
