import { Flex, FormControl, FormLabel } from '@chakra-ui/react';
import { type List, Map } from 'immutable';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';

import { withQuery } from '@burnsred/entity-duck-query';
import { Autocomplete } from 'components/Autocomplete';
import ApplicableControlForPlanEntity, {
  type ApplicableControlForPlanEntityRecord,
} from 'entities/api/CompliancePlan/ApplicableControlForPlan.ts';
import type { ComplianceTabsRouteNames } from 'screens/compliance/tabs';
import type { WithQueryReturnProps } from 'types.ts';

import { useControlSelector } from './ControlSelector.hooks.tsx';

export type CompliancePlanSelectorProps = WithQueryReturnProps<
  List<ApplicableControlForPlanEntityRecord>
> & {
  subTabSegment: ComplianceTabsRouteNames;
  has_gaps: boolean;
};

export const ControlSelector = (props: CompliancePlanSelectorProps) => {
  const { onChange, options, selectedOption } = useControlSelector(props);

  return (
    <Flex maxWidth={'560px'}>
      <FormControl>
        <FormLabel>
          <FormattedMessage
            id="dashboard.stats.label.controls"
            defaultMessage="Controls"
          />
        </FormLabel>
        <Autocomplete
          options={options}
          onChange={onChange}
          value={selectedOption}
        />
      </FormControl>
    </Flex>
  );
};

export default withQuery((props: CompliancePlanSelectorProps) => {
  const { siteCompliancePlanUuid } = useParams();
  const params = Map({
    site_compliance_plan: siteCompliancePlanUuid,
    ...(props.has_gaps && { has_gaps: 'true' }),
  });

  return {
    action: ApplicableControlForPlanEntity.duck.actions.get({
      params: params,
    }),
  };
})(ControlSelector);
