import { useDisclosure } from '@chakra-ui/react';
import type { List } from 'immutable';
import { useCallback, useEffect, useState } from 'react';

import { useFilePicker } from 'components/FilePicker';
import { type BaselineAssessmentAnswerAttachmentEntityRecord } from 'entities/api/CompliancePlan/BaselineAssessmentAnswerAttachment.ts';
import GapEntity from 'entities/api/CompliancePlan/Gap';
import { useLocale } from 'locales/useLocale';

import { type AnswerFormFormProps } from './AnswerForm';

export function useAnswerFormControls(props: AnswerFormFormProps) {
  const {
    name,
    onChange,
    field,
    value,
    valueInitial,
    index,
    onSubmit,
    errors,
    onChangeInput,
    options,
  } = props;
  const { getI18nField } = useLocale();

  /** converts the string values of the ThreeWaySwitch */
  const onChangeResponse = useCallback(
    (stringVal: string) => {
      const val =
        stringVal == 'true' ? true : stringVal == 'false' ? false : null;
      const updatedValue = value.set('response', val);
      onChange({ target: { name, index, value: updatedValue } });
    },
    [name, onChange, value, index],
  );
  const threeWaySwitchProps = {
    options: [
      { value: 'false', label: '' },
      { value: 'null', label: '' },
      { value: 'true', label: '' },
    ],
    value: String(value.get('response')),
    onChange: onChangeResponse,
    unsetEnabled: true,
    positiveAnswerColor:
      value.get('positive_response') === false ? '#fab636' : undefined,
  };

  const [isCommentsVisible, setIsCommentsVisible] = useState(
    !!value.get('comments').length,
  );
  const toggleShowComments = useCallback(
    () => setIsCommentsVisible(!isCommentsVisible),
    [isCommentsVisible],
  );

  const comments = value.get('comments');
  useEffect(() => {
    const responseIsPositive =
      value.get('response') === value.get('positive_response');
    if (!responseIsPositive) {
      setIsCommentsVisible(false);
    }
  }, [comments, value]);

  const addNewGap = useCallback(() => {
    onChange({
      target: {
        name,
        index,
        value: value.set(
          'gaps',
          value.get('gaps').push(GapEntity.dataToRecord({})),
        ),
      },
    });
  }, [name, onChange, value, index]);

  const removeGap = useCallback(
    (gapIndex: number) => {
      onChange({
        target: {
          name,
          index,
          value: value.set('gaps', value.get('gaps').remove(gapIndex)),
        },
      });
    },
    [name, onChange, value, index],
  );

  const gaps = value.get('gaps');

  const { fileList, setFileList, batchUploadFilerFileList } = useFilePicker([]);
  const {
    isOpen: isOpenFileModal,
    onOpen: onOpenFileModal,
    onClose: onCloseFileModal,
  } = useDisclosure();

  const handleAnswerAttachmentChange = useCallback(
    (new_attachments: BaselineAssessmentAnswerAttachmentEntityRecord[]) => {
      const retVal = value.withMutations((rec) => {
        let updated_attachments = rec.getIn([
          'attachments',
        ]) as List<BaselineAssessmentAnswerAttachmentEntityRecord>;

        new_attachments.forEach((attachment) => {
          updated_attachments = updated_attachments.push(attachment);
        });

        rec.setIn(['attachments'], updated_attachments);
      });

      props.onChange({
        target: {
          name: name,
          index: index,
          value: retVal,
        },
      });
      onCloseFileModal();
    },
    [value, props, name, index, onCloseFileModal],
  );

  const removeAnswerAttachment = useCallback(
    (attachmentIndex: number) => {
      onChange({
        target: {
          name,
          index,
          value: value.set(
            'attachments',
            value.get('attachments').remove(attachmentIndex),
          ),
        },
      });
    },
    [name, onChange, value, index],
  );

  return {
    getI18nField,
    threeWaySwitchProps,
    toggleShowComments,
    isCommentsVisible,
    addNewGap,
    removeGap,
    gaps,
    fileList,
    setFileList,
    batchUploadFilerFileList,
    isOpenFileModal,
    onOpenFileModal,
    onCloseFileModal,
    handleAnswerAttachmentChange,
    removeAnswerAttachment,
    formProps: {
      name,
      field,
      onChange,
      errors,
      onChangeInput,
      onSubmit,
      value,
      valueInitial,
      index,
      options,
    },
  };
}
