import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  LinkBox,
  LinkOverlay,
  Text,
} from '@chakra-ui/react';

import { RouterLink } from '@burnsred/ui-chakra';
import { type SupportFactorEntityRecord } from 'entities/api/SupportFactor';
import { useLocale } from 'locales/useLocale';
import { createLogger } from 'util/createLogger';

import { cardHeaderSx, cardSx, headingSx } from './SupportFactor.styles';

const _log = createLogger('SupportFactorCard');
export type LinkTargetCallback = (supportfactorUuid: string) => string;

type SupportFactorCardProps = {
  record: SupportFactorEntityRecord;
  linkTarget: string | LinkTargetCallback | null;
};

export const SupportFactorCard = (props: SupportFactorCardProps) => {
  const { toString } = useLocale();
  const { record } = props;

  const linkTarget = props.linkTarget
    ? typeof props.linkTarget == 'function'
      ? props.linkTarget(record.get('uuid'))
      : props.linkTarget?.replace(':supportfactorUuid', record.get('uuid'))
    : '#';

  return (
    <Card as={LinkBox} size="sm" sx={cardSx}>
      <CardHeader sx={cardHeaderSx}>
        <Heading as="h4" sx={headingSx}>
          <LinkOverlay
            as={RouterLink}
            to={linkTarget}
            relative="path"
            isExternal
          >
            {toString(record) || '(Blank)'}
          </LinkOverlay>
        </Heading>
      </CardHeader>
      <CardBody>
        <Text fontSize="sm">{toString(record, 'purpose')}</Text>
      </CardBody>
    </Card>
  );
};
