import { type List } from 'immutable';

import { type InputProps } from '@burnsred/entity-form';
import { type CompliancePlanEntityRecord } from 'entities/api/CompliancePlan/CompliancePlan';
import { type SiteEquipmentOperatingContextEntityRecord } from 'entities/api/CompliancePlan/SiteEquipmentOperatingContext';
import { type OperatingContextEntityRecord } from 'entities/api/OperatingContext';
import RootEquipmentOperationControls from 'screens/compliance/tabs/OperatingEnvironment/tabs/OperatingContext/SiteCompliancePlanForm/Widgets/EquipmentOperationWidget/RootEquipmentOperationControls/RootEquipmentOperationControls.tsx';
import { type EntityDuckAction, type FormChangeEvent } from 'types.ts';

import useEquipmentOperation from './EquipmentOperationWidget.hooks';

export type RootEquipmentOperationWidgetProps =
  InputProps<SiteEquipmentOperatingContextEntityRecord> & {
    compliancePlan: CompliancePlanEntityRecord;
    operatingContexts: List<OperatingContextEntityRecord>;
    name: string;
    onChange: (event: FormChangeEvent) => EntityDuckAction;
  };

export const RootEquipmentOperationWidget = (
  props: RootEquipmentOperationWidgetProps,
) => {
  const { equipmentOperationList, operatingContexts, onChange, name } =
    useEquipmentOperation(props);

  return (
    <RootEquipmentOperationControls
      equipmentOperationList={equipmentOperationList}
      operatingContexts={operatingContexts}
      onChange={onChange}
      name={name}
    />
  );
};
