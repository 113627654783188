import { useEffect } from 'react';

import type { InputProps } from '@burnsred/entity-form';
import type { I18nTextEntityRecord } from 'entities/api/i18n/I18nText';
import I18nTranslationEntity from 'entities/api/i18n/I18nTranslation';
import { localeKeys } from 'locales';
import { createLogger } from 'util/createLogger';

const log = createLogger('useI18nTextWidget');

export const useI18nTextWidget = (
  props: InputProps<I18nTextEntityRecord, false>,
) => {
  const { name, value, onChange } = props;

  // Ensure translation records exist for both English and Spanish
  useEffect(() => {
    let translations = value?.get('translations');

    for (const language of localeKeys) {
      const existingTranslation = translations?.find(
        (t) => t.get('language') === language,
      );
      if (!existingTranslation) {
        translations = translations.push(
          I18nTranslationEntity.dataToRecord({}).set('language', language),
        );
      }
    }

    const isTranslationsChanged =
      value?.get('translations').size !== translations.size;

    if (isTranslationsChanged)
      onChange({
        target: {
          name,
          value: value.set('translations', translations),
        },
      });
  }, [name, onChange, value]);

  log('%o', props);
};
