import { type List } from 'immutable';
import { useCallback, useState } from 'react';

import { Paginator, VStack } from 'components';
import { type ApplicableRuleEntityRecord } from 'entities/api/ApplicableRule';

import { GlobalRule } from '../Rule/Rule';

type GlobalControlRulesProps = {
  value: List<ApplicableRuleEntityRecord>;
  totalItems: number;
  initialPage: number;
  pageSize: number;
  onPageChange: (page: number, pageSize: number) => void;
};

export function GlobalControlRules(props: GlobalControlRulesProps) {
  const { value, totalItems, initialPage, pageSize, onPageChange } = props;

  const [currentPage, setCurrentPage] = useState(initialPage);
  const [currentPageSize, setCurrentPageSize] = useState(pageSize);

  const handlePageChange = useCallback(
    (paginatorState: { page: number; page_size: number }) => {
      setCurrentPage(paginatorState.page);
      setCurrentPageSize(paginatorState.page_size);
      onPageChange(paginatorState.page, paginatorState.page_size);
    },
    [onPageChange],
  );

  return (
    <VStack>
      {value?.map((val, idx) => (
        <GlobalRule
          key={val.get('uuid')}
          rule={val}
          headingSuffix={(currentPage - 1) * currentPageSize + idx + 1} // ✅ Corrected Indexing for Rule Number
        />
      ))}

      <Paginator
        page={currentPage}
        page_size={currentPageSize}
        totalItems={totalItems}
        onChange={handlePageChange}
        pageSizeOptions={[20]}
      />
    </VStack>
  );
}
