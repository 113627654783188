import type { SystemStyleObject } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { Stats, statsSx } from 'screens/compliance/components/Stats';

import { useActionPlanStats } from './ActionPlanStats.hooks.tsx';

const sx: SystemStyleObject = {
  '.chakra-stat': {
    // @ts-expect-error expression of type '".chakra-stat"' can't be used to index
    ...statsSx['.chakra-stat'],
    flexGrow: 1,
  },
};

export const ActionPlanStats = () => {
  const {
    control_total,
    controls_with_gaps_total,
    gap_total,
    action_plan_total,
    latest_completion_date,
  } = useActionPlanStats();

  return (
    <Stats
      sx={sx}
      stats={[
        {
          label: (
            <FormattedMessage
              id="ActionPlanStats.label.controls"
              defaultMessage="Controls"
            />
          ),
          value: control_total,
        },
        {
          label: (
            <FormattedMessage
              id="ActionPlanStats.label.controls-with-gaps"
              defaultMessage="Controls with gaps"
            />
          ),
          value: controls_with_gaps_total,
        },
        {
          label: (
            <FormattedMessage
              id="ActionPlanStats.label.gaps"
              defaultMessage="Gaps"
            />
          ),
          value: gap_total,
        },
        {
          label: (
            <FormattedMessage
              id="ActionPlanStats.label.actions"
              defaultMessage="Actions"
            />
          ),
          value: action_plan_total,
        },
        {
          label: (
            <FormattedMessage
              id="ActionPlanStats.label.latest-completion-date"
              defaultMessage="Latest completion date"
            />
          ),
          value: latest_completion_date?.format('DD/MM/YYYY'),
        },
      ]}
    />
  );
};
