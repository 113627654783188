import type { AccordionProps, SystemStyleObject } from '@chakra-ui/react';

export const gapAccordionProps: AccordionProps = {
  defaultIndex: [0],
  allowToggle: true,
  variant: 'outline',

  sx: {
    '&.chakra-accordion': {
      bgColor: 'gray.100',
    },
    '.chakra-accordion__item': {
      border: 0,
    },
    '.chakra-accordion__button': {
      paddingY: 3,
    },
    '.chakra-accordion__panel': {
      // display borderTop here so that there are no borders on the closed Accordion
      borderTop: '1px',
      borderColor: 'gray.300',
    },
  },
};

export const gapForm__headerSx: SystemStyleObject = {
  width: 'full',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 8,
};

export const gapForm__header__titleWrapperSx: SystemStyleObject = {
  alignItems: 'flex-start',
  gap: 0.5,
};

export const gapForm__identifierSx: SystemStyleObject = {
  minWidth: 'fit-content',
  fontSize: 'sm',
};

export const buttonsWrapperSx: SystemStyleObject = {
  width: 'full',
  justifyContent: 'flex-end',
  gap: 3,
};
