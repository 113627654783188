import {
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { Form, Input, type InputProps } from '@burnsred/entity-form';
import { VStack } from 'components';
import { AttachmentModal } from 'components/AttachmentModal';
import { FileItemRow } from 'components/FilePicker';
import { PersonPickerField, Textarea } from 'components/widgets';
import { getAttachmentUrl } from 'entities/api/BaseAttachment';
import { type ActionEntityRecord } from 'entities/api/CompliancePlan/Action';
import ActionAttachmentEntity, {
  type ActionAttachmentEntityRecord,
} from 'entities/api/CompliancePlan/ActionAttachment.ts';
import { useActionFormControls } from 'screens/compliance/tabs/Actions/ActionPlanForControl/ActionForm/ActionForm.hooks.ts';
import { modalButtonProps } from 'screens/compliance/tabs/BaselineAssessment/ControlAssessment/BaselineAssessmentForm/BaselineAssessmentForm.styles';

import {
  actionFormWrapperSx,
  deleteButtonProps,
  descriptionFieldProps,
  personPickerStyles,
  titleProps,
} from './ActionForm.styles';

export type ActionFormFormProps = InputProps<ActionEntityRecord, false> & {
  removeAction: (actionIndex: number) => void;
  onUnlinkAction: (action: ActionEntityRecord) => void;
};

export function ActionForm(props: ActionFormFormProps) {
  const { index, removeAction, value, onUnlinkAction } = props;
  const {
    isOpen: isDeleteActionModalOpen,
    onOpen: onDeleteActionModalOpen,
    onClose: onDeleteActionModalClose,
  } = useDisclosure();

  const deleteActionHandler = () => {
    if (index !== undefined) {
      removeAction(index);
    }
    onDeleteActionModalClose();
  };

  const {
    isOpenFileModal,
    onOpenFileModal,
    onCloseFileModal,
    fileList,
    setFileList,
    removeActionAttachment,
    handleActionAttachmentChange,
    saveAction,
    batchUploadFilerFileList,
    allActionsUuids,
  } = useActionFormControls(props);

  /** @fixme Gets re computed on each render. Can be cached method on entity level */
  const countOfMatchingActions = allActionsUuids.filter((action) => {
    return action === value.get('uuid');
  });
  const isActionLinked = countOfMatchingActions.length > 1;

  return (
    <Fragment>
      <Modal
        isOpen={isDeleteActionModalOpen}
        onClose={onDeleteActionModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage
              id="compliance.actions.action-form.delete"
              defaultMessage="Delete action"
            />
          </ModalHeader>
          <ModalBody>
            <FormattedMessage
              id="compliance.actions.action-form.delete-confirm"
              defaultMessage="Do you want to delete the action?"
            />
          </ModalBody>
          <ModalFooter justifyContent={'space-between'} gap={2}>
            <Button
              {...modalButtonProps}
              variant="whiteOutline"
              onClick={onDeleteActionModalClose}
            >
              <FormattedMessage id="ui.cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              {...modalButtonProps}
              variant="primary"
              onClick={deleteActionHandler}
            >
              <FormattedMessage id="ui.ok" defaultMessage="OK" />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Form {...props}>
        <Grid as="section" className="ActionForm" sx={actionFormWrapperSx}>
          <GridItem colSpan={6}>
            <Input
              name="title"
              componentProps={{
                label: (
                  <FormattedMessage
                    id="compliance.actions.action-form.action-title"
                    defaultMessage="Action title"
                  />
                ),
                ...titleProps,
              }}
            />
          </GridItem>

          <GridItem colSpan={6}>
            <Input
              name="person_responsible"
              component={PersonPickerField}
              componentProps={{
                label: (
                  <FormattedMessage
                    id="compliance.actions.action-form.action-owner"
                    defaultMessage="Action owner"
                  />
                ),
                chakraStyles: personPickerStyles,
              }}
              loadOptionsFromAPI
            />
          </GridItem>

          <GridItem colSpan={2}>
            <Input
              name="start_date"
              componentProps={{
                label: (
                  <FormattedMessage
                    id="compliance.actions.action-form.start-date"
                    defaultMessage="Start date"
                  />
                ),
                size: 'sm',
                maxDate: value?.get('completion_target_date')?.toDate(),
              }}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <Input
              name="completion_target_date"
              componentProps={{
                label: (
                  <FormattedMessage
                    id="compliance.actions.action-form.target-completion-date"
                    defaultMessage="Target completion date"
                  />
                ),
                size: 'sm',
                minDate: value?.get('start_date')?.toDate(),
              }}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <Input
              name="cost_estimate"
              componentProps={{
                label: (
                  <FormattedMessage
                    id="compliance.actions.action-form.cost-estimate"
                    defaultMessage="Cost Estimate (AUD)"
                  />
                ),
                size: 'sm',
              }}
            />
          </GridItem>

          <GridItem colSpan={6} rowSpan={2}>
            <Input
              name="description"
              component={Textarea}
              componentProps={{
                label: (
                  <FormattedMessage
                    id="compliance.actions.action-form.action-description"
                    defaultMessage="Action description"
                  />
                ),
                ...descriptionFieldProps,
              }}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <Input
              name="purchase_order"
              componentProps={{
                label: (
                  <FormattedMessage
                    id="compliance.actions.action-form.po-number"
                    defaultMessage="PO#"
                  />
                ),
                size: 'sm',
              }}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <Input
              name="management_of_change"
              componentProps={{
                label: (
                  <FormattedMessage
                    id="compliance.actions.action-form.moc-number"
                    defaultMessage="MOC#"
                  />
                ),
                size: 'sm',
              }}
            />
          </GridItem>

          <GridItem as={VStack} colSpan={6}>
            {value?.get('attachments')?.size && (
              <VStack gap={0}>
                {value
                  ?.get('attachments')
                  ?.map(
                    (
                      attachment: ActionAttachmentEntityRecord,
                      attachmentIndex: number,
                    ) => (
                      <FileItemRow
                        key={attachmentIndex}
                        canDelete
                        onDelete={() => {
                          index !== undefined &&
                            removeActionAttachment(attachmentIndex);
                        }}
                        name={
                          (attachment?.getIn([
                            'attachment',
                            'name',
                          ]) as string) || 'unnamed'
                        }
                        url={getAttachmentUrl(attachment)}
                      />
                    ),
                  )}
              </VStack>
            )}

            <Button
              onClick={onOpenFileModal}
              variant="whiteOutline"
              size="sm"
              width="fit-content"
            >
              <FormattedMessage
                id="compliance.actions.action-form.attach-media"
                defaultMessage="Attach media"
              />
            </Button>
          </GridItem>

          <GridItem
            colSpan={6}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Button onClick={saveAction} variant="whiteOutline" size="sm">
              <FormattedMessage
                id="compliance.actions.action-form.save"
                defaultMessage="Save"
              />
            </Button>

            {isActionLinked ? (
              <Button
                variant="whiteOutline"
                size="sm"
                onClick={() => onUnlinkAction(value)}
              >
                <FormattedMessage
                  id="compliance.actions.action-form.unlink"
                  defaultMessage="Unlink action"
                />
              </Button>
            ) : (
              <Button {...deleteButtonProps} onClick={onDeleteActionModalOpen}>
                <FormattedMessage
                  id="compliance.actions.action-form.delete"
                  defaultMessage="Delete action"
                />
              </Button>
            )}
          </GridItem>
        </Grid>

        <AttachmentModal
          isOpen={isOpenFileModal}
          onOpen={onOpenFileModal}
          onClose={onCloseFileModal}
          // @ts-expect-error  TS2322: Type (new_attachments: GapAttachmentEntityRecord) => void is not assignable
          onConfirm={handleActionAttachmentChange}
          attachmentEntity={ActionAttachmentEntity}
          fileList={fileList}
          setFileList={setFileList}
          batchUploadFilerFileList={batchUploadFilerFileList}
          maxFiles={10}
        />
      </Form>
    </Fragment>
  );
}
