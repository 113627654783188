import { useCallback, useEffect } from 'react';

import CompliancePlanEntity from 'entities/api/CompliancePlan/CompliancePlan';
import SiteCompliancePlanEntity from 'entities/api/CompliancePlan/SiteCompliancePlan';
import { type SiteEquipmentOperatingContextEntityRecord } from 'entities/api/CompliancePlan/SiteEquipmentOperatingContext';
import { type OperatingContextEntityRecord } from 'entities/api/OperatingContext';

import { type EquipmentOperationWidgetProps } from './EquipmentOperationWidget';

const useEquipmentOperation = (props: EquipmentOperationWidgetProps) => {
  const {
    compliancePlan,
    value: equipmentOperationList,
    name,
    onChange,
    operatingContexts,
  } = props;
  const equipmentTree = CompliancePlanEntity.getEquipmentTree(compliancePlan);

  // Create missing equipment operating contexts
  useEffect(() => {
    const newEquipmentOperationList =
      SiteCompliancePlanEntity.syncEquipmentOperatingContexts(
        compliancePlan,
        equipmentOperationList,
      );
    if (!newEquipmentOperationList.equals(equipmentOperationList)) {
      onChange({
        target: {
          name,
          value: newEquipmentOperationList,
        },
      });
    }
  }, [equipmentOperationList, compliancePlan, name, onChange]);

  const handleEquipmentOperationChange = useCallback(
    (
      equipmentOperation: SiteEquipmentOperatingContextEntityRecord,
      operatingContext: OperatingContextEntityRecord,
    ) => {
      const newEquipmentOperationList =
        SiteCompliancePlanEntity.updateEquipmentOperatingContexts(
          equipmentOperation,
          operatingContext,
          equipmentOperationList,
          equipmentTree,
        );
      if (!newEquipmentOperationList.equals(equipmentOperationList)) {
        onChange({
          target: {
            name,
            value: newEquipmentOperationList,
          },
        });
      }
    },
    [equipmentOperationList, onChange, name, equipmentTree],
  );
  return {
    equipmentTree,
    equipmentOperationList,
    operatingContexts,
    handleEquipmentOperationChange,
    onChange,
    name,
  };
};

export default useEquipmentOperation;
