import { type List } from 'immutable';

import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import DamageEnergy, { type DamageEnergyEntityRecord } from './DamageEnergy';
import FilerFileEntity, { type FilerFileEntityRecord } from './FilerFile.ts';
import I18nTextEntity from './i18n/I18nText';
import { type TitleEntityFields } from './types';

class RiskEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/risk/',
  };

  static fields: EntityFields<RiskEntityFields> = {
    uuid: new Fields.IdField({ blank: true }),

    title: new Fields.EntityField({
      entity: I18nTextEntity,
    }),

    damaging_energies: new Fields.EntityField({
      entity: DamageEnergy,
      many: true,
      blank: true,
    }),
    risk_icon: new Fields.EntityField({
      entity: FilerFileEntity,
      blank: true,
    }),
    global_framework_id: new Fields.CharField(),
  };

  static toString(record: RiskEntityRecord, locale: string) {
    return I18nTextEntity.toString(record.get('title'), locale);
  }

  static getRiskIconPath(record: RiskEntityRecord) {
    return record.getIn(['risk_icon', 'url']) as string;
  }
}

type RiskEntityFields = TitleEntityFields & {
  damaging_energies: List<DamageEnergyEntityRecord>;
  risk_icon: FilerFileEntityRecord;
  global_framework_id: string;
};

export type RiskEntityRecord = EntityRecord<RiskEntityFields>;

RiskEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: RiskEntity,
  name: 'Risk',
});

export default RiskEntity;
