import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import { toString } from '../i18n/I18nText';

/**
 * A CompliancePlanDashboardReportEntity respresents the dashboard report for Compliance Plans.
 */
class CompliancePlanDashboardReportEntity extends Entity {
  static paginated = false;

  static paths = {
    apiBase: '/cube_compliance/v1/reporting/site_complianceplan_report/',
  };

  static fields: EntityFields<CompliancePlanDashboardReportEntityFields> = {
    plans_in_progress: new Fields.NumberField(),
    controls_assessed_count: new Fields.NumberField(),
    controls_assessed_total: new Fields.NumberField(),
    gap_total: new Fields.NumberField(),
    actions_total: new Fields.NumberField(),
  };

  static toString = toString<CompliancePlanDashboardReportEntityRecord>;
}

export type CompliancePlanDashboardReportEntityFields = {
  plans_in_progress: number;
  controls_assessed_count: number;
  controls_assessed_total: number;
  gap_total: number;
  actions_total: number;
};

export type CompliancePlanDashboardReportEntityRecord =
  EntityRecord<CompliancePlanDashboardReportEntityFields>;

CompliancePlanDashboardReportEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: CompliancePlanDashboardReportEntity,
  name: 'CompliancePlan',
});

export default CompliancePlanDashboardReportEntity;
