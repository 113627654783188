import { useMemo } from 'react';
import { useOutletContext } from 'react-router';

import type { PaginatorProps, PaginatorState } from 'components';
import { SupportFactorListFilterEntity, usePagination } from 'entities';
import SupportFactorEntity from 'entities/api/SupportFactor.ts';
import { type SupportFactorListFilterEntityRecord } from 'entities/filter/SupportFactorListFilter.ts';
import { useEntityFilterControls } from 'forms/hooks/filters';
import { useLocale } from 'locales/useLocale';
import { type GlobalFrameworkContext } from 'screens/global-frameworks/GlobalFramework/GlobalFramework.tsx';

export function useSupportFactorsList() {
  const {
    name,
    filterRecord: rawFilterRecord,
    onChange,
    FilterField,
    errors,
  } = useEntityFilterControls<
    typeof SupportFactorListFilterEntity,
    SupportFactorListFilterEntityRecord
  >(
    SupportFactorListFilterEntity,
    'SupportFactorListFilters',
    SupportFactorListFilterEntity.onChangeClean,
  );
  const { formControls } = useOutletContext<GlobalFrameworkContext>();

  const filterRecord = useMemo(
    () =>
      rawFilterRecord.set(
        'for_risk',
        formControls?.value?.getIn(['risk', 'uuid']),
      ),
    [formControls, rawFilterRecord],
  );

  // memoise params to prevent a duplicate API call triggered by useSelector call
  const params = useMemo(
    () => SupportFactorListFilterEntity.asMappedParams(filterRecord),
    [filterRecord],
  );

  const pagination = usePagination(SupportFactorEntity, params);
  const supportFactorCount = pagination?.get('count') || '0';

  const onChangePagination = ({ page, page_size }: PaginatorState) => {
    // note that filterRecord stores page & page_size as strings, and Paginator requires numbers
    const newRecord = filterRecord.withMutations((rec) => {
      rec.set('page', String(page) || '1');
      rec.set('page_size', String(page_size) || '10');
    });
    onChange({
      target: { name, value: newRecord },
    });
  };

  const paginatorProps: PaginatorProps = {
    page: parseInt(filterRecord?.get('page') ?? '1'),
    page_size: parseInt(filterRecord?.get('page_size') ?? 30),
    totalItems: pagination?.get('count') ?? 0,
    onChange: onChangePagination,
    pageSizeOptions: [30, 60],
  };

  const localeContext = useLocale();

  return {
    name,
    filterRecord,
    onChange,
    FilterField,
    errors,
    localeContext,
    params,
    paginatorProps,
    formControls,
    supportFactorCount,
  };
}
