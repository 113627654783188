import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import I18nTextEntity, {
  type I18nTextEntityRecord,
  toString,
} from './i18n/I18nText';
import { type TitleEntityFields } from './types';

class SupportFactorEntity extends Entity {
  static paths = {
    apiBase: '/cube/v1/support_factor/',
  };

  static paginated = true;

  static fields: EntityFields<SupportFactorEntityFields> = {
    uuid: new Fields.IdField(),

    title: new Fields.EntityField({
      entity: I18nTextEntity,
    }),
    purpose: new Fields.EntityField({
      entity: I18nTextEntity,
    }),
    design_std: new Fields.EntityField({
      entity: I18nTextEntity,
    }),
    operating_std: new Fields.EntityField({
      entity: I18nTextEntity,
    }),
    erosion_factors: new Fields.EntityField({
      entity: I18nTextEntity,
    }),
    features: new Fields.EntityField({
      entity: I18nTextEntity,
    }),
  };

  static toString = toString<SupportFactorEntityRecord>;
}

export type SupportFactorEntityFields = TitleEntityFields & {
  purpose: I18nTextEntityRecord;
  design_std: I18nTextEntityRecord;
  operating_std: I18nTextEntityRecord;
  erosion_factors: I18nTextEntityRecord;
  features: I18nTextEntityRecord;
};

export type SupportFactorEntityRecord = EntityRecord<SupportFactorEntityFields>;

SupportFactorEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: SupportFactorEntity,
  name: 'SupportFactor',
});

export default SupportFactorEntity;
