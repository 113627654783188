import { Flex } from '@chakra-ui/react';
import { MdOutlineCheck } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import { vehicleClassHelperBaseFlexSx } from './VehicleClassHelper.styles.tsx';

export const VehicleClassHelper = () => {
  return (
    <Flex sx={vehicleClassHelperBaseFlexSx}>
      <Flex>
        <MdOutlineCheck />
        <FormattedMessage
          id="compliance.tab.operating-environment.tab.scenarios.legend"
          defaultMessage="I have this vehicle class in my plan"
        />
      </Flex>
    </Flex>
  );
};
