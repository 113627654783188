import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  GridItem,
  Heading,
  SimpleGrid,
} from '@chakra-ui/react';
import { type List } from 'immutable';

import { type SiteEquipmentOperatingContextEntityRecord } from 'entities/api/CompliancePlan/SiteEquipmentOperatingContext';
import { type EquipmentEntityFields } from 'entities/api/Equipment';
import { type OperatingContextEntityRecord } from 'entities/api/OperatingContext';
import {
  contentGridItemProps,
  iconGridItemProps,
  mainGridProps,
} from 'screens/compliance/tabs/OperatingEnvironment/tabs/OperatingContext/OperatingContext.styles';
import { type EntityRecord } from 'types';

import EquipmentOperatingNode from '../EquipmentOperatingNode/EquipmentOperatingNode';

export type EquipmentTreeNode = EntityRecord<
  EquipmentEntityFields & {
    relative_level: number;
    children: List<EquipmentTreeNode>;
  }
>;

type EquipmentTreeProps = {
  equipmentTree: List<EquipmentTreeNode>;
  equipmentOperationList: List<SiteEquipmentOperatingContextEntityRecord>;
  operatingContexts: List<OperatingContextEntityRecord>;
  onChange?: (
    equipmentOperation: SiteEquipmentOperatingContextEntityRecord,
    operatingContext: OperatingContextEntityRecord,
  ) => void;
};

export default function EquipmentTree(props: EquipmentTreeProps) {
  const { equipmentTree, equipmentOperationList, operatingContexts, onChange } =
    props;
  return (
    <>
      {equipmentTree.map((equipmentNode) => (
        <Accordion
          defaultIndex={[0]}
          py={'1px'}
          allowToggle={equipmentNode.get('relative_level') < 2}
          key={equipmentNode.get('uuid')}
        >
          <AccordionItem key={equipmentNode.get('uuid')} border="none">
            <Heading>
              <AccordionButton
                py={'7px'}
                px={0}
                sx={{
                  fontWeight:
                    equipmentNode.get('relative_level') < 2 ? 'bold' : 'normal',
                  backgroundColor:
                    equipmentNode.get('relative_level') < 2
                      ? 'gray.100'
                      : 'white',
                  color: 'black',
                  borderBottom:
                    equipmentNode.get('relative_level') < 2
                      ? 'none'
                      : '1px solid #cbd5e0',
                }}
                _hover={{
                  bg:
                    equipmentNode.get('relative_level') < 2
                      ? 'gray.100'
                      : 'inherit',
                }}
              >
                <SimpleGrid {...mainGridProps}>
                  <GridItem {...contentGridItemProps}>
                    <EquipmentOperatingNode
                      equipmentNode={equipmentNode}
                      equipmentOperationList={equipmentOperationList}
                      operatingContexts={operatingContexts}
                      onChange={onChange}
                    />
                  </GridItem>

                  <GridItem {...iconGridItemProps}>
                    {equipmentNode.get('relative_level') < 2 &&
                      !equipmentNode.get('children').isEmpty() && (
                        <AccordionIcon sx={{ color: '#718096' }} />
                      )}
                  </GridItem>
                </SimpleGrid>
              </AccordionButton>
            </Heading>
            {!equipmentNode.get('children').isEmpty() && (
              <AccordionPanel py={0} px={0}>
                <EquipmentTree
                  equipmentTree={equipmentNode.get('children')}
                  equipmentOperationList={equipmentOperationList}
                  operatingContexts={operatingContexts}
                  onChange={onChange}
                />
              </AccordionPanel>
            )}
          </AccordionItem>
        </Accordion>
      ))}
    </>
  );
}
