import { Entity, Fields } from '@burnsred/entity';
import { type BaseAttachmentEntityFields } from 'entities/api/BaseAttachment.ts';
import { BaseAttachment } from 'entities/api/index.ts';
import { type EntityRecord } from 'types.ts';

class ImplementationAttachmentEntity extends Entity {
  static fields = {
    ...BaseAttachment.fields,
    baseline_assessment: new Fields.CharField(),
  };
}

export type ImplementationAttachmentEntityFields =
  BaseAttachmentEntityFields & {
    baseline_assessment: string;
  };

export type ImplementationAttachmentEntityRecord =
  EntityRecord<ImplementationAttachmentEntityFields>;

export default ImplementationAttachmentEntity;
