import { type EquipmentEntityRecord } from 'entities/api/Equipment';

import { ListComponent } from './components/List';
import { Wrapper } from './components/Wrapper';
import { EquipmentPicker, type EquipmentPickerProps } from '../EquipmentPicker';

/** Composes EquipmentPicker with enhanced List styles */
export const TieredEquipmentPicker = <
  EntityRecordType extends EquipmentEntityRecord,
>(
  props: EquipmentPickerProps<EntityRecordType>,
) => (
  // @ts-expect-error Types of parameters 'updater' and 'updater' are incompatible
  <EquipmentPicker<EquipmentEntityRecord>
    wrapperComponent={Wrapper}
    listComponent={ListComponent}
    {...props}
  />
);
