import { useDisclosure, useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

import type { InputProps } from '@burnsred/entity-form';
import { ApplicableControlForPlan } from 'entities';
import { type ActionEntityRecord } from 'entities/api/CompliancePlan/Action.ts';
import type { GapEntityRecord } from 'entities/api/CompliancePlan/Gap.ts';

type useGapFormProps = InputProps<GapEntityRecord> & {
  value: GapEntityRecord;
};

export const useGapForm = (props: useGapFormProps) => {
  const { value, onSubmit, onChange, name, index } = props;
  const linkToActionDisclosure = useDisclosure();

  const toast = useToast();
  const { formatMessage } = useIntl();

  const { controlUuid } = useParams();
  const onLinkAction = (action: ActionEntityRecord) => {
    onSubmit({
      target: {
        name,
        index,
        value: value.set('actions', value.get('actions').push(action)),
      },
    });
    return linkToActionDisclosure.onClose();
  };

  const onUnlinkAction = useCallback(
    (action: ActionEntityRecord) => {
      if (controlUuid && value?.get('uuid') && action?.get('uuid')) {
        ApplicableControlForPlan.unlinkAction(
          controlUuid,
          value.get('uuid'),
          action.get('uuid'),
        )
          .then(() => {
            onChange({
              target: {
                name,
                index,
                value: value.set(
                  'actions',
                  value?.get('actions').filter((record) => record !== action),
                ),
              },
            });
            toast({
              title: formatMessage({
                id: 'compliance.actions.action-form.unlink.success',
                defaultMessage: 'Action unlinked successfully.',
              }),
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
          })
          .catch(() => {
            toast({
              title: formatMessage({
                id: 'compliance.actions.action-form.unlink.error',
                defaultMessage: 'Failed to unlink action.',
              }),
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          });
      }
    },
    [index, name, onChange, value, controlUuid, toast, formatMessage],
  );

  return {
    linkToActionDisclosure,
    onLinkAction,
    onUnlinkAction,
  };
};
