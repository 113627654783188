import { Flex, Progress, Text } from '@chakra-ui/react';

type ComplianceProgressProps = {
  value: number;
  state: string;
};

export const ComplianceProgress = (props: ComplianceProgressProps) => {
  const { value, state } = props;

  const isDeclined = state.toLowerCase() === 'declined';
  return (
    <Flex
      direction="row"
      justifyContent="space-between"
      gap={2}
      alignItems="center"
    >
      <div style={{ width: '100%' }}>
        <Progress
          value={value}
          colorScheme={isDeclined ? 'red' : 'blue'}
          sx={{ bg: '#cbd5e0' }}
        />
      </div>
      <Text minW="31px" align="right">{`${value}%`}</Text>
    </Flex>
  );
};
