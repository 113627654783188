import {
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { Form, Input, type InputProps } from '@burnsred/entity-form';
import { VStack } from 'components';
import { AttachmentModal } from 'components/AttachmentModal';
import { FileItemRow } from 'components/FilePicker';
import { Textarea } from 'components/widgets';
import { getAttachmentUrl } from 'entities/api/BaseAttachment';
import { type GapEntityRecord } from 'entities/api/CompliancePlan/Gap';
import type { GapAttachmentEntityRecord } from 'entities/api/CompliancePlan/GapAttachment';
import GapAttachmentEntity from 'entities/api/CompliancePlan/GapAttachment';

import { useGapFormControls } from './GapForm.hooks';
import {
  buttonProps,
  gridProps,
  identifierFieldSx,
  modalButtonProps,
} from './GapForm.styles';

export type GapFormProps = InputProps<GapEntityRecord> & {
  value: GapEntityRecord;
  removeGap: (gapIndex: number) => void;
};

export default function GapForm(props: GapFormProps) {
  const { index, removeGap, value: gap } = props;
  const {
    isOpen: isDeleteGapModalOpen,
    onOpen: onDeleteGapModalOpen,
    onClose: onDeleteGapModalClose,
  } = useDisclosure();

  const deleteGapHandler = () => {
    if (index !== undefined) {
      removeGap(index);
    }
    onDeleteGapModalClose();
  };

  const {
    isOpenFileModal,
    onOpenFileModal,
    onCloseFileModal,
    fileList,
    setFileList,
    removeGapAttachment,
    handleGapAttachmentChange,
    batchUploadFilerFileList,
  } = useGapFormControls(props);

  return (
    <Fragment>
      <Modal isOpen={isDeleteGapModalOpen} onClose={onDeleteGapModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage
              id="compliance.baseline-assessment.gap-form.delete"
              defaultMessage="Delete Gap"
            />
          </ModalHeader>
          <ModalBody>
            <FormattedMessage
              id="compliance.baseline-assessment.gap-form.delete-confirm"
              defaultMessage="Do you want to delete the gap?"
            />
          </ModalBody>
          <ModalFooter justifyContent={'space-between'} gap={2}>
            <Button
              {...modalButtonProps}
              variant="whiteOutline"
              onClick={onDeleteGapModalClose}
            >
              <FormattedMessage id="ui.cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              {...modalButtonProps}
              variant="primary"
              onClick={deleteGapHandler}
            >
              <FormattedMessage id="ui.ok" defaultMessage="OK" />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Form {...props}>
        <VStack className="GapForm">
          <Grid {...gridProps}>
            <GridItem>
              <Input
                name="title"
                componentProps={{
                  label: (
                    <FormattedMessage
                      id="compliance.baseline-assessment.gap-form.title"
                      defaultMessage="Gap title"
                    />
                  ),
                  size: 'sm',
                }}
              />
            </GridItem>

            <GridItem>
              <Button {...buttonProps} onClick={onDeleteGapModalOpen}>
                <FormattedMessage
                  id="compliance.baseline-assessment.gap-form.delete"
                  defaultMessage="Delete Gap"
                />
              </Button>
            </GridItem>

            <GridItem>
              <Input
                name="identifier"
                componentProps={{
                  disabled: true,
                  label: (
                    <FormattedMessage
                      id="compliance.baseline-assessment.gap-form.identifier"
                      defaultMessage="Gap-ID"
                    />
                  ),
                  sx: identifierFieldSx,
                  size: 'sm',
                }}
              />
            </GridItem>
          </Grid>

          <Grid {...gridProps}>
            <GridItem>
              <Input
                name="description"
                component={Textarea}
                componentProps={{
                  label: (
                    <FormattedMessage
                      id="compliance.baseline-assessment.gap-form.describe"
                      defaultMessage="Describe the identified gap"
                    />
                  ),
                  variant: 'reset',
                  size: 'sm',
                }}
              />
            </GridItem>

            <GridItem>
              <Button {...buttonProps} onClick={onOpenFileModal}>
                <FormattedMessage
                  id="compliance.baseline-assessment.gap-form.attach-media"
                  defaultMessage="Attach media"
                />
              </Button>
            </GridItem>
          </Grid>

          <Grid {...gridProps}>
            <VStack gap={0}>
              {gap
                ?.get('attachments')
                ?.map(
                  (
                    attachment: GapAttachmentEntityRecord,
                    attachmentIndex: number,
                  ) => (
                    <FileItemRow
                      key={attachmentIndex}
                      canDelete
                      onDelete={() => {
                        index !== undefined &&
                          removeGapAttachment(attachmentIndex);
                      }}
                      name={
                        (attachment?.getIn(['attachment', 'name']) as string) ||
                        'unnamed'
                      }
                      url={getAttachmentUrl(attachment)}
                    />
                  ),
                )}
            </VStack>
          </Grid>
        </VStack>

        <AttachmentModal
          isOpen={isOpenFileModal}
          onOpen={onOpenFileModal}
          onClose={onCloseFileModal}
          // @ts-expect-error  TS2322: Type (new_attachments: GapAttachmentEntityRecord) => void is not assignable
          onConfirm={handleGapAttachmentChange}
          attachmentEntity={GapAttachmentEntity}
          fileList={fileList}
          setFileList={setFileList}
          batchUploadFilerFileList={batchUploadFilerFileList}
          maxFiles={10}
        />
      </Form>
    </Fragment>
  );
}
