import { useToast } from '@chakra-ui/react';
import type { AxiosError } from 'axios';
import { type List, Map } from 'immutable';
import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useOutletContext, useParams } from 'react-router';

import { useQuery, withQuery } from '@burnsred/entity-duck-query';
import { Form, Input } from '@burnsred/entity-form';
import { useStickyActionsContext } from 'components/layout';
import ApplicableControlForPlanEntity, {
  type ApplicableControlForPlanEntityRecord,
} from 'entities/api/CompliancePlan/ApplicableControlForPlan';
import type { EquipmentEntityRecord } from 'entities/api/Equipment';
import EquipmentEntity from 'entities/api/Equipment';
import { useSubmitOnUnmount } from 'forms/hooks/submit';
import { ControlSelector } from 'screens/compliance/components/ControlSelector';
import type { WithQueryReturnProps } from 'types';

import { BaselineAssessmentActionForm } from './BaselineAssessmentActionForm';
import { SaveButton } from '../../../components/SaveButton';
import { COMPLIANCE_TABS } from '../../COMPLIANCE_TABS';
import useGetAssessmentMandate from '../../hooks';
import type { ActionsContext } from '../Actions';

export type ActionPlanForControlProps =
  WithQueryReturnProps<ApplicableControlForPlanEntityRecord>;

const ActionPlanForControl = (
  applicableControlsForPlanProps: ActionPlanForControlProps,
) => {
  const toast = useToast();
  const {
    applicableControlsForPlanFormProps: {
      clear: clearApplicableControlsForPlan,
    },
    refreshActionsStats,
    compliancePlanFormProps: { value: compliancePlan },
  } = useOutletContext<ActionsContext>();
  const { name, value, valueInitial, onSubmit } =
    applicableControlsForPlanProps;
  const { hasAssess, hasMandatory } = useGetAssessmentMandate(value);
  const { setChildren } = useStickyActionsContext();
  const { pathname } = useLocation();

  const { formatMessage } = useIntl();

  const { value: equipmentLevel1 } = useQuery<List<EquipmentEntityRecord>>({
    action: EquipmentEntity.duck.actions.get({
      params: Map({
        predicates__rule__risk: compliancePlan?.get('risk')?.get('uuid'),
        level: '1',
      }),
    }),
    persist: true,
  });

  useSubmitOnUnmount<ApplicableControlForPlanEntityRecord>(
    name,
    value,
    valueInitial,
    onSubmit,
    (record) => ApplicableControlForPlanEntity.clean(record),
    (action) => {
      if (action.name == 'save_resolved') {
        toast({
          title: formatMessage({
            id: 'toast.savedAssessment',
            defaultMessage: 'Saved assessment',
          }),
          status: 'success',
          duration: 1000,
          isClosable: true,
        });
        refreshActionsStats();
        clearApplicableControlsForPlan();
      } else {
        toast({
          title: formatMessage({
            id: 'toast.errorSavingAssessment',
            defaultMessage: 'Error saving assessment',
          }),
          status: 'error',
          duration: 1000,
          isClosable: true,
        });
      }
    },
  );

  const saveActions = useCallback(async () => {
    if (value.equals(valueInitial)) return;
    const result = onSubmit({
      target: {
        name: name,
        value: value,
      },
    });
    const promise = await result.promise;
    if (promise.name == 'save_resolved') {
      toast({
        title: formatMessage({
          id: 'toast.saved',
          defaultMessage: 'Saved',
        }),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      refreshActionsStats();
      clearApplicableControlsForPlan();
    } else {
      toast({
        title: 'Error',
        description: (promise?.payload as AxiosError)?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    // skip clearApplicableControlsForPlan; don't ever update on useQueryReturn.clear!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    value,
    valueInitial,
    onSubmit,
    name,
    toast,
    formatMessage,
    refreshActionsStats,
  ]);

  useEffect(() => {
    setChildren([
      <SaveButton key="save-actions-button" onClick={saveActions} />,
    ]);
    // render cautiously, but always on pathname!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, saveActions]);

  return (
    <>
      <ControlSelector
        subTabSegment={COMPLIANCE_TABS.tabPaths.actions.routeName}
        has_gaps={true}
      />

      <Form {...applicableControlsForPlanProps}>
        {hasMandatory && (
          <Input
            name="baseline_assessments"
            component={BaselineAssessmentActionForm}
            componentProps={{
              isForMandatory: true,
              applicableControlsForPlanProps,
              equipmentLevel1,
            }}
            many
          />
        )}
        {hasAssess && (
          <Input
            name="baseline_assessments"
            component={BaselineAssessmentActionForm}
            componentProps={{
              isForMandatory: false,
              applicableControlsForPlanProps,
              equipmentLevel1,
            }}
            many
          />
        )}
      </Form>
    </>
  );
};

export default withQuery(() => {
  const { controlUuid: id } = useParams();
  return {
    action: ApplicableControlForPlanEntity.duck.actions.get({ id }),
    persist: false,
  };
})(ActionPlanForControl);
