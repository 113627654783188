import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import type { GrcOrganisationalUnitEntityFields } from './GrcOrganisationalUnit.ts';
import GrcOrganisationalUnitEntity from './GrcOrganisationalUnit.ts';

class BhpPersonEntity extends Entity {
  static paginated = true;

  static paths = {
    // FIXME IIRC, this should use v2 with no FY data included,
    // but that endpoint doesn't return organisational_unit.ancestors
    apiBase: '/bhp_people/v1/person/',
  };

  static fields: EntityFields<BhpPersonEntityFields> = {
    uuid: new Fields.IdField(),
    user: new Fields.CharField({ blank: true }),
    first_name: new Fields.CharField({ blank: true }),
    last_name: new Fields.CharField({ blank: true }),
    bhp_personnel_id: new Fields.CharField({ blank: true }),
    bhp_personnel_type: new Fields.CharField(),
    bhp_contractor_company_name: new Fields.CharField(),
    bhp_contractor_company_code: new Fields.CharField(),
    bhp_source_system: new Fields.CharField(),
    organisational_unit: new Fields.EntityField({
      blank: true,
      entity: GrcOrganisationalUnitEntity,
    }),
  };

  static toString(record: BHPPersonEntityRecord) {
    return `${record?.get('first_name')} ${record?.get('last_name')}`.trim();
  }
}

export type BhpPersonEntityFields = {
  uuid: string;
  user: string;
  first_name: string;
  last_name: string;
  bhp_personnel_id: string;
  bhp_personnel_type: string;
  bhp_contractor_company_name: string;
  bhp_contractor_company_code: string;
  bhp_source_system: string;
  organisational_unit: GrcOrganisationalUnitEntityFields;
};

export type BHPPersonEntityRecord = EntityRecord<BhpPersonEntityFields>;

BhpPersonEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: BhpPersonEntity,
  name: 'BhpPerson',
});

export default BhpPersonEntity;
