import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { VStack } from 'components';
import { Autocomplete } from 'components/Autocomplete';
import type { ActionEntityRecord } from 'entities/api/CompliancePlan/Action.ts';

import { useLinkToActionModal } from './LinkToActionModal.hooks.ts';
import {
  modalBodySx,
  modalFooterSx,
  modalHeaderSx,
} from './LinkToActionModal.styles.tsx';

type LinkToActionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  onLinkAction: (action: ActionEntityRecord) => void;
  gapUuid: string;
};

export const LinkToActionModal = (props: LinkToActionModalProps) => {
  const { isOpen, onClose, onLinkAction, gapUuid } = props;
  const { linkableActions, linkAction, onActionSelection } =
    useLinkToActionModal({
      onLinkAction,
      gapUuid,
    });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader sx={modalHeaderSx}>
          <FormattedMessage
            id="ui.compliance.linkToAction"
            defaultMessage="Link to an existing action"
          />
        </ModalHeader>
        <ModalBody sx={modalBodySx}>
          <VStack>
            <FormControl className="LinkToActionModal">
              <FormLabel>
                <FormattedMessage
                  id={'ui.compliance.linkToAction.formlabel'}
                  defaultMessage={'Action title'}
                />
              </FormLabel>
              <Autocomplete
                onChange={onActionSelection}
                options={linkableActions}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter sx={modalFooterSx}>
          <Button variant="outline" onClick={onClose}>
            <FormattedMessage id={'ui.cancel'} defaultMessage={'Cancel'} />
          </Button>
          <Button variant="primary" onClick={() => linkAction()}>
            <FormattedMessage
              id={'ui.compliance.linkToAction.button'}
              defaultMessage={'Link To Action'}
            />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
