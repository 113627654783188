import { type List, Map } from 'immutable';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router';

import type { EquipmentEntityRecord } from 'entities/api/Equipment';
import EquipmentEntity from 'entities/api/Equipment';
import OperatingContextEntity, {
  type OperatingContextEntityRecord,
} from 'entities/api/OperatingContext';
import PersonEntity, { useCurrentUser } from 'entities/api/Person/Person';
import type { ScenarioEntityRecord } from 'entities/api/Scenario';
import ScenarioEntity from 'entities/api/Scenario';
import GlobalVisualisationFilterEntity, {
  type GlobalVisualisationFilterEntityRecord,
} from 'entities/filter/GlobalVisualisationFilter';
import { useEntityFilterControls } from 'forms/hooks/filters';
import { useLocale } from 'locales/useLocale';

import { controlHrefCb as _controlHrefCb } from '../GlobalFramework/GlobalFramework';
import { type GlobalFrameworkContext } from '../GlobalFrameworks';

export function useControlVisualisationControls() {
  const { framework } = useOutletContext<GlobalFrameworkContext>();
  const { toString } = useLocale();
  const { name, filterRecord, onChange, FilterField, errors } =
    useEntityFilterControls<
      typeof GlobalVisualisationFilterEntity,
      GlobalVisualisationFilterEntityRecord
    >(
      GlobalVisualisationFilterEntity,
      'GlobalDashboardFilters',
      GlobalVisualisationFilterEntity.onChangeClean,
    );
  const riskId = useMemo(() => {
    return framework?.get('risk')?.get('uuid') ?? '';
  }, [framework]);
  const params = useMemo(() => {
    return GlobalVisualisationFilterEntity.asMappedParams(filterRecord).set(
      'applicable_rules__risk',
      riskId,
    );
  }, [filterRecord, riskId]);
  // // Clear the filters if the framework change
  useEffect(() => {
    if (riskId) {
      onChange({
        target: {
          name: name,
          value: GlobalVisualisationFilterEntity.dataToRecord({}),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskId]);
  const user = useCurrentUser();

  /** Initial options equipment for the 'Equipment L1 filter' */
  const equipmentLevel1 = useSelector<unknown, List<EquipmentEntityRecord>>(
    (state) =>
      EquipmentEntity.duck.selectors.record(state, {
        params: Map({
          predicates__rule__risk: riskId || undefined,
          level: '1',
        }),
      }),
  );

  /** Initial options equipment for the 'Equipment L2 filter' */
  const equipmentLevel2 = useSelector<unknown, List<EquipmentEntityRecord>>(
    (state) =>
      EquipmentEntity.duck.selectors.record(state, {
        params: Map({
          predicates__rule__risk: riskId || undefined,
          // note: in order get the initial options don't look up state indexed by the parents__in param!
          // parents__in: GlobalVisualisationFilterEntity.getUuidList(
          //   filterRecord?.get('equipment_l1'),
          // ),
          level: '2',
        }),
      }),
  );

  /** Initial options for the 'Operating Context filter' */
  const operatingContexts = useSelector<
    unknown,
    List<OperatingContextEntityRecord>
  >((state) =>
    OperatingContextEntity.duck.selectors.record(state, {
      params: Map({
        globalframework__risk: riskId || undefined,
        predicates__rule__risk: riskId || undefined,
      }),
    }),
  );

  /**
   * Initial options for the 'Scenario filter'
   * @REVIEW will we hit pagination problems later, when using this in ControlPreview?
   */
  const scenarios = useSelector<unknown, List<ScenarioEntityRecord>>((state) =>
    ScenarioEntity.duck.selectors.record(state, {
      params: Map({
        page_size: '30',
        ordering: 'mapping_id',
        damage_energy_mechanism__damage_energy__risk: riskId || undefined,
        damage_energy_mechanism__in:
          GlobalVisualisationFilterEntity.getUuidList(
            filterRecord?.get('scenarios__damage_energy_mechanism'),
          ),
      }),
    }),
  );

  const controlHrefCb = useCallback(
    (controlUuid: string) => {
      return _controlHrefCb(framework?.get('uuid'), controlUuid);
    },
    [framework],
  );

  return {
    toString,
    name,
    filterRecord,
    onChange,
    FilterField,
    errors,
    params,
    framework,
    riskId,
    isPublicUser: PersonEntity.isPublicUser(user),
    equipmentLevel1,
    equipmentLevel2,
    operatingContexts,
    scenarios,
    controlHrefCb,
  };
}

export type UseControlVisualisationControlsReturn = ReturnType<
  typeof useControlVisualisationControls
>;
