import { defineStyleConfig } from '@chakra-ui/react';

/**
 * Theme largely inherits from Input
 *
 * @see https://v2.chakra-ui.com/docs/components/textarea/usage
 * @see https://github.com/chakra-ui/chakra-ui/blob/%40chakra-ui/react%402.4.2/packages/components/theme/src/components/textarea.ts
 */
export const textareaTheme = defineStyleConfig({
  // base: {},

  variants: {
    /** opt out of our default variant */
    reset: {
      border: '1px',
      borderColor: 'border',
    },
  },

  defaultProps: {
    size: 'md',
    // @ts-expect-error filled is defined in the upstream styles
    variant: 'filled',
  },
});
