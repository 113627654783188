import { Flex, GridItem, Heading, Text } from '@chakra-ui/react';
import { type List, Map, fromJS } from 'immutable';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useOutletContext } from 'react-router';

import { withQuery } from '@burnsred/entity-duck-query';
import { VStack } from 'components';
import { RAOPicker } from 'components/widgets';
import type { GrcOrganisationalUnitEntityRecord } from 'entities/api/Person/GrcOrganisationalUnit';
import SiteAssociationEntity, {
  type SiteAssociationEntityRecord,
} from 'entities/api/SiteAssociation';
import { Fieldset } from 'forms/fields';
import { useLocale } from 'locales/useLocale';
import type { EntityFormProps, WithQueryReturnProps } from 'types';
import { createLogger } from 'util/createLogger';

import { SiteAssociationRow } from './components/SiteAssociationRow';
import type { FrameworkOutletContext } from '../ControlFramework';

const log = createLogger('ControlFrameworkLocalisation');

function ControlFrameworkLocalisation({
  value: siteAssociations,
  siteFramework,
}: WithQueryReturnProps<List<SiteAssociationEntityRecord>> &
  FrameworkOutletContext) {
  const { toString } = useLocale();

  log('%o', { siteFramework, siteAssociations });

  const sites = siteAssociations?.map((sa) =>
    sa.get('site'),
  ) as unknown as List<GrcOrganisationalUnitEntityRecord>;

  // TODO in future, use form.onChange
  const [selected, setSelected] = useState<
    List<GrcOrganisationalUnitEntityRecord>
  >(sites ?? fromJS([]));

  // @ts-expect-error [Return Type] 'void' is not assignable to type 'EntityDuckAction'
  const onChange: EntityFormProps<GrcOrganisationalUnitEntityRecord>['onChange'] =
    (event) => {
      const newValue = event?.target
        ?.value as List<GrcOrganisationalUnitEntityRecord>;

      // _field.onChange(asEvent({ name, value }));
      setSelected(newValue);
    };

  return (
    <VStack gap={2}>
      <Flex layerStyle="cardLike">
        <Heading as="h3" size="sm" variant="primary">
          {toString(siteFramework)}
        </Heading>

        <VStack gap={1}>
          <Text fontSize="sm" fontWeight="bold">
            <FormattedMessage
              id="control-framework.tabs.localisation.select-operations"
              defaultMessage="Select the operation(s) you nominate for this control framework"
            />
          </Text>

          <Text fontSize="xs" color="muted">
            <FormattedMessage
              id="control-framework.tabs.localisation.select-operations-help-text"
              defaultMessage="You can select more than one operation."
            />
          </Text>
        </VStack>
      </Flex>

      {/* TODO render as an Input */}
      <RAOPicker name="FIXME" value={selected} onChange={onChange} isDisabled />

      <Flex layerStyle="cardLike">
        <Heading as="h3" size="sm">
          <FormattedMessage
            id="control-framework.tabs.localisation.heading.risk-owners"
            defaultMessage="Risk owners for operations"
          />
        </Heading>

        <Fieldset>
          {siteAssociations?.count() ? (
            siteAssociations.map((siteAssociation, i) => (
              <SiteAssociationRow key={i} siteAssociation={siteAssociation} />
            ))
          ) : (
            <GridItem colSpan={12}>
              <FormattedMessage
                id="control-framework.tabs.localisation.empty-text"
                defaultMessage="Assign to an Asset or Operation to assign risk owners"
              />
            </GridItem>
          )}
        </Fieldset>
      </Flex>
    </VStack>
  );
}

export default withQuery(() => {
  const { siteFramework } = useOutletContext<FrameworkOutletContext>();

  return {
    action: SiteAssociationEntity.duck.actions.get({
      params: Map({
        site_framework: siteFramework?.get('uuid'),
      }),
    }),
  };
})(ControlFrameworkLocalisation);
