import {
  Flex,
  LinkBox,
  LinkOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Loading, RouterLink } from '@burnsred/ui-chakra';
import { VStack } from 'components';
import { MandatoryAssessBadge } from 'components/MandatoryAssessBadges';
import ApplicableControlForPlanEntity from 'entities/api/CompliancePlan/ApplicableControlForPlan.ts';
import { type ApplicableControlForPlanListEntityRecord } from 'entities/api/CompliancePlan/ApplicableControlForPlanListOnly.ts';
import { useLocale } from 'locales/useLocale.tsx';
import { cx } from 'util/cx';

import {
  mandatoryAssessLegendSx,
  tableSx,
} from './ActionControlListTable.styles';

type ActionControlListTable = {
  children?: ReactNode;
  count?: number;
  isLoading: boolean;
};

// TODO: Replace with actual status of Actions
// Constants and getStatusColor are temporary
const NOT_STARTED = 'Not started';
const IN_PROGRESS = 'In progress';
const TO_REVIEW = 'To review';

const getStatusColor = (status: string) => {
  switch (status) {
    case NOT_STARTED:
      return '#e65400';
    case IN_PROGRESS:
      return '#0057ff';
    case TO_REVIEW:
      return '#39b54a';
    default:
      return 'black';
  }
};

export const ActionControlListTable = (props: ActionControlListTable) => {
  const { children, count, isLoading } = props;

  if (isLoading)
    return (
      <VStack layerStyle="loadingWrapper">
        <Loading />
      </VStack>
    );

  return (
    <TableContainer>
      <Table variant="simple" size="sm" sx={tableSx}>
        <Thead>
          <Tr>
            <Th colSpan={2} width="60%">
              <FormattedMessage
                id="compliance.actions.control-list.table.header.controls"
                defaultMessage="Controls ({count})"
                values={{ count }}
              />
            </Th>
            <Th className="center">
              <FormattedMessage
                id="compliance.actions.control-list.table.header.gaps"
                defaultMessage="Gaps"
              />
            </Th>
            <Th className="center">
              <FormattedMessage
                id="compliance.actions.control-list.table.header.actions"
                defaultMessage="Actions"
              />
            </Th>
            <Th className="center">
              <FormattedMessage
                id="compliance.actions.control-list.table.header.implementation"
                defaultMessage="Implementation"
              />
            </Th>
          </Tr>
        </Thead>

        <Tbody>{children}</Tbody>
      </Table>

      <Flex sx={mandatoryAssessLegendSx}>
        <Flex>
          <Flex>
            <MandatoryAssessBadge type={'mandatory'} />
            <FormattedMessage
              id="compliance.baselineassessment.control-list.table.legend.mandatory"
              defaultMessage="Mandatory control"
            />
          </Flex>
          <Flex>
            <MandatoryAssessBadge type={'assess'} />
            <FormattedMessage
              id="compliance.baselineassessment.control-list.table.legend.assess"
              defaultMessage="Assess control"
            />
          </Flex>
        </Flex>
      </Flex>
    </TableContainer>
  );
};

type ActionControlListRowProps = {
  record: ApplicableControlForPlanListEntityRecord;
};

export const ActionControlListRow = ({ record }: ActionControlListRowProps) => {
  const globalControl = record.get('global_control');
  const { toString, getI18nField } = useLocale();

  const hasMandatory =
    // @ts-expect-error: works anyway
    ApplicableControlForPlanEntity.shouldHaveMandatory(record);
  // @ts-expect-error: works anyway
  const hasAssess = ApplicableControlForPlanEntity.shouldHaveAssess(record);

  return (
    <LinkBox
      as={Tr}
      key={record.get('uuid')}
      className={cx(
        record?.get('has_some_not_implemented_baseline_assessment') &&
          'has-not-implemented',
      )}
      sx={{
        '&.has-not-implemented': { color: 'error' },
      }}
    >
      <Td verticalAlign="middle">
        <Flex gap={1} maxWidth="50px">
          {hasMandatory && <MandatoryAssessBadge type="mandatory" />}
          {hasAssess && <MandatoryAssessBadge type="assess" />}
        </Flex>
      </Td>
      <Td className="left">
        <LinkOverlay
          as={RouterLink}
          to={`control/${record.get('uuid')}`}
          relative="route"
        >
          {toString(globalControl)}
        </LinkOverlay>
      </Td>
      <Td className="center">
        {record.get('gaps') !== 0 ? record.get('gaps') : '-'}
      </Td>
      <Td className="center">
        {record.get('actions') !== 0 ? record.get('actions') : '-'}
      </Td>
      <Td className="center">
        {/*TODO: Replace when actual Status of Actions is available*/}
        {/* Temporary Status Work */}
        <Text
          sx={{
            color: getStatusColor(record.get('action_status_en')),
            fontWeight: 'bold',
            textTransform: 'uppercase',
            fontSize: 'xs',
          }}
        >
          {getI18nField(record, 'action_status')}
        </Text>
      </Td>
    </LinkBox>
  );
};
