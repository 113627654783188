import { type List } from 'immutable';
import type { Moment } from 'moment';

import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import ActionAttachmentEntity, {
  type ActionAttachmentEntityRecord,
} from 'entities/api/CompliancePlan/ActionAttachment.ts';
import { type EntityFields, type EntityRecord } from 'types';

import BhpPersonEntity, {
  type BHPPersonEntityRecord,
} from '../Person/BhpPerson';

class ActionEntity extends Entity {
  static paginated = false;

  static paths = {
    apiBase: '/cube_compliance/v1/actions/',
  };

  static fields: EntityFields<ActionEntityFields> = {
    uuid: new Fields.IdField(),
    title: new Fields.CharField(),
    description: new Fields.TextField(),
    person_responsible: new Fields.EntityField({
      entity: BhpPersonEntity,
      default: null,
    }),
    start_date: new Fields.DateTimeField({
      blank: true,
    }),
    completion_target_date: new Fields.DateTimeField(),
    cost_estimate: new Fields.NumberField({ default: 0, blank: true }),
    purchase_order: new Fields.CharField({ blank: true }),
    management_of_change: new Fields.CharField({ blank: true }),
    attachments: new Fields.EntityField({
      entity: ActionAttachmentEntity,
      many: true,
    }),
  };
}

export type ActionEntityFields = {
  uuid: string;
  title: string;
  description: string;
  person_responsible: BHPPersonEntityRecord;
  start_date: Moment;
  completion_target_date: Moment;
  cost_estimate: number;
  purchase_order: string;
  management_of_change: string;
  attachments: List<ActionAttachmentEntityRecord>;
};

export type ActionEntityRecord = EntityRecord<ActionEntityFields>;

ActionEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: ActionEntity,
  name: 'GapAction',
});

export default ActionEntity;
