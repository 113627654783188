import { useDisclosure } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import CubeAppEntity, { type CubeAppEntityRecord } from 'entities/CubeApp.ts';

export function useAppErrorModalControls() {
  const dispatch = useDispatch();
  const state: CubeAppEntityRecord = useSelector((state) =>
    CubeAppEntity.duck.selectors.record(state, {
      id: null,
      dirty: true,
    }),
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { formatMessage } = useIntl();

  return {
    state,
    dispatch,
    isOpen,
    onOpen,
    onClose,
    formatMessage,
  };
}
