import {
  LinkBox,
  LinkOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { type ReactNode } from 'react';

import { RouterLink } from '@burnsred/ui-chakra';
import type { SiteCompliancePlanRowEntityRecord } from 'entities/api/CompliancePlan/SiteCompliancePlanRow.ts';
import { useLocale } from 'locales/useLocale.tsx';

import { tableSx } from './CompliancePlanTable.styles';
import { type CompliancePlanTableColumn } from '../../CompliancePlanTableHeaders.tsx';
import { CompliancePlanComments } from '../CompliancePlanComments';
import { ComplianceProgress } from '../ComplianceProgress';

const DECLINED = 'Declined';

type CompliancePlanTableProps = {
  children?: ReactNode;
  tableColumns: CompliancePlanTableColumn[];
};

export const CompliancePlanTable = (props: CompliancePlanTableProps) => {
  const { children, tableColumns } = props;

  return (
    <TableContainer>
      <Table variant="simple" size="sm" sx={tableSx}>
        <Thead>
          <Tr>
            {/* these percentages are proportional... no need to add up to 100 YOLO! */}
            {tableColumns.map((column, index) => (
              <Th key={index} width={column.width} className={column.className}>
                {column.name}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
      </Table>
    </TableContainer>
  );
};

type CompliancePlanTableRowProps = {
  record: SiteCompliancePlanRowEntityRecord;
};

export const MyLatestCompliancePlanTableRow = ({
  record,
}: CompliancePlanTableRowProps) => {
  const planIsDeclined = record.get('status_en') === DECLINED;
  const declinedStyle = { color: planIsDeclined ? '#e53e3e' : 'inherit' };
  const { getI18nField } = useLocale();
  return (
    <LinkBox as={Tr} key={record.get('uuid')}>
      <Td className="left" sx={declinedStyle}>
        <LinkOverlay
          as={RouterLink}
          to={record.get('plan_uuid')}
          relative="route"
        >
          {record.get('name')}
        </LinkOverlay>
      </Td>
      <Td className="left">{record.get('operation')}</Td>

      <Td className="right">{record.get('controls')}</Td>
      <Td className="right">{record.get('gaps')}</Td>
      <Td className="right">{record.get('actions')}</Td>
      <Td></Td>
      <Td className="left">{record.get('created').format('DD/MM/YYYY')}</Td>
      <Td className="left" sx={declinedStyle}>
        {getI18nField(record, 'status')}
      </Td>
      <Td>
        <CompliancePlanComments count={0} />
      </Td>
      <Td className="left">
        <ComplianceProgress
          value={record.get('assessment_complete_percentage')}
          state={record.get('status_en')}
        />
      </Td>
    </LinkBox>
  );
};
