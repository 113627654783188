import { Button, Flex, Heading, Text, useDisclosure } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { Form, Input } from '@burnsred/entity-form';
import { Loading } from '@burnsred/ui-chakra';
import { VStack } from 'components';
import { TieredEquipmentPicker } from 'components/widgets/TieredEquipmentPicker';
import { useLocale } from 'locales/useLocale.tsx';

import { NewEquipmentModal } from './components/NewEquipmentModal';
import { useCompliancePlanEquipment } from './Equipment.hooks';
import { headingSx, subheadingSx } from './Equipment.styles';

export const Equipment = () => {
  const { toString } = useLocale();
  const {
    parentProps,
    baseParams,
    baseParent,
    tieredEquipmentProps,
    errors,
    refreshTieredEquipmentPicker,
  } = useCompliancePlanEquipment();
  const compliancePlan = parentProps.value;
  const modalDisclosure = useDisclosure();

  return (
    <VStack>
      <Form {...parentProps}>
        <VStack gap={2}>
          <Heading size="sm" sx={headingSx}>
            {compliancePlan?.get('name') || (
              <FormattedMessage
                id="compliance.operating-environment.equipment.new-compliance-plan"
                defaultMessage="New compliance plan"
              />
            )}
          </Heading>

          <Text size="xs" sx={subheadingSx}>
            {toString(compliancePlan.get('risk'))}
          </Text>
        </VStack>

        <Flex direction="row" justifyContent="space-between">
          <Heading size="xs" sx={{ paddingTop: 5, paddingBottom: 1 }}>
            <FormattedMessage
              id="compliance.operating-environment.equipment.vehicle-category"
              defaultMessage="Vehicle Category"
            />
          </Heading>

          <Button variant="outline" onClick={modalDisclosure.onOpen}>
            <FormattedMessage
              id="compliance.operating-environment.equipment.add-local-vehicle"
              defaultMessage="Add a local vehicle"
            />
          </Button>
        </Flex>

        <VStack layerStyle="loadingWrapper">
          {baseParent ? (
            <VStack gap={2}>
              <Input
                name="complianceplanbaseequipment_set"
                component={TieredEquipmentPicker}
                componentProps={{ ...tieredEquipmentProps, cachedQuery: false }}
              />
              {errors && (
                // not using FormErrorMessage here because all checkboxes get highlighted, which is confusing
                <Text color="error" fontSize="sm">
                  {errors}
                </Text>
              )}
            </VStack>
          ) : (
            <Loading />
          )}
        </VStack>
      </Form>

      <NewEquipmentModal
        disclosure={modalDisclosure}
        compliancePlan={compliancePlan}
        equipmentPickerBaseParams={baseParams}
        refreshTieredEquipmentPicker={refreshTieredEquipmentPicker}
      />
    </VStack>
  );
};
