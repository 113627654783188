import { List } from 'immutable';

import { Entity, Fields } from '@burnsred/entity';
import ImplementationAttachmentEntity, {
  type ImplementationAttachmentEntityRecord,
} from 'entities/api/CompliancePlan/ImplementationAttachment.ts';
import { type EntityFields, type EntityRecord } from 'types';

import BaselineAssessmentAnswerEntity, {
  type BaselineAssessmentAnswerEntityRecord,
} from './BaselineAssessmentAnswer';
import { type BaselineAssessmentQuestionEntityRecord } from './BaselineAssessmentQuestion';
import SiteEquipmentOperatingContextEntity, {
  type SiteEquipmentOperatingContextEntityRecord,
} from './SiteEquipmentOperatingContext';
import { createImmutableSelector } from '../abstract';
import { toString } from '../i18n/I18nText';
import OperatingContextEntity, {
  type OperatingContextEntityRecord,
} from '../OperatingContext';

export const IMPLEMENTED = 'I';
export const PARTIALLY_IMPLEMENTED = 'PI';
export const NOT_IMPLEMENTED = 'NI';
export const NOT_TO_BE_IMPLEMENTED = 'NTBI';
export const BLANK = '';
export const OUTCOMES = [
  IMPLEMENTED,
  PARTIALLY_IMPLEMENTED,
  NOT_IMPLEMENTED,
  NOT_TO_BE_IMPLEMENTED,
  BLANK,
] as const;

export const REQUESTED = 'R';
export const CONTROL_SCOPE = 'CS';
export const IMPLENTATION_REQUIREMENTS = 'IR';

export const EXEMPTION_REASON_CHOICES = [
  REQUESTED,
  CONTROL_SCOPE,
  IMPLENTATION_REQUIREMENTS,
] as const;

export type ExemptionReasonChoices =
  | typeof REQUESTED
  | typeof CONTROL_SCOPE
  | typeof IMPLENTATION_REQUIREMENTS;

export type BaselineAssessmentOutcomes = (typeof OUTCOMES)[number];

class BaselineAssessmentEntity extends Entity {
  static fields: EntityFields<BaselineAssessmentEntityFields> = {
    uuid: new Fields.IdField({ blank: true }),
    operating_contexts: new Fields.EntityField({
      entity: OperatingContextEntity,
      many: true,
    }),

    equipment: new Fields.EntityField({
      entity: SiteEquipmentOperatingContextEntity,
      many: true,
    }),
    answers: new Fields.EntityField({
      entity: BaselineAssessmentAnswerEntity,
      cleaners: [
        (record: BaselineAssessmentAnswerEntityRecord) =>
          BaselineAssessmentAnswerEntity.clean(record),
      ],
      many: true,
      blank: true,
    }),
    is_control_being_implemented: new Fields.BooleanField({ blank: true }),
    is_mandatory: new Fields.BooleanField({ default: true }),
    non_implementation_reason: new Fields.CharField({ blank: true }),
    exemption_reason: new Fields.CharField({ default: REQUESTED }),
    outcome: new Fields.CharField({ blank: true, default: '' }),
    outcome_display_en: new Fields.CharField({ blank: true }),
    outcome_display_es: new Fields.CharField({ blank: true }),
    attachments: new Fields.EntityField({
      entity: ImplementationAttachmentEntity,
      many: true,
    }),
  };

  static toString = toString<BaselineAssessmentEntityRecord>;

  static getEquipmentOptions = createImmutableSelector(
    [
      (
        allOptions: List<SiteEquipmentOperatingContextEntityRecord>,
        _missingOptions: List<SiteEquipmentOperatingContextEntityRecord>,
      ) => allOptions,
      (
        _allOptions: List<SiteEquipmentOperatingContextEntityRecord>,
        missingOptions: List<SiteEquipmentOperatingContextEntityRecord>,
      ) => missingOptions,
    ],
    (allOptions, missingOptions) => {
      return allOptions.filter(
        (options) =>
          !missingOptions
            .filter(
              (missingOption) =>
                missingOption.get('uuid') === options.get('uuid'),
            )
            .isEmpty(),
      );
    },
  );

  static getOperatingContexts = createImmutableSelector(
    [
      (chosenEquipment: List<SiteEquipmentOperatingContextEntityRecord>) =>
        chosenEquipment,
    ],
    (chosenEquipment) => {
      return chosenEquipment
        .reduce((acc, equipment) => {
          equipment.get('operating_contexts').forEach((operatingContext) => {
            acc = acc.push(operatingContext);
          });
          return acc;
        }, List<OperatingContextEntityRecord>())
        .toSet()
        .toList();
    },
  );

  static getOperatingContextsForEquipment = createImmutableSelector(
    [
      (
        allOptions: List<SiteEquipmentOperatingContextEntityRecord>,
        _missingOptions: List<SiteEquipmentOperatingContextEntityRecord>,
      ) => allOptions,
      (
        _allOptions: List<SiteEquipmentOperatingContextEntityRecord>,
        missingOptions: List<SiteEquipmentOperatingContextEntityRecord>,
      ) => missingOptions,
    ],
    (allOptions, missingOptions) => {
      return this.getOperatingContexts(
        missingOptions.filter(
          (option) =>
            !allOptions
              .filter((equip) => equip.get('uuid') === option.get('uuid'))
              .isEmpty(),
        ),
      );
    },
  );
  static createQuestionAnswers(
    record: BaselineAssessmentEntityRecord,
    questions: List<BaselineAssessmentQuestionEntityRecord>,
  ) {
    // Do this better, check if the questions are already set, check if different, check any need removal
    // This is a naive implementation @Fixme
    return record.set(
      'answers',
      questions.map((question) =>
        BaselineAssessmentAnswerEntity.fromQuestion(question),
      ),
    );
  }

  static updateOutcome(record: BaselineAssessmentEntityRecord) {
    /* Do not refactor to !record.get() as it can be null */
    if (record.get('is_control_being_implemented') === false) {
      return record.set('outcome', NOT_TO_BE_IMPLEMENTED);
    }

    const answers = record.get('answers');

    if (
      answers.every(
        (answer) => answer.get('response') !== answer.get('positive_response'),
      )
    ) {
      return record.set('outcome', NOT_IMPLEMENTED);
    }

    if (
      answers.every(
        (answer) => answer.get('response') === answer.get('positive_response'),
      )
    ) {
      return record.set('outcome', IMPLEMENTED);
    }

    if (
      answers.some(
        (answer) => answer.get('response') !== answer.get('positive_response'),
      )
    ) {
      return record.set('outcome', PARTIALLY_IMPLEMENTED);
    }
    return record.set('outcome', BLANK);
  }

  static cleanOperatingContexts(record: BaselineAssessmentEntityRecord) {
    if (record.get('equipment').isEmpty()) {
      record = record.set(
        'operating_contexts',
        record.get('operating_contexts').clear(),
      );
    } else {
      const operatingContexts = this.getOperatingContexts(
        record.get('equipment'),
      );
      record = record.set(
        'operating_contexts',
        record
          .get('operating_contexts')
          .filter((operatingContext) =>
            operatingContexts.contains(operatingContext),
          ),
      );
    }
    return record;
  }

  static clean(record: BaselineAssessmentEntityRecord) {
    record = this.updateOutcome(record);
    record = this.cleanOperatingContexts(record);
    return record;
  }
}

export type BaselineAssessmentEntityFields = {
  uuid: string;
  operating_contexts: List<OperatingContextEntityRecord>;
  equipment: List<SiteEquipmentOperatingContextEntityRecord>;
  answers: List<BaselineAssessmentAnswerEntityRecord>;
  is_mandatory: boolean;
  is_control_being_implemented: boolean;
  non_implementation_reason: string;
  exemption_reason: ExemptionReasonChoices;
  outcome: BaselineAssessmentOutcomes;
  outcome_display_en: string;
  outcome_display_es: string;
  attachments: List<ImplementationAttachmentEntityRecord>;
};

export type BaselineAssessmentEntityFieldsWithIndex =
  BaselineAssessmentEntityFields & {
    original_index: number;
  };
export type BaselineAssessmentEntityRecordWithIndex =
  EntityRecord<BaselineAssessmentEntityFieldsWithIndex>;

export type BaselineAssessmentEntityRecord =
  EntityRecord<BaselineAssessmentEntityFields>;

export default BaselineAssessmentEntity;
