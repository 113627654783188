import { type List } from 'immutable';
import { type ChangeEvent, useCallback } from 'react';

import type { SiteCompliancePlanEntityRecord } from 'entities/api/CompliancePlan/SiteCompliancePlan.ts';
import type { ScenarioEntityRecord } from 'entities/api/Scenario.ts';
import type { WithQueryReturnProps } from 'types';

export type UseCompliancePlanScenarioReturn = ReturnType<
  typeof useCompliancePlanScenario
>;

type useCompliancePlanScenarioProps = {
  allScenarios: List<ScenarioEntityRecord>;
  parentProps: WithQueryReturnProps<SiteCompliancePlanEntityRecord>;
};

export function useCompliancePlanScenario(
  props: useCompliancePlanScenarioProps,
) {
  const {
    parentProps: { onChange, name, value, field },
    allScenarios,
  } = props;

  const getScenarioEntity = useCallback(
    (scenario: string) => {
      return allScenarios.find((s) => s.get('uuid') === scenario);
    },
    [allScenarios],
  );

  const scenarioIsPresent = useCallback(
    (scenario: ScenarioEntityRecord) => {
      return value
        .get('scenarios')
        .some((s) => s.get('uuid') === scenario.get('uuid'));
    },
    [value],
  );

  const onChangeValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const scenario = getScenarioEntity(e.currentTarget?.value);

      if (!scenario) {
        return;
      }

      const scenarioInList = scenarioIsPresent(scenario);
      let updatedScenariosList = value.get('scenarios');

      if (scenarioInList) {
        updatedScenariosList = updatedScenariosList.filter(
          (s) => s.get('uuid') !== scenario.get('uuid'),
        );
      } else {
        updatedScenariosList = updatedScenariosList.push(scenario);
      }

      onChange({
        target: {
          name: name,
          // @ts-expect-error doesn't seem to understand field.entity is a class and clean exists
          value: field.entity.clean(
            value.set('scenarios', updatedScenariosList),
          ),
        },
      });
    },
    [getScenarioEntity, scenarioIsPresent, value, onChange, name, field.entity],
  );

  return {
    onChangeValue,
  };
}
