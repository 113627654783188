import { type List, is } from 'immutable';
import { useSelector } from 'react-redux';
import { createSelectorCreator, defaultMemoize } from 'reselect';

import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import PersonEntity, { type PersonEntityRecord } from './Person';

class DashboardPreferences extends Entity {
  static idField = 'person_uuid';

  static paths = {
    apiBase: '/cube/v1/dashboard_preferences/',
  };
  static fields: EntityFields<DashboardPreferencesFields> = {
    person_uuid: new Fields.IdField(),
    is_framework_owner: new Fields.BooleanField({
      default: false,
    }),
    is_control_owner: new Fields.BooleanField({
      default: false,
    }),
    is_local_control_advisor: new Fields.BooleanField({
      default: false,
    }),
  };
}

export type DashboardPreferencesFields = {
  person_uuid: string;
  is_framework_owner: boolean;
  is_control_owner: boolean;
  is_local_control_advisor: boolean;
};

export type DashboardPreferencesRecord =
  EntityRecord<DashboardPreferencesFields>;
DashboardPreferences.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: DashboardPreferences,
  name: 'DashboardPreferences',
});

const createImmutableSelector = createSelectorCreator(defaultMemoize, is);

export const selectDashboardPreferences = createImmutableSelector<
  //@ts-expect-error missing readonly
  Map<unknown, unknown>,
  DashboardPreferencesRecord
>(
  [
    (state: Map<unknown, unknown>) =>
      PersonEntity.duck.selectors.currentUser(state),
    (state: Map<unknown, unknown>) =>
      DashboardPreferences.duck.selectors.record(state),
  ],
  (
    currentPerson: PersonEntityRecord,
    dashboardPreferences: List<DashboardPreferencesRecord>,
  ) => {
    return dashboardPreferences?.find(
      (preference) =>
        preference.get('person_uuid') === currentPerson.get('uuid'),
    );
  },
);

export function useDashboardPreferences() {
  return useSelector(selectDashboardPreferences);
}

export default DashboardPreferences;
