import { type SystemStyleObject, Text } from '@chakra-ui/react';

import { VStack } from 'components';
import { secondLineSx } from 'components/widgets/PersonPicker';

export const defaultColspan = { base: 12, lg: 3 };

export const multipleAssigneesSx: SystemStyleObject = {
  fontStyle: 'italic',
  color: 'gray.600',
};

const gridSx: SystemStyleObject = {
  gridTemplateColumns: 'repeat(12, 1fr)',
  gap: 'gutter',
  width: 'full',
  paddingX: 4,
};

export const headersGridSx: SystemStyleObject = {
  ...gridSx,

  '> div': {
    color: 'gray.500',
    fontSize: 'sm',
  },
};

export const headerWidgetGridSx: SystemStyleObject = {
  ...gridSx,
  paddingY: 1,
  bgColor: 'gray.300',
};

export const headerWidgetHeadingSx: SystemStyleObject = {
  display: 'inline-flex',
  height: 'full',
  alignItems: 'center',
  paddingLeft: 1,
  color: 'black',
};

export const rowSx: SystemStyleObject = {
  width: 'full',
  paddingX: 4,
  paddingY: 1,
  backgroundColor: 'gray.100',
};

export const multiLineOptionSx: SystemStyleObject = {
  '&.multiple-assignees': {
    fontStyle: 'italic',
    color: 'muted',
  },
};

export const MultiLineOption = ({
  isMultipleAssignees,
  firstLine,
  secondLine,
}: {
  isMultipleAssignees: boolean;
  firstLine: string;
  secondLine: string;
}) => (
  <VStack
    gap={0}
    sx={multiLineOptionSx}
    className={isMultipleAssignees ? 'multiple-assignees' : ''}
  >
    <Text>{firstLine}</Text>
    <Text sx={secondLineSx}>{secondLine}</Text>
  </VStack>
);
