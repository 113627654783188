import type { Moment } from 'moment';

import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import { toString } from '../i18n/I18nText';

export const COMPLETE = 'Complete';
export const INPROGRESS = 'In Progress';
export const NOTSTARTED = 'Not Started';

export const STATUS_CHOICES = [COMPLETE, INPROGRESS, NOTSTARTED];

export type StatusEnType = (typeof STATUS_CHOICES)[number];
/**
 * A SiteCompliancePlanRow is a row in the compliance plan table, which are
 * returned by the endpoint's List Response.
 *
 * We probably won't ever display the Detail Response for this endpoint
 * directly, rather, we'll link to the CompliancePlan which will return all the
 * associated SiteCompliancePlans as nested records.
 */
class SiteCompliancePlanRowEntity extends Entity {
  static paginated = true;

  static paths = {
    // note: we only anticipate using the list view here
    apiBase: '/cube_compliance/v1/site_complianceplan_dashboard/',
  };

  static fields: EntityFields<SiteCompliancePlanRowEntityFields> = {
    uuid: new Fields.IdField(),
    plan_uuid: new Fields.CharField(),
    name: new Fields.CharField(),
    operation: new Fields.CharField(),
    status_en: new Fields.CharField({
      validators: [(value: string) => STATUS_CHOICES.includes(value)],
    }),
    status_es: new Fields.CharField(),
    // @fixme: needs a status_es variant
    created: new Fields.DateField(),
    controls: new Fields.NumberField(),
    gaps: new Fields.NumberField(),
    actions: new Fields.NumberField(),
    assessment_complete_percentage: new Fields.NumberField(),
  };

  static toString = toString<SiteCompliancePlanRowEntityRecord>;
}

export type SiteCompliancePlanRowEntityFields = {
  uuid: string;
  plan_uuid: string;
  name: string;
  operation: string;
  status_en: StatusEnType;
  status_es: string;
  created: Moment;
  controls: number;
  gaps: number;
  actions: number;
  assessment_complete_percentage: number;
};

export type SiteCompliancePlanRowEntityRecord =
  EntityRecord<SiteCompliancePlanRowEntityFields>;

SiteCompliancePlanRowEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: SiteCompliancePlanRowEntity,
  name: 'SiteCompliancePlanRow',
});

export default SiteCompliancePlanRowEntity;
