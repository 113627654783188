import { Checkbox, Grid, GridItem, Text } from '@chakra-ui/react';
import { type List } from 'immutable';

import SiteEquipmentOperatingContextEntity, {
  type SiteEquipmentOperatingContextEntityRecord,
} from 'entities/api/CompliancePlan/SiteEquipmentOperatingContext';
import { type OperatingContextEntityRecord } from 'entities/api/OperatingContext';
import {
  innerContentGridProps,
  innerGridTitleProps,
  operatingContextGridItemProps,
} from 'screens/compliance/tabs/OperatingEnvironment/tabs/OperatingContext/OperatingContext.styles';

import useEquipmentOperatingNode from './EquipmentOperatingNode.hooks';
import { type EquipmentTreeNode } from '../EquipmentTree/EquipmentTree';

export type EquipmentOperatingNodeProps = {
  equipmentNode: EquipmentTreeNode;
  equipmentOperationList: List<SiteEquipmentOperatingContextEntityRecord>;
  operatingContexts: List<OperatingContextEntityRecord>;
  onChange?: (
    equipmentOperation: SiteEquipmentOperatingContextEntityRecord,
    operatingContext: OperatingContextEntityRecord,
  ) => void;
};

export default function EquipmentOperatingNode(
  props: EquipmentOperatingNodeProps,
) {
  const {
    equipmentNode,
    equipmentOperatingContext,
    operatingContexts,
    onChange,
    toString,
    childEquipmentOperationList,
  } = useEquipmentOperatingNode(props);

  return (
    <Grid {...innerContentGridProps}>
      <GridItem
        {...innerGridTitleProps}
        paddingLeft={`${equipmentNode.get('relative_level') * 15}px`}
      >
        <Text
          fontSize={'sm'}
          color={
            !equipmentOperatingContext?.get('compliance_equipment').get('uuid')
              ? 'red'
              : 'initial'
          }
        >
          {toString(equipmentNode)}
        </Text>
      </GridItem>
      {operatingContexts.map((operatingContext) => (
        <GridItem
          key={operatingContext.get('uuid')}
          {...operatingContextGridItemProps}
        >
          <Checkbox
            isDisabled={
              !equipmentOperatingContext
                ?.get('compliance_equipment')
                .get('uuid')
            }
            isIndeterminate={
              equipmentNode.get('children') &&
              SiteEquipmentOperatingContextEntity.getEquipmentOperationContextSize(
                operatingContext,
                childEquipmentOperationList,
              ) > 0 &&
              SiteEquipmentOperatingContextEntity.getEquipmentOperationContextSize(
                operatingContext,
                childEquipmentOperationList,
              ) < childEquipmentOperationList.size
            }
            isChecked={
              equipmentOperatingContext &&
              !!equipmentOperatingContext
                ?.get('operating_contexts')
                .find((selectedOpContext) =>
                  selectedOpContext.equals(operatingContext),
                )
            }
            onChange={() => {
              onChange &&
                equipmentOperatingContext &&
                onChange(equipmentOperatingContext, operatingContext);
            }}
          ></Checkbox>
        </GridItem>
      ))}
    </Grid>
  );
}
