import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import {
  ActionControlListRow,
  ActionControlListTable,
} from './ActionControlListTable';
import { useActionPlanControlList } from './ActionPlanControlList.hooks';

export const ActionPlanControlList = () => {
  const { applicableControlsForPlan, modalDisclosure } =
    useActionPlanControlList();

  return (
    <>
      <ActionControlListTable
        count={applicableControlsForPlan?.size ?? 0}
        isLoading={!applicableControlsForPlan}
      >
        {applicableControlsForPlan?.map((control) => (
          <ActionControlListRow key={control.get('uuid')} record={control} />
        ))}
      </ActionControlListTable>

      <Modal isOpen={modalDisclosure.isOpen} onClose={modalDisclosure.onClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>
            <FormattedMessage
              id={'compliance.actions.submitHeading'}
              defaultMessage={'Assessment Completed'}
            />
          </ModalHeader>

          <ModalBody>
            <Text>
              <FormattedMessage
                id={'compliance.actions.submitBody'}
                defaultMessage={
                  'You have completed the assessment, submission and approval will come in a future update.'
                }
              />
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button variant="outline" onClick={modalDisclosure.onClose}>
              <FormattedMessage id="ui.ok" defaultMessage="OK" />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ActionPlanControlList;
