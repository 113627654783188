import { type SystemStyleObject } from '@chakra-ui/react';

export const accordionSx: SystemStyleObject = {
  '.chakra-accordion__item': {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    borderWidth: 0,

    _last: {
      borderWidth: 0,
    },
  },

  '.chakra-accordion__button': {
    paddingX: 0,
  },

  '.chakra-accordion__icon': {
    marginRight: 2,
  },

  '.chakra-accordion__panel': {
    padding: 0,
  },
};
