import { List, Map, isImmutable } from 'immutable';
import { useCallback, useMemo } from 'react';
import { defineMessage, useIntl } from 'react-intl';

import { Fields } from '@burnsred/entity';
import { noop } from '@burnsred/ui-chakra';
import SiteCompliancePlanEntity from 'entities/api/CompliancePlan/SiteCompliancePlan';
import SiteCompliancePlanBulkAssignEntity, {
  type SiteCompliancePlanBulkAssignEntityRecord,
} from 'entities/api/CompliancePlan/SiteCompliancePlanBulkAssign';
import type { BhpPersonEntityFields } from 'entities/api/Person/BhpPerson';
import type { EntityFormProps, FormChangeEvent } from 'types';

import { MultiLineOption } from './OwnershipTable.styles';
import type { OwnershipTableHeaderWidgetProps } from './OwnershipTableHeaderWidget';

export const MULTIPLE_ASSIGNEES_UUID = 'MULTIPLE_ASSIGNEES';

const MULTIPLE_ASSIGNEES_NAME = defineMessage({
  id: 'OwnershipTableHeaderWidget.option.multiple-assignees',
  defaultMessage: 'Multiple Assignees',
});

type PersonnelFieldNames =
  | 'general_manager'
  | 'president'
  | 'local_control_advisor';

export const useBulkEditAssignees = (
  props: OwnershipTableHeaderWidgetProps,
) => {
  const { formatMessage } = useIntl();
  const { onChange: _ } = props;

  const multipleAssigneesName = useMemo(
    () => formatMessage(MULTIPLE_ASSIGNEES_NAME),
    [formatMessage],
  );

  const multipleAssigneesOption = useMemo(
    () =>
      Map({
        uuid: multipleAssigneesName,
      }),
    [multipleAssigneesName],
  );

  const calculateFieldValue = useCallback(
    (
      fieldName: PersonnelFieldNames,
      value: List<SiteCompliancePlanBulkAssignEntityRecord>,
    ) => {
      const personnel = value.map((rec) => rec?.get(fieldName));
      const isPersonnelEmpty = personnel.every((person) => !person);
      const isPersonnelUniform = personnel.every(
        // compare UUIDs because differing serializer shapes cause
        // immutable.equals / toSet to return a false negative
        (rec) => rec?.get('uuid') == personnel?.first()?.get('uuid'),
      );
      return isPersonnelEmpty
        ? null
        : isPersonnelUniform
          ? personnel?.first()
          : multipleAssigneesOption;
    },
    [multipleAssigneesOption],
  );

  const assetName = useMemo(() => {
    return SiteCompliancePlanEntity.getAsset(props?.value?.first())?.get(
      'name',
    );
  }, [props.value]);

  /** compose a value for each role from the personnel assigned in the SiteCompliancePlans */
  const value = useMemo(
    () =>
      Map({
        general_manager: calculateFieldValue('general_manager', props.value),
        president: calculateFieldValue('president', props.value),
        local_control_advisor: calculateFieldValue(
          'local_control_advisor',
          props.value,
        ),
      }),
    [calculateFieldValue, props.value],
  );

  const onChange = useCallback(
    (event: FormChangeEvent<SiteCompliancePlanBulkAssignEntityRecord>) => {
      // Find the changed field by comparing old and new values
      const changedFieldKey = event.target.value.findKey((newVal, key) => {
        const prevVal = value.get(key);
        return isImmutable(newVal)
          ? !newVal.equals(prevVal)
          : newVal !== prevVal;
      }) as PersonnelFieldNames;

      props.onChange({
        target: {
          name: props.name,
          value: props.value.map((rec) =>
            rec.set(changedFieldKey, event.target.value.get(changedFieldKey)),
          ),
        },
      });
    },
    [props, value],
  );

  const formProps = useMemo(() => {
    return {
      name: '_bulk-assign-personnel',
      field: new Fields.EntityField({
        entity: SiteCompliancePlanBulkAssignEntity,
      }),
      value,
      onChange,
      onSubmit: noop,
      errors: List(),
    } as unknown as EntityFormProps<SiteCompliancePlanBulkAssignEntityRecord>;
  }, [onChange, value]);

  const formatOptionLabel = useCallback(
    (option: BhpPersonEntityFields) => {
      const isMultipleAssignees = option.uuid === multipleAssigneesName;

      // note: there are some Persons missing core info!
      const firstLine = isMultipleAssignees
        ? multipleAssigneesName
        : option.user
          ? `${option.first_name} ${option.last_name} (${option.user})`
          : option.uuid;

      // FIXME the SiteCompliancePlan entity doesn't return ancestors,
      // so the initial render shows this line blank
      const secondLine = isMultipleAssignees
        ? ''
        : option?.organisational_unit?.ancestors
            // @ts-expect-error option the result of record.toJS(); TS expects the nested record to be a Map
            ?.map(({ name }) => name)
            ?.join(' | ') || '...';

      return (
        <MultiLineOption {...{ isMultipleAssignees, firstLine, secondLine }} />
      );
    },
    [multipleAssigneesName],
  );

  return {
    assetName,
    formProps,
    formatOptionLabel,
  };
};
