import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { ENGLISH, type LocaleKey, SPANISH } from 'locales';
import { type EntityFields, type EntityRecord } from 'types';

class I18nTranslationEntity extends Entity {
  static fields: EntityFields<I18nTranslationRecordFields> = {
    uuid: new Fields.IdField({ blank: true }),
    language: new Fields.CharField({
      default: ENGLISH,
      validators: [
        (value: string) => {
          if (![ENGLISH, SPANISH].includes(value)) {
            throw new Error('Invalid language key');
          }
        },
      ],
    }),
    translated_message: new Fields.CharField(),
  };

  static toString(record: I18nTranslationEntityRecord) {
    return record?.get('translated_message') || '';
  }

  static dataToRecord(data?: Partial<I18nTranslationRecordFields>) {
    return super.dataToRecord(data) as I18nTranslationEntityRecord;
  }
}

export type I18nTranslationRecordFields = {
  uuid: string;
  translated_message: string;
  language: LocaleKey;
};

export type I18nTranslationEntityRecord =
  EntityRecord<I18nTranslationRecordFields>;

I18nTranslationEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: I18nTranslationEntity,
  name: 'I18nTranslation',
});

export default I18nTranslationEntity;
