import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { Form, Input } from '@burnsred/entity-form';
import { Autocomplete, Loading } from '@burnsred/ui-chakra';
import { VStack } from 'components';
import { I18nTextWidget } from 'forms/components';

import { I18nTranslation } from './I18nTranslation';
import type { NewEquipmentModalProps } from './NewEquipmentModal';
import { useNewEquipmentModal } from './NewEquipmentModal.hooks';
import { modalBodySx, modalFooterSx } from './NewEquipmentModal.styles';

export const NewEquipmentModalForm = (props: NewEquipmentModalProps) => {
  const {
    onChangeParent,
    onSubmit,
    selectOptions,
    selectValue,
    onInputChange,
    getOptionLabel,
    getOptionValue,
    errors,
    isLoading,
  } = useNewEquipmentModal(props);

  if (isLoading)
    return (
      <VStack layerStyle="loadingWrapper">
        <Loading />
      </VStack>
    );
  return (
    <Form {...props}>
      <ModalBody as={Flex} sx={modalBodySx}>
        <Input
          name="title"
          component={I18nTextWidget}
          componentProps={{
            translationComponent: I18nTranslation,
          }}
        />

        <FormControl isRequired>
          <FormLabel>
            <FormattedMessage
              id="compliance.operating-environment.equipment.modal.label.parent"
              defaultMessage="Assign vehicle class to"
            />
          </FormLabel>

          <Input
            name="parent"
            component={Autocomplete}
            componentProps={{
              options: selectOptions,
              value: selectValue,
              onChange: onChangeParent,
              onInputChange,
              getOptionLabel,
              getOptionValue,
            }}
          />
        </FormControl>
      </ModalBody>

      <ModalFooter as={Flex} sx={modalFooterSx}>
        <Button variant="outline" onClick={props.disclosure.onClose}>
          <FormattedMessage id="ui.close" defaultMessage="Close" />
        </Button>

        <Button
          isDisabled={errors.size > 0 || props.value?.get('parent') == null}
          variant="primary"
          onClick={onSubmit}
        >
          <FormattedMessage
            id="compliance.operating-environment.equipment.modal.submit"
            defaultMessage="Add vehicle"
          />
        </Button>
      </ModalFooter>
    </Form>
  );
};
