import { Box } from '@chakra-ui/react';

import {
  AssessBadgeSx,
  MandatoryBadgeSx,
} from './MandatoryAssessBadge.style.tsx';

const MANDATORY = 'mandatory';
const ASSESSED = 'assess';

type MandatoryAssessBadgeProps = {
  type: typeof MANDATORY | typeof ASSESSED;
};

const MandatoryBadge = () => {
  return <Box sx={MandatoryBadgeSx}>M</Box>;
};

const AssessBadge = () => {
  return <Box sx={AssessBadgeSx}>A</Box>;
};

export const MandatoryAssessBadge = (props: MandatoryAssessBadgeProps) => {
  const isMandatory = props.type === MANDATORY;

  if (isMandatory) return <MandatoryBadge />;

  return <AssessBadge />;
};
