import type { ReactNode } from 'react';

import { Form, Input, type InputProps } from '@burnsred/entity-form';
import type { I18nTextEntityRecord } from 'entities/api/i18n/I18nText';
import type { I18nTranslationEntityRecord } from 'entities/api/i18n/I18nTranslation';

import { useI18nTextWidget } from './I18nTextWidget.hooks';

export type I18nTextWidgetProps = InputProps<I18nTextEntityRecord, false> & {
  translationComponent: ReactNode;
};

export const I18nTextWidget = (props: I18nTextWidgetProps) => {
  useI18nTextWidget(props);

  return (
    <Form {...props}>
      <Input
        name="translations"
        component={TranslationWrapper}
        componentProps={{ translationComponent: props.translationComponent }}
        many
      />
    </Form>
  );
};

/**
 * ...
 */
const TranslationWrapper = (
  props: InputProps<I18nTranslationEntityRecord, false> & {
    translationComponent: ReactNode;
  },
) => {
  const TranslationComponent = props?.translationComponent;
  return (
    <Form {...props}>
      <Input
        name="translated_message"
        component={TranslationComponent}
        componentProps={{
          // pass the entire I18nTranslationEntityRecord value to the component
          // so it can render the correct label
          parentValue: props.value,
        }}
      />
    </Form>
  );
};
