import { List } from 'immutable';

import { Entity, Fields } from '@burnsred/entity';
import BaselineAssessmentAnswerAttachmentEntity, {
  type BaselineAssessmentAnswerAttachmentEntityRecord,
} from 'entities/api/CompliancePlan/BaselineAssessmentAnswerAttachment.ts';
import { ENGLISH, SPANISH } from 'locales';
import { type EntityFields, type EntityRecord } from 'types';

import BaselineAssessmentQuestionEntity, {
  type BaselineAssessmentQuestionEntityRecord,
} from './BaselineAssessmentQuestion';
import GapEntity, { type GapEntityRecord } from './Gap';
import I18nTextEntity, { toString } from '../i18n/I18nText';

/**
 * This is a default used when there should be at least one Gap in an Answer;
 * it will be filtered out of the payload before being sent to the server.
 */
const EMPTY_GAP = GapEntity.dataToRecord({});

class BaselineAssessmentAnswerEntity extends Entity {
  static fields: EntityFields<BaselineAssessmentAnswerEntityFields> = {
    uuid: new Fields.IdField({ blank: true }),
    response: new Fields.BooleanField({ blank: true }),
    positive_response: new Fields.BooleanField({}),
    question: new Fields.EntityField({
      blank: true,
      entity: BaselineAssessmentQuestionEntity,
    }),
    question_text_en: new Fields.CharField({ blank: true }),
    question_text_es: new Fields.CharField({ blank: true }),
    comments: new Fields.CharField({ blank: true }),
    gaps: new Fields.EntityField({
      entity: GapEntity,
      many: true,
      blank: true,
      cleaners: [(record: GapEntityRecord) => GapEntity.clean(record)],
    }),
    attachments: new Fields.EntityField({
      entity: BaselineAssessmentAnswerAttachmentEntity,
      many: true,
      blank: true,
    }),
    order: new Fields.IntegerField(),
  };

  static toString = toString<BaselineAssessmentAnswerEntityRecord>;

  static fromQuestion(question: BaselineAssessmentQuestionEntityRecord) {
    let newQuestion = this.dataToRecord({});

    newQuestion = newQuestion.withMutations(
      (record: BaselineAssessmentQuestionEntityRecord) => {
        record.set('order', question.get('order'));
        record.set('positive_response', question.get('positive_response'));
        record.set('question', question);
        record.set(
          'question_text_en',
          I18nTextEntity.getRecordForLocale(
            question.get('title'),
            ENGLISH,
          )?.get('translated_message') || '',
        );
        record.set(
          'question_text_es',
          I18nTextEntity.getRecordForLocale(
            question.get('title'),
            SPANISH,
          )?.get('translated_message') || '',
        );
      },
    );
    return newQuestion;
  }

  static updateGaps(
    record: BaselineAssessmentAnswerEntityRecord,
  ): BaselineAssessmentAnswerEntityRecord {
    const response = record.get('response');

    if (response === record.get('positive_response') || response == null) {
      return record.set('gaps', List());
    }

    if (record.get('gaps').isEmpty()) {
      return record.set('gaps', List([EMPTY_GAP]));
    }

    return record;
  }

  static updateCommentAttachments(
    record: BaselineAssessmentAnswerEntityRecord,
  ) {
    const responseIsPositive =
      record.get('response') === record.get('positive_response');
    if (responseIsPositive) {
      return record;
    }
    return record.set('attachments', List()).set('comments', '');
  }

  // onchange / onsubmit
  static clean(record: BaselineAssessmentAnswerEntityRecord) {
    record = this.updateGaps(record);
    record = this.updateCommentAttachments(record);
    return record;
  }

  // On create (includes from server)
  static dataToRecord(data?: Record<string, unknown> | null) {
    let newRecord = super.dataToRecord(data);
    newRecord = this.updateGaps(newRecord);
    return newRecord;
  }

  // When sending to the server
  static toData(record: BaselineAssessmentAnswerEntityRecord) {
    if (!record.get('gaps').isEmpty()) {
      record = record.set(
        'gaps',
        record.get('gaps').filter((gap) => !EMPTY_GAP.equals(gap)),
      );
    }
    return super.toData(record);
  }
}

export type BaselineAssessmentAnswerEntityFields = {
  uuid: string;
  response: boolean | null;
  positive_response: boolean;
  question: BaselineAssessmentQuestionEntityRecord;
  question_text_en: string;
  question_text_es: string;
  comments: string;
  gaps: List<GapEntityRecord>;
  attachments: List<BaselineAssessmentAnswerAttachmentEntityRecord>;
  order: number;
};

export type BaselineAssessmentAnswerEntityRecord =
  EntityRecord<BaselineAssessmentAnswerEntityFields>;

export default BaselineAssessmentAnswerEntity;
