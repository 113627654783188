import { Grid, GridItem, Heading } from '@chakra-ui/react';
import { type List } from 'immutable';

import { Form, Input } from '@burnsred/entity-form';
import { PersonPicker } from 'components/widgets/PersonPicker';
import type { SiteCompliancePlanEntityRecord } from 'entities/api/CompliancePlan/SiteCompliancePlan';
import type { EntityFormProps } from 'types';

import {
  defaultColspan,
  headerWidgetGridSx,
  headerWidgetHeadingSx,
} from './OwnershipTable.styles';
import { useBulkEditAssignees } from './OwnershipTableHeaderWidget.hooks';

export type OwnershipTableHeaderWidgetProps = EntityFormProps<
  List<SiteCompliancePlanEntityRecord>
>;

/**
 * This widget is used to bulk edit the personnel assigned to a
 * SiteCompliancePlan.
 *
 * It uses a partial Form built on SiteCompliancePlanBulkAssignEntity so we can
 * utilise:
 *
 * `<Input name="*" component={Autocomplete} loadOptionsFromAPI />`
 */
export const OwnershipTableHeaderWidget = (
  props: OwnershipTableHeaderWidgetProps,
) => {
  const { assetName, formProps, formatOptionLabel } =
    useBulkEditAssignees(props);

  return (
    <Form {...formProps}>
      <Grid sx={headerWidgetGridSx}>
        <GridItem colSpan={defaultColspan}>
          <Heading size="sm" sx={headerWidgetHeadingSx}>
            {assetName}
          </Heading>
        </GridItem>

        <GridItem colSpan={defaultColspan}>
          <Input
            name="local_control_advisor"
            component={PersonPicker}
            componentProps={{ formatOptionLabel }}
            loadOptionsFromAPI
          />
        </GridItem>

        <GridItem colSpan={defaultColspan}>
          <Input
            name="general_manager"
            component={PersonPicker}
            componentProps={{ formatOptionLabel }}
            loadOptionsFromAPI
          />
        </GridItem>

        <GridItem colSpan={defaultColspan}>
          <Input
            name="president"
            component={PersonPicker}
            componentProps={{ formatOptionLabel }}
            loadOptionsFromAPI
          />
        </GridItem>
      </Grid>
    </Form>
  );
};
