import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types.ts';

class SiteFrameworkCountEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/reporting/site_framework_count/',
  };

  static fields: EntityFields<SiteFrameworkCountEntityFields> = {
    uuid: new Fields.IdField(),
    count_cc: new Fields.IntegerField(),
    count_ccc: new Fields.IntegerField(),
    count_interruptions: new Fields.IntegerField(),
  };
}

type SiteFrameworkCountEntityFields = {
  uuid: string;
  count_cc: number;
  count_ccc: number;
  count_interruptions: number;
};
export type SiteFrameworkCountEntityRecord =
  EntityRecord<SiteFrameworkCountEntityFields>;

SiteFrameworkCountEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: SiteFrameworkCountEntity,
  name: 'SiteFrameworkCount',
});

export default SiteFrameworkCountEntity;
