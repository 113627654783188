import type {
  ButtonProps,
  GridProps,
  SystemStyleObject,
} from '@chakra-ui/react';

const commonLayoutSx: SystemStyleObject = {
  paddingX: 3,
  bgColor: 'gray.100',
};

export const wrapperSx: SystemStyleObject = {
  gap: 0,
};

export const topRowSx: SystemStyleObject = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: 'full',
  gap: 6,
  paddingY: 2,
  ...commonLayoutSx,
};

export const commentWrapperSx: SystemStyleObject = {
  ...commonLayoutSx,
  paddingY: 3,
};

export const questionTextSx: SystemStyleObject = {
  marginRight: 'auto',
  fontSize: 'sm',
};

export const gridProps: GridProps = {
  gridTemplateColumns: '6fr 2fr 2fr',
  gap: 6,
};

export const buttonProps: ButtonProps = {
  variant: 'whiteOutline',
  size: 'sm',

  sx: {
    width: '50%',
    // attempt to match the label height of neighbouring field labels
    marginTop: '29px',
  },
};

export const modalHeaderSx: SystemStyleObject = {
  color: 'primary',
  borderBottom: 0,
};

export const modalBodySx: SystemStyleObject = {
  minHeight: '160px',
};

export const modalButtonProps: ButtonProps = {
  sx: {
    minWidth: 24,
  },
};
