import { type SystemStyleObject, Text } from '@chakra-ui/react';

import { VStack } from 'components';
import type {
  BHPPersonEntityRecord,
  BhpPersonEntityFields,
} from 'entities/api/Person/BhpPerson';

import { Autocomplete } from '../Autocomplete';
import type { WidgetSelectInputProps } from '../Autocomplete/Autocomplete';

/**
 * Renders an Autocomplete with double-line options which provide more context
 * to distinguish between similarly named Persons.
 */
export const PersonPicker = <
  T extends BHPPersonEntityRecord = BHPPersonEntityRecord,
  Many extends boolean = true,
>({
  // @ts-expect-error Type '(option: BhpPersonEntityFields) => JSX.Element' is not assignable to type '(option: object) => string'
  formatOptionLabel = _formatOptionLabel,
  ...props
}: WidgetSelectInputProps<T, Many>) => {
  return (
    // @ts-expect-error Type 'T | List<T>' is not assignable to type 'List<Map<string, unknown>>'
    <Autocomplete
      menuPortalTarget={undefined}
      // FIXME on initial render, second line is not displayed
      formatOptionLabel={formatOptionLabel}
      // PersonPicker is taller due to double-line options; display larger by default
      size="lg"
      // @ts-expect-error Property 'chakraStyles' does not exist on type 'Omit<WidgetSelectInputProps<T, Many>, "getOptionLabel">
      chakraStyles={{ ...personPickerChakraStyles, ...props.chakraStyles }}
      {...props}
      getOptionValue={(option: BhpPersonEntityFields) => option?.uuid}
    />
  );
};

/** https://react-select.com/props#select-props */
const _formatOptionLabel = (
  option: BhpPersonEntityFields,
  // formatOptionLabelMeta: {
  //   context: FormatOptionLabelContext; // 'menu' |'value'
  //   inputValue: string;
  //   selectValue: Options<Option>;
  // };
) => {
  // note: there are some Persons missing core info!
  const firstLine = option.user
    ? `${option.first_name} ${option.last_name} (${option.user})`
    : option.uuid;

  const secondLine =
    option?.organisational_unit?.ancestors
      // @ts-expect-error option the result of record.toJS(); TS expects the nested record to be a Map
      ?.map(({ name }) => name)
      ?.join(' | ') || '-';

  return <MultiLineOption {...{ firstLine, secondLine }} />;
};

/** display elements for the 'lg' Autocomplete at the same size as 'md' */
const personPickerChakraStyles = {
  /** Box (uses theme styles for Chakra's CloseButton) */
  clearIndicator: (provided: SystemStyleObject) => ({
    ...provided,
    boxSize: 8,
    fontSize: 'xs',
  }),

  /** Box (uses theme styles for Chakra's InputRightAddon) */
  dropdownIndicator: (provided: SystemStyleObject) => ({
    ...provided,
    fontSize: 'xl',
  }),
};

export const secondLineSx: SystemStyleObject = {
  fontSize: 'xs',
  color: 'muted',

  '[data-focus=true] &': { color: 'gray.600' },
  '[aria-selected=true] &': { color: 'white' },
  '[data-focus=true] [aria-selected=true] &': { color: 'white' },
};

const MultiLineOption = ({
  firstLine,
  secondLine,
}: {
  firstLine: string;
  secondLine: string;
}) => (
  <VStack gap={0}>
    <Text>{firstLine}</Text>
    <Text sx={secondLineSx}>{secondLine}</Text>
  </VStack>
);
