import type { List } from 'immutable';

import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import CompliancePlanBaseEquipmentEntity, {
  type CompliancePlanBaseEquipmentEntityRecord,
} from './CompliancePlanBaseEquipment';
import type { SiteCompliancePlanEntityRecord } from './SiteCompliancePlan';
import { SiteCompliancePlan } from '..';
import { createImmutableSelector, createMemoizedTree } from '../abstract';
import { type EquipmentEntityRecord } from '../Equipment';
import { toString } from '../i18n/I18nText';
import RiskEntity, { type RiskEntityRecord } from '../Risk';

/**
 * A CompliancePlan defines is the parent of the various SiteCompliancePlans,
 * and hosts common properties (name, risk, equipment).
 *
 * Note: Corresponds to the BE model CompliancePlanInfo
 */
class CompliancePlanEntity extends Entity {
  static paginated = true;

  static paths = {
    // note: we only anticipate using the Detail view here
    apiBase: '/cube_compliance/v1/complianceplan_info/',
  };

  static fields: EntityFields<CompliancePlanEntityFields> = {
    uuid: new Fields.IdField({ blank: true }),

    name: new Fields.CharField(),

    risk: new Fields.EntityField({
      entity: RiskEntity,
      blank: true,
    }),

    complianceplanbaseequipment_set: new Fields.EntityField({
      entity: CompliancePlanBaseEquipmentEntity,
      many: true,
      blank: true,
    }),

    sitecomplianceplan_set: new Fields.EntityField({
      entity: SiteCompliancePlan,
      many: true,
      blank: true,
    }),
  };

  static toString = toString<CompliancePlanEntityRecord>;

  /** returns the common asset for the CompliancePlan by traversing sitecomplianceplan_set.[0]site.ancestors */
  static getAsset(record: CompliancePlanEntityRecord) {
    return record
      ?.get('sitecomplianceplan_set')
      ?.get(0)
      ?.get('site')
      ?.get('ancestors')
      ?.last();
  }

  static hasDescendantEquipment(
    record: CompliancePlanEntityRecord,
    equipment: EquipmentEntityRecord,
  ) {
    return record.get('complianceplanbaseequipment_set').some((prevEquipment) =>
      prevEquipment
        ?.get('equipment')
        ?.get('ancestors')
        .some((ancestor) => ancestor.get('uuid') == equipment.get('uuid')),
    );
  }

  static getEquipmentList = createImmutableSelector(
    [
      (record: CompliancePlanEntityRecord) =>
        record.get('complianceplanbaseequipment_set'),
    ],
    (baseEquipment: List<CompliancePlanBaseEquipmentEntityRecord>) =>
      baseEquipment.map((baseEquipment) => baseEquipment.get('equipment')),
  );

  static getEquipmentTree(record: CompliancePlanEntityRecord) {
    const [_, tree] = createMemoizedTree(this.getEquipmentList(record));
    return tree;
  }

  static clean = (record: CompliancePlanEntityRecord) => {
    // Check and remove equipment associations without actual equipment
    record = record.withMutations((record) => {
      record.set(
        'complianceplanbaseequipment_set',
        record
          .get('complianceplanbaseequipment_set')
          .filter((baseEquipment) => {
            if (!baseEquipment.get('equipment')) {
              console.warn(
                'Upstream component created a blank equipment association',
              );
            }
            return !!baseEquipment.get('equipment');
          }),
      );
    });
    // Check for duplicate entries in the list
    let hasDuplicates = false;
    record.get('complianceplanbaseequipment_set').forEach((baseEquipment) => {
      if (
        record.get('complianceplanbaseequipment_set').indexOf(baseEquipment) !==
        record.get('complianceplanbaseequipment_set').lastIndexOf(baseEquipment)
      ) {
        console.warn(
          'Upstream component creating duplicate entries, causing increased load to clean',
        );
        hasDuplicates = true;
      }
    });
    if (hasDuplicates) {
      // De dupe the list, check upstream component logic.
      record = record.set(
        'complianceplanbaseequipment_set',
        record.get('complianceplanbaseequipment_set').toSet().toList(),
      );
    }
    return record;
  };

  /**
   * CompliancePlanBaseEquipment enforces:
   * `unique_together = (('equipment', 'compliance_plan_info'))`
   * so ensure that if such an instance existed previously,
   * and in the course of user engagement was removed then re-added,
   * that we re-use the original instance.
   */
  static cleanBaseEquipment(
    records: List<CompliancePlanBaseEquipmentEntityRecord>,
    valueInitial: List<CompliancePlanBaseEquipmentEntityRecord>,
  ) {
    return records.map((rec) => {
      if (rec.get('uuid')) return rec;

      const existingInstance = valueInitial?.find((initialRec) =>
        initialRec.get('equipment').equals(rec.get('equipment')),
      );
      return existingInstance ?? rec;
    });
  }
}

export type CompliancePlanEntityFields = {
  uuid: string;
  name: string;
  risk: RiskEntityRecord;
  complianceplanbaseequipment_set: List<CompliancePlanBaseEquipmentEntityRecord>;
  sitecomplianceplan_set: List<SiteCompliancePlanEntityRecord>;
  // REVIEW can we get this here? it's required for tab 3,
  // and we are guaranteed to know risk ahead of time
  // operating_contexts: List<OperatingContextEntityRecord>;
};

export type CompliancePlanEntityRecord =
  EntityRecord<CompliancePlanEntityFields>;

CompliancePlanEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: CompliancePlanEntity,
  name: 'CompliancePlan',
});

export default CompliancePlanEntity;
