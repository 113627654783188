import {
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  ListItem,
  Textarea,
  UnorderedList,
} from '@chakra-ui/react';
import { type List } from 'immutable';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';

import { Link } from '@burnsred/ui-chakra';
import { InputResizer } from 'components';
import { type SupportFactorEntityRecord } from 'entities/api/SupportFactor';
import { useLocale } from 'locales/useLocale';
import { createLogger } from 'util/createLogger';

import {
  gridSx,
  helperTextSx,
  linkedSupportFactorListSx,
  textAreaResizerSx,
  textAreaSx,
} from './GlobalFrameworkControlDocumentation.styles';
import { GLOBAL_FRAMEWORK_TABS } from '../../GlobalFramework/GlobalFramework';
import type { GlobalControlDetailContextType } from '../GlobalFrameworkControlDetail';

const log = createLogger('GlobalFrameworkControlDocumentation');

type DocumentationFieldProps = {
  value: string;
  label: ReactElement;
  helperText: ReactElement;
};

/**
 * Renders control documentation into a Fieldset.
 */
const DocumentationField = ({
  value = 'TODO',
  label,
  helperText,
}: DocumentationFieldProps) => (
  <FormControl>
    <FormLabel>{label}</FormLabel>
    <InputResizer value={value} afterSx={textAreaResizerSx}>
      <Textarea
        variant="outline"
        size="sm"
        defaultValue={value}
        sx={textAreaSx}
        isReadOnly
      />
    </InputResizer>
    <FormHelperText sx={helperTextSx}>{helperText}</FormHelperText>
  </FormControl>
);

/**
 * Renders support Factors as a List of Links.
 */

type SupportFactorsLinkFieldProps = {
  records: List<SupportFactorEntityRecord>;
  label: ReactElement;
  helperText: ReactElement;
  globalFrameworkUuid: string;
};

const SupportFactorsLinkField = ({
  label,
  helperText,
  records,
  globalFrameworkUuid,
}: SupportFactorsLinkFieldProps) => {
  const { toString } = useLocale();
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <UnorderedList sx={linkedSupportFactorListSx} layerStyle={'nakedList'}>
        {records?.count() > 0
          ? records?.map((item, index) => (
              <ListItem key={index}>
                <Link
                  to={`/${GLOBAL_FRAMEWORK_TABS.base}/${globalFrameworkUuid}/${GLOBAL_FRAMEWORK_TABS.tabPaths.supportfactors.routeName}/${item.get('uuid')}/`}
                  isExternal
                >
                  {toString(item)}
                </Link>
              </ListItem>
            ))
          : 'supportFactorsFallback'}
      </UnorderedList>
      <FormHelperText sx={helperTextSx}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export const GlobalFrameworkControlDocumentation = ({
  controlFormControls,
}: GlobalControlDetailContextType) => {
  const { globalFrameworkUuid } = useParams();
  const { toString } = useLocale();
  const control = controlFormControls?.value;

  log('control: %o', control);

  const supportFactors = control?.get('support_factors');

  return (
    <Grid sx={gridSx}>
      {/* First row: Two columns */}
      <GridItem>
        <DocumentationField
          value={toString(control, 'purpose')}
          label={
            <FormattedMessage
              id="global-framework.control.documentation.purpose.label"
              defaultMessage="Control purpose"
            />
          }
          helperText={
            <FormattedMessage
              id="global-framework.control.documentation.control-purpose.help-text"
              defaultMessage="The intention of the control, which the control features must deliver on."
            />
          }
        />
      </GridItem>

      {/* Implement Support factors field as a List of Item Links */}
      <GridItem>
        <SupportFactorsLinkField
          globalFrameworkUuid={globalFrameworkUuid as string}
          label={
            <FormattedMessage
              id="global-framework.control.documentation.support-factors.label"
              defaultMessage="Support factors"
            />
          }
          helperText={
            <FormattedMessage
              id="global-framework.control.documentation.support-factors.help-text"
              defaultMessage=" Factors that support the effectiveness of the control that ensure its proper functioning. "
            />
          }
          records={supportFactors}
        />
      </GridItem>

      {/* Second row: Two columns */}
      <GridItem>
        <DocumentationField
          value={toString(control, 'in_scope')}
          label={
            <FormattedMessage
              id="global-framework.control.documentation.in-scope.label"
              defaultMessage="Scope included"
            />
          }
          helperText={
            <FormattedMessage
              id="global-framework.control.documentation.in-scope.help-text"
              defaultMessage="Context that control applies to (e.g. operations type, activity type, equipment type etc)."
            />
          }
        />
      </GridItem>
      <GridItem>
        <DocumentationField
          value={toString(control, 'out_of_scope')}
          label={
            <FormattedMessage
              id="global-framework.control.documentation.out-of-scope.label"
              defaultMessage="Scope excluded"
            />
          }
          helperText={
            <FormattedMessage
              id="global-framework.control.documentation.out-of-scope.help-text"
              defaultMessage="Context that control does not apply to (where clarification necessary)."
            />
          }
        />
      </GridItem>

      <GridItem colSpan={2}>
        <DocumentationField
          value={toString(control, 'design_standard')}
          label={
            <FormattedMessage
              id="global-framework.control.documentation.design-standards.label"
              defaultMessage="Design requirements"
            />
          }
          helperText={
            <FormattedMessage
              id="global-framework.control.documentation.design-standards.help-text"
              defaultMessage="Features for control effectiveness that must be 'designed in'."
            />
          }
        />
      </GridItem>

      <GridItem colSpan={2}>
        <DocumentationField
          value={toString(control, 'operating_standard')}
          label={
            <FormattedMessage
              id="global-framework.control.documentation.operating-standards.label"
              defaultMessage="Operating requirements"
            />
          }
          helperText={
            <FormattedMessage
              id="global-framework.control.documentation.operating-standards.help-text"
              defaultMessage="Features for control effectiveness that must be delivered during operation of the control in practice."
            />
          }
        />
      </GridItem>

      <GridItem colSpan={2}>
        <DocumentationField
          value={toString(control, 'implementation')}
          label={
            <FormattedMessage
              id="global-framework.control.documentation.implementation-requirements.label"
              defaultMessage="Implementation requirements"
            />
          }
          helperText={
            <FormattedMessage
              id="global-framework.control.documentation.implementation-requirements.help-text"
              defaultMessage="Describes the rationale and criteria for implementing controls, including business decisions, technology selection, and assessments required for each scenario or vehicle type."
            />
          }
        />
      </GridItem>
    </Grid>
  );
};
