import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { type Map } from 'immutable';
import type { ReactNode } from 'react';

import { useLocale } from 'locales/useLocale';

import Autocomplete, { type WidgetSelectInputProps } from './Autocomplete';

type AutocompleteFieldProps<
  T extends Map<string, unknown>,
  Many extends boolean,
> = WidgetSelectInputProps<T, Many> & { label: ReactNode };

export const AutocompleteField = <
  T extends Map<string, unknown>,
  Many extends boolean = true,
>(
  props: AutocompleteFieldProps<T, Many>,
) => {
  const { translateErrors } = useLocale();
  const errors = translateErrors(props?.errors);

  return (
    <FormControl isInvalid={!!errors} isRequired={props.isRequired}>
      <FormLabel>{props.label}</FormLabel>
      <Autocomplete<T, Many> {...props} />
      <FormErrorMessage>{errors}</FormErrorMessage>
    </FormControl>
  );
};
