import { type AxiosInstance, type AxiosResponse } from 'axios';

import CubeAppEntity, { ErrorsEntity } from 'entities/CubeApp.ts';
import store from 'store.ts';

export function errorInterceptor(error: {
  response: { status: number; statusText: string; data: unknown };
}) {
  if (
    error.response &&
    (error.response.status === 400 || error.response.status === 500)
  ) {
    const state = store.getState();
    let appConfig = CubeAppEntity.duck.selectors.record(state, {
      id: null,
      dirty: true,
    });
    if (!appConfig) {
      appConfig = CubeAppEntity.dataToRecord({});
    }
    store.dispatch(
      CubeAppEntity.duck.actions.save_local(
        appConfig.set(
          'errors',
          ErrorsEntity.dataToRecord({}).merge({
            error_modal_open: true,
            status_text: error.response.statusText,
            status_code: error.response.status,
            data: error.response.data,
          }),
        ),
      ),
    );
  }
  return Promise.reject(error);
}

/**
 * This interceptor is registered after store instantiation
 * in order to prevent circular dependency issues
 *
 * @param axios
 */
export default function registerAppErrorInterceptor(axios: AxiosInstance) {
  axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    errorInterceptor,
  );
}
