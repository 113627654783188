import { useCallback } from 'react';

import SiteCompliancePlanEntity from 'entities/api/CompliancePlan/SiteCompliancePlan.ts';
import SiteEquipmentOperatingContextEntity from 'entities/api/CompliancePlan/SiteEquipmentOperatingContext.ts';
import { type OperatingContextEntityRecord } from 'entities/api/OperatingContext.ts';
import { useLocale } from 'locales/useLocale';
import { type RootEquipmentOperationControlsProps } from 'screens/compliance/tabs/OperatingEnvironment/tabs/OperatingContext/SiteCompliancePlanForm/Widgets/EquipmentOperationWidget/RootEquipmentOperationControls/RootEquipmentOperationControls.tsx';

const useRootEquipmentOperationControls = (
  props: RootEquipmentOperationControlsProps,
) => {
  const { equipmentOperationList, name, operatingContexts, onChange } = props;

  const { toString } = useLocale();

  const handleRootEquipmentOperationChange = useCallback(
    (operatingContext: OperatingContextEntityRecord) => {
      onChange({
        target: {
          name,
          value: SiteCompliancePlanEntity.updateAllEquipmentOperatingContexts(
            operatingContext,
            equipmentOperationList,
            !(
              SiteEquipmentOperatingContextEntity.getEquipmentOperationContextSize(
                operatingContext,
                equipmentOperationList,
              ) > 0
            ),
          ),
        },
      });
    },
    [equipmentOperationList, name, onChange],
  );

  return {
    equipmentOperationList,
    operatingContexts,
    onChange,
    toString,
    handleRootEquipmentOperationChange,
  };
};

export default useRootEquipmentOperationControls;
