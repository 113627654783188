import { Checkbox, Flex, Icon } from '@chakra-ui/react';

import { noop } from '@burnsred/ui-chakra';
import { icons } from 'assets/icons';
import type { TreePickerRecordProps } from 'components/widgets/TreePicker/DefaultComponents';

export const Record = ({
  children,
  item,
  hasChildren: _,
  isOpen,
  isSelected,
  level: _l,
  disabled,
  isIndeterminate,
  count,
  extraProps: _extraProps,
  ...rest
}: TreePickerRecordProps) => {
  return (
    <Flex sx={{}} {...rest}>
      <Checkbox
        variant="none"
        value={item.get('uuid') as string}
        isChecked={isSelected}
        isIndeterminate={isIndeterminate}
        onChange={noop}
        isDisabled={disabled}
      >
        <span className="TreePicker__Node__label">{children}</span>

        {count && <span className="TreePicker__Node__count">{count}</span>}

        {isOpen && (
          <Icon
            as={icons.MdOutlineChevronRight}
            className="TreePicker__Node__expanded-icon"
          />
        )}
      </Checkbox>
    </Flex>
  );
};
