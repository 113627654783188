import { type Moment } from 'moment';

import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import { toString } from '../i18n/I18nText';

/**
 * Stats for the 'Action Plan' page in compliance.
 */
class ApplicableControlForPlanReportEntity extends Entity {
  static paths = {
    apiBase: '/cube_compliance/v1/reporting/applicable_controls_report/',
  };

  static fields: EntityFields<ApplicableControlForPlanReportEntityFields> = {
    control_total: new Fields.NumberField({
      default: 0,
    }),
    controls_with_gaps_total: new Fields.NumberField({
      default: 0,
    }),
    controls_not_to_be_implemented_total: new Fields.NumberField({
      default: 0,
    }),
    gap_total: new Fields.NumberField({
      default: 0,
    }),
    action_plan_total: new Fields.NumberField({
      default: 0,
    }),
    implementation_cost_total: new Fields.NumberField({
      default: 0,
    }),
    latest_completion_date: new Fields.DateField(),
  };

  static toString = toString<ApplicableControlForPlanReportEntityRecord>;
}

export type ApplicableControlForPlanReportEntityFields = {
  control_total: number;
  controls_with_gaps_total: number;
  controls_not_to_be_implemented_total: number;
  gap_total: number;
  action_plan_total: number;
  implementation_cost_total: number;
  latest_completion_date: Moment;
};

export type ApplicableControlForPlanReportEntityRecord =
  EntityRecord<ApplicableControlForPlanReportEntityFields>;

ApplicableControlForPlanReportEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: ApplicableControlForPlanReportEntity,
  name: 'ApplicableControlForPlanReport',
});

export default ApplicableControlForPlanReportEntity;
