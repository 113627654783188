import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import DashboardPreferences, {
  useDashboardPreferences,
} from 'entities/api/Person/DashboardPreferences';
import { COMPLIANCE_TABS } from 'screens/compliance/tabs';

import { DASHBOARD_PATHS } from './DASHBOARD_PATHS';

export default function DefaultLandingPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dashboardPreference = useDashboardPreferences();

  useEffect(() => {
    if (!dashboardPreference) {
      dispatch(DashboardPreferences.duck.actions.get());
    } else {
      let dashboard = DASHBOARD_PATHS.global;
      if (dashboardPreference.get('is_framework_owner')) {
        dashboard = DASHBOARD_PATHS.frameworks;
      } else if (dashboardPreference.get('is_control_owner')) {
        dashboard = DASHBOARD_PATHS.controls;
      } else if (dashboardPreference.get('is_local_control_advisor')) {
        // not strictly a dashboard?
        return navigate(`/${COMPLIANCE_TABS.base}`);
      }
      navigate(`/dashboards/${dashboard}`);
    }
  }, [dashboardPreference, dispatch, navigate]);

  return null;
}
