import { type List, type Map } from 'immutable';
import { useCallback, useEffect, useMemo } from 'react';

import { useQuery } from '@burnsred/entity-duck-query';
import type { MPTTEntityRecord } from 'entities/api/MPTT';

import { type UseTreePickerReturn, useTreePicker } from '../TreePicker.hooks';

const HACK_RETURN_EMPTY_UUID = '00000000-0000-0000-0000-000000000000';

export type TreePickerListProps<T extends MPTTEntityRecord> = Pick<
  UseTreePickerReturn<T>,
  'Entity' | 'baseParams'
> & {
  level: number;
  parents: List<string>;
  items: List<T>;
  cachedQuery?: boolean;
};

/**
 * Handles fetching the items for this level of list
 *
 * (re-exports all of useTreePicker's returns)
 */
export const useTreePickerList = <
  T extends MPTTEntityRecord = MPTTEntityRecord,
>({
  level: _level,
  parent,
  cachedQuery = true,
}: {
  level: number;
  parent: string;
  cachedQuery?: boolean;
}) => {
  const treePickerContext = useTreePicker();
  const { Entity, baseParams, parents, onChangeList } = treePickerContext;

  const params = useMemo(
    () =>
      baseParams.merge({
        parent: parent ?? HACK_RETURN_EMPTY_UUID,
        // _debug: `level-${level}`,
      }) as Map<string, string>,
    [baseParams, parent],
  );
  const { value: children } = useQuery<List<T>>({
    action: Entity.duck.actions.get({ params }),
    persist: cachedQuery,
  });

  /** FIXME doesn't help Node */
  const getIsExpanded = useCallback(
    (item: T) => parents.includes(item?.get('uuid') as string),
    [parents],
  );

  useEffect(() => {
    if (children?.size) onChangeList({ parentUuid: parent, list: children });

    // don't re-run for onChangeList or we get a render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parent, children]);

  return {
    getIsExpanded,

    // re-export everything from useTreePicker
    ...treePickerContext,
  };
};

export type TreePickerListReturn = ReturnType<typeof useTreePickerList>;
