import { type List, Map } from 'immutable';
import { type Key, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import type { Entity } from '@burnsred/entity';
import { withQuery } from '@burnsred/entity-duck-query';
import { Loading } from '@burnsred/ui-chakra';
import type {
  BasicEntityRecord,
  WithQueryProps,
  WithQueryReturnProps,
} from 'types';
import { createLogger } from 'util/createLogger';

import { DefaultEmptyMessageComponent } from './DefaultEmptyMessageComponent';
import { DefaultListComponent } from './DefaultListComponent';
import { DefaultRecordComponent } from './DefaultRecordComponent';

const log = createLogger('SimpleEntityList');

export type SimpleEntityListProps<T = List<BasicEntityRecord>> =
  WithQueryReturnProps<T> & {
    RecordComponent: typeof DefaultRecordComponent;
    recordComponentProps: Record<string, unknown>;
    ListComponent?: typeof DefaultListComponent;
    listComponentProps?: Record<string, unknown>;
    EmptyMessageComponent?: typeof DefaultEmptyMessageComponent;
    clear: () => unknown;
    emptyMessage: string;
    refreshList?: number;
  };

export type SimpleEntityListRecordComponentProps<T = BasicEntityRecord> = {
  record: T;
};
const SimpleEntityList = (props: SimpleEntityListProps) => {
  log('props %o', props);

  const { formatMessage } = useIntl();

  const noResults = formatMessage({
    id: 'ui.list.no-results',
    defaultMessage: 'No results',
  });

  const {
    RecordComponent = DefaultRecordComponent,
    value,
    recordComponentProps,
    ListComponent = DefaultListComponent,
    listComponentProps,
    EmptyMessageComponent = DefaultEmptyMessageComponent,
    emptyMessage = noResults,
    refreshList = 0,
    clear,
  } = props;

  // workaround: without this intermediate state, incrementing refreshList triggers a render loop
  const [refreshCount, setRefreshCount] = useState(refreshList);

  useEffect(() => {
    if (refreshList > 0 && refreshList !== refreshCount) {
      clear();
      setRefreshCount(refreshList);
    }
  }, [refreshList, refreshCount, setRefreshCount, clear]);

  if (value?.isEmpty())
    return <EmptyMessageComponent>{emptyMessage}</EmptyMessageComponent>;

  return (
    <ListComponent {...listComponentProps} records={value}>
      {value.map((record) => (
        <RecordComponent
          key={record?.get('uuid') as Key /* || (record?.get('id') as Key) */}
          record={record}
          {...recordComponentProps}
        />
      ))}
    </ListComponent>
  );
};

type SimpleEntityListWithQueryProps = Partial<WithQueryProps> & {
  Entity: typeof Entity;
  params?: Map<string, string>;
} & Record<string, unknown>;

/**
 * Fetches, stores, and renders a list representation of the passed Entity.
 *
 * ```tsx
 * <SimpleEntityList
 *   Entity={SomeEntity}
 *   RecordComponent={SomeCustomRecordComponent}
 * />
 *
 * @see DefaultRecordComponent
 * ```
 */
const SimpleEntityListWithQuery = withQuery(
  (props: SimpleEntityListWithQueryProps) => {
    const Entity = props.Entity;
    const params = props?.params ?? Map(); // Entity.toFilterParams(props.filterParams);
    log('withQuery %o', { props, params });

    return {
      action: Entity.duck.actions.get({ params }),
      recordCountHidden: true,
      processingComponent: Loading,
    };
  },
)(SimpleEntityList);

export default SimpleEntityListWithQuery;
