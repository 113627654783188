import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  HStack,
  Heading,
  IconButton,
  Link,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { type List } from 'immutable';
import { useCallback } from 'react';
import { MdOutlineClear } from 'react-icons/md';
import { FormattedMessage, useIntl } from 'react-intl';

import { Loading } from '@burnsred/ui-chakra';
import { InputResizer, PhonePreviewFrame, VStack } from 'components';
import { FileItemRow, useFilePicker } from 'components/FilePicker';
import { QuestionAttachmentModal } from 'components/QuestionAttachmentModal';
import { getAttachmentUrl } from 'entities/api/BaseAttachment';
import I18nTextEntity, {
  type I18nTextEntityRecord,
} from 'entities/api/i18n/I18nText.ts';
import I18nTranslationEntity from 'entities/api/i18n/I18nTranslation.ts';
import { type BaseQuestionEntityFields } from 'entities/api/Question/BaseQuestion.ts';
import { type QuestionAttachmentEntityRecord } from 'entities/api/Question/QuestionAttachment.ts';
import { type SiteControlC3QuestionEntityRecord } from 'entities/api/Question/SiteC3Question.ts';
import { type SiteControlCCFVQuestionEntityRecord } from 'entities/api/Question/SiteCCFVQuestion.ts';
import { type SiteControlEntityRecord } from 'entities/api/SiteControl.ts';
import { Fieldset, FieldsetItem } from 'forms/fields';
import { ENGLISH } from 'locales';
import { useLocale } from 'locales/useLocale';
import type { createLogger } from 'util/createLogger';

import {
  type UseControlQuestionProps,
  useControlQuestions,
} from './QuestionsLayout.hooks';
import {
  deleteQuestionButtonSx,
  previewQuestionTextSx,
  questionsTabsSx,
} from './QuestionsLayout.styles';

type QuestionsLayoutProps = Pick<
  UseControlQuestionProps,
  'fieldName' | 'controlFormControls' | 'QuestionEntity'
> & {
  heading: string;
  log: ReturnType<typeof createLogger>;
  observationType: 'ControlCCC' | 'ControlFCC';
};

function setField(
  record: SiteControlEntityRecord,
  questions_field: QuestionsLayoutProps['fieldName'],
  text_field: keyof BaseQuestionEntityFields,
  index: number,
  value: unknown,
) {
  const questionList = record.get(questions_field);
  const question = questionList.get(index)?.get('site_question');
  const instruction = question?.get(text_field) as I18nTextEntityRecord;

  if (instruction) {
    // Field exists
    let translations = instruction.get('translations');
    const englishIndex = translations.findIndex((x) => {
      return x.get('language') === ENGLISH;
    });
    // English already exists update it
    if (englishIndex !== -1) {
      translations = translations?.setIn(
        [englishIndex, 'translated_message'],
        value,
      );
      return record.setIn(
        [questions_field, index, 'site_question', text_field, 'translations'],
        translations,
      );
    }
    // No English translation create and add
    else {
      let translation = I18nTranslationEntity.dataToRecord({});
      translation = translation.withMutations((rec) => {
        rec.set('language', ENGLISH);
        rec.set('translated_message', value);
      });
      return record.setIn(
        [questions_field, index, 'site_question', text_field],
        instruction.set(
          'translations',
          instruction.get('translations').push(translation),
        ),
      );
    }
  } else {
    const text = I18nTextEntity.dataToRecord({});
    let translation = I18nTranslationEntity.dataToRecord({});
    translation = translation.withMutations((rec) => {
      rec.set('language', ENGLISH);
      rec.set('translated_message', value);
    });

    return record.setIn(
      [questions_field, index, 'site_question', text_field],
      text.set('translations', text.get('translations').push(translation)),
    );
  }
}

export const QuestionsLayout = ({
  heading,
  fieldName,
  controlFormControls,
  QuestionEntity,
  log,
  observationType,
}: QuestionsLayoutProps) => {
  const { locale, toString } = useLocale();
  const toast = useToast();
  const { fileList, setFileList, batchUploadFilerFileList } = useFilePicker([]);
  const {
    isOpen: isOpenFileModal,
    onOpen: onOpenFileModal,
    onClose: onCloseFileModal,
  } = useDisclosure();

  const useControlReturn = useControlQuestions({
    fieldName,
    controlFormControls,
    locale,
    QuestionEntity,
  });
  const {
    currentTab,
    setCurrentTab,
    questions,
    currentQuestion,
    currentInstruction,
    showInstructions,
    setShowInstructions,
  } = useControlReturn;

  const {
    onChange,
    onSubmit,
    value: control,
    // valueInitial: controlInitial,
    name,
  } = controlFormControls;
  log('%o QuestionsLayout', { control, useControlReturn });

  const handleQuestionDelete = (index: number) => {
    const newQuestionList = control.deleteIn([fieldName, index]).get(fieldName);

    const orderedQuestionList = newQuestionList.withMutations((rec) => {
      for (index = 0; index < rec.size; index++) {
        rec.setIn([index, 'order'], index);
      }
    });

    return onChange({
      target: {
        name: name,
        value: control.set(fieldName, orderedQuestionList),
      },
    });
  };

  const handleAttachmentDelete = (
    record: SiteControlEntityRecord,
    index: number,
    attachmentIndex: number,
    site_control_question:
      | SiteControlC3QuestionEntityRecord
      | SiteControlCCFVQuestionEntityRecord,
  ) => {
    const site_question_attachments = site_control_question.getIn([
      'site_question',
      'attachments',
    ]) as List<QuestionAttachmentEntityRecord>;
    const new_site_question_attachments = site_question_attachments
      .slice()
      .splice(attachmentIndex, 1);

    return onChange({
      target: {
        name: name,
        value: record.setIn(
          [fieldName, index, 'site_question', 'attachments'],
          new_site_question_attachments,
        ),
      },
    });
  };

  const handleQuestionMediaChange = useCallback(
    (new_attachments: QuestionAttachmentEntityRecord) => {
      const value = control.withMutations((rec) => {
        const updated_attachments = (
          rec.getIn([
            fieldName,
            currentTab,
            'site_question',
            'attachments',
          ]) as List<QuestionAttachmentEntityRecord>
        ).push(new_attachments);
        return rec.setIn(
          [fieldName, currentTab, 'site_question', 'attachments'],
          updated_attachments,
        );
      });

      onChange({
        target: {
          name: name,
          value: value,
        },
      });
      onCloseFileModal();
    },
    [control, onChange, name, onCloseFileModal, fieldName, currentTab],
  );
  const { formatMessage } = useIntl();

  const phonePreviewHeader =
    observationType === 'ControlCCC'
      ? formatMessage({
          id: 'ControlCCC.heading.critical-control-check',
          defaultMessage: 'Critical control check',
        })
      : formatMessage({
          id: 'ControlFCC.heading.critical-control-check',
          defaultMessage: 'Field control confirmation',
        });

  return (
    <VStack>
      <Stack direction={{ base: 'column', lg: 'row' }}>
        <VStack>
          <Heading size="sm">{heading}</Heading>

          <Tabs
            index={currentTab}
            onChange={setCurrentTab}
            variant="line"
            sx={questionsTabsSx}
          >
            {questions.size < 10 ? (
              <TabList>
                {(questions ?? [0]).map((_: unknown, i: number) => (
                  <Tab key={i}>{`Q${i + 1}`}</Tab>
                ))}
              </TabList>
            ) : (
              <HStack>
                <label htmlFor="tab-select">Select Question: </label>
                <Select
                  id="tab-select"
                  value={currentTab}
                  onChange={(event) =>
                    setCurrentTab(Number(event.target.value))
                  }
                  rootProps={{ sx: { maxWidth: 32 } }}
                >
                  {questions.map((_q, i) => (
                    <option key={i} value={i}>
                      Q{i + 1}
                    </option>
                  ))}
                </Select>
              </HStack>
            )}

            <TabPanels>
              {!questions && (
                <TabPanel height="auto">
                  <Loading />
                </TabPanel>
              )}

              {questions && questions.count() > 0 ? (
                questions?.map((question, i: number) => {
                  const siteQuestion = question?.get('site_question');

                  return (
                    <TabPanel key={i}>
                      <Fieldset columnGap={2} rowGap={6}>
                        <FieldsetItem colSpan={11}>
                          <FormControl>
                            <FormLabel>
                              <FormattedMessage
                                id="questionsLayout.question-number"
                                defaultMessage="Question #"
                              />
                              {currentTab + 1}
                            </FormLabel>
                            <InputResizer value={toString(siteQuestion)}>
                              <Textarea
                                isDisabled
                                value={toString(siteQuestion)}
                                onChange={(evt) => {
                                  onChange({
                                    target: {
                                      name: name,
                                      value: setField(
                                        control,
                                        fieldName,
                                        'title',
                                        i,
                                        evt.target.value,
                                      ),
                                    },
                                  });
                                }}
                              />
                            </InputResizer>
                          </FormControl>
                        </FieldsetItem>

                        <GridItem>
                          <IconButton
                            // NOTE - Connor: Force disabled 01/08 as per client request
                            isDisabled
                            icon={<MdOutlineClear />}
                            aria-label="Delete Question"
                            variant="ghost"
                            onClick={() => {
                              handleQuestionDelete(currentTab);
                              toast({
                                title: formatMessage(
                                  {
                                    id: 'questionsLayout.control-question-removed',
                                    defaultMessage:
                                      'Control question {index} removed',
                                  },
                                  { index: i + 1 },
                                ),
                                status: 'info',
                              });
                              setCurrentTab(0);
                            }}
                            sx={deleteQuestionButtonSx}
                          />
                        </GridItem>

                        <FieldsetItem colSpan={11}>
                          <FormControl>
                            <FormLabel>
                              <FormattedMessage
                                id="questionsLayout.instruction-number"
                                defaultMessage="Instruction #"
                              />
                              {currentTab + 1}
                            </FormLabel>

                            <InputResizer
                              value={toString(siteQuestion, 'instruction')}
                            >
                              <Textarea
                                isDisabled
                                value={toString(siteQuestion, 'instruction')}
                                onChange={(evt) => {
                                  onChange({
                                    target: {
                                      name: name,
                                      value: setField(
                                        control,
                                        fieldName,
                                        'instruction',
                                        i,
                                        evt.target.value,
                                      ),
                                    },
                                  });
                                }}
                              />
                            </InputResizer>
                          </FormControl>
                        </FieldsetItem>

                        <FieldsetItem colSpan={12}>
                          <Button
                            // NOTE - Connor: Force disabled 01/08 as per client request
                            isDisabled
                            variant="outline"
                            onClick={onOpenFileModal}
                          >
                            <FormattedMessage
                              id="questionsLayout.attachMedia"
                              defaultMessage="Attach media"
                            />
                          </Button>
                        </FieldsetItem>

                        <FieldsetItem colSpan={11}>
                          <VStack>
                            {siteQuestion
                              ?.get('attachments')
                              ?.map(
                                (questionAttachment, questionIndex: number) => (
                                  <FileItemRow
                                    key={questionIndex}
                                    canDelete
                                    onDelete={() => {
                                      handleAttachmentDelete(
                                        control,
                                        i,
                                        questionIndex,
                                        question,
                                      );
                                    }}
                                    name={
                                      toString(questionAttachment) ||
                                      questionAttachment
                                        .get('attachment')
                                        .get('name')
                                    }
                                    url={getAttachmentUrl(questionAttachment)}
                                  />
                                ),
                              )}
                          </VStack>
                        </FieldsetItem>
                      </Fieldset>
                    </TabPanel>
                  );
                })
              ) : (
                <VStack marginBottom={4}>
                  <Card variant="filled" bgColor="gray.200">
                    <CardBody>
                      <FormattedMessage
                        id="questionsLayout.noCurrentQuestions"
                        defaultMessage="No current questions"
                      />
                    </CardBody>
                  </Card>
                </VStack>
              )}

              <VStack>
                <FieldsetItem colSpan={12}>
                  <Button
                    // NOTE - Connor: Force disabled 01/08 as per client request
                    // isDisabled={control.get(fieldName).count() >= 8}
                    isDisabled
                    variant="outline"
                    onClick={() => {
                      onChange({
                        target: {
                          name: name,
                          value: control.set(
                            fieldName,
                            control.get(fieldName).push(
                              QuestionEntity.dataToRecord({
                                order: questions.size,
                              }),
                            ),
                          ),
                        },
                      });
                      setCurrentTab(questions.size);
                    }}
                  >
                    <FormattedMessage
                      id="questionsLayout.add-question"
                      defaultMessage="Add a question"
                    />
                  </Button>
                </FieldsetItem>

                <FieldsetItem colSpan={12}>
                  <Button
                    // NOTE - Connor: Force disabled 01/08 as per client request
                    // isDisabled={controlInitial?.equals(control)}
                    isDisabled
                    onClick={() => {
                      toast({
                        title: formatMessage({
                          id: 'questionsLayout.toast.saving',
                          defaultMessage: 'Saving...',
                        }),
                        status: 'loading',
                      });
                      onSubmit({
                        target: {
                          name: name,
                          value: control,
                        },
                      }).promise.then((action) => {
                        toast.closeAll();
                        if (action.name === 'save_resolved') {
                          toast({
                            title: formatMessage({
                              id: 'questionsLayout.toast.save-questions',
                              defaultMessage: 'Control questions saved',
                            }),
                            status: 'success',
                          });
                        }
                        if (action.name === 'save_rejected') {
                          toast({
                            title: formatMessage({
                              id: 'questionsLayout.toast.error-save-questions',
                              defaultMessage:
                                'Control questions failed to save',
                            }),
                            status: 'error',
                          });
                        }
                      });
                    }}
                  >
                    <FormattedMessage
                      id="questionsLayout.save"
                      defaultMessage="Save"
                    />
                  </Button>
                </FieldsetItem>
              </VStack>
            </TabPanels>
          </Tabs>
        </VStack>

        <PhonePreviewFrame heading={phonePreviewHeader}>
          <Flex as="header" gap={2} align="center">
            <Box rounded="xl" boxSize="12" bgColor="gray.100" />
            <Text color="gray.300" fontWeight="bold">
              <FormattedMessage
                id="control-framework.risk"
                defaultMessage="Risk"
              />
            </Text>
          </Flex>

          <Text sx={previewQuestionTextSx}>
            {toString(questions?.get(currentTab)?.get('site_question'))}
          </Text>

          <Button
            variant="outline"
            onClick={setShowInstructions.toggle}
            isDisabled={!currentInstruction}
          >
            <FormattedMessage
              id="questionsLayout.instructions"
              defaultMessage="Instructions"
            />
          </Button>

          {showInstructions && (
            <>
              <Text sx={previewQuestionTextSx}>
                {toString(currentQuestion, 'instruction')}
              </Text>

              {currentQuestion?.get('attachments')?.map((attachment, index) => (
                <Link
                  key={index}
                  href={getAttachmentUrl(attachment)}
                  isExternal
                >
                  {toString(attachment) ||
                    attachment.get('attachment').get('name')}
                </Link>
              ))}
            </>
          )}
        </PhonePreviewFrame>
      </Stack>

      <QuestionAttachmentModal
        isOpen={isOpenFileModal}
        onOpen={onOpenFileModal}
        onClose={onCloseFileModal}
        onConfirm={handleQuestionMediaChange}
        fileList={fileList}
        setFileList={setFileList}
        batchUploadFilerFileList={batchUploadFilerFileList}
      />
    </VStack>
  );
};
