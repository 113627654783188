import ApplicableControlForPlanEntity, {
  type ApplicableControlForPlanEntityRecord,
} from 'entities/api/CompliancePlan/ApplicableControlForPlan';

export default function useGetAssessmentMandate(
  value: ApplicableControlForPlanEntityRecord,
) {
  return {
    hasAssess: ApplicableControlForPlanEntity.shouldHaveAssess(value),
    hasMandatory: ApplicableControlForPlanEntity.shouldHaveMandatory(value),
  };
}
