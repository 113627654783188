import {
  type Colors,
  type Pseudos,
  type SemanticValue,
} from '@chakra-ui/react';

import { baseColors } from './foundations/baseColors';

/**
 * Type extracted from `ChakraTheme['semanticTokens']`
 *
 * > A semantic token value can be a string | SemanticValue. The string value is
 * > used as reference to another token in the same scale
 * @see https://chakra-ui.com/docs/styled-system/semantic-tokens#token-references
 */
type SemanticColorTokens = Record<string, SemanticValue<keyof Pseudos>>;

/**
 * Colour roles for use in components, which are merged into semanticTokens.
 *
 * > Chakra UI supports conditional semantic tokens for every scale (colors,
 * > font sizes, etc). This allows to change the value of a token depending on
 * > the environment, like dark mode, direction and other CSS selectors.
 * > @see https://chakra-ui.com/docs/styled-system/semantic-tokens
 *
 * > The object notation SemanticValue allows to define the default value and
 * > conditional keys. The condition can be one of chakra pseudo selectors or a
 * > custom CSS selector. Try _dark, _light, _rtl, _ltr and _mediaReduceMotion.
 * > @see https://chakra-ui.com/docs/styled-system/semantic-tokens#conditional-tokens
 *
 * Default values:
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/semantic-tokens.ts
 */
export const semanticColors: SemanticColorTokens = {
  /**
   * These chakra-* tokens set baseline values
   *
   * Don't rename these keys! They're hard-coded into the base theme, not
   * affected by `theme.config.cssVarPrefix`. They will be rendered as
   * var(--cssVarPrefix-colors-chakra-border-color), defaulting to
   * var(--chakra-colors-chakra-border-color)
   */
  'chakra-body-text': {
    _light: 'black',
    _dark: 'whiteAlpha.900',
  },
  'chakra-body-bg': {
    _light: 'pageBackground',
    _dark: 'gray.800',
  },
  'chakra-border-color': {
    _light: 'gray.300',
    _dark: 'whiteAlpha.300',
  },
  'chakra-subtle-bg': {
    _light: 'gray.100',
    _dark: 'gray.700',
  },
  'chakra-placeholder-color': {
    _light: 'gray.500',
    _dark: 'whiteAlpha.400',
  },
  // end baseline tokens

  headingText: 'chakra-body-text',

  // We can alias other colours like this:
  bodyText: 'chakra-body-text',
  bodyTextInverted: {
    _light: 'whiteAlpha.900',
    _dark: 'gray.800',
  },

  bodyBg: 'chakra-body-bg',
  bodyBgInverted: {
    _light: 'gray.800',
    _dark: 'whiteAlpha.900',
  },

  border: 'chakra-border-color',
  subtleBg: 'chakra-subtle-bg',
  placeholder: 'chakra-placeholder-color',

  primary: {
    default: 'brandOrange500',
  },
  secondary: {
    default: 'brandpink500',
  },

  muted: {
    default: 'gray.500',
  },
  faded: {
    default: 'gray.400',
  },
  faint: {
    default: 'gray.300',
  },
  superFaint: {
    default: 'gray.200',
  },
  subtle: {
    default: 'gray.100',
  },

  /**
   * Icon is not theme-able, so:
   *
   * ```tsx
   * // defaults to currentColor
   * <Icon as={MdSettings} />
   * <Icon as={MdSettings} color="icon" />
   * ```
   */
  icon: 'muted',
  iconLight: 'faded',

  // TODO one day, provide dark variants for each
  // See also colour definitions in Alert:
  // https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/alert/src/alert-context.ts#L20
  errorFg: 'red.500',
  errorBg: 'red.100',
  warningFg: 'orange.500',
  warningBg: 'orange.100',
  infoFg: 'blue.500',
  infoBg: 'blue.100',
  successFg: 'green.500',
  successBg: 'green.100',
  disabledFg: 'gray.500',
  disabledBg: 'gray.100',

  // simple values alias the foreground colours defined above
  error: 'errorFg',
  warning: 'warningFg',
  info: 'infoFg',
  success: 'successFg',
  disabled: 'disabledFg',

  // https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/input.ts#L73
  borderFocus: {
    _light: 'info',
    _dark: 'blue.300',
  },
  borderError: {
    _light: 'error',
    _dark: 'red.300',
  },

  // note: one-indexed
  timezone1: 'gray.400',
  timezone2: 'brandDustyOrange',
  timezone3: 'brandPurplishPink',
  timezone4: 'brandWarmPurple',
  timezone5: 'brandDarkishPurple',
  timezone6: 'gray.400',
  timezoneEmpty: 'gray.100',

  // these are ordered in the backend - order must match!
  controlType_1: 'gray.400', // support factor
  controlType_2: 'brandBottleGreen', // interrupt
  controlType_3: 'brandSageGreen', // assisted interrupt
  controlType_4: 'brandSkyBlue', // human action
  controlType_5: 'brandDarkBlue', // damage reduction
  controlType_6: 'gray.400', // other

  badge_mandatory: 'primary',
  badge_assess: 'brandStormyBlue',
};

/**
 * Raw colours available in the theme.
 *
 * ℹ️ THIS IS THE ONLY PLACE IN THE ENTIRE CODEBASE WHERE HEX VALUES SHOULD EXIST!
 *
 * In general, prefer using a color _role_ from `semanticColors`
 * in your components, eg: 'primary', 'secondary', 'error'.
 *
 * As much as possible, try to think of the color in generalised terms, eg:
 *
 * > <think> "this borderColor should be the same as all other borderColors,
 * > so there should be a shared token for this role"
 *
 * ```ts
 * // ❌❌ never use hex outside this
 * borderColor: '#fff',
 *
 * // ❌ better, but avoid using ad-hoc colors which will result inconsistencies
 * borderColor: 'gray.300',
 *
 * // ✅ best, use the appropriate generalisation
 * borderColor: 'border', // set in sematicColors to 'gray.300'
 * ```
 *
 * > By default these colors can be referenced by the `color`, `borderColor`,
 * > `backgroundColor`, `fill`, `stroke`, styles.
 * >
 * > We recommend adding a palette that ranges from 50 to 900. Tools like Themera,
 * > Smart Swatch, Coolors or Palx are available to generate these palettes.
 * > @see https://chakra-ui.com/docs/styled-system/theme#colors
 *
 * @see https://chakra-ui.com/docs/styled-system/customize-theme#customizing-theme-tokens
 */
export const colors: Colors = {
  // chakra base colors - don't edit these
  ...baseColors,

  pageBackground: '#f5f5f5',
  brandOrange500: '#e65400',
  brandPurplishPink: '#d04d97',
  brandDustyOrange: '#f67b2f', // brand/bhp/brand.100
  brandWarmPurple: '#9f2996',
  brandDarkishPurple: '#742068',

  brandBottleGreen: '#2a7e72',
  brandSkyBlue: '#2da3d7',
  brandDarkBlue: '#245091',
  brandSageGreen: '#3cad95',
  brandBlueGrey: '#d8e0e3', // 'BHP_Blue grey 4'
  brandStormyBlue: '#4e879d',

  // these are aliased full colorSchemes, allowing us to reference semantic
  // colorSchemes, eg `<Badge colorScheme='success'>Success</Badge>`
  error: baseColors.red,
  warning: baseColors.orange,
  success: baseColors.green,
  info: baseColors.blue,
  cancel: baseColors.gray,
  delete: baseColors.red,
  link: baseColors.blue,
};
