import { ListItem, Td, Text, UnorderedList } from '@chakra-ui/react';
import { type List } from 'immutable';

import { Tooltip } from 'components';
import {
  scenarioTooltipSx,
  tooltipTextSx,
} from 'components/ControlGrid/components/MandatoryAssessTable.styles';
import type { ScenarioEntityRecord } from 'entities/api/Scenario';
import { useLocale } from 'locales/useLocale';

export type ScenarioCellProps = {
  damageEnergyToScenarioMap: Map<string, List<ScenarioEntityRecord>>;
  scenarios: List<ScenarioEntityRecord> | undefined;
};

export const ScenarioCell = ({
  damageEnergyToScenarioMap,
}: ScenarioCellProps) => {
  const { toString } = useLocale();

  return (
    <Td rowSpan={0} className="scenarios">
      <UnorderedList styleType="none" paddingLeft={0}>
        {Array.from(damageEnergyToScenarioMap.entries())
          .filter(([_, scenarioList]) => scenarioList && scenarioList.size > 0) // Ensure scenarios exist
          .map(([damageEnergy, scenarioList], i) => (
            <ListItem key={i} paddingBottom={3}>
              <Text fontWeight="bold" paddingBottom={1}>
                {damageEnergy}
              </Text>

              <UnorderedList styleType="none">
                {scenarioList.toArray().map((scenario, _j) => {
                  const description = toString(scenario, 'description');
                  return (
                    <ListItem
                      key={scenario.get('uuid')}
                      className={description ? 'has-tooltip' : ''}
                      sx={scenarioTooltipSx}
                    >
                      {description ? (
                        <Tooltip
                          label={<Text sx={tooltipTextSx}>{description}</Text>}
                          placement="left-start"
                        >
                          <span>{toString(scenario)}</span>
                        </Tooltip>
                      ) : (
                        <span>{toString(scenario)}</span>
                      )}
                    </ListItem>
                  );
                })}
              </UnorderedList>
            </ListItem>
          ))}
      </UnorderedList>
    </Td>
  );
};
