import { Grid, GridItem } from '@chakra-ui/react';
import { type List } from 'immutable';

import { type EquipmentEntityRecord } from 'entities/api/Equipment.ts';
import { useLocale } from 'locales/useLocale.tsx';

type ScenarioTableHeaderProps = {
  equipmentLevel1: List<EquipmentEntityRecord>;
  equipmentLevel2: List<EquipmentEntityRecord>;
};

export const ScenarioTableHeader = (props: ScenarioTableHeaderProps) => {
  const { equipmentLevel1, equipmentLevel2 } = props;
  const { toString } = useLocale();

  if (!equipmentLevel1 || !equipmentLevel2) return;

  return (
    <Grid
      templateColumns="repeat(10, 1fr)"
      templateRows="repeat(2, 1fr)"
      gap="2px"
      w="100%"
      sx={{
        '> div': {
          color: 'white',
          fontSize: 'xs',
          textAlign: 'center',
          alignContent: 'center',
          paddingY: '3px',
        },
        '> div.l1': {
          backgroundColor: 'gray.400',
          paddingX: 0,
        },
        '> div.l2': {
          backgroundColor: 'gray.600',
          display: '-webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
        },
      }}
    >
      <GridItem
        colSpan={2}
        rowSpan={2}
        sx={{ backgroundColor: 'white !important' }}
      />
      {equipmentLevel1.map((lvl1) => {
        const children = equipmentLevel2.filter(
          (lvl2) => lvl2.get('parent') === lvl1.get('uuid'),
        );
        const rowSpan = children.size > 0 ? 1 : 2;

        return (
          <GridItem
            key={lvl1.get('uuid')}
            rowSpan={rowSpan}
            colSpan={children.size ?? 1}
            bg="gray.200"
            p={2}
            textAlign="center"
            className="l1"
          >
            {toString(lvl1)}
          </GridItem>
        );
      })}
      {equipmentLevel1.map((lvl1) =>
        equipmentLevel2
          .filter((lvl2) => lvl2.get('parent') === lvl1.get('uuid'))
          .map((lvl2) => (
            <GridItem
              key={lvl2.get('uuid')}
              bg="gray.400"
              p={2}
              textAlign="center"
              className="l2"
            >
              {toString(lvl2)}
            </GridItem>
          )),
      )}
    </Grid>
  );
};
