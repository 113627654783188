import { useMemo, useState } from 'react';
import { useOutletContext } from 'react-router';

import type { PaginatorProps, PaginatorState } from 'components';
import { ControlListFilterEntity, usePagination } from 'entities';
import SiteControlEntity from 'entities/api/SiteControl';
import { type ControlListFilterEntityRecord } from 'entities/filter/ControlListFilter.ts';
import { useEntityFilterControls } from 'forms/hooks/filters';
import { useLocale } from 'locales/useLocale';
import type { FrameworkOutletContext } from 'screens/control-frameworks/ControlFramework.tsx';

export type UseControlListControlsReturn = ReturnType<
  typeof useControlListControls
>;

export function useControlListControls() {
  const {
    name,
    filterRecord: rawFilterRecord,
    onChange,
    FilterField,
    errors,
  } = useEntityFilterControls<
    typeof ControlListFilterEntity,
    ControlListFilterEntityRecord
  >(
    ControlListFilterEntity,
    'ControlListFilters',
    ControlListFilterEntity.onChangeClean,
  );
  const { siteFramework } = useOutletContext<FrameworkOutletContext>();
  const filterRecord = useMemo(
    () =>
      rawFilterRecord.set(
        'rule_site_framework',
        siteFramework?.get('uuid') || '',
      ),
    [siteFramework, rawFilterRecord],
  );

  // memoise params to prevent a duplicate API call triggered by useSelector call
  const params = useMemo(
    () => ControlListFilterEntity.asMappedParams(filterRecord),
    [filterRecord],
  );
  const [appliedParams, setAppliedParams] = useState(params);
  const handleApplyParams = () => {
    //reset page to 1 on each API call to avoid invalid page
    const updatedParams = params.set('page', '1');
    setAppliedParams(updatedParams);
  };

  const pagination = usePagination(SiteControlEntity, appliedParams);
  const controlCount = pagination?.get('count') || '0';

  const onChangePagination = ({ page, page_size }: PaginatorState) => {
    const appliedParamsWithPaginationProps = appliedParams
      .set('page', String(page || 1))
      .set('page_size', String(page_size || '8'));
    setAppliedParams(appliedParamsWithPaginationProps);

    // note that filterRecord stores page & page_size as strings, and Paginator requires numbers
    const newRecord = filterRecord.withMutations((rec) => {
      rec.set('page', String(page) || '1');
      rec.set('page_size', String(page_size) || '10');
    });
    onChange({
      target: { name, value: newRecord },
    });
  };

  const paginatorProps: PaginatorProps = {
    page: parseInt(filterRecord?.get('page') ?? '1'),
    page_size: parseInt(filterRecord?.get('page_size') ?? '23'),
    totalItems: pagination?.get('count') ?? 0,
    onChange: onChangePagination,
    pageSizeOptions: [23, 47],
  };
  const localeContext = useLocale();

  const damagingEnergyMechanismFilterParams = {
    damage_energy__risk: siteFramework.get('risk').get('uuid'),
  };

  return {
    name,
    filterRecord,
    onChange,
    FilterField,
    errors,
    localeContext,
    paginatorProps,
    siteFramework,
    controlCount,
    handleApplyParams,
    appliedParams,
    damagingEnergyMechanismFilterParams,
  };
}
