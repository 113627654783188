import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  type SystemStyleObject,
} from '@chakra-ui/react';
import { enUS, es } from 'date-fns/esm/locale';
import type { Moment } from 'moment';
import moment from 'moment';
import { registerLocale } from 'react-datepicker';

import type { InputProps } from '@burnsred/entity-form';
import { filterProps } from '@burnsred/higher-order-component';
import {
  type DatePickerProps,
  DatePicker as RawDatePicker,
} from 'components/Date';
import { useLocale } from 'locales/useLocale';

export type InputWidgetProps = Omit<InputProps, 'value'> & {
  value: Moment;
  label?: string;
  /**
   * passed to FormControl
   *
   * note: this overrides `sx` which is passed to the DatePicker,
   * but can be used in exactly the same way.
   */
  sx?: SystemStyleObject;
} & Omit<DatePickerProps, 'isDisabled'>;

/**
 * Renders a Chakra Input, complete with FormControl & FormErrorMessage
 *
 * @see RawInput for an Input which renders only the Input
 */
export const DatePicker = (props: InputWidgetProps) => {
  const {
    label,
    size,
    variant,
    colorScheme,
    sx,
    value,
    onChange: _onChange,

    // DatePicker props
    disabled,
    customInputRef,
    showTimeSelect,
    showTimeSelectOnly,
    dateFormat,
    showMonthYearDropdown,
    showPopperArrow,
    popperProps,
    placeholderText,
    showMonthDropdown,
    showYearDropdown,
    scrollableYearDropdown,
    yearDropdownItemNumber,
    dropdownMode,
    isClearable,
    selectsEnd,
    selectsStart,
    startDate,
    endDate,
    minDate,
    maxDate,
    open,
    inputComponent,

    // Replaceable elements,
    calendarContainer,
    customInput,
    customTimeInput,
    formatWeekDay,
    nextMonthButtonLabel,
    nextYearButtonLabel,
    popperContainer,
    previousMonthButtonLabel,
    previousYearButtonLabel,
    renderCustomHeader,
    renderDayContents,
    todayButton,
    // /DatePickerProps

    ...inputProps
  } = props;

  /**
   * Explicitly defined DatePicker props.
   * There are more, but prioritise these,
   * for which we've previously had use-cases.
   */
  const datePickerProps = Object.fromEntries(
    Object.entries({
      disabled,
      customInputRef,
      showTimeSelect,
      showTimeSelectOnly,
      dateFormat,
      showMonthYearDropdown,
      showPopperArrow,
      popperProps,
      placeholderText,
      showMonthDropdown,
      showYearDropdown,
      scrollableYearDropdown,
      yearDropdownItemNumber,
      dropdownMode,
      isClearable,
      selectsEnd,
      selectsStart,
      startDate,
      endDate,
      minDate,
      maxDate,
      open,
      inputComponent,
      size,
      variant,
      colorScheme,
      // Replaceable elements,
      calendarContainer,
      customInput,
      customTimeInput,
      formatWeekDay,
      nextMonthButtonLabel,
      nextYearButtonLabel,
      popperContainer,
      previousMonthButtonLabel,
      previousYearButtonLabel,
      renderCustomHeader,
      renderDayContents,
      todayButton,
    })
      // workaround: drop undefined keys, as they were clobbering defaults in the component
      .flatMap(([key, value]) => (value === undefined ? [] : [[key, value]])),
  );

  const { locale, translateErrors } = useLocale();
  const errors = translateErrors(props?.errors);

  const localeMap: Record<string, Locale> = {
    en: enUS,
    es: es,
  };

  if (localeMap[locale]) {
    registerLocale(locale, localeMap[locale]);
  }

  const onChange = (date: Date) => {
    _onChange({
      target: {
        name: props.name,
        // note: DatePicker returns null if it can't construct a valid Date
        value: date == null ? null : moment(date),
      },
    });
  };

  return (
    <FormControl isInvalid={!!errors} isDisabled={props.disabled} sx={sx}>
      {label && <FormLabel>{label}</FormLabel>}
      <RawDatePicker
        {...filterProps(inputProps)}
        {...datePickerProps}
        value={value ?? null}
        onChange={onChange}
        isDisabled={disabled}
        locale={locale}
        size={size}
        variant={variant}
        colorScheme={colorScheme}
      />
      <FormErrorMessage>{errors}</FormErrorMessage>
    </FormControl>
  );
};
