import type { SiteCompliancePlanEntityRecord } from 'entities/api/CompliancePlan/SiteCompliancePlan';

/**
 * Note that the routing to tabs is funky because:
 * - we maintain siteCompliancePlan context when navigating between tabs
 * - siteCompliancePlan context is not available on when creating a new
 *   CompliancePlan (operating-environment tab)
 *
 * As such, when navigating, the route looks different if we've got
 * :siteCompliancePlanUuid or not. @see getComplianceTabRoute
 */
export const COMPLIANCE_TABS = {
  base: 'compliance',
  tabPaths: {
    operatingEnvironment: {
      routeName: 'operating-environment',
      index: 0,
    },
    baselineAssessment: {
      routeName: 'baseline-assessment',
      index: 1,
    },
    actions: {
      routeName: 'actions',
      index: 2,
    },
  },
} as const;

export type ComplianceTabsRouteNames =
  (typeof COMPLIANCE_TABS)['tabPaths'][keyof (typeof COMPLIANCE_TABS)['tabPaths']]['routeName'];

/**
 * Navigate conditionally based on whether we have a siteCompliancePlanUuid,
 * maintaining context where possible.
 *
 * Navigate to the first siteCompliancePlan if no siteCompliancePlanUuid is
 * known (baseline/actions tabs are disabled if there are no
 * siteCompliancePlans).
 */
export const getComplianceTabRoute = ({
  targetTab,
  compliancePlanUuid,
  siteCompliancePlanUuid,
  firstSiteCompliancePlan,
}: {
  targetTab: ComplianceTabsRouteNames;
  compliancePlanUuid?: string;
  siteCompliancePlanUuid?: string;
  firstSiteCompliancePlan?: SiteCompliancePlanEntityRecord;
}) => {
  let route = '';
  if (targetTab == 'operating-environment') {
    route = `/${COMPLIANCE_TABS.base}/${compliancePlanUuid}/`;
  } else if (siteCompliancePlanUuid) {
    // note: we can't currently navigate to the same control on the other tab
    // because controlUuid is actually:
    // ApplicableRulForPlan.uuid, not ApplicableRulForPlan.global_control.uuid
    route = `/${COMPLIANCE_TABS.base}/${compliancePlanUuid}/site/${siteCompliancePlanUuid}/${targetTab}`;
  } else {
    // we're coming from the operating-environment tab, go to the first defined SiteCompliancePlan
    route = `/${COMPLIANCE_TABS.base}/${compliancePlanUuid}/site/${firstSiteCompliancePlan?.get('uuid')}/${targetTab}`;
  }
  return route;
};
