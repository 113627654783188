import type { SystemStyleObject } from '@chakra-ui/react';

export const cardSx: SystemStyleObject = {
  border: '1px',
  borderRadius: 'base',
  shadow: 'lg',
  fontSize: 'sm',
  gap: 3,
  padding: 3,
  minHeight: 32,

  '.chakra-card__header': {
    padding: 0,
  },
  '.chakra-card__body': {
    minHeight: '8',
    padding: 0,
    lineHeight: 'short',
  },
  '.chakra-card__footer': {
    padding: 0,
  },

  '&.disabled .chakra-linkbox a': {
    pointerEvents: 'none',
    cursor: 'default',
  },
};

export const cardHeaderSx: SystemStyleObject = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
};

export const headingSx: SystemStyleObject = {
  marginRight: 'auto',
  fontSize: 'sm',
  fontWeight: 'bold',
};
