import { Checkbox, GridItem } from '@chakra-ui/react';
import { type List } from 'immutable';

import SiteEquipmentOperatingContextEntity, {
  type SiteEquipmentOperatingContextEntityRecord,
} from 'entities/api/CompliancePlan/SiteEquipmentOperatingContext';
import { type OperatingContextEntityRecord } from 'entities/api/OperatingContext';
import { operatingContextGridItemProps } from 'screens/compliance/tabs/OperatingEnvironment/tabs/OperatingContext/OperatingContext.styles.ts';
import useRootEquipmentOperationControls from 'screens/compliance/tabs/OperatingEnvironment/tabs/OperatingContext/SiteCompliancePlanForm/Widgets/EquipmentOperationWidget/RootEquipmentOperationControls/RootEquipmentOperationControls.hooks.ts';
import { type EntityDuckAction, type FormChangeEvent } from 'types';

import { checkboxSx } from './RootEquipmentOperationControls.styles';

export type RootEquipmentOperationControlsProps = {
  equipmentOperationList: List<SiteEquipmentOperatingContextEntityRecord>;
  operatingContexts: List<OperatingContextEntityRecord>;
  name: string;
  onChange: (event: FormChangeEvent) => EntityDuckAction;
};

/**
 * Checkboxes that control state for all equipment context in an operation
 */
export default function RootEquipmentOperationControls(
  props: RootEquipmentOperationControlsProps,
) {
  const {
    operatingContexts,
    handleRootEquipmentOperationChange,
    equipmentOperationList,
  } = useRootEquipmentOperationControls(props);

  return (
    <>
      {operatingContexts.map(
        (operatingContext: OperatingContextEntityRecord) => (
          <GridItem
            key={operatingContext.get('uuid')}
            {...operatingContextGridItemProps}
          >
            <Checkbox
              variant="cube_contrast"
              sx={checkboxSx}
              isDisabled={false}
              isIndeterminate={
                SiteEquipmentOperatingContextEntity.getEquipmentOperationContextSize(
                  operatingContext,
                  equipmentOperationList,
                ) > 0 &&
                SiteEquipmentOperatingContextEntity.getEquipmentOperationContextSize(
                  operatingContext,
                  equipmentOperationList,
                ) < equipmentOperationList.size
              }
              isChecked={
                SiteEquipmentOperatingContextEntity.getEquipmentOperationContextSize(
                  operatingContext,
                  equipmentOperationList,
                ) > 0
              }
              onChange={() => {
                handleRootEquipmentOperationChange(operatingContext);
              }}
            ></Checkbox>
          </GridItem>
        ),
      )}
    </>
  );
}
