import { type MouseEventHandler } from 'react';

import { MPTT } from 'entities';
import type { MPTTEntityRecord } from 'entities/api/MPTT';
import { createLogger } from 'util/createLogger';

import { DefaultRecord } from '../DefaultComponents';
import { type UseTreePickerReturn } from '../TreePicker.hooks';
import type { TreePickerListReturn } from '../TreePickerList/TreePickerList.hooks';

const log = createLogger('TreePicker.TreePickerNode');

type TreePickerNodeProps<T extends MPTTEntityRecord> = Pick<
  UseTreePickerReturn<T>,
  | 'value'
  | 'onChange'
  | 'parents'
  | 'recordComponent'
  | 'getLabel'
  | 'isSelectedCallback'
  | 'isIndeterminate'
  | 'disabled'
> & {
  item: T;
  level: number;
  isExpanded: boolean;
  count: number;
  onChangeParents: TreePickerListReturn['onChangeParents'];
  extraProps: TreePickerListReturn;
};

export const TreePickerNode = <T extends MPTTEntityRecord>({
  item,
  level,
  isExpanded,
  onChangeParents,
  recordComponent,
  getLabel,
  value,
  onChange,
  isSelectedCallback,
  isIndeterminate,
  count,
  disabled,
  extraProps,
}: TreePickerNodeProps<T>) => {
  const Component = recordComponent ?? DefaultRecord;

  const uuid = item.get('uuid') as string;

  /**
   * if the entity has no field 'has_children',
   * fail open so user can attempt to browse children,
   * displaying 'No results' if empty
   *
   * Note: has_children may return unexpected results, eg:
   *
   * - Carrapateena.has_children = true
   *   http://localhost:8000/api/bhp_people/v2/grc_organisational_unit/?is_active=true&is_location=false&parent=4ad29811-ba89-49bd-a2b5-23e1a6ed4ee9
   * - but direct query returns no children:
   *   http://localhost:8000/api/bhp_people/v2/grc_organisational_unit/?is_active=true&is_location=false&parent=50c381bc-871b-4a19-aefb-ab8290524abd
   *
   * TODO provide an override here?
   */
  const hasChildren = MPTT.hasChildren(item) ?? true;

  const isSelected =
    typeof isSelectedCallback == 'function'
      ? isSelectedCallback(item, value)
      : value?.includes(item) ?? false;

  /**
   * Differentiates between click intentions:
   * - clicking the checkbox should trigger onChange
   * - clicking anywhere else should trigger onChangeParents
   *
   * Note: ensure styles cause the Checkbox to completely fill its parent so it
   * catches all clicks
   */
  const handleClick: MouseEventHandler = (event) => {
    // if we don't preventDefault, this function gets called twice!
    event.preventDefault();

    /** whether we clicked on a checkbox or radio button, otherwise it's the label */
    const isCheckbox = (event.target as HTMLElement).matches(
      '.chakra-checkbox__control, .chakra-checkbox__control *, .chakra-radio__control, .chakra-radio__control *',
    );

    const debug = {
      isCheckbox,
      isSelected,
      isExpanded,
      hasChildren,
      level,
      item,
    };
    log('handleClick %o', debug);

    if (isCheckbox) {
      if (!disabled) onChange(item, debug);
    } else {
      if (hasChildren && !isExpanded)
        onChangeParents({
          childLevel: level + 1,
          parentUuid: item.get('uuid'),
          debug,
        });
    }
  };

  return (
    <Component
      className={[
        'TreePicker__Node',
        hasChildren ? 'has-children' : '',
        isSelected ? 'is-selected' : '',
      ].join(' ')}
      item={item}
      level={level}
      data-uuid={uuid}
      data-test-id="TreePicker__Node"
      onClick={handleClick}
      role="listitem"
      hasChildren={hasChildren}
      isOpen={isExpanded}
      isSelected={isSelected}
      isIndeterminate={isIndeterminate(item)}
      count={count}
      data-has-children={hasChildren}
      data-has-selected-children={count > 0}
      data-expanded={isExpanded || undefined}
      aria-expanded={isExpanded || undefined}
      aria-disabled={disabled}
      disabled={disabled}
      extraProps={extraProps}
    >
      {getLabel(item) || 'unknown label'}
    </Component>
  );
};
