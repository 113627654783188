import { Box, Flex, Heading, Text, Tooltip, VStack } from '@chakra-ui/react';
import { type List } from 'immutable';
import { FormattedMessage } from 'react-intl';

import { type SiteEquipmentOperatingContextEntityRecord } from 'entities/api/CompliancePlan/SiteEquipmentOperatingContext.ts';

import {
  completedBarStyle,
  coverageBarStackContainerProps,
  headingFlexProps,
  helperTextFlexProps,
  missingBarStyle,
} from './AssessmentCoverageBar.styles.tsx';
import TooltipItems from './TooltipItems/TooltipItems.tsx';

type AssessmentCoverageBarProps = {
  assessEquipmentOperationCovered: List<SiteEquipmentOperatingContextEntityRecord>;
  mandatoryEquipmentOperationCovered: List<SiteEquipmentOperatingContextEntityRecord>;
  assessEquipmentOperationMissing: List<SiteEquipmentOperatingContextEntityRecord>;
  mandatoryEquipmentOperationMissing: List<SiteEquipmentOperatingContextEntityRecord>;
  percent: number;
};

export const AssessmentCoverageBar = (props: AssessmentCoverageBarProps) => {
  const {
    percent,
    assessEquipmentOperationCovered,
    mandatoryEquipmentOperationCovered,
    assessEquipmentOperationMissing,
    mandatoryEquipmentOperationMissing,
  } = props;

  return (
    <VStack {...coverageBarStackContainerProps}>
      <Flex {...headingFlexProps}>
        <Heading size="sm">
          <FormattedMessage
            id="compliance.baseline-assessment.control-assessment.coverage-bar"
            defaultMessage="Compliance plan coverage"
          />
        </Heading>
        <Heading size="sm">{Math.round(percent)}%</Heading>
      </Flex>

      <Box width="100%">
        <Tooltip
          hasArrow
          label={TooltipItems({
            mandatoryEquipmentOperation: mandatoryEquipmentOperationCovered,
            assessEquipmentOperation: assessEquipmentOperationCovered,
          })}
        >
          <Box
            sx={{
              ...completedBarStyle,
              width: `${percent}%`,
            }}
          >
            &nbsp;
          </Box>
        </Tooltip>
        <Tooltip
          hasArrow
          label={TooltipItems({
            mandatoryEquipmentOperation: mandatoryEquipmentOperationMissing,
            assessEquipmentOperation: assessEquipmentOperationMissing,
          })}
        >
          <Box
            sx={{
              ...missingBarStyle,
              width: `${100 - percent}%`,
            }}
          >
            &nbsp;
          </Box>
        </Tooltip>
      </Box>
      <Flex {...helperTextFlexProps}>
        <Text>
          <FormattedMessage
            id="compliance.baseline-assessment.control-assessment.coverage-bar.helper"
            defaultMessage="Hover for more details"
          />
        </Text>
      </Flex>
    </VStack>
  );
};
