import { VStack } from 'components';
import { type CompliancePlanEntityRecord } from 'entities/api/CompliancePlan/CompliancePlan.ts';

import { useScenarioTable } from './ScenarioTable.hooks.tsx';
import { ScenarioTableBody } from './ScenarioTableBody';
import { ScenarioTableHeader } from './ScenarioTableHeader';

type ScenarioTableProps = {
  compliancePlan: CompliancePlanEntityRecord;
};

export const ScenarioTable = (props: ScenarioTableProps) => {
  const { equipmentLevel1, equipmentLevel2, columnMapping } =
    useScenarioTable(props);

  return (
    <VStack gap={0}>
      <ScenarioTableHeader
        equipmentLevel1={equipmentLevel1}
        equipmentLevel2={equipmentLevel2}
      />
      <ScenarioTableBody
        compliancePlan={props.compliancePlan}
        columnMapping={columnMapping}
      />
    </VStack>
  );
};
