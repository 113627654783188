import { chakra } from '@chakra-ui/react';
import type React from 'react';
import { type SyntheticEvent } from 'react';

type SwitchProps = {
  children: React.ReactNode;
  positiveAnswerColor?: string;
  currentIndex: number;
};

export const SwitchElement = ({
  currentIndex,
  children,
  positiveAnswerColor,
}: SwitchProps) => {
  const bgColors: { [key: number]: string } = {
    0: 'gray.400',
    1: positiveAnswerColor ? positiveAnswerColor : '#28cd41',
  };

  return (
    <chakra.div
      sx={{
        position: 'relative',
        height: '20px',
        width: '56px',
        backgroundColor: `${bgColors[currentIndex]}`,
        transition: '0.15s background linear',
        borderRadius: 'full',
        padding: '0.125rem',
        boxSizing: 'content-box',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {children}
    </chakra.div>
  );
};

const transformLoc: { [key: number]: string } = {
  0: '0px',
  1: '36px',
};

export const SwitchSelection = ({ currentIndex }: { currentIndex: number }) => (
  <chakra.span
    sx={{
      display: 'block',
      position: 'absolute',
      zIndex: '1',
      width: '20px',
      height: '20px',
      background: 'white',
      borderRadius: 'full',
      transition: 'transform 0.15s linear',
      transform: `translateX(${transformLoc[currentIndex]})`,
      boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.16)',
    }}
  />
);

export const SwitchRadio = ({ checked }: { checked: boolean }) => (
  <input
    type="radio"
    name="switch"
    className="SwitchRadio"
    checked={checked}
    style={{ display: 'none' }}
    readOnly
  />
);

const labelColor = (checked: boolean, currentIndex: number) => {
  if (checked) {
    if (currentIndex === 0) {
      return 'gray.400';
    } else if (currentIndex === 2) {
      return '#28cd41';
    }
  } else {
    if (currentIndex === 0) {
      return 'gray.400';
    } else if (currentIndex === 1) {
      return 'gray.100';
    } else {
      return '#28cd41';
    }
  }
};

type SwitchLabelProps = {
  children: React.ReactNode;
  onClick: (event: SyntheticEvent<HTMLLabelElement>) => void;
  checked: boolean;
  currentIndex: number;
};
export const SwitchLabel = ({
  children,
  onClick,
  checked,
  currentIndex,
  ...rest
}: SwitchLabelProps) => (
  <chakra.label
    onClick={onClick}
    sx={{
      position: 'relative',
      zIndex: '2',
      float: 'left',
      width: '18px',
      height: '100%',
      lineHeight: '20px',
      fontSize: '11px',
      color: `${labelColor(checked, currentIndex)}`,
      textAlign: 'center',
      fontWeight: 'bold',
      cursor: 'pointer',
      transition: '0.3s color ease-out',
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none',
    }}
    {...rest}
  >
    {children}
  </chakra.label>
);
