import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import DamageEnergyMechanism, {
  type DamageEnergyMechanismEntityRecord,
} from './DamageEnergyMechanism';
import I18nTextEntity, { type I18nTextEntityRecord } from './i18n/I18nText';
import { type TitleEntityFields } from './types';

class ScenarioEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/scenario/',
  };
  static fields: EntityFields<ScenarioEntityFields> = {
    uuid: new Fields.IdField(),

    title: new Fields.EntityField({
      entity: I18nTextEntity,
    }),

    description: new Fields.EntityField({
      entity: I18nTextEntity,
      blank: true,
    }),

    damage_energy_mechanism: new Fields.EntityField({
      entity: DamageEnergyMechanism,
    }),
    mapping_id: new Fields.CharField(),
  };

  static toString(record: ScenarioEntityRecord, locale = ''): string {
    return I18nTextEntity.toString(record.get('title'), locale);
  }
}

type ScenarioEntityFields = TitleEntityFields & {
  description: I18nTextEntityRecord;
  damage_energy_mechanism: DamageEnergyMechanismEntityRecord;
  mapping_id: string;
};

export type ScenarioEntityRecord = EntityRecord<ScenarioEntityFields>;

ScenarioEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: ScenarioEntity,
  name: 'Scenario',
});

export default ScenarioEntity;
