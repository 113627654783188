import { type List } from 'immutable';
import { useCallback, useEffect, useMemo, useState } from 'react';

import CompliancePlanEntity, {
  type CompliancePlanEntityRecord,
} from 'entities/api/CompliancePlan/CompliancePlan.ts';
import type { I18nTranslationEntityRecord } from 'entities/api/i18n/I18nTranslation.ts';
import type { ScenarioEntityRecord } from 'entities/api/Scenario.ts';

import { axiosInstance } from '../../../../../../../../api';

type useScenarioTableBodyProps = {
  compliancePlan: CompliancePlanEntityRecord;
  value: List<ScenarioEntityRecord>;
};

export type equipmentScenarioDataType = Record<string, string[]>;

const equipmentIntersectionList = [
  'Trailer',
  'Rubber Tyre Heavy Vehicle',
  'Tracked Heavy Vehicle',
  'Road going but not normally road used',
  'Operational Vehicles',
  'Road going and road used',
  'Light Vehicle',
  'High Occupancy Vehicle',
];

export const useScenarioTableBody = (props: useScenarioTableBodyProps) => {
  const { compliancePlan, value } = props;
  const [equipmentScenarioData, setEquipmentScenarioData] =
    useState<equipmentScenarioDataType | null>(null);

  const siteCompliancePlans = compliancePlan.get('sitecomplianceplan_set');

  const compliancePlanSelectedEquipment = compliancePlan.get(
    'complianceplanbaseequipment_set',
  );

  const equipmentIntersections = useMemo(() => {
    return equipmentIntersectionList.map((equipmentName) => {
      return compliancePlanSelectedEquipment.some((item) => {
        const translations = item.getIn([
          'equipment',
          'title',
          'translations',
        ]) as unknown as List<I18nTranslationEntityRecord>;
        return translations?.some(
          (t) => t.getIn(['translated_message']) === equipmentName,
        );
      });
    });
  }, [compliancePlanSelectedEquipment]);

  const equipmentScenarioApplicability = useCallback(async () => {
    const url = `${CompliancePlanEntity.paths.apiBase}${compliancePlan.get('uuid')}/retrieve_scenario_equipment_applicability/`;
    try {
      const response = await axiosInstance.get(url, {});
      return response.data;
    } catch (err) {
      return;
    }
  }, [compliancePlan]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await equipmentScenarioApplicability();
      setEquipmentScenarioData(data);
    };

    fetchData();
  }, [equipmentScenarioApplicability]);

  const groupedScenarios = useMemo(
    () => value.groupBy((scenario) => scenario.get('damage_energy_mechanism')),
    [value],
  );

  return {
    siteCompliancePlans,
    groupedScenarios,
    equipmentIntersections,
    equipmentScenarioData,
  };
};
