import {
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  type SystemStyleObject,
} from '@chakra-ui/react';
import type { ReactNode } from 'react';

import { statsSx } from './Stats.styles';

type StatsProps = {
  sx?: SystemStyleObject;
  stats: {
    label: ReactNode;
    value?: string | number;
    sx?: SystemStyleObject;
  }[];
  fallbackValue?: string | number;
};

/**
 * @example basic usage
 *
 * ```tsx
 * const errorStatSx: SystemStyleObject = {
 *   dd: { color: 'error' },
 * };
 *
 * <Stats
 *   stats={[
 *     {
 *       label: <FormattedMessage id="example.foo" defaultMessage="Foo" />,
 *       value: '24',
 *     },
 *     {
 *       label: <FormattedMessage id="example.bar" defaultMessage="Bar" />,
 *       value: 6,
 *       sx: errorStatSx,
 *     },
 *     // ...
 *   ]}
 * />
 *
 * @example custom styles
 *
 * ```tsx
 * import { Stats, statsSx } from 'screens/compliance/components/Stats'
 *
 * // these will be shallow merged with the default styles, so ensure you extend sub-keys:
 * const sx: SystemStyleObject = {
 *   '.chakra-stat': {
 *     // @ts-expect-error expression of type '".chakra-stat"' can't be used to index
 *     ...statsSx['.chakra-stat'],
 *     flexGrow: 1,
 *   },
 * };
 *
 * <Stats sx={sx} stats={[]} />
 */
export const Stats = ({ stats, sx, fallbackValue = '-' }: StatsProps) => {
  return (
    <StatGroup sx={{ ...statsSx, ...sx }}>
      {stats.map(({ label, value, sx }, i) => (
        <Stat key={i} sx={sx}>
          <StatLabel>{label}</StatLabel>
          <StatNumber>{value ?? fallbackValue}</StatNumber>
        </Stat>
      ))}
    </StatGroup>
  );
};
