import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { type List } from 'immutable';

import { type DamageEnergyMechanismEntityRecord } from 'entities/api/DamageEnergyMechanism.ts';
import { type ScenarioEntityRecord } from 'entities/api/Scenario.ts';
import { useLocale } from 'locales/useLocale.tsx';

import { GroupedScenarioAccordionSx } from './GroupedScenarioAccordion.styles.tsx';
import { ScenarioRow } from './ScenarioRow';
import type { equipmentScenarioDataType } from '../../ScenarioTableBody/ScenarioTableBody.hooks.tsx';
import { type UseCompliancePlanScenarioReturn } from '../OperationAccordion.hooks.tsx';

const defaultColspan = { base: 2, lg: 2 };

type GroupedScenarioAccordionProps = {
  mechanism: DamageEnergyMechanismEntityRecord;
  scenarios: List<ScenarioEntityRecord>;
  onChangeValue: UseCompliancePlanScenarioReturn['onChangeValue'];
  planScenarios: List<ScenarioEntityRecord>;
  equipmentIntersections: boolean[];
  columnMapping: Record<number, string>;
  equipmentScenarioData: equipmentScenarioDataType | null;
};

export const GroupedScenarioAccordion = (
  props: GroupedScenarioAccordionProps,
) => {
  const { toString } = useLocale();
  return (
    <Accordion defaultIndex={[0]} allowToggle sx={GroupedScenarioAccordionSx}>
      <AccordionItem>
        <AccordionButton>
          <Grid>
            <GridItem colSpan={{ base: 8, lg: 8 }}>
              {toString(props.mechanism)}
            </GridItem>
            <GridItem colSpan={defaultColspan}>
              <AccordionIcon />
            </GridItem>
          </Grid>
        </AccordionButton>
        <AccordionPanel as={Flex}>
          {props.scenarios.map((scenario, i) => (
            <ScenarioRow
              key={`ScenarioRow_${i}`}
              scenario={scenario}
              onChangeValue={props.onChangeValue}
              planScenarios={props.planScenarios}
              equipmentIntersections={props.equipmentIntersections}
              columnMapping={props.columnMapping}
              equipmentScenarioData={props.equipmentScenarioData}
            />
          ))}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
