import { type List, Map } from 'immutable';

import { useQuery } from '@burnsred/entity-duck-query';
import type { CompliancePlanEntityRecord } from 'entities/api/CompliancePlan/CompliancePlan.ts';
import EquipmentEntity, {
  type EquipmentEntityRecord,
} from 'entities/api/Equipment.ts';

type useScenarioTableProps = {
  compliancePlan: CompliancePlanEntityRecord;
};

export function useScenarioTable(props: useScenarioTableProps) {
  const { compliancePlan } = props;

  const { value: equipmentLevel1 } = useQuery<List<EquipmentEntityRecord>>({
    action: EquipmentEntity.duck.actions.get({
      params: Map({
        predicates__rule__risk: compliancePlan.get('risk').get('uuid'),
        level: '1',
        nonce: '1',
      }),
    }),
  });

  const { value: equipmentLevel2 } = useQuery<List<EquipmentEntityRecord>>({
    action: EquipmentEntity.duck.actions.get({
      params: Map({
        predicates__rule__risk:
          compliancePlan.get('risk').get('uuid') || undefined,
        level: '2',
      }),
    }),
  });

  const mapColumnsToUuid = () => {
    let columnIndex = 0;
    const columnMap: Record<number, string> = {}; // Index -> uuid

    if (!equipmentLevel1 || !equipmentLevel2) return;

    equipmentLevel1.forEach((lvl1) => {
      const children = equipmentLevel2.filter(
        (lvl2) => lvl2.get('parent') === lvl1.get('uuid'),
      );

      if (children.size > 0) {
        children.forEach((lvl2) => {
          columnMap[columnIndex] = lvl2.get('uuid');
          columnIndex++;
        });
      } else {
        columnMap[columnIndex] = lvl1.get('uuid');
        columnIndex++;
      }
    });

    return columnMap;
  };

  const columnMapping = mapColumnsToUuid();

  return {
    equipmentLevel1,
    equipmentLevel2,
    columnMapping,
  };
}
