import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  HStack,
  Heading,
  Text,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Form, Input, type InputProps } from '@burnsred/entity-form';
import { VStack } from 'components';
import { FileItemRow } from 'components/FilePicker';
import { getAttachmentUrl } from 'entities/api/BaseAttachment.ts';
import ActionEntity from 'entities/api/CompliancePlan/Action';
import { type BaselineAssessmentAnswerEntityRecord } from 'entities/api/CompliancePlan/BaselineAssessmentAnswer';
import { type GapEntityRecord } from 'entities/api/CompliancePlan/Gap';
import type { GapAttachmentEntityRecord } from 'entities/api/CompliancePlan/GapAttachment.ts';
import { useLocale } from 'locales/useLocale';

import { useGapForm } from './GapForm.hooks.ts';
import {
  buttonsWrapperSx,
  gapAccordionProps,
  gapForm__headerSx,
  gapForm__header__titleWrapperSx,
  gapForm__identifierSx,
} from './GapForm.styles';
import { LinkToActionModal } from './LinkToActionModal';
import { ActionForm } from '../ActionForm';

export type GapFormProps = InputProps<GapEntityRecord> & {
  value: GapEntityRecord;
  answer: BaselineAssessmentAnswerEntityRecord;
};

export function GapForm(props: GapFormProps) {
  const { value, answer, onChange, onSubmit, name, index } = props;
  const { getI18nField } = useLocale();

  const { linkToActionDisclosure, onLinkAction, onUnlinkAction } =
    useGapForm(props);
  const { isOpen, onClose, onOpen } = linkToActionDisclosure;

  const addAction = useCallback(() => {
    onChange({
      target: {
        name,
        index,
        value: value.set(
          'actions',
          value.get('actions').push(ActionEntity.dataToRecord({})),
        ),
      },
    });
  }, [name, onChange, value, index]);

  const removeAction = useCallback(
    (actionIndex: number) => {
      onSubmit({
        target: {
          name,
          index,
          value: value.set('actions', value.get('actions').remove(actionIndex)),
        },
      });
    },
    [name, onSubmit, value, index],
  );

  const gapUuid = value.get('uuid');

  return (
    <Form {...props}>
      <Accordion className="GapForm" {...gapAccordionProps}>
        <AccordionItem>
          <AccordionButton>
            <HStack sx={gapForm__headerSx}>
              <VStack sx={gapForm__header__titleWrapperSx}>
                <Heading as="h4" size="xs">
                  {value.get('title') || '(No title)'}
                </Heading>
                <Text fontSize="xs">
                  Q: {getI18nField(answer, 'question_text')}
                </Text>
              </VStack>

              <Text sx={gapForm__identifierSx}>{value.get('identifier')}</Text>

              <Text sx={gapForm__identifierSx}>
                <FormattedMessage
                  id="compliance.actions.gap-form.actions"
                  defaultMessage="Actions"
                />
                {` ${value.get('actions').size}`}
              </Text>

              <AccordionIcon />
            </HStack>
          </AccordionButton>

          <AccordionPanel>
            <VStack gap={3}>
              <VStack as="header" gap={2}>
                <Heading as="h5" variant="form-label">
                  <FormattedMessage
                    id="compliance.actions.gap-form.gap-description"
                    defaultMessage="Gap Description"
                  />
                </Heading>
                <Text>{value.get('description') || '-'}</Text>
              </VStack>

              <VStack gap={0}>
                {value
                  ?.get('attachments')
                  ?.map(
                    (
                      attachment: GapAttachmentEntityRecord,
                      attachmentIndex: number,
                    ) => (
                      <FileItemRow
                        key={attachmentIndex}
                        canDelete={false}
                        name={
                          (attachment?.getIn([
                            'attachment',
                            'name',
                          ]) as string) || 'unnamed'
                        }
                        url={getAttachmentUrl(attachment)}
                      />
                    ),
                  )}
              </VStack>

              <VStack gap={3}>
                <Input
                  name="actions"
                  component={ActionForm}
                  componentProps={{
                    removeAction,
                    onUnlinkAction,
                  }}
                  many
                />

                <HStack sx={buttonsWrapperSx}>
                  <Button variant="whiteOutline" onClick={onOpen}>
                    <FormattedMessage
                      id="compliance.actions.gap-form.link-to-action"
                      defaultMessage="Link to action"
                    />
                  </Button>
                  <Button variant="whiteOutline" onClick={addAction}>
                    <FormattedMessage
                      id="compliance.actions.gap-form.add-action"
                      defaultMessage="Add Action"
                    />
                  </Button>
                </HStack>
              </VStack>
            </VStack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      {isOpen && (
        <LinkToActionModal
          gapUuid={gapUuid}
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          onLinkAction={onLinkAction}
        />
      )}
    </Form>
  );
}
