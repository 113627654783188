import { Flex, HStack, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import type { List } from 'immutable';
import { FormattedMessage } from 'react-intl';

import { Form, Input, type InputProps } from '@burnsred/entity-form';
import { VStack } from 'components';
import { AutocompleteField } from 'components/widgets';
import { type BaselineAssessmentEntityRecord } from 'entities/api/CompliancePlan/BaselineAssessment';
import SiteEquipmentOperatingContextEntity from 'entities/api/CompliancePlan/SiteEquipmentOperatingContext';
import type { EquipmentEntityRecord } from 'entities/api/Equipment';
import { useLocale } from 'locales/useLocale';

import {
  MandatoryAssessBadge,
  StatusBadge,
  controlHeadingSx,
  headerSx as headerSx,
  header__contentDescriptionSx,
  header__contentWrapperSx,
} from './BaselineAssessmentActionForm.styles';
import type { ActionPlanForControlProps } from '../ActionPlanForControl';
import { AnswerForm } from '../AnswerForm';

export type BaselineAssessmentActionFormProps = InputProps<
  BaselineAssessmentEntityRecord,
  false
> & {
  isForMandatory: boolean;
  risk: string;
  applicableControlsForPlanProps: ActionPlanForControlProps;
  equipmentLevel1: List<EquipmentEntityRecord>;
};

export function BaselineAssessmentActionForm(
  props: BaselineAssessmentActionFormProps,
) {
  const { toString, getI18nField } = useLocale();
  const { value, isForMandatory, applicableControlsForPlanProps } = props;

  const gapCount = value
    ?.get('answers')
    ?.flatMap((answer) => answer.get('gaps')).size;

  const control = applicableControlsForPlanProps.value.get('global_control');

  return (
    <Form {...props}>
      <VStack
        className="BaselineAssessmentActionForm"
        hidden={!(isForMandatory === value.get('is_mandatory'))}
      >
        <VStack as="header" sx={headerSx}>
          <HStack sx={controlHeadingSx}>
            <Heading as="h2" size="xs">
              {toString(control)}
            </Heading>
            <Flex>
              <Text>
                <FormattedMessage
                  id="compliance.actions.baseline-assessment-action-form.gaps"
                  defaultMessage="Gaps: {gapCount}"
                  values={{ gapCount }}
                />
              </Text>
            </Flex>
          </HStack>

          <VStack sx={header__contentWrapperSx}>
            <HStack sx={header__contentDescriptionSx}>
              <MandatoryAssessBadge is_mandatory={value.get('is_mandatory')} />

              <Text fontSize="sm">
                {value.get('is_mandatory') ? (
                  <FormattedMessage
                    id="compliance.baseline-assessment.form.control-mandatory-explanation"
                    defaultMessage="This control is mandatory for the following vehicles and operating
              contexts:"
                  />
                ) : (
                  <FormattedMessage
                    id="compliance.baseline-assessment.form.control-assess-explanation"
                    defaultMessage="This control is assess for the following vehicles and operating
              contexts:"
                  />
                )}
              </Text>

              <StatusBadge sx={{ marginLeft: 'auto' }}>
                {getI18nField(value, 'outcome_display')}
              </StatusBadge>
            </HStack>

            <SimpleGrid columns={2} gap={4}>
              <Input
                name="equipment"
                component={AutocompleteField}
                componentProps={{
                  label: (
                    <FormattedMessage
                      id="compliance.baseline-assessment.form.equipment"
                      defaultMessage="Equipment"
                    />
                  ),
                  getOptionLabel: (option: unknown) =>
                    SiteEquipmentOperatingContextEntity.getEquipmentOptionLabel(
                      option,
                      props.equipmentLevel1,
                      toString,
                    ),
                  disabled: true,
                }}
              />

              <Input
                name="operating_contexts"
                component={AutocompleteField}
                componentProps={{
                  label: (
                    <FormattedMessage
                      id="compliance.baseline-assessment.form.operating-contexts"
                      defaultMessage="Operating Contexts"
                    />
                  ),
                  disabled: true,
                }}
              />
            </SimpleGrid>
          </VStack>
        </VStack>

        <Input name="answers" component={AnswerForm} many />
      </VStack>
    </Form>
  );
}
