import { type SystemStyleObject } from '@chakra-ui/react';

export const titleSx: SystemStyleObject = {
  color: 'black',
  fontSize: 'sm',

  textAlign: 'center',

  // Prevent text wrapping
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const assetTitleSx: SystemStyleObject = {
  ...titleSx,
  textAlign: 'left',
  fontWeight: 'bold',
};
