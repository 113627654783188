import { Box } from '@chakra-ui/react';

import { Form, Input, type InputProps } from '@burnsred/entity-form';
import { type BaselineAssessmentAnswerEntityRecord } from 'entities/api/CompliancePlan/BaselineAssessmentAnswer';

import { GapForm } from '../GapForm';

export type AnswerFormFormProps =
  InputProps<BaselineAssessmentAnswerEntityRecord> & {
    value: BaselineAssessmentAnswerEntityRecord;
  };

export function AnswerForm(props: AnswerFormFormProps) {
  if (props?.value?.get('gaps')?.isEmpty()) {
    return null;
  }
  return (
    <Form {...props}>
      <Box>
        <Input
          name="gaps"
          component={GapForm}
          componentProps={{
            answer: props.value,
          }}
          many
        />
      </Box>
    </Form>
  );
}
