import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

class MPTTAncestorEntity extends Entity {
  static fields: EntityFields<MPTTAncestorEntityFields> = {
    uuid: new Fields.IdField(),
    parent: new Fields.IdField({ blank: true }),
    is_active: new Fields.BooleanField(),
  };
}

export type MPTTAncestorEntityFields = {
  uuid: string;
  parent: string;
  is_active: boolean;
};

export type MPTTAncestorEntityRecord = EntityRecord<MPTTAncestorEntityFields>;

MPTTAncestorEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: MPTTAncestorEntity,
  name: 'MPTTAncestor',
});

export default MPTTAncestorEntity;
