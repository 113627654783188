import { useMemo, useState } from 'react';
import { useOutletContext } from 'react-router';

import type { PaginatorProps, PaginatorState } from 'components';
import { ControlListFilterEntity, usePagination } from 'entities';
import ControlEntity from 'entities/api/Control.ts';
import { type ControlListFilterEntityRecord } from 'entities/filter/ControlListFilter.ts';
import { useEntityFilterControls } from 'forms/hooks/filters';
import { useLocale } from 'locales/useLocale';
import { type GlobalFrameworkContext } from 'screens/global-frameworks/GlobalFramework/GlobalFramework.tsx';

export type UseGlobalControlListControlsReturn = ReturnType<
  typeof useGlobalControlListControls
>;

export function useGlobalControlListControls() {
  const {
    name,
    filterRecord: rawFilterRecord,
    onChange,
    FilterField,
    errors,
  } = useEntityFilterControls<
    typeof ControlListFilterEntity,
    ControlListFilterEntityRecord
  >(
    ControlListFilterEntity,
    'ControlListFilters',
    ControlListFilterEntity.onChangeClean,
  );

  const { formControls } = useOutletContext<GlobalFrameworkContext>();

  const filterRecord = useMemo(
    () =>
      rawFilterRecord.set(
        'applicable_rules__risk',
        formControls?.value?.getIn(['risk', 'uuid']),
      ),
    [formControls, rawFilterRecord],
  );

  // memoise params to prevent a duplicate API call triggered by useSelector call
  const params = useMemo(
    () => ControlListFilterEntity.asMappedParams(filterRecord),
    [filterRecord],
  );
  const [appliedParams, setAppliedParams] = useState(params);
  const handleApplyParams = () => {
    //reset page to 1 on each API call to avoid invalid page
    const updatedParams = params.set('page', '1');
    setAppliedParams(updatedParams);
  };

  const pagination = usePagination(ControlEntity, appliedParams);
  const controlCount = pagination?.get('count') || '0';

  const onChangePagination = ({ page, page_size }: PaginatorState) => {
    const appliedParamsWithPaginationProps = appliedParams
      .set('page', String(page || 1))
      .set('page_size', String(page_size || '8'));
    setAppliedParams(appliedParamsWithPaginationProps);

    // note that filterRecord stores page & page_size as strings, and Paginator requires numbers
    const newRecord = filterRecord.withMutations((rec) => {
      rec.set('page', String(page) || '1');
      rec.set('page_size', String(page_size) || '10');
    });
    onChange({
      target: { name, value: newRecord },
    });
  };

  const paginatorProps: PaginatorProps = {
    page: parseInt(filterRecord?.get('page') ?? '1'),
    page_size: parseInt(filterRecord?.get('page_size') ?? '23'),
    totalItems: pagination?.get('count') ?? 0,
    onChange: onChangePagination,
    pageSizeOptions: [23, 47],
  };

  const localeContext = useLocale();
  const damagingEnergyMechanismFilterParams = {
    predicates__rule__risk: params.get('applicable_rules__risk'),
  };

  return {
    name,
    filterRecord,
    onChange,
    FilterField,
    errors,
    localeContext,
    paginatorProps,
    formControls,
    controlCount,
    handleApplyParams,
    appliedParams,
    damagingEnergyMechanismFilterParams,
  };
}
