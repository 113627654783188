import { Flex, Text } from '@chakra-ui/react';
import { MdChatBubbleOutline } from 'react-icons/md';

type CompliancePlanCommentsProps = {
  count: number;
};

export const CompliancePlanComments = (props: CompliancePlanCommentsProps) => {
  const { count } = props;

  const isVisible = count > 0;
  return (
    <>
      {isVisible && (
        <Flex direction="row" gap={1} alignItems="center">
          <MdChatBubbleOutline style={{ fontSize: '16px', color: 'blue' }} />
          <Text>{count}</Text>
        </Flex>
      )}
    </>
  );
};
