import { fromJS } from 'immutable';

import EquipmentEntity, {
  type EquipmentEntityRecord,
} from 'entities/api/Equipment';
import type { Optional } from 'types';

import { equipmentPickerSx } from './EquipmentPicker.styles';
import { Record } from './Record';
import { TreePicker, type TreePickerProps } from '../TreePicker';

const defaultBaseParams = fromJS({
  is_active: 'true',
  page_size: '200',
});

export type EquipmentPickerProps<T extends EquipmentEntityRecord> = Optional<
  TreePickerProps<T>,
  'Entity' | 'baseParams'
>;

/** Composes AccordionPicker to display Equipment */
export const EquipmentPicker = <
  EntityRecordType extends EquipmentEntityRecord,
>({
  Entity = EquipmentEntity,
  baseParams = defaultBaseParams,
  sx = equipmentPickerSx,
  ...rest
}: EquipmentPickerProps<EntityRecordType>) => (
  // @ts-expect-error Types of parameters 'updater' and 'updater' are incompatible
  <TreePicker<EquipmentEntityRecord>
    {...{ Entity, baseParams, sx, ...rest }}
    recordComponent={Record}
  />
);
