import { Divider, Flex, Heading } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { EntriesFound, Paginator, VStack } from 'components';
import { Stats } from 'components/Stats/Stats';
import { FrameworksList } from 'screens/control-frameworks/components/FrameworksList';

import { GlobalDashboardFilters } from './DashboardFilterForm/DashboardFilterForm';
import { useGlobalDashboardControls } from './GlobalDashboard.hooks';

export function GlobalDashboard() {
  const {
    name,
    filterRecord,
    onChange,
    FilterField,
    errors,
    stats,
    params,
    paginatorProps,
    counts,
  } = useGlobalDashboardControls();
  return (
    <Flex direction="column" gap={8}>
      <Heading size="lg">
        <FormattedMessage
          id="dashboard.global-performance.title"
          defaultMessage="Global performance"
        />
      </Heading>

      <GlobalDashboardFilters
        name={name}
        filterRecord={filterRecord}
        onChange={onChange}
        FilterField={FilterField}
        errors={errors}
      />

      <Stats stats={stats} filterRecord={filterRecord} />

      <Divider />

      <Heading as="h3" size="sm">
        <FormattedMessage
          id="dashboard.global-performance.list.heading"
          defaultMessage="Control frameworks"
        />
      </Heading>

      <VStack gap={4}>
        <EntriesFound count={paginatorProps.totalItems} />

        <FrameworksList
          filterRecord={filterRecord}
          params={params}
          refreshList={0}
          showStats={true}
          counts={counts}
        />
      </VStack>

      <Paginator
        displayAlways={
          paginatorProps.pageSizeOptions
            ? paginatorProps.totalItems > paginatorProps.pageSizeOptions[0]
            : true
        }
        {...paginatorProps}
      />
    </Flex>
  );
}
