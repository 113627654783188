import {
  type GridItemProps,
  type GridProps,
  type SimpleGridProps,
} from '@chakra-ui/react';

export const mainGridProps: SimpleGridProps = {
  columns: { base: 16 },
  w: '100%',
  gap: 2,
  paddingX: 4,
};

export const contentGridItemProps: GridItemProps = {
  colSpan: {
    base: 15,
  },
  textAlign: 'left',
};

export const iconGridItemProps: GridItemProps = {
  colSpan: 1,
  textAlign: 'right',
};

export const innerContentGridProps: GridProps = {
  templateColumns: 'repeat(14, 1fr)',
  gap: 'gutter',
  w: '100%',
  h: '100%',
};

export const innerGridTitleProps: GridItemProps = {
  colSpan: { base: 5, lg: 5 },
  textAlign: 'left',
  height: '100%',
  sx: {
    display: 'flex',
    alignItems: 'center',
  },
};

export const operatingContextGridItemProps: GridItemProps = {
  colSpan: { base: 2, lg: 2 },
  textAlign: 'center',
  sx: {
    // Accordion.ts in theme sets the marginRight
    'svg:first-of-type:not(.chakra-accordion__icon)': {
      marginRight: '0px !important',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
