import { Box, Flex, Icon } from '@chakra-ui/react';
import {
  type GroupBase,
  type OptionProps,
  type Props as ReactSelectProps,
} from 'chakra-react-select';
import { FormattedMessage, useIntl } from 'react-intl';

import { Autocomplete as BrChakraAutocomplete } from '@burnsred/ui-chakra';
import { icons } from 'assets/icons';

import { checkBoxSx, optionSx, optionTextSx } from './Autocomplete.styles';

export type OptionType = Record<string, unknown>;

export type AutocompleteProps<
  Option extends OptionType,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = Omit<ReactSelectProps<Option, IsMulti, Group>, 'selectedOptionStyle'> & {
  /**
   * The target element to render the menu portal to. If not provided, the menu
   * will be rendered to the document body.
   *
   * Note that this won't solve issues with rendering in a modal, as the
   * Autocomplete is rendered before the target Element is available in the DOM.
   * This issue is resolved with the z-index we set in styles passed to the
   * menuPortal component:
   *
   * > ...there is one key that is available in the styles prop that does not exist
   * > in the chakraStyles object; menuPortal. This key applies to the
   * > MenuPortal element which is only used when the menuPortalTarget prop is
   * > passed in. This component is replaceable, however, it is very tightly
   * > integrated with the menu placement logic (and a context provider) so it
   * > appears to be impossible to fully replace it with a chakra component
   * >
   * > @see https://github.com/csandman/chakra-react-select?tab=readme-ov-file#caveats
   * > @see https://react-select.com/advanced#portaling
   */
  menuPortalTarget?: Element | null;
  menuPosition?: 'fixed' | 'absolute';
  /**
   * Useful for debugging rendering of the dropdown options,
   * which disappear when the the document lose focus.
   */
  menuIsOpen?: boolean;

  selectedOptionStyle?: 'color' | 'check' | 'checkbox';
};

/**
 * Provides a react-select Autocomplete widget with Chakra fields
 *
 * Extends @burnsred/ui-chakra#Autocomplete with i18n messages for placeholder, noOptionsMessage.
 *
 * - https://github.com/csandman/chakra-react-select
 * - https://react-select.com
 */
export function Autocomplete<
  Option extends OptionType,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  menuPortalTarget,
  placeholder,
  noOptionsMessage,
  selectedOptionStyle = 'checkbox',
  ...props
}: AutocompleteProps<Option, IsMulti, Group>) {
  const { formatMessage } = useIntl();
  const isMultiComponents =
    props.isMulti && selectedOptionStyle === 'checkbox'
      ? {
          Option: CheckboxOption<Option, IsMulti, Group>,
        }
      : {};

  return (
    <BrChakraAutocomplete
      menuPortalTarget={menuPortalTarget ?? document.body}
      styles={{
        // Ensure that the menu is rendered above a modal
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 'var(--chakra-zIndices-popover)',
        }),
      }}
      components={{ ...isMultiComponents, ...props.components }}
      placeholder={
        placeholder ?? (
          <FormattedMessage id="ui.select" defaultMessage="Select..." />
        )
      }
      noOptionsMessage={
        noOptionsMessage ??
        (() =>
          formatMessage({ id: 'ui.no-options', defaultMessage: 'No Options' }))
      }
      {...props}
    />
  );
}

/**
 * A custom Option component that renders a checkbox next to the option to indicate selectability.
 * This is used specifically for multi-select Autocomplete widgets.
 * @see https://react-select.com/components#option-component
 */
export const CheckboxOption = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>({
  children,
  isSelected,
  innerRef,
  innerProps,
}: OptionProps<Option, IsMulti, Group>) => (
  <Box
    ref={innerRef}
    {...innerProps}
    sx={{
      ...optionSx,
    }}
  >
    <Flex>
      <Icon
        sx={checkBoxSx}
        as={
          isSelected
            ? icons.MdOutlineCheckBox
            : icons.MdOutlineCheckBoxOutlineBlank
        }
      />
      <Flex sx={optionTextSx} ml={1}>
        {children}
      </Flex>
    </Flex>
  </Box>
);
