import {
  Button,
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { Form, Input, type InputProps } from '@burnsred/entity-form';
import { ThreeWaySwitch, VStack } from 'components';
import { AttachmentModal } from 'components/AttachmentModal';
import { FileItemRow } from 'components/FilePicker';
import { Switch } from 'components/Switch';
import { Textarea } from 'components/widgets';
import { getAttachmentUrl } from 'entities/api/BaseAttachment.ts';
import { type BaselineAssessmentEntityRecord } from 'entities/api/CompliancePlan/BaselineAssessment.ts';
import ImplementationAttachmentEntity from 'entities/api/CompliancePlan/ImplementationAttachment.ts';

import ExemptionReasonWidget from './ExemptionReasonWidget/ExemptionReasonWidget.tsx';
import { useImplementationAnswerForm } from './ImplementationAnswerForm.hooks.ts';
import {
  buttonProps,
  commentWrapperSx,
  gridProps,
  modalBodySx,
  modalButtonProps,
  modalHeaderSx,
  questionTextSx,
  topRowSx,
  wrapperSx,
} from './ImplementationAnswerForm.styles';

export type ImplementationAnswerFormFormProps =
  InputProps<BaselineAssessmentEntityRecord> & {
    value: BaselineAssessmentEntityRecord;
  };

export function ImplementationAnswerForm(
  props: ImplementationAnswerFormFormProps,
) {
  const { value } = props;

  const {
    responseProps,
    isCommentsVisible,
    fileList,
    setFileList,
    batchUploadFilerFileList,
    isOpenFileModal,
    onCloseFileModal,
    onOpenFileModal,
    handleImplementationAttachmentChange,
    removeImplementationAttachment,
    isOpenWarningModal,
    onCloseWarningModal,
    confirmChange,
  } = useImplementationAnswerForm(props);

  const isMandatory = value.get('is_mandatory');

  const determineMandatoryTextColor = () => {
    const isImplemented = value.get('is_control_being_implemented');
    const isMandatory = value.get('is_mandatory');
    if (isImplemented === null) return 'black';
    return isMandatory && !isImplemented ? 'red.500' : 'black';
  };

  return (
    <Form {...props}>
      <VStack sx={wrapperSx} className="AnswerForm">
        <Flex sx={topRowSx}>
          <Text
            as="label"
            sx={{
              color: determineMandatoryTextColor(),
              ...questionTextSx,
            }}
          >
            {isMandatory ? (
              <FormattedMessage
                id="compliance.baseline-assessment.implementation-answer-form.mandatory-question"
                defaultMessage="Request that this MANDATORY control not be implemented."
              />
            ) : (
              <FormattedMessage
                id="compliance.baseline-assessment.implementation-answer-form.assess-question"
                defaultMessage="Do you plan to implement this assess control?"
              />
            )}
          </Text>

          <Input
            name="is_control_being_implemented"
            // TODO style Switch to be red if mandatory and true
            component={isMandatory ? Switch : ThreeWaySwitch}
            componentProps={responseProps}
          />
        </Flex>

        <Modal isOpen={isOpenWarningModal} onClose={onCloseWarningModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader sx={modalHeaderSx}>
              {value.get('is_mandatory') ? (
                <FormattedMessage
                  id="compliance.baseline-assessment.implementation-answer-not-required-modal.title"
                  defaultMessage={
                    'Request to not implement a MANDATORY control'
                  }
                />
              ) : (
                <FormattedMessage
                  id="compliance.baseline-assessment.implementation-answer-not-required-modal-assess.title"
                  defaultMessage={'Not implementing this assess control'}
                />
              )}
            </ModalHeader>
            <ModalBody sx={modalBodySx}>
              {value.get('is_mandatory') ? (
                <FormattedMessage
                  id="compliance.baseline-assessment.implementation-answer-not-required-modal.body"
                  defaultMessage={`You are requesting this <strong>Mandatory Control</strong> to
                    be marked as  <strong>"Not to Be Implemented"</strong>. If a <strong>gap exists</strong>,
                    all associated <strong>gap data</strong> will be removed.`}
                  values={{
                    strong: (chunks) => <strong>{chunks}</strong>,
                  }}
                />
              ) : (
                <FormattedMessage
                  id="compliance.baseline-assessment.implementation-answer-not-required-modal-assess.body"
                  defaultMessage={`You are setting this assess control to <strong>"Not to Be Implemented"</strong>. If a <strong>gap exists</strong>, all associated <strong>gap data</strong> will be removed.`}
                  values={{
                    strong: (chunks) => <strong>{chunks}</strong>,
                  }}
                />
              )}
            </ModalBody>
            <ModalFooter justifyContent={'space-between'} gap={2}>
              <Button
                {...modalButtonProps}
                variant="whiteOutline"
                onClick={onCloseWarningModal}
              >
                <FormattedMessage id="ui.cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                {...modalButtonProps}
                variant="primary"
                onClick={confirmChange}
              >
                <FormattedMessage id="ui.confirm" defaultMessage="Confirm" />
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {isCommentsVisible && (
          <VStack sx={commentWrapperSx}>
            {/* Super slow input delay debounced version? Do some performance analysis since we are so deeply nested */}
            {isMandatory && (
              <Input
                name="exemption_reason"
                component={ExemptionReasonWidget}
              />
            )}
            <Grid {...gridProps}>
              <Input
                name="non_implementation_reason"
                component={Textarea}
                componentProps={{
                  sx: { maxWidth: '700px' },
                  isRequired: true,
                  label: isMandatory ? (
                    <FormattedMessage
                      id="compliance.baseline-assessment.implementation-answer-form.mandatory-question.comment"
                      defaultMessage="Provide detailed reasons for not implementing this MANDATORY control"
                    />
                  ) : (
                    <FormattedMessage
                      id="compliance.baseline-assessment.implementation-answer-form.assess-question.comment"
                      defaultMessage="Add a comment"
                    />
                  ),
                  variant: 'reset',
                }}
              />
              <GridItem>
                <Button {...buttonProps} onClick={onOpenFileModal}>
                  <FormattedMessage
                    id="compliance.baseline-assessment.implementation.attach-media"
                    defaultMessage="Attach media"
                  />
                </Button>
              </GridItem>
            </Grid>
            <Grid {...gridProps}>
              <VStack gap={0}>
                {value
                  ?.get('attachments')
                  ?.map((attachment, attachmentIndex: number) => (
                    <FileItemRow
                      key={attachmentIndex}
                      canDelete
                      onDelete={() => {
                        removeImplementationAttachment(attachmentIndex);
                      }}
                      name={
                        (attachment?.getIn(['attachment', 'name']) as string) ||
                        'unnamed'
                      }
                      url={getAttachmentUrl(attachment)}
                    />
                  ))}
              </VStack>
            </Grid>
          </VStack>
        )}
      </VStack>
      <AttachmentModal
        isOpen={isOpenFileModal}
        onOpen={onOpenFileModal}
        onClose={onCloseFileModal}
        // @ts-expect-error  TS2322: Type (new_attachments: GapAttachmentEntityRecord) => void is not assignable
        onConfirm={handleImplementationAttachmentChange}
        attachmentEntity={ImplementationAttachmentEntity}
        fileList={fileList}
        setFileList={setFileList}
        batchUploadFilerFileList={batchUploadFilerFileList}
        maxFiles={10}
      />
    </Form>
  );
}
