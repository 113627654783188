import { useToast } from '@chakra-ui/react';
import { List, Map } from 'immutable';
import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { useQuery } from '@burnsred/entity-duck-query';
import type {
  EquipmentEntityFields,
  EquipmentEntityRecord,
} from 'entities/api/Equipment';
import EquipmentEntity from 'entities/api/Equipment';
import type { GlobalFrameworkEntityRecord } from 'entities/api/GlobalFramework';
import GlobalFrameworkEntity from 'entities/api/GlobalFramework';
import { useLocale } from 'locales/useLocale';
import type { EntityDuckAction } from 'types';
import { createLogger } from 'util/createLogger';

import type { NewEquipmentModalProps } from './NewEquipmentModal';

const log = createLogger('useNewEquipmentModal');

export const useNewEquipmentModal = (props: NewEquipmentModalProps) => {
  const toast = useToast();
  const { formatMessage } = useIntl();

  const { toString } = useLocale();
  const dispatch = useDispatch();
  const {
    name,
    onChange,
    onSubmit: _onSubmit,
    value,
    disclosure: { onClose },
    compliancePlan,
    equipmentPickerBaseParams,
    errors,
    refreshTieredEquipmentPicker,
  } = props;

  const [parentSearchTerm, setParentSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { value: selectOptions } = useQuery<List<EquipmentEntityRecord>>({
    action: EquipmentEntity.duck.actions.get({
      params: equipmentPickerBaseParams
        .set('search', parentSearchTerm)
        .set('is_attachable', 'true'),
    }),
  });

  const { value: globalFrameworks } = useQuery<
    List<GlobalFrameworkEntityRecord>
  >({
    action: GlobalFrameworkEntity.duck.actions.get({
      params: Map({ risk: compliancePlan?.get('risk')?.get('uuid') }),
    }),
  });

  const getOptionLabel = useCallback(
    (option: EquipmentEntityRecord) => toString(option),
    [toString],
  );

  const getOptionValue = useCallback((option: EquipmentEntityRecord) => {
    return option?.get('uuid');
  }, []);

  const selectValue = useMemo(
    () =>
      selectOptions?.find((o) => o?.get('uuid') === value?.get('parent')) ??
      null,
    [selectOptions, value],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onInputChange = useCallback(debounce(setParentSearchTerm, 200), []);

  const onChangeParent = useCallback(
    (rec: EquipmentEntityRecord) => {
      onChange({
        target: {
          name,
          value: EquipmentEntity.clean(value?.set('parent', rec?.get('uuid'))),
        },
      });
    },
    [name, onChange, value],
  );

  const onSubmit = useCallback(async () => {
    setIsLoading(true); // Start tracking loading state
    const parent = selectOptions?.find(
      (o) => o?.get('uuid') === value?.get('parent'),
    );
    // fallback to 0: fail closed
    const parentLevel = parent?.get('level') ?? 0;

    /**
     * Only allow creation of "level 3 and 4 equipment"
     * where the Global Framework Risk equipment level is 0, eg:
     *
     * 0: Vehicles & Mobile Equipment ❌
     *   1: Passenger ✅ new child should be attachable
     *     2: Light Vehicle ✅ new child should be attachable
     *       3: SUV ❌
     */
    const shouldBeAttachable = parentLevel == 1 || parentLevel == 2;
    const response = _onSubmit({
      target: { name, value: value?.set('is_attachable', shouldBeAttachable) },
    });

    const action = (await response.promise) as {
      name: string;
      payload: EquipmentEntityFields;
    };

    const createdItem: EquipmentEntityRecord = EquipmentEntity.dataToRecord(
      action?.payload,
    );

    if (action?.name === 'save_resolved') {
      // add the new equipment to the global framework
      let framework = globalFrameworks?.first();
      framework = framework?.set(
        'equipment',
        List([...(framework?.get('equipment') ?? List([])), createdItem]),
      );
      const frameworkResponse = dispatch(
        GlobalFrameworkEntity.duck.actions.save(framework),
      );
      const promise = await frameworkResponse.promise;
      if (promise.name === 'save_resolved') {
        refreshTieredEquipmentPicker();
        onClose();
      }
      toast({
        title: formatMessage({
          id: 'toast.savedEquipment',
          defaultMessage: 'Equipment saved',
        }),
        status: 'success',
      });
    } else {
      toast({
        title: formatMessage({
          id: 'toast.errorNewEquipment',
          defaultMessage: 'Error saving new equipment',
        }),
        status: 'error',
      });
    }
    setIsLoading(false); // End tracking loading state
    return response as unknown as EntityDuckAction;
  }, [
    selectOptions,
    value,
    formatMessage,
    _onSubmit,
    name,
    onClose,
    toast,
    globalFrameworks,
    dispatch,
    refreshTieredEquipmentPicker,
  ]);

  log('%o', { props, parent: props.value.get('parent') });

  return {
    isLoading,
    onChangeParent,
    onSubmit,
    selectOptions,
    onInputChange,
    getOptionValue,
    getOptionLabel,
    selectValue,
    errors,
  };
};
