import { Outlet, useOutletContext, useParams } from 'react-router';

import { withQuery } from '@burnsred/entity-duck-query';
import { Loading } from '@burnsred/ui-chakra';
import SiteCompliancePlanEntity, {
  type SiteCompliancePlanEntityRecord,
} from 'entities/api/CompliancePlan/SiteCompliancePlan';
import type { CompliancePlanContext } from 'screens/compliance/CompliancePlan';
import type { WithQueryReturnProps } from 'types';
import { createLogger } from 'util/createLogger';

const log = createLogger('SiteCompliancePlanContext');

type SiteCompliancePlanContextProps =
  WithQueryReturnProps<SiteCompliancePlanEntityRecord>;

export type SiteCompliancePlanContext = {
  // WORKAROUND clarify naming of formProps without messing with the Operating Environment tab
  compliancePlanFormProps: CompliancePlanContext['compliancePlanFormProps'];
  compliancePlanExtraProps: Omit<
    CompliancePlanContext,
    'compliancePlanFormProps'
  >;
  siteCompliancePlanFormProps: SiteCompliancePlanContextProps;
};

const SiteCompliancePlanContext = (
  siteCompliancePlanFormProps: SiteCompliancePlanContextProps,
) => {
  const { compliancePlanFormProps, ...compliancePlanExtraProps } =
    useOutletContext<CompliancePlanContext>();

  const context: SiteCompliancePlanContext = {
    compliancePlanFormProps,
    compliancePlanExtraProps,
    siteCompliancePlanFormProps,
  };
  log('%o', context);

  return <Outlet context={context} />;
};

export default withQuery(() => {
  const { siteCompliancePlanUuid: id } = useParams();

  return {
    action: SiteCompliancePlanEntity.duck.actions.get({ id }),
    processingComponent: Loading,
  };
})(SiteCompliancePlanContext);
