import {
  type FlexProps,
  type StackProps,
  type SystemStyleObject,
} from '@chakra-ui/react';

export const coverageBarStackContainerProps: StackProps = {
  paddingX: 'gutter',
  gap: 2,
};

export const headingFlexProps: StackProps = {
  justifyContent: 'space-between',
  width: '100%',
};

const baseBarStyle: SystemStyleObject = {
  display: 'inline-block',
};

export const completedBarStyle: SystemStyleObject = {
  ...baseBarStyle,
  backgroundColor: '#39b54a',
};

export const missingBarStyle: SystemStyleObject = {
  ...baseBarStyle,
  backgroundColor: 'gray.200',
};

export const helperTextFlexProps: FlexProps = {
  width: '100%',
  justifyContent: 'flex-start',
  sx: {
    '> p': {
      fontSize: 'xs',
      color: 'gray.500',
    },
  },
};
