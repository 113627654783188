import { Checkbox, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import type { List } from 'immutable';
import { useCallback } from 'react';
import { MdOutlineCheck } from 'react-icons/md';

import { type ScenarioEntityRecord } from 'entities/api/Scenario.ts';
import { useLocale } from 'locales/useLocale.tsx';

import {
  ScenarioIntersectGridItemProps,
  ScenarioRowGridSx,
} from './ScenarioRow.style.tsx';
import type { equipmentScenarioDataType } from '../../../ScenarioTableBody/ScenarioTableBody.hooks.tsx';
import { type UseCompliancePlanScenarioReturn } from '../../OperationAccordion.hooks.tsx';

type ScenarioRowProps = {
  scenario: ScenarioEntityRecord;
  onChangeValue: UseCompliancePlanScenarioReturn['onChangeValue'];
  planScenarios: List<ScenarioEntityRecord>;
  equipmentIntersections: boolean[];
  columnMapping: Record<number, string>;
  equipmentScenarioData: equipmentScenarioDataType | null;
};

export const ScenarioRow = (props: ScenarioRowProps) => {
  const { toString } = useLocale();

  const scenarioExistsInPlan = (scenario: ScenarioEntityRecord) => {
    return props.planScenarios.some(
      (planScenario) => planScenario.get('uuid') === scenario.get('uuid'),
    );
  };

  const isChecked = scenarioExistsInPlan(props.scenario);

  const checkVehicleClassInPlan = (index: number) => {
    if (!props.columnMapping) return;
    const equipmentClassUuid = props.columnMapping[index];
    const scenariosForEquipment =
      props.equipmentScenarioData &&
      props.equipmentScenarioData[equipmentClassUuid];

    return scenariosForEquipment
      ? scenariosForEquipment.some(
          (scenario) => scenario === props.scenario.get('uuid'),
        )
      : false;
  };

  const gridItemLength = useCallback(() => {
    if (!props.columnMapping) return 0;
    return Object.keys(props.columnMapping).length;
  }, [props.columnMapping]);

  return (
    <Grid sx={ScenarioRowGridSx}>
      <GridItem colSpan={{ base: 2, lg: 2 }}>
        <Flex>
          <Checkbox
            value={props.scenario.get('uuid')}
            onChange={props.onChangeValue}
            isChecked={isChecked}
          >
            <Text>{toString(props.scenario)}</Text>
          </Checkbox>
        </Flex>
      </GridItem>
      {Array.from({ length: gridItemLength() }).map((_, index) => {
        const scenarioIsApplicable = checkVehicleClassInPlan(index);
        const cellGreen = scenarioIsApplicable && isChecked;

        return (
          <GridItem
            key={index}
            sx={{
              backgroundColor: cellGreen ? '#39b54a' : 'gray.100',
            }}
            {...ScenarioIntersectGridItemProps}
          >
            {scenarioIsApplicable && (
              <MdOutlineCheck
                color={cellGreen ? 'white' : '#39b54a'}
                size="24px"
              />
            )}
          </GridItem>
        );
      })}
    </Grid>
  );
};
