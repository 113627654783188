import { Checkbox, Flex, Icon } from '@chakra-ui/react';

import { noop } from '@burnsred/ui-chakra';
import { icons } from 'assets/icons';
import type { TreePickerRecordProps } from 'components/widgets/TreePicker/DefaultComponents';
import { cx } from 'util/cx';

export const Record = ({
  children,
  item,
  hasChildren,
  isOpen,
  isSelected,
  level: _l,
  disabled,
  isIndeterminate,
  count,
  extraProps: _extraProps,
  className,
  ...rest
}: TreePickerRecordProps) => {
  return (
    <Flex {...rest} className={cx('EquipmentPicker__Record', className)}>
      <Checkbox
        variant="none"
        value={item.get('uuid') as string}
        isChecked={isSelected}
        isIndeterminate={isIndeterminate}
        onChange={noop}
        isDisabled={disabled}
      >
        <span className="TreePicker__Node__label">{children}</span>

        {count && <span className="TreePicker__Node__count">{count}</span>}

        {hasChildren && (
          <Icon
            as={icons.MdOutlineChevronRight}
            className={[
              'TreePicker__Node__expanded-icon',
              isOpen ? 'is-open' : '',
            ].join(' ')}
          />
        )}
      </Checkbox>
    </Flex>
  );
};
