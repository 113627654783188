import type { SystemStyleObject } from '@chakra-ui/react';

export const statsSx: SystemStyleObject = {
  '&.chakra-stat__group': {
    justifyContent: 'flex-start',
  },

  '.chakra-stat': {
    flexGrow: 0,
    paddingX: 6,
    borderRight: '1px',
    borderColor: 'gray.400',

    _first: {
      paddingLeft: 0,
    },
  },

  dl: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minWidth: 24,
    minHeight: 24,
  },

  dt: {
    color: 'muted',
  },

  dd: {
    fontSize: '3xl',
    fontWeight: 'medium',
  },
};
