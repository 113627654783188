import type {
  ButtonProps,
  GridProps,
  SystemStyleObject,
} from '@chakra-ui/react';

export const gapFormWrapperSx: SystemStyleObject = {
  paddingBottom: 3,
  borderBottom: '1px',
  borderColor: 'gray.200',
};

export const gridProps: GridProps = {
  gridTemplateColumns: '6fr 2fr 2fr',
  gap: 6,
};

export const identifierFieldSx: SystemStyleObject = {
  width: 'min-content',
  marginLeft: 'auto',
};

export const buttonProps: ButtonProps = {
  variant: 'whiteOutline',
  size: 'sm',

  sx: {
    width: '50%',
    // attempt to match the label height of neighbouring field labels
    marginTop: '29px',
  },
};

export const modalButtonProps: ButtonProps = {
  sx: {
    minWidth: 24,
  },
};
